import React from 'react';
import cx from 'classnames';
import { Section } from 'shared/SectionBlocks';
import { Field, Fieldset } from 'shared/Fieldset';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import styles from 'components/publisher/settings/SettingsEditor/settings-editor.module.css';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { NotificationFields } from './sections/Campaign/NotificationFields';

const useMessageSettings = () => {
  const {
    options: { defaultNotificationText },
    settings: { notifDeliveryTimesEnabled },
    fields: { notifications },
  } = useSettings();

  const emailOnly = !!useFeatureFlag('emailOnly');
  const messageLabel = 'Email subject and push notification';
  const label = emailOnly ? 'Email subject' : messageLabel;
  const channelText = emailOnly
    ? ' the email subject line '
    : ' the email subject line and/or the push notif text ';

  return {
    defaultNotificationText,
    notifications,
    notifDeliveryTimesEnabled,
    label,
    channelText,
  };
};

export const MessageSettings: React.FC<{ disabled: boolean }> = ({
  disabled,
}) => {
  const {
    defaultNotificationText,
    notifications,
    notifDeliveryTimesEnabled,
    label,
    channelText,
  } = useMessageSettings();

  return (
    <Section
      title="Messaging"
      className={cx({
        [styles.disabledSetting]: disabled,
      })}
    >
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field
            label={label}
            help="Messaging text will be sent in the order configured."
            tooltip={
              <div className="tooltip-content">
                <div>
                  Note: To leverage first or last name personalization
                  variables, type {'{{'} into the messaging box and select the
                  variable you want.
                </div>
                <div>
                  Custom text added here may be used for {channelText}
                  for your campaign. If you do not write anything here, then the
                  title of your content will be used.
                </div>
                <div>
                  Messages will only be sent if a user does not engage with the
                  campaign. If the engine can send more retargeting attempts
                  within the selected duration, the last configured message text
                  will be used for those attempts.
                </div>
              </div>
            }
          >
            <NotificationFields
              notifDeliveryTimesEnabled={notifDeliveryTimesEnabled}
              onChange={notifications.set}
              notifications={notifications?.value || []}
              disabled={disabled}
              placeholder={defaultNotificationText}
            />
          </Field>
        </div>
      </Fieldset>
    </Section>
  );
};
