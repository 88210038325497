import React from 'react';
import { Section } from 'shared/SectionBlocks';
import cx from 'classnames';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { TargetSelect } from './TargetSelect/TargetSelect';
import styles from '../settings-editor.module.css';

export const Delivery: React.FC = () => {
  const {
    settings: { audiences, contentTopics },
    fields: {
      audiences: { set: setAudiences },
      contentTopics: { set: setTopics },
    },
  } = useSettings();

  return (
    <Section title="Target">
      <div className={cx(styles.fieldset, styles.topicSelect)}>
        <TargetSelect
          selectedAudiences={audiences}
          onAudienceChange={setAudiences}
          selectedTopics={contentTopics}
          onTopicsChange={setTopics}
        />
      </div>
    </Section>
  );
};
