import { useTopicAboutPageQuery } from 'hooks/topics';
import { AboutPage } from 'models/about-page';
import { Topic } from 'models/topic';
import React, { createContext, FC, useMemo } from 'react';

export type AboutDesignContextType = {
  aboutPage?: AboutPage & { isEmpty: boolean };
};

export const AboutDesignContext = createContext<AboutDesignContextType>({});

type AboutDesignContextProviderProps = {
  topic: Topic;
  programId: number;
};

export const AboutDesignContextProvider: FC<AboutDesignContextProviderProps> = ({
  topic,
  programId,
  children,
}) => {
  const { data: aboutPage, isEmpty } = useTopicAboutPageQuery(
    programId,
    topic.id
  );

  const ctx = useMemo(
    () => ({
      aboutPage: aboutPage ? { ...aboutPage, isEmpty } : undefined,
    }),
    [aboutPage, isEmpty]
  );

  return (
    <AboutDesignContext.Provider value={ctx}>
      {children}
    </AboutDesignContext.Provider>
  );
};
