import React from 'react';
import { Link } from '@reach/router';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useTemplate } from 'contexts/template';
import { NavigationFooter } from 'shared/NavigationFooter';
import { Footer } from 'App/Program/Editors/Publisher/Footer';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { SplitFooter } from 'App/Program/Editors/Publisher/Footer/SplitFooter';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { titleCase } from 'utility/text';

export const TemplateFooter: React.FC<{
  nextPage?: string;
}> = ({ nextPage }) => {
  const { id: programId } = useProgram();
  const { template, save } = useTemplate();
  const usingNewPublisher = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  ).data?.value;
  const usingNewDelivery = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  ).data?.value;
  const {
    permissions: { manageTemplateAccess },
  } = usePermissions();
  const hideSubmit = !manageTemplateAccess;

  const exitPath = `/${programId}`;

  const fullPath = React.useCallback(
    (p: string) => {
      return `/${programId}/edit/template/${template.id}/${p}`;
    },
    [programId, template.id]
  );

  const menuItems = {
    section1: [
      {
        element: <Link to={exitPath}>Close</Link>,
      },
    ],
  };

  const title = React.useMemo(() => template.title || 'Untitled', [template]);
  const actionName = template.id === 'new' ? 'Save' : 'Update';
  const { PAGES } = useProductVocabulary();

  const links = React.useMemo(
    () => [
      {
        name: titleCase(PAGES.PUBLISHER.COMPOSE),
        href: fullPath('compose'),
      },
      {
        name: titleCase(PAGES.PUBLISHER.ORCHESTRATE),
        href: fullPath('deliver'),
      },
      {
        name: titleCase(PAGES.PUBLISHER.TEMPLATE),
        href: fullPath('template'),
      },
    ],
    [
      PAGES.PUBLISHER.COMPOSE,
      PAGES.PUBLISHER.ORCHESTRATE,
      PAGES.PUBLISHER.TEMPLATE,
      fullPath,
    ]
  );

  // This could be better:
  // Next Page should reference a path fragment, not a label in a link.
  //
  // This hook is responsible for creating the list of links.
  // The components that use this tell it where to go next with a prop,
  // even though those are not responsible for creating the list itself.
  //
  // For now, this tries to adjust the next page based on the vocabulary.
  let nextPageLabel = nextPage;
  if (nextPage === 'compose') nextPageLabel = PAGES.PUBLISHER.COMPOSE;
  if (nextPage === 'orchestrate') nextPageLabel = PAGES.PUBLISHER.ORCHESTRATE;
  if (nextPage === 'template') nextPageLabel = PAGES.PUBLISHER.TEMPLATE;

  if (usingNewPublisher) {
    return (
      <SplitFooter
        createType="template"
        title={title}
        nextPage={nextPageLabel}
        links={links}
        actionName={actionName}
        action={save}
        exitPath={exitPath}
        leftMenuItems={menuItems}
        canPerformAction
        hideSubmit={hideSubmit}
        disableSchedule
      />
    );
  }

  if (usingNewDelivery) {
    return (
      <Footer
        title={title}
        nextPage={nextPageLabel}
        links={links}
        actionName={actionName}
        action={save}
        exitPath={exitPath}
        leftMenuItems={menuItems}
        canPerformAction
        hideSubmit={hideSubmit}
        hideSchedule
      />
    );
  }

  return (
    <NavigationFooter
      title={title}
      nextPage={nextPageLabel}
      links={links}
      actionName={actionName}
      action={save}
      exitPath={exitPath}
      leftMenuItems={menuItems}
      canPerformAction
      hideSubmit={hideSubmit}
    />
  );
};
