import * as React from 'react';
import * as Type from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { titleCase } from 'utility/text';
import { useDesignContext } from 'contexts/design';
import { TextInput } from 'shared/TextInput';

export const TitleBar: React.FC<{
  canEdit: boolean;
}> = ({ canEdit }) => {
  const { design, update } = useDesignContext();
  const updateTitle = React.useCallback(
    (name: string) => {
      update({ ...design, name });
    },
    [design, update]
  );
  return (
    <Flex>
      <Type.Heading color={Type.color.gray40}>
        {titleCase(`${design.id === 'new' ? 'create' : 'edit'} design`)}/
      </Type.Heading>
      <Box padding={[0, 8]}>
        <PlainTitle
          updateTitle={updateTitle}
          title={design.name ?? ''}
          canEdit={canEdit}
        />
      </Box>
    </Flex>
  );
};

const PlainTitle: React.FC<{
  canEdit: boolean;
  title: string;
  updateTitle: (title: string) => void;
}> = ({ canEdit, title, updateTitle }) => {
  return !canEdit ? (
    <Type.Heading bold color={Type.color.gray90}>
      {title}
    </Type.Heading>
  ) : (
    <TextInput value={title} onChange={updateTitle} />
  );
};
