import React, { useMemo } from 'react';
import { useShortcutQuery } from 'hooks/shortcuts';
import { RouteComponentProps } from '@reach/router';
import { useProgramQuery } from 'hooks/program';
import { useProgram } from 'contexts/program';
import { Section } from 'DesignSystem/Form';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { TLinkItem } from 'models/shortcut';
import { includes } from 'utility/objectUtils';
import { Spinner } from '../../Shared/Spinner';
import styles from '../shortcuts.module.css';
import createStyles from '../create/create.module.css';
import { DeleteShortcutButton } from '../components/DeleteShortcutButton';
import { ShortcutTopicsAndAudiencesDisplay } from '../components/ShortcutTopicsAndAudiencesDisplay';
import { EDITABLE_SHORTCUT_TYPES } from '../utils/constants';

export const Shortcut: React.FC<RouteComponentProps<{
  id: number;
}>> = ({ id }) => {
  const { id: programId } = useProgram();
  const { data: program } = useProgramQuery(programId);
  const shortcutId = Number(id);
  const { data: shortcutsData, isLoading, errorMessage } = useShortcutQuery({
    programId,
    shortcutId,
  });
  const isEditable = shortcutsData?.type === 'link';

  const isGeneralEditable = includes(
    EDITABLE_SHORTCUT_TYPES,
    shortcutsData?.type ?? ''
  );

  const { data: topicEnabledFlag } = useFeatureFlagsQuery(
    programId,
    'Experimental.TargetedChannelLinks'
  );

  const selectedTopics = useMemo(() => {
    if (shortcutsData?.type !== 'link') {
      return [];
    }
    return (
      shortcutsData?.configuration?.channelsData?.map((topic) => ({
        id: String(topic.id),
        name: topic.name,
        count: topic.followerCount,
      })) ?? []
    );
  }, [shortcutsData?.configuration, shortcutsData?.type]);

  const selectedAudiences = useMemo(
    () =>
      shortcutsData?.audienceList?.map((audience) => ({
        id: String(audience.id),
        name: audience.title,
        count: audience.totalUsers,
      })) ?? [],
    [shortcutsData?.audienceList]
  );

  return (
    <WithPermission permissions={['configureExperienceSettingsAccess']}>
      <FormPage
        breadcrumbs={[
          { to: '../..', label: 'Configure' },
          { to: '..', label: 'Shortcuts' },
          { isLoading, label: shortcutsData?.name ?? '' },
        ]}
        actionsOverride={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <DeleteShortcutButton
              id={shortcutId}
              title="Delete shortcut?"
              body="Are you sure you would like to delete this shortcut? This action cannot be undone."
            />
          </div>
        }
        title={shortcutsData?.name ?? ''}
        isLoading={isLoading}
      >
        {isLoading && <Spinner />}
        {!isLoading && !errorMessage && shortcutsData && (
          <>
            <Section
              title="General"
              href={
                isGeneralEditable
                  ? `/${program?.id}/configure/shortcuts/${shortcutId}/edit/General`
                  : ''
              }
              className={styles.section}
            >
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Shortcut Enabled</span>
                <span className={styles.itemValue}>
                  {shortcutsData?.status === 'active' ? 'true' : 'false'}
                </span>
              </div>
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Name</span>
                <span className={styles.itemValue}>{shortcutsData?.name}</span>
              </div>
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Description</span>
                <span className={styles.itemValue}>
                  {shortcutsData?.description}
                </span>
              </div>
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Icon</span>
                <span className={styles.itemValue}>
                  <img
                    className={createStyles.imagePreview}
                    src={
                      shortcutsData?.imageUrl || shortcutsData?.service?.iconUrl
                    }
                    alt="shortcut icon"
                  />
                </span>
              </div>
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Header</span>
                <span className={styles.itemValue}>
                  <img
                    className={createStyles.headerImagePreview}
                    src={
                      shortcutsData?.bannerImageUrl ||
                      shortcutsData?.service?.bannerImageUrl
                    }
                    alt="shortcut header"
                  />
                </span>
              </div>
            </Section>
            {shortcutsData.type === 'link' &&
              shortcutsData?.configuration?.links?.length > 0 && (
                <Section
                  title="Links"
                  href={
                    isEditable
                      ? `/${program?.id}/configure/shortcuts/${shortcutId}/edit/Links`
                      : ''
                  }
                  className={styles.section}
                >
                  {shortcutsData?.configuration?.links?.map(
                    (link: TLinkItem) => (
                      <div className={styles.itemDesc} key={link.label}>
                        <span className={styles.itemTitle}>{link.label}</span>
                        <a
                          className={styles.itemValue}
                          href={link.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {link.url}
                        </a>
                      </div>
                    )
                  )}
                </Section>
              )}
            <Section
              title="Target"
              href={
                isEditable
                  ? `/${program?.id}/configure/shortcuts/${shortcutId}/edit/Target`
                  : ''
              }
              className={styles.section}
            >
              {topicEnabledFlag?.value ? (
                <div className={styles.itemDesc}>
                  <span className={styles.itemTitle}>Topic</span>
                  <ShortcutTopicsAndAudiencesDisplay
                    topicsAndAudiences={selectedTopics}
                  />
                </div>
              ) : null}
              <div className={styles.itemDesc}>
                <span className={styles.itemTitle}>Audience</span>
                <ShortcutTopicsAndAudiencesDisplay
                  topicsAndAudiences={selectedAudiences}
                />
              </div>
            </Section>
          </>
        )}
      </FormPage>
    </WithPermission>
  );
};
