import cx from 'classnames';
import React, { useCallback, useState } from 'react';

import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useContentDuplicator } from 'hooks/useContentDuplicator';
import { useFlashMessage } from 'contexts/flasher';
import { usePermissions } from 'contexts/permissions';
import { CampaignHistoryModal as AuditHistoryModal } from 'components/publisher/theme/CampaignHistoryModal/CampaignHistoryModal';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { MenuItem } from 'hooks/useFooter';
import { Post } from 'models/publisher/post';
import { Check, OpenPadLock, ClosedPadLock } from 'shared/icons';
import buttonStyles from 'shared/styles/buttons.module.css';

import { StateLink } from '../StateLink';
import { NotesModal as Notes } from '../NotesModal';
import styles from '../compose.module.css';

type ReturnType = {
  menuItems: { [sectionName: string]: Array<MenuItem> };
  isDuplicating: boolean;
  showNotesModal: boolean;
  NotesModal: () => JSX.Element;
  showCampaignHistoryModal: boolean;
  CampaignHistoryModal: () => JSX.Element | null;
};

export const useLeftMenuItems = (): ReturnType => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const {
    permissions: { setVisibility },
  } = usePermissions();
  const { id: publisherId, post, status, updateContent } = usePublisher();
  const [isCopyingStudioLink, setCopyingStudioLink] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const emailOnly = !!useFeatureFlag('emailOnly');

  const { duplicate, isDuplicating } = useContentDuplicator();

  const duplicateCampaign = React.useCallback(() => {
    duplicate({ post, programId });
  }, [duplicate, post, programId]);

  const toggleHideCampaign = React.useCallback(() => {
    updateContent({ isHidden: !post.content.isHidden }, () => {
      setFlashMessage({
        timeout: 5000,
        severity: 'info',
        message: !post.content.isHidden
          ? 'Campaign is now private.'
          : 'Campaign is now shared.',
      });
    });
  }, [updateContent, setFlashMessage, post.content.isHidden]);

  const canArchive = React.useCallback((p: Post) => {
    return p.content.id > 0 && p.content.publicationState !== 'archived';
  }, []);

  const canDelete = React.useCallback((p: Post) => {
    return p.content.id > 0 && p.content.publicationState !== 'erased';
  }, []);

  const [
    showCampaignHistoryModal,
    setShowCampaignHistoryModal,
  ] = React.useState(false);

  const copyStudioLink = React.useCallback(() => {
    const el = document.createElement('input');
    document.body.append(el);
    el.value = window.location.href;
    el.select();
    document.execCommand('copy');
    el.remove();
    setCopyingStudioLink(true);
    setTimeout(() => {
      setCopyingStudioLink(false);
    }, 3000);
  }, []);

  const menuItems = React.useMemo(() => {
    return {
      section1: [
        {
          element: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${post.content.permalinkUrl}`}
            >
              Open in Experience
            </a>
          ),
          visible: post.content.publicationState === 'published' && !emailOnly,
        },
        {
          element: isCopyingStudioLink ? (
            <div className={styles.linkCopied}>
              Copied! <Check />
            </div>
          ) : (
            <button
              className={cx(buttonStyles.transparent, buttonStyles.pointer)}
              type="button"
              onClick={copyStudioLink}
            >
              Copy Studio link
            </button>
          ),
          visible: publisherId !== 'new',
        },
        {
          name: 'Open notes',
          onClick: () => setShowNotesModal(true),
          visible: publisherId !== 'new',
        },
        {
          name: 'Duplicate',
          onClick: duplicateCampaign,
          disabled: status.isSaving,
          isWorking: status.isSaving,
        },
        {
          element: post.content.isHidden ? (
            <div className={styles.shareOptions}>
              <button
                className={cx(buttonStyles.transparent, buttonStyles.pointer)}
                type="button"
                onClick={toggleHideCampaign}
              >
                Change to shared draft
              </button>
              <ClosedPadLock />
            </div>
          ) : (
            <div className={styles.shareOptions}>
              <button
                className={cx(buttonStyles.transparent, buttonStyles.pointer)}
                type="button"
                onClick={toggleHideCampaign}
              >
                Change to private draft
              </button>
              <OpenPadLock />
            </div>
          ),
          disabled: status.isSaving,
          isWorking: status.isSaving,
          visible:
            ['draft', 'scheduled'].includes(post.content.publicationState) &&
            setVisibility,
        },
        {
          name: 'Campaign history',
          onClick: () => setShowCampaignHistoryModal(true),
          isMenuClosedOnClick: true,
          visible: publisherId !== 'new',
        },
      ],
      section2: [
        {
          element: (
            <StateLink
              action="archive"
              message="Campaign archived."
              label="Archive"
              showCondition={canArchive}
            />
          ),
        },
        {
          element: (
            <StateLink
              action="erase"
              message="Campaign deleted."
              label="Delete"
              showCondition={canDelete}
            />
          ),
        },
      ],
    };
  }, [
    status.isSaving,
    copyStudioLink,
    isCopyingStudioLink,
    post.content.permalinkUrl,
    canDelete,
    canArchive,
    post.content.publicationState,
    publisherId,
    duplicateCampaign,
    toggleHideCampaign,
    post.content.isHidden,
    setVisibility,
    emailOnly,
  ]);

  const NotesModal = useCallback(
    () => (
      <Notes
        postId={post.content.id}
        programId={programId}
        onClose={() => setShowNotesModal(false)}
      />
    ),
    [setShowNotesModal, programId, post.content.id]
  );

  const CampaignHistoryModal = useCallback(
    () =>
      showCampaignHistoryModal ? (
        <AuditHistoryModal onClose={() => setShowCampaignHistoryModal(false)} />
      ) : null,
    [setShowCampaignHistoryModal, showCampaignHistoryModal]
  );
  return {
    menuItems,
    isDuplicating,
    showNotesModal,
    NotesModal,
    showCampaignHistoryModal,
    CampaignHistoryModal,
  };
};
