import { useProgramIdState } from 'contexts/program';
import { useVideoUploader } from 'hooks/useVideoUploader';
import { useVideoValidator } from 'hooks/useVideoValidator';
import { VideoFieldData } from 'models/donkey';
import { videoToField } from 'models/publisher/block';
import {
  ExternalVideoStatus,
  Job,
  Video,
  VideoProcessingStatus,
  VideoProcessingStep,
} from 'models/video';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDesignContext } from 'contexts/design';
import { findOrCreateExternalVideo } from 'services/api-assets';
import { isUrlValid } from 'utility/text';
import { FieldFormProps } from '../../../useFieldForm';
import { useKeepOpen } from '../../shared/KeepOpen';
import { ExitBlocker } from '../components/ExitBlocker';
import { useFetchVideoFromFeed } from './useFetchVideoFromFeed';

export const useVideo: ({
  fieldData,
  onChange,
  autoplay,
}: {
  fieldData?: FieldFormProps<VideoFieldData>['data'];
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  autoplay?: boolean;
}) => {
  video?: Video;
  refetchVideo: ReturnType<typeof useFetchVideoFromFeed>['refetchVideo'];
  uploadProcessingStatus: VideoProcessingStatus;
  externalVideoStatus: ExternalVideoStatus;
  upload: (file: File) => void;
  setUrl: (url: string) => void;
  updateCaption: () => void;
  resetVideoStatus: (videoId?: number) => void;
  isLoadingVideoFromFeed: boolean;
  isReplacingJob: (jobs: Array<Job> | undefined) => boolean;
  isCaptionUpdated: boolean;
} = ({ fieldData, onChange, autoplay }) => {
  const [programId] = useProgramIdState();
  const { active: isDesignAsset } = useDesignContext();

  const {
    videoFromFeed,
    refetchVideo,
    isLoading: isLoadingVideoFromFeed,
  } = useFetchVideoFromFeed(fieldData?.video_id, isDesignAsset);

  const update = useCallback(
    (newVideo: Video) => {
      onChange(videoToField(newVideo));
    },
    [onChange]
  );

  // region UploadVideo
  const [processingStep, setProcessingStep] = useState<VideoProcessingStep>(
    VideoProcessingStep.Idle
  );

  const onTranscoding = useCallback(
    async (videoFromUploader) => {
      if (
        videoFromUploader.id === videoFromFeed?.id &&
        videoFromUploader.status === videoFromFeed?.status
      ) {
        return;
      }

      const latestVideo = await refetchVideo();

      update({
        ...latestVideo.data,
        ...videoFromUploader,
      });
    },
    [refetchVideo, update, videoFromFeed?.id, videoFromFeed?.status]
  );

  const isReplacingJob = (jobs: Array<Job> | undefined): boolean => {
    if (jobs === undefined) return false;

    const replaceJob = jobs.find(
      (job) =>
        job.currentStatus === 'uploading' &&
        job.executionAction === 'video replacement'
    );

    return !!replaceJob;
  };

  const onSuccess = useCallback(
    async (videoFromUploader) => {
      const isSameVideo =
        videoFromUploader.id === videoFromFeed?.id &&
        videoFromUploader.status === videoFromFeed?.status;

      const notReplacingVideo = !isReplacingJob(videoFromFeed?.jobs);

      if (isSameVideo && notReplacingVideo) {
        return;
      }

      const oldVideo = await refetchVideo();

      update({
        ...oldVideo.data,
        ...videoFromUploader,
      });
    },
    [
      refetchVideo,
      update,
      videoFromFeed?.id,
      videoFromFeed?.status,
      videoFromFeed?.jobs,
    ]
  );

  const {
    upload,
    replace,
    uploadedBytes,
    totalBytes,
    isUploading,
    isTranscoding,
    isCompleted,
    isErrored,
    transcodeJobPercentComplete,
    errorMessage,
    isCaptionUpdated,
    onCaptionUpdated,
    ensureVideoId,
  } = useVideoUploader({
    programId,
    isDesignAsset,
    videoId: videoFromFeed?.id,
    onTranscoding,
    onSuccess,
  });

  const { validate } = useVideoValidator();
  const [validationError, setValidationError] = useState<string | undefined>();

  const onUpload = (file: File) => {
    setValidationError(undefined);

    const { isValid, errors } = validate({ file });

    if (isValid) {
      if (videoFromFeed) {
        // if there is a current videoFromFeed then it is a video replacement
        replace(videoFromFeed.id, file);
      } else {
        // if there is a not current videoFromFeed present, then it is a video creation for first time
        upload(file);
      }

      return;
    }

    if (errors.length) {
      setValidationError(`${file.name}: ${errors.join(', ')}`);
    }
  };

  useEffect(() => {
    if (isUploading) {
      setProcessingStep(VideoProcessingStep.Uploading);
    } else if (isTranscoding) {
      setProcessingStep(VideoProcessingStep.Transcoding);
    } else if (isCompleted) {
      setProcessingStep(VideoProcessingStep.Complete);
    } else if (isErrored) {
      setProcessingStep(VideoProcessingStep.Error);
    }
  }, [isCompleted, isErrored, isTranscoding, isUploading]);

  useKeepOpen(ExitBlocker, isUploading);

  const uploadProcessingStatus = useMemo(() => {
    const errors = [];
    if (validationError) errors.push(validationError);
    if (errorMessage) errors.push(errorMessage);

    const status: VideoProcessingStatus = {
      step: processingStep,
      uploadPercentComplete:
        uploadedBytes && totalBytes
          ? Math.floor((uploadedBytes / totalBytes) * 100)
          : undefined,
      transcodePercentComplete: transcodeJobPercentComplete,
      errorMessage: errors.length ? errors.join(', ') : undefined,
    };
    return status;
  }, [
    errorMessage,
    processingStep,
    totalBytes,
    transcodeJobPercentComplete,
    uploadedBytes,
    validationError,
  ]);
  // endregion

  // region ExternalVideo
  const [url, setUrl] = useState<string>('');

  const { data: externalVideo, error, isLoading } = useQuery<Video, Error>(
    [programId, url, autoplay, isDesignAsset],
    () => findOrCreateExternalVideo(programId, url, autoplay, isDesignAsset),
    {
      enabled: isUrlValid(url),
      refetchOnWindowFocus: false,
      retry: false, // unnecessary retries delay error from being shown that may confuse users
    }
  );

  useEffect(() => {
    if (!externalVideo || !url) return;

    setUrl('');
    update({ ...externalVideo, sourceType: 'external' });
  }, [externalVideo, update, url, autoplay]);

  const externalVideoStatus = useMemo(() => {
    return { errorMessage: error?.message, isLoading };
  }, [error?.message, isLoading]);
  // endregion

  const resetVideoStatus = useCallback(
    (videoId) => {
      ensureVideoId(videoId);
      setProcessingStep(VideoProcessingStep.Idle);
    },
    [ensureVideoId]
  );

  return {
    video: videoFromFeed,
    refetchVideo,
    uploadProcessingStatus,
    externalVideoStatus,
    upload: onUpload,
    setUrl,
    updateCaption: onCaptionUpdated,
    resetVideoStatus,
    isReplacingJob,
    isLoadingVideoFromFeed,
    isCaptionUpdated,
  };
};
