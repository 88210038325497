import { Position } from 'reactflow';
import { reassignStepEdge } from 'App/Program/Main/Journey/JourneyCanvas/utils/graph';
import {
  AddableStepTypes,
  CommunicationStep,
  DEFAULT_EDGE_TARGET_ID,
  DecisionEdge,
  DecisionStep,
  DelayStep,
  JourneyGraph,
  Journey,
  Step,
  Steps,
  getDefaultStep,
} from 'models/journeys/journey';
import { JourneyErrors } from 'models/journeys/journey-errors';

const BLOCKED_MOVE_TYPES: Array<keyof Steps> = ['start', 'end', 'decision'];

export type UseJourneyStepProps = {
  currentJourney?: Journey;
  currentGraph?: JourneyGraph;
  setCurrentJourney: (journey: Journey) => void;
  errors?: JourneyErrors;
  setErrors: (errors?: JourneyErrors) => void;
};

export type JourneyStepsControls = {
  findStep: (id: string) => Step | undefined;
  moveStep: (id: string, direction: Position.Left | Position.Right) => void;
  deleteStep: (id: string) => void;
  updateStep: (step: Step) => void;
  deleteDecisionOption: (step: Step, edge: DecisionEdge) => void;
  insertDefaultStep: (
    type: AddableStepTypes,
    sourceId: string,
    targetId: string
  ) => void;
  canMoveStep: (
    id: string,
    direction: Position.Left | Position.Right
  ) => boolean;
};
export type UseJourneyStep = (
  props: UseJourneyStepProps
) => JourneyStepsControls;

export const useJourneySteps: UseJourneyStep = ({
  currentJourney,
  currentGraph,
  setCurrentJourney,
  errors,
  setErrors,
}): JourneyStepsControls => {
  const setDraftGraph = (graph: JourneyGraph) => {
    if (!currentJourney) return;
    setCurrentJourney({
      ...currentJourney,
      draftGraph: graph,
    });
  };

  const canMoveStep = (
    id: string,
    direction: Position.Left | Position.Right
  ) => {
    if (direction === Position.Left) {
      const prevStep = findPreviousStep(id);
      return !!prevStep && !BLOCKED_MOVE_TYPES.includes(prevStep.type);
    }

    const currentStep = findStep(id);
    if (!currentStep) return false;
    if (currentStep.next.length !== 1) return false;

    const nextStep = findStep(currentStep.next[0].targetId);
    return !!nextStep && !BLOCKED_MOVE_TYPES.includes(nextStep.type);
  };

  const updateStep = (step: Step) => {
    if (!currentGraph) return;
    const newGraph = {
      ...currentGraph,
      steps: currentGraph.steps.map((s) => (s.id === step.id ? step : s)),
    };

    setDraftGraph(newGraph);
  };

  const getAllChildrenIds = (step: Step): string[] => {
    const toDelete = step.next.flatMap((n) => {
      const sp = currentGraph?.steps.find((s) => s.id === n.targetId);
      if (!sp) return [];
      return getAllChildrenIds(sp);
    });

    return [step.id].concat(toDelete);
  };
  let rootStepId = currentGraph ? currentGraph.rootStepId : '';

  const findStep = (id: string) => currentGraph?.steps.find((s) => s.id === id);

  const findPreviousStep = (id: string) =>
    currentGraph?.steps.find((s) =>
      s.next.some((edge) => edge.targetId === id)
    );

  const moveLeft = (currentStep: Step) => {
    const previousStep = findPreviousStep(currentStep.id);
    if (!previousStep) return;

    if (previousStep.id === currentGraph?.rootStepId) {
      rootStepId = currentStep.id;
    }

    const previousGrandStep = findPreviousStep(previousStep.id);
    if (!previousGrandStep) return;

    // when moving left the next step is allowed to not exist
    const nextStep =
      currentStep.next.length === 1
        ? findStep(currentStep.next[0].targetId)
        : undefined;

    if (currentGraph && previousStep && previousGrandStep) {
      // create a new step from currentStep and replace current step's next target to previous step,
      // or set it to `default` if it does not yet have a next target
      const updatedCurrentStep = reassignStepEdge(
        currentStep,
        nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID,
        previousStep.id
      );

      // create a new step from previousStep and replace previous step's next target to next step if it exists
      // otherwise set the step's next target to `default`
      const updatedPreviousStep = reassignStepEdge(
        previousStep,
        currentStep.id,
        nextStep ? nextStep.id : DEFAULT_EDGE_TARGET_ID
      );

      // create a new step from previousGrandStep and replace previous grand-step's next target to current step
      const updatedPreviousGrandStep = reassignStepEdge(
        previousGrandStep,
        previousStep.id,
        currentStep.id
      );

      // generate a new set of journey steps replacing the modified steps with the newly created ones
      const updatedSteps = currentGraph.steps.map(
        (s) =>
          [
            updatedCurrentStep,
            updatedPreviousStep,
            updatedPreviousGrandStep,
          ].find((step) => s.id === step?.id) || s
      );

      setDraftGraph({
        ...currentGraph,
        steps: updatedSteps,
        rootStepId,
      });
    }
  };

  const insertDefaultStep = (
    type: AddableStepTypes,
    sourceId: string,
    targetId: string
  ) => {
    if (!currentGraph) return;
    const [defaultStep, additionalSteps] = getDefaultStep(type);

    const newStep = insertStepBetween(defaultStep, sourceId, targetId);

    const newRootStepId =
      currentGraph.rootStepId === targetId
        ? defaultStep.id
        : currentGraph.rootStepId;

    const steps = [...newStep, ...additionalSteps];
    setDraftGraph({
      ...currentGraph,
      steps,
      rootStepId: newRootStepId,
    });
  };

  /**
   * Modify steps to insert a new step between two existing steps
   *
   * @param step new step to insert
   * @param sourceId existing step id to insert the new step after
   * @param targetId existing step id to insert the new step before
   * @returns new steps array with inserted step and updated edges
   */
  const insertStepBetween = (
    step: Step,
    sourceId: string,
    targetId: string
  ): Step[] => {
    const source = findStep(sourceId);
    const target = findStep(targetId);

    if (!source || !currentGraph || !target) return []; // Invalid insert, source or target doesn't exist
    if (!source.next.some((edge) => edge.targetId === targetId)) return []; // Invalid insert, edge doesn't exist

    // create new step with edge pointing to target
    const newStep = reassignStepEdge(step, DEFAULT_EDGE_TARGET_ID, targetId);
    const updatedStep = reassignStepEdge(source, targetId, newStep.id);

    return [
      ...currentGraph.steps.map((s) =>
        s.id === updatedStep.id ? updatedStep : s
      ),
      newStep,
    ];
  };

  const moveStep = (id: string, direction: Position.Left | Position.Right) => {
    // do not trust UI that the node is actually movable
    if (!canMoveStep(id, direction)) return;

    const currentStep = findStep(id);

    if (!currentStep) return;

    if (direction === Position.Left) {
      moveLeft(currentStep);
    } else {
      moveRight(currentStep);
    }
  };

  const moveRight = (currentStep: Step) => {
    // moving a step right is the same as moving the next step left
    const nextStep =
      currentStep.next.length === 1
        ? findStep(currentStep.next[0].targetId)
        : undefined;

    if (!nextStep) return;
    moveLeft(nextStep);
  };

  const deleteDecisionStep = (step: DecisionStep) => {
    if (!currentGraph) return;

    const currentSteps = currentGraph?.steps;

    const stepBefore = currentSteps.find((s) =>
      s.next.map((n) => n.targetId).includes(step.id)
    );

    if (!stepBefore) return;

    const defaultPathEdge = step.next[step.next.length - 1];
    const allOtherPathEdges = step.next.slice(0, -1);

    const childIds = allOtherPathEdges
      .map(({ targetId }) => currentSteps.find((s) => s.id === targetId))
      .filter((s): s is Step => s !== undefined)
      .flatMap(getAllChildrenIds);

    const newStepBefore = reassignStepEdge(
      stepBefore,
      step.id,
      defaultPathEdge.targetId
    );

    const newSteps = currentGraph.steps
      .filter((s) => s.id !== step.id && !childIds.includes(s.id))
      .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

    const newRootStepId =
      currentGraph.rootStepId === step.id
        ? defaultPathEdge.targetId
        : currentGraph.rootStepId;

    setDraftGraph({
      ...currentGraph,
      steps: newSteps,
      rootStepId: newRootStepId,
    });
  };

  const deleteNonDecisionStep = (step: CommunicationStep | DelayStep) => {
    const stepBefore = currentGraph?.steps.find((s) =>
      s.next.map((n) => n.targetId).includes(step.id)
    );
    const stepAfterId = step.next.length === 1 && step.next[0].targetId;

    if (!stepBefore || !stepAfterId || !currentGraph) return;

    const newStepBefore = reassignStepEdge(stepBefore, step.id, stepAfterId);

    const newSteps: Step[] = currentGraph.steps
      .filter((s) => s.id !== step.id)
      .map((s) => (s.id === stepBefore.id ? newStepBefore : s));

    const newRootStepId =
      currentGraph.rootStepId === step.id
        ? stepAfterId
        : currentGraph.rootStepId;

    setDraftGraph({
      ...currentGraph,
      steps: newSteps,
      rootStepId: newRootStepId,
    });
  };

  const deleteDecisionOption = (step: Step, edge: DecisionEdge) => {
    if (!currentGraph) return;

    const nodeId = edge.targetId;
    const stepsToDelete: Array<string> = [];
    const next = step.next.filter(
      (s) => s.targetId !== edge.targetId
    ) as DecisionEdge[];
    const newDecisionStep = {
      ...step,
      next,
    };

    function findStepsToDelete(deletedEdgeId: string): void {
      if (currentGraph) {
        const deletedStep = currentGraph.steps.find(
          (s) => s.id === deletedEdgeId
        );
        if (deletedStep) {
          stepsToDelete.push(deletedStep.id);
          if (deletedStep.next) {
            deletedStep.next.forEach((s) => findStepsToDelete(s.targetId));
          }
        }
      }
    }

    findStepsToDelete(nodeId);

    const newJourney = {
      ...currentGraph,
      steps: currentGraph.steps
        .filter((s) => !stepsToDelete.includes(s.id))
        .map((s) => (s.id === newDecisionStep.id ? newDecisionStep : s)),
    };
    setDraftGraph(newJourney);
  };

  const deleteStep = (id: string) => {
    const step = currentGraph?.steps.find((s) => s.id === id);

    if (!step || !currentGraph) return;

    if (step.type === 'decision') {
      deleteDecisionStep(step);
    } else if (step.type === 'communication' || step.type === 'delay') {
      deleteNonDecisionStep(step);
    }

    const newErrors = { ...errors };
    if (newErrors?.graph?.[id]) {
      delete newErrors.graph[id];
    }
    setErrors(newErrors);
  };

  return {
    deleteStep,
    deleteDecisionOption,
    findStep,
    canMoveStep,
    moveStep,
    updateStep,
    insertDefaultStep,
  };
};
