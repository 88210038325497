import * as React from 'react';
import {
  DefinitionBlock,
  isRichTextFieldData,
  isRTHFieldDataArray,
  RichTextFieldData,
  Styling,
} from 'models/publisher/block';
import { ColorField, SizeField } from 'models/publisher/style';
import { Layout } from 'models/publisher/format';

export function useStyleForm(
  block: DefinitionBlock
): {
  availableSections: { block: boolean; image: boolean; buttons: boolean };
  canEditColor: (name: ColorField) => boolean;
  canEditSize: (name: SizeField) => boolean;
  canEditImageWidth: () => boolean;
  canEditFont: (name: keyof Styling['fonts']) => boolean;
} {
  const canEditColor = React.useCallback(
    (name: ColorField) => {
      // when all blocks have 'page', this edge case can be removed
      if (name === 'page') return true;
      return !!block.style_options?.colors?.includes(name);
    },
    [block.style_options]
  );

  const canEditSize = React.useCallback(
    (name: SizeField) => {
      return !!block.style_options?.sizes?.includes(name);
    },
    [block.style_options]
  );

  const hasReadingTime = React.useCallback((format_data) => {
    return (
      format_data &&
      format_data.flags &&
      format_data.flags.includes('show-read-time')
    );
  }, []);

  const canEditImageWidth = React.useCallback(() => {
    return (
      canEditSize('imageWidth') &&
      block.format_data?.layout !== Layout.FullBleed
    );
  }, [canEditSize, block.format_data?.layout]);

  const richTextFieldData = React.useMemo(() => {
    const fieldData: RichTextFieldData[] = [];
    Object.values(block.field_data).forEach((fieldDatum) => {
      if (isRichTextFieldData(fieldDatum)) {
        fieldData.push(fieldDatum);
      } else if (isRTHFieldDataArray(fieldDatum)) {
        fieldDatum.forEach((text) => fieldData.push(text.markup));
      }
    });
    return fieldData;
  }, [block.field_data]);

  const canEditFont = React.useCallback(
    (name: keyof Styling['fonts']) => {
      if (
        name === 'headlines' &&
        (block.name === 'quote' || hasHeadlines(block.fields))
      )
        return true;
      if (
        name === 'subtitle' &&
        (block.name === 'quote' || hasReadingTime(block.format_data))
      )
        return true;

      // Font style checking is only needed for rich text field data
      return (
        !richTextFieldData.length ||
        richTextFieldData.some(({ value }) => hasFontSetting(value, name))
      );
    },
    [
      block.fields,
      block.format_data,
      block.name,
      hasReadingTime,
      richTextFieldData,
    ]
  );

  const imagesAvailable = canEditSize('radiuses') || canEditSize('imageWidth');
  const buttonsAvailable = canEditSize('buttonRadiuses');

  return {
    availableSections: {
      block: true,
      image: imagesAvailable,
      buttons: buttonsAvailable,
    },
    canEditColor,
    canEditSize,
    canEditImageWidth,
    canEditFont,
  };
}

const hasHeadlines = (fields: DefinitionBlock['fields']) =>
  fields.some(
    ({ name, type }) => name === 'title' || type === 'complex-rich-text[]'
  );

const hasFontSetting = (html: string, setting: keyof Styling['fonts']) => {
  const elementMap: Record<keyof Styling['fonts'], string | string[]> = {
    headlines: 'h1',
    subhead: 'h2',
    title: 'h3',
    subtitle: 'h4',
    caption: 'h5',
    body: ['p', 'li', 'td'],
    link: 'a',
    button: 'button',
  };

  const hasValidTag = (tag: string) => {
    const openingPosition = html.match(new RegExp(`<${tag}[^>]*>`))?.index;

    if (typeof openingPosition === 'undefined') return false;

    return html.indexOf(`</${tag}>`, openingPosition) > -1;
  };

  const element = elementMap[setting];
  return Array.isArray(element)
    ? element.some(hasValidTag)
    : hasValidTag(element);
};
