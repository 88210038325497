import {
  bossanovaDomain,
  deepCamelcaseKeys,
  prepareQueryString,
  request,
} from 'services/api-shared';
import { Page } from './common';

type ResponseItem = {
  id: string;
  type: 'program-contact-addresses' | unknown;
  attributes: {
    name: string;
    description: string;
    senderName: string;
    replyToEmail: string;
    senderEmail: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    companyName: string;
    status: 'active' | unknown;
    customPhysicalAddressEnabled: boolean;
    onBehalfOfSenderEnabled: boolean;
    onBehalfName: string;
    onBehalfEmail: string;
    contentChannels: unknown[];
    default: boolean;
  };
};

export type ApiContactAddress = {
  id: ResponseItem['id'];
  type: ResponseItem['type'];
  defaultSender: boolean;
} & ResponseItem['attributes'];

function transformContact(contact: ResponseItem): ApiContactAddress {
  return {
    id: contact.id,
    type: contact.type,
    defaultSender: contact.attributes.default,
    ...contact.attributes,
  };
}

export type FetchProps = {
  programId: number;
  status?: 'active' | 'archived';
  page?: number;
  pageSize?: number;
  perPage?: number;
};

export async function fetchContactAddresses({
  programId,
  ...queryParams
}: FetchProps): Promise<Page<ApiContactAddress>> {
  const queryString = prepareQueryString({ ...queryParams });
  const url = `${bossanovaDomain}/samba/programs/${programId}/contact_addresses?${queryString}`;
  const response = await request(url);

  if (response.status === 200) {
    return response
      .json()
      .then((body) => deepCamelcaseKeys(body))
      .then(({ data, meta }) => {
        return {
          data: data.map((item: ResponseItem) => transformContact(item)),
          meta: {
            currentPage: meta.page,
            ...meta,
          },
        };
      });
  }

  throw new Error(`Error fetching contact addresses: ${response.status}`);
}
