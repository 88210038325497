import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { DateTime } from 'luxon';
import {
  fetchDefaultAuthor,
  getPost,
  mapServerDataToPost,
  sendTestEmail,
} from 'services/api-post';
import {
  buildPreviewPermalink,
  defaultPost,
  Post,
  processChange,
} from 'models/publisher/post';
import { useProgram } from 'contexts/program';
import { resolveBlocks } from 'services/api-content-blocks';
import { ForbiddenError } from 'services/Errors/ForbiddenError';
import { NotFoundError } from 'services/Errors/NotFoundError';
import { FeatureFlags } from 'models/feature-flag';
import { Author, BaseAuthor, isAuthor, isAuthorAlias } from 'models/author';
import { FontStylesheet, Template } from 'models/library';
import { useLocation, useNavigate } from '@reach/router';
import { useTemplate } from 'contexts/template';
import { EDIT_SESSION_WARNING } from 'models/flash-message';
import { blockIsProcessing } from 'models/publisher/block';
import { Settings } from 'models/publisher/settings';
import { useFlashMessage } from 'contexts/flasher';
import { Content } from 'models/content';
import { withEmailOnly, withModifiers, withTopicOnly } from 'models/channel';
import { resolveStyling } from 'services/api-styling';
import { ServerPost } from 'services/server-post-type';
import { fetchById } from 'services/api-author-alias';
import { Program } from 'models/program';
import {
  AudiencesCollectionData,
  fetchAudiences,
} from 'services/api-audiences';
import { useUser } from 'contexts/user';
import { User } from 'models/user';
import { asserts } from 'utility/asserts';
import { Audience } from 'models/audience';
import {
  defaultPostStatus,
  PostStatus,
  UsePersistPost,
  usePersistPost,
} from './persist-post';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from './feature-flags';
import { useFieldVariables } from './publisher/useFieldVariables';
import { useAuthCheck } from './auth-check';
import { useLibraryFonts } from './useLibrary';
import { mapDataToAudience } from './audience';

export type UsePost = {
  post?: Post;
  update: (changes: Partial<Post>) => void;
  save: UsePersistPost['save'];
  touch: (cb: (data?: Post) => void) => void;
  pauseAutosave: () => void;
  unpauseAutosave: () => void;
  cancelAutosave: () => void;
  updateNotes: (notes: string, callback: () => void) => void;
  updateContent: (param: Partial<Content>, callback: () => void) => void;
  status: PostStatus;
  isEditingTemplate: boolean;
  disableFetch: () => void;
  isProcessing: boolean;
};

function raise(error: string): never {
  throw new Error(error);
}

function determineAuthorType(
  contentAuthor: BaseAuthor,
  { authorAliasEnabled }: { authorAliasEnabled: boolean }
) {
  if (isAuthorAlias(contentAuthor) && authorAliasEnabled) {
    return 'authorAlias';
  }

  if (isAuthor(contentAuthor) && contentAuthor.userId === 0) {
    return 'defaultAuthor';
  }

  if (isAuthor(contentAuthor)) {
    return 'userAuthor';
  }

  return 'none';
}

async function getContentAuthorSettings({
  contentAuthor,
  programId,
  authorAliasEnabled,
}: {
  contentAuthor: BaseAuthor;
  programId: Program['id'];
  authorAliasEnabled: boolean;
}) {
  const authorType = determineAuthorType(contentAuthor, { authorAliasEnabled });

  // exhaustive switch with no default case
  // eslint-disable-next-line default-case
  switch (authorType) {
    case 'authorAlias':
      try {
        const templateAuthorAlias = await fetchById(
          contentAuthor.authorAliasId ?? raise('Author Alias ID missing'),
          programId
        );
        return {
          ...templateAuthorAlias,
          displayName:
            templateAuthorAlias.displayName || `${templateAuthorAlias.id}`,
          defaultDisplayName:
            templateAuthorAlias.displayName || `${templateAuthorAlias.id}`,
        };
      } catch (error) {
        return undefined;
      }
    case 'userAuthor':
      return contentAuthor;
    case 'defaultAuthor':
      return undefined;
    case 'none':
      return undefined;
    default:
      return undefined;
  }
}

async function buildNewPost({
  flags,
  author,
  programId,
  template,
  fonts,
  authorAliasEnabled,
  user,
}: {
  flags?: FeatureFlags;
  author?: Author;
  programId: number;
  template: Template;
  fonts: FontStylesheet[];
  authorAliasEnabled?: boolean;
  user: User;
}): Promise<Post> {
  const asset = template.asset.template;
  const blocks = await resolveBlocks(programId, [
    ...(asset?.blocks ?? defaultPost.blocks),
  ]);
  // template id='new' means we are creating a new post either from scratch
  // or from the simple templates in the sidebar
  const creatingFromAsset = template.id !== 'new';

  const postFromTemplate: Post = {
    ...defaultPost,
    content: {
      ...defaultPost.content,
      libraryTemplateId: creatingFromAsset ? template.id : undefined,
    },
    blocks,
    callToAction: asset.callToAction,
    settings: {
      ...asset.settings,
      publishedAt: undefined,
      archiveAt: undefined,
    },
    styles: await resolveStyling(asset.styles, fonts, programId),
  };

  const newSettings: Partial<Settings> = {};

  if (flags) {
    if (!creatingFromAsset) {
      newSettings.isTranslatable =
        flags.contentTranslationsEnabled?.value === true;

      newSettings.isCommentable =
        flags.commentingEnabled &&
        flags.commentingEnabled.value === true &&
        flags.commentingDefaultEnabled &&
        flags.commentingDefaultEnabled.value === true;
    }

    const isEmailOnly = !!flags.emailTokenAuthEnabled?.value;
    const isTopicOnly = postFromTemplate.settings.audiences.length === 0;

    newSettings.deliveryChannels = withModifiers(
      [withEmailOnly(isEmailOnly), withTopicOnly(isTopicOnly)],
      postFromTemplate.settings.deliveryChannels
    );

    newSettings.retries = isTopicOnly ? 0 : postFromTemplate.settings.retries;

    newSettings.deliveryType = isTopicOnly
      ? undefined
      : postFromTemplate.settings.deliveryType;
  }

  const { contentAuthor } = postFromTemplate.settings;

  const contentAuthorSettings = await getContentAuthorSettings({
    contentAuthor,
    programId,
    authorAliasEnabled: Boolean(authorAliasEnabled),
  }).catch(() => author);

  if (contentAuthorSettings ?? author) {
    newSettings.contentAuthor = contentAuthorSettings ?? author;
  } else {
    newSettings.contentAuthor =
      !postFromTemplate.settings.contentAuthor ||
      (postFromTemplate.settings.contentAuthor as Author).userId === 0 ||
      postFromTemplate.settings.contentAuthor.authorAliasId === 0
        ? {
            displayName:
              user.displayName ?? `${user.firstName} ${user.lastName}`,
            defaultDisplayName: user.displayName ?? '',
            avatarUrl: user.avatarUrl,
            userId: user.id,
          }
        : postFromTemplate.settings.contentAuthor;
  }

  const mergedSettings = { ...postFromTemplate.settings, ...newSettings };

  return Promise.resolve({
    ...postFromTemplate,
    settings: mergedSettings,
  });
}

/*
  The usePost hook manages the Post for the publisher context.
  It relies on two ReactQuery hooks.
    - The first is responsible for the loading a post, for both 'new' and
      previously saved cases
    - The second is responsible for saving the post
 */
export function usePost(
  programId: number,
  id: number | 'new',
  readOnly = false
): UsePost {
  const {
    refs: { setFlashMessage, dismiss },
  } = useFlashMessage();
  const featureFlags = useProgramCustomizationsQuery(programId);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { template } = useTemplate();
  const isEditingTemplate = !!location.pathname.match(/edit\/template/);
  const user = useUser();
  const fieldVariables = useFieldVariables();

  /*
    This is not exactly future-proof.
    We are loading the fonts here to determine if arial is enabled and using it as default
    Otherwise we are using a different font as default.

    This has been done to solve https://firstup-io.atlassian.net/browse/PUB-2637
    Please introduce a concept of default font instead and revert this commit once done.
  */
  const { isLoading: isLoadingFonts, data: fonts } = useLibraryFonts({
    filter: {
      type: 'search',
      search: '',
      status: ['published'],
    },
    pageSize: 1000,
    includeDisabled: false,
  });

  const authorAliasEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  const [lastModified, setLastModified] = useState<DateTime | undefined>(
    undefined
  );

  const [isFetchDisabled, setIsFetchDisabled] = useState(false);

  const [currentPost, setCurrentPost] = useState<Post>();
  const { autosave, save, status } = usePersistPost(
    currentPost,
    setCurrentPost,
    readOnly
  );

  const { fromPost: varsFromPost } = useFieldVariables(currentPost);
  const { isAuthenticated } = useAuthCheck();

  const studioOrchestrateNewPages = !!useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  ).data?.value;

  const { data: fetchedPost, error: fetchPostError } = useQuery(
    ['publisher/content', id, featureFlags.data],
    async () => {
      if (id === 'new') {
        return buildNewPost({
          flags: { ...featureFlags.data },
          programId,
          author: await fetchDefaultAuthor(programId),
          template,
          fonts,
          authorAliasEnabled,
          user,
        });
      }

      const serverContent = await getPost({ programId, postId: id }).then(
        (serverPost: ServerPost) => {
          return {
            ...serverPost,
            attributes: {
              ...serverPost.attributes,
              deliveryPageVersion: studioOrchestrateNewPages ? 2 : 1,
            },
          };
        }
      );

      if (serverContent.attributes.version !== 2) {
        await navigate(`/${programId}/edit/content/${id}`, { replace: true });
        return undefined;
      }

      return mapServerDataToPost(programId, serverContent, fieldVariables);
    },
    {
      enabled: !!featureFlags.data && !isLoadingFonts,
      retry: false,
    }
  );

  const audiences = fetchedPost?.settings.audiences ?? [];

  useQuery(
    ['post-audiences', id, featureFlags],
    async () => {
      if (!fetchedPost) return;

      const serverAudiencesData = await fetchAudiences({
        programId,
        page: 1,
        pageSize: audiences?.length,
        name: audiences?.map((a) => a.name),
      });

      // Revalidating the Post's audiences with the latest definitions from the server.
      // This ensures the Post's audiences are up-to-date with any changes made on the server
      // since the last persistence. Audiences not found on the server are retained as they might
      // be temporary and specific to this Post.
      const revalidatedAudiences = revalidateAudiencesWithServerData(
        serverAudiencesData,
        audiences
      );

      const newSettings = {
        ...fetchedPost.settings,
        audiences: revalidatedAudiences,
      };

      asserts(
        newSettings.audiences.length === fetchedPost.settings.audiences.length,
        "A Post's number of audiences should remain consistent after revalidation with the server."
      );
      update({ settings: newSettings }, true);
    },
    {
      retry: false,
      enabled:
        fetchedPost !== undefined &&
        (id !== 'new' || template.id !== 'new') &&
        audiences &&
        audiences?.length > 0,
    }
  );

  const fetchErrorStatus = useMemo(() => {
    if (fetchPostError instanceof ForbiddenError) return 'unauthorized';
    if (fetchPostError instanceof NotFoundError) return 'not-found';
    return undefined;
  }, [fetchPostError]);

  useEffect(() => {
    setCurrentPost(fetchedPost);
  }, [fetchedPost]);

  useEffect(() => {
    if (!isAuthenticated) {
      setFlashMessage.current(EDIT_SESSION_WARNING);
    } else {
      dismiss.current(EDIT_SESSION_WARNING);
    }
  }, [isAuthenticated, setFlashMessage, dismiss]);

  useEffect(() => {
    if (
      id === 'new' &&
      currentPost?.content?.id &&
      currentPost.content.id > 0 &&
      !isFetchDisabled
    ) {
      // We're prefilling the query cache so that when we navigate to update
      // the url, we'll immediately have data and we won't get a loading screen
      queryClient.setQueryData(
        ['publisher/content', currentPost.content.id, featureFlags.data],
        currentPost
      );
      navigate(
        location.pathname.replace(id as 'new', `${currentPost.content.id}`),
        { replace: true }
      );
    }
  }, [
    currentPost,
    currentPost?.content,
    id,
    isFetchDisabled,
    location.pathname,
    navigate,
    queryClient,
    featureFlags.data,
  ]);

  const update = useCallback(
    (changes: Partial<Post>, avoidAutosave?: boolean) => {
      if (!avoidAutosave) {
        setLastModified(DateTime.now());
      }

      if (currentPost?.content.publicationState === 'draft')
        autosave.cancelSave();

      setCurrentPost((current) => {
        if (!current) return undefined;
        return processChange(current, {
          post: changes,
          varsFromPost,
        });
      });

      if (currentPost?.content.publicationState === 'draft') {
        autosave.scheduleSave(() => setIsFetchDisabled(false));
      } else {
        setIsFetchDisabled(false);
      }
    },
    [autosave, varsFromPost, currentPost?.content.publicationState]
  );

  const touch = useCallback(
    (callback: (data?: Post) => void) => {
      if (
        currentPost?.content.publicationState === 'draft' &&
        currentPost?.content.id < 1
      ) {
        save({
          shouldValidate: false,
          post: processChange(currentPost || {}, { post: {}, varsFromPost }),
          callback,
        });
      }
    },
    [currentPost, save, varsFromPost]
  );

  // TODO:  split saving notes into a different request, separate from saving the rest of the post
  const updateNotes = useCallback(
    (notes, callback) => {
      if (!currentPost || readOnly) return;
      save({
        shouldValidate: false,
        post: {
          ...currentPost,
          content: {
            ...currentPost?.content,
            contentNote: notes,
          },
        },
        callback,
      });
    },
    [currentPost, save, readOnly]
  );

  const updateContent = useCallback(
    (changes: Partial<Content>, callback) => {
      if (!currentPost || readOnly) return;
      save({
        post: {
          ...currentPost,
          content: {
            ...currentPost?.content,
            ...changes,
          },
        },
        callback,
      });
    },
    [currentPost, save, readOnly]
  );
  const pauseAutosave = useCallback(() => autosave.pause(), [autosave]);
  const unpauseAutosave = useCallback(() => autosave.unpause(), [autosave]);

  const isProcessing = useMemo<boolean>(
    () => currentPost?.blocks.some(blockIsProcessing) ?? false,
    [currentPost?.blocks]
  );

  if (!currentPost)
    return {
      pauseAutosave() {},
      unpauseAutosave() {},
      cancelAutosave() {},
      save() {},
      touch() {},
      update() {},
      updateNotes() {},
      updateContent() {},
      status: { ...defaultPostStatus, error: fetchErrorStatus },
      isEditingTemplate,
      disableFetch() {},
      isProcessing,
    };

  return {
    pauseAutosave,
    unpauseAutosave,
    cancelAutosave: autosave.cancelSave,
    post: currentPost,
    update,
    updateNotes,
    updateContent,
    save,
    touch,
    status: { ...status, lastModified, error: fetchErrorStatus },
    isEditingTemplate,
    disableFetch: () => setIsFetchDisabled(true),
    isProcessing,
  };
}

export const useSendTestEmail = (
  subject: string,
  previewText: string,
  post: Post,
  userIds: number[],
  preferOutlook365: boolean,
  onSuccess?: () => void
): { sendEmail: () => void; sendEmailRequest: () => Promise<void> } => {
  const { id: programId } = useProgram();
  const { fromPost } = useFieldVariables();
  const sendEmailRequest = () => {
    return sendTestEmail(
      subject,
      previewText,
      post,
      { ...fromPost(post), content_permalink: buildPreviewPermalink(post) },
      programId,
      userIds,
      preferOutlook365
    );
  };
  const sendEmail = () => {
    if (userIds && userIds.length > 0) {
      const request = sendEmailRequest();
      request.then(() => {
        if (onSuccess) onSuccess();
      });
    }
  };
  return {
    sendEmail,
    sendEmailRequest,
  };
};

function revalidateAudiencesWithServerData(
  serverAudiencesData: AudiencesCollectionData,
  localAudiences: Audience[]
) {
  const serverAudiencesMap = new Map(
    serverAudiencesData.data.map((d) => [
      d.attributes.name,
      mapDataToAudience(d.attributes),
    ])
  );

  const revalidatedAudiences = localAudiences.map(
    (audience) => serverAudiencesMap.get(audience.name) || audience
  );
  return revalidatedAudiences;
}

export const spec = { buildNewPost };
