import { FiltersStateType } from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { useExternalSourcesQuery } from 'hooks/external-source';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ClickDropdown, ClickDropdownHandle } from 'shared/ClickDropdown';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import cx from 'classnames';
import { useApiQuery } from 'hooks/common';
import { fetchSubmitters, SubmitterData } from 'services/api-submitters';
import { ExternalSource } from 'models/external-source';
import { Checkbox } from 'shared/Checkbox';
import { ChevronRight } from 'shared/icons';
import { useJourneyState } from 'contexts/journey';
import styles from './filters.module.css';
import { TriggerButton } from './TriggerButton';

const SourceOption: React.FC<{
  checked: boolean;
  name: string;
  onChange: (event: boolean) => void;
  customClassName?: string;
}> = ({ checked, name, onChange, customClassName }) => {
  return (
    <div className={cx(styles.sourceOption, customClassName)}>
      <label className={styles.sourcesOptionRow}>
        <div>
          <Checkbox checked={checked} onChange={onChange} />
        </div>
        <div className={styles.sourceName}>{name}</div>
      </label>
    </div>
  );
};

const SOURCE_TYPES = [
  'sourceTypes',
  'externalSources',
  'external',
  'submitters',
];

export const SourcesFilter: React.FC<{
  filters: FiltersStateType;
  onChange: (name: string, value: Array<string>) => void;
  closeFilter: () => void;
  align?: 'left' | 'right' | 'center';
}> = ({ filters, onChange, closeFilter, align = 'left' }) => {
  const { currentGraph } = useJourneyState();
  const hideHoverDropdowns = !!currentGraph;
  const { id: programId, programAuthor } = useProgram();

  const externalSourcesData = useExternalSourcesQuery({
    programId,
  });
  const availableTypes = (externalSourcesData.data || [])
    .map((t) => t.type)
    .filter((value, index, self) => self.indexOf(value) === index);

  const sourcesByType = useMemo(() => {
    const map: { [type: string]: { [key: string]: ExternalSource } } = {};
    (externalSourcesData.data || []).forEach((source: ExternalSource) => {
      const sources = map[source.type];
      const newSource = { [`${source.id}`]: source };
      const newState = {
        ...sources,
        ...newSource,
      };
      map[source.type] = newState;
    });
    return map;
  }, [externalSourcesData.data]);

  const sourcesByTypeRowIds = useMemo(() => {
    const map: { [type: string]: Array<string> } = {};
    Object.keys(sourcesByType).forEach((type) => {
      map[type] = Object.keys(sourcesByType[type]);
    });
    return map;
  }, [sourcesByType]);

  const submittersData = useApiQuery('submitters', fetchSubmitters, {
    programId,
  });

  const submittersByRowId = useMemo(() => {
    const map: { [key: string]: SubmitterData } = {};
    (submittersData.data || []).forEach((submitter) => {
      map[`${submitter.userId}`] = submitter;
    });
    return map;
  }, [submittersData]);

  const submitterRowIds = Object.keys(submittersByRowId).sort((a, b) => {
    const nameA =
      submittersByRowId[a].displayName?.toLowerCase() ||
      submittersByRowId[a].email?.toLowerCase();
    const nameB =
      submittersByRowId[b].displayName?.toLowerCase() ||
      submittersByRowId[b].email?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const isProgramAuthorChecked = React.useMemo(() => {
    return filters.standard.sourceTypes.values.includes('admin_created');
  }, [filters]);

  const isExternalSourcesChecked = React.useMemo(() => {
    return filters.standard.sourceTypes.values.includes('external');
  }, [filters]);

  const isUserSubmittedSourceChecked = React.useMemo(() => {
    return filters.standard.sourceTypes.values.includes('submitted');
  }, [filters]);

  const handleSourceChange = React.useCallback(
    (event: boolean, name) => {
      const { sourceTypes } = filters.standard;
      if (event) {
        if (!sourceTypes.values.includes(name)) {
          onChange('sourceTypes', [...sourceTypes.values, name]);
        }
      } else {
        onChange(
          'sourceTypes',
          (sourceTypes.values as string[]).filter((f) => f !== name)
        );
      }
    },
    [onChange, filters]
  );

  const resetSourceFilter = React.useCallback(() => {
    SOURCE_TYPES.forEach((type) => {
      onChange(type, []);
    });
    closeFilter();
  }, [closeFilter, onChange]);

  const handleExternalSourcesChange = useCallback(
    (ids: string[]) => {
      onChange('externalSources', ids);
      if (ids.length !== (externalSourcesData.data || []).length) {
        handleSourceChange(false, 'external');
      }
      if (ids.length === (externalSourcesData.data || []).length) {
        handleSourceChange(true, 'external');
      }
    },
    [onChange, handleSourceChange, externalSourcesData.data]
  );

  const handleAllExternal = React.useCallback(
    (event: boolean) => {
      handleSourceChange(event, 'external');
      if (event) {
        handleExternalSourcesChange(
          (externalSourcesData.data || []).map((s) => `${s.id}`)
        );
      } else {
        handleExternalSourcesChange([]);
      }
    },
    [externalSourcesData, handleSourceChange, handleExternalSourcesChange]
  );

  // Ensures all external sources are selected if the parent source type 'external'
  // is selected. This is needed when rendering the component from an indirect action
  // such as clicking 'Feed Posts' from the notification bell, and the external sources
  // have yet to be fetched.
  React.useEffect(() => {
    if (
      externalSourcesData.data &&
      filters.standard.sourceTypes.values.includes('external') &&
      filters.standard.externalSources.values.length !==
        externalSourcesData.data.length
    ) {
      onChange(
        'externalSources',
        externalSourcesData.data.map((s) => `${s.id}`)
      );
    }
  }, [
    externalSourcesData.data,
    filters.standard.externalSources.values.length,
    filters.standard.sourceTypes.values,
    onChange,
  ]);

  const handleSubmittersChange = useCallback(
    (ids: string[]) => {
      if (ids.length !== (submittersData.data || []).length) {
        if (isUserSubmittedSourceChecked && ids.length > 0) {
          // Transitioning from 'all' to selecting individual submitters
          const inversedIds = (submittersData.data || [])
            .filter((data) => !ids.includes(data.userId.toString()))
            .map((data) => data.userId.toString());
          onChange('submitters', inversedIds);
        } else {
          onChange('submitters', ids);
        }
        handleSourceChange(false, 'submitted');
      } else {
        onChange('submitters', ids);
        handleSourceChange(true, 'submitted');
      }
    },
    [
      onChange,
      handleSourceChange,
      submittersData.data,
      isUserSubmittedSourceChecked,
    ]
  );

  const handleAllSubmitters = React.useCallback(
    (event: boolean) => {
      handleSourceChange(event, 'submitted');
      if (event) {
        handleSubmittersChange(
          (submittersData.data || []).map((s) => `${s.userId}`)
        );
      } else {
        handleSubmittersChange([]);
      }
    },
    [handleSubmittersChange, submittersData, handleSourceChange]
  );

  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  useEffect(() => {
    if (isFirstLoad && submittersData.data) {
      setIsFirstLoad(false);
      if (isUserSubmittedSourceChecked) {
        handleAllSubmitters(true);
      }
    }
  }, [
    isFirstLoad,
    isUserSubmittedSourceChecked,
    handleAllSubmitters,
    submittersData.data,
  ]);

  const renderExternalSourceRow = React.useCallback(
    (type: string, rowId: string) => {
      const item = sourcesByType[type][rowId];
      return item ? (
        <div className={styles.sourcesTitle}>
          <span>{item.alias || item.identifier}</span>
        </div>
      ) : null;
    },
    [sourcesByType]
  );

  const renderSubmitterRow = React.useCallback(
    (rowId: string) => {
      const item = submittersByRowId[rowId];
      return item ? (
        <div className={styles.sourcesTitle}>
          <span>{item.displayName || item.email}</span>
        </div>
      ) : null;
    },
    [submittersByRowId]
  );

  const externalSourceValuesDropdown = useCallback(
    (type: string) => {
      return (
        <InfiniteSelect
          className={styles.externalSourcesDropdown}
          rowIds={sourcesByTypeRowIds[type]}
          rowRenderProp={(id) => renderExternalSourceRow(type, id)}
          maxHeight={400}
          itemHeight={30}
          selectedIds={filters.standard.externalSources.values as string[]}
          onSelectedIdsChange={handleExternalSourcesChange}
          isLoading={externalSourcesData.isLoading}
        />
      );
    },
    [
      sourcesByTypeRowIds,
      handleExternalSourcesChange,
      filters.standard.externalSources.values,
      externalSourcesData.isLoading,
      renderExternalSourceRow,
    ]
  );

  const externalSourcesDropdown = useCallback(() => {
    return (
      <div className={styles.sourcesDropdownContainer}>
        <SourceOption
          onChange={handleAllExternal}
          checked={isExternalSourcesChecked}
          name="All External"
        />
        {availableTypes.map((t) => {
          return (
            <HoverDropdown
              key={t}
              closeDelay={0}
              dropdownClassName={cx(
                'dropdown-align-left',
                styles.expandedDropdown
              )}
              dropdownRenderProp={() => externalSourceValuesDropdown(t)}
            >
              <div className={styles.sourcesDropdownRow}>
                <div>{t === 'twitter' ? '"X"' : t}</div>
                <ChevronRight />
              </div>
            </HoverDropdown>
          );
        })}
      </div>
    );
  }, [
    handleAllExternal,
    isExternalSourcesChecked,
    availableTypes,
    externalSourceValuesDropdown,
  ]);

  const submittersDropdown = React.useCallback(() => {
    return (
      <div
        className={cx(
          styles.sourcesDropdownContainer,
          styles.submittersContainer
        )}
      >
        <SourceOption
          onChange={handleAllSubmitters}
          checked={isUserSubmittedSourceChecked}
          name="All User-Submitted"
        />
        <InfiniteSelect
          className={styles.sourcesDropdown}
          rowIds={submitterRowIds}
          rowRenderProp={renderSubmitterRow}
          maxHeight={400}
          itemHeight={30}
          selectedIds={filters.standard.submitters.values as string[]}
          onSelectedIdsChange={handleSubmittersChange}
          isLoading={submittersData.isLoading}
        />
      </div>
    );
  }, [
    submitterRowIds,
    isUserSubmittedSourceChecked,
    filters.standard.submitters.values,
    handleSubmittersChange,
    renderSubmitterRow,
    submittersData.isLoading,
    handleAllSubmitters,
  ]);

  const programAuthorName = React.useMemo(() => {
    return (
      programAuthor?.displayName ||
      programAuthor?.defaultDisplayName ||
      'Program'
    );
  }, [programAuthor]);

  const dropdown = React.useMemo(
    () => (
      <div className={styles.sourcesDropdownContainer}>
        <SourceOption
          onChange={(e) => handleSourceChange(e, 'admin_created')}
          checked={isProgramAuthorChecked}
          name={programAuthorName}
          customClassName={hideHoverDropdowns ? styles.noBorderBottom : ''}
        />
        {!hideHoverDropdowns && (
          <>
            <HoverDropdown
              closeDelay={0}
              dropdownClassName={cx(
                'dropdown-align-left',
                styles.expandedDropdown
              )}
              dropdownRenderProp={submittersDropdown}
            >
              <div className={styles.sourcesDropdownRow}>
                <div>User-Submitted</div>
                <ChevronRight />
              </div>
            </HoverDropdown>
            <HoverDropdown
              closeDelay={0}
              dropdownClassName={cx(
                'dropdown-align-left',
                styles.expandedDropdown
              )}
              dropdownRenderProp={externalSourcesDropdown}
            >
              <div className={styles.sourcesDropdownRow}>
                <div>External</div>
                <ChevronRight />
              </div>
            </HoverDropdown>
          </>
        )}
      </div>
    ),
    [
      externalSourcesDropdown,
      handleSourceChange,
      isProgramAuthorChecked,
      programAuthorName,
      submittersDropdown,
      hideHoverDropdowns,
    ]
  );

  const names = React.useMemo(() => {
    const { standard } = filters;
    const memo = [
      ...(externalSourcesData.data || [])
        .filter((d) => standard.externalSources.values.includes(`${d.id}`))
        .map((s) => s.alias || s.identifier),
      ...(submittersData.data || [])
        .filter((d) => standard.submitters.values.includes(`${d.userId}`))
        .map((s) => s.displayName),
    ];
    if (standard.sourceTypes.values.includes('admin_created')) {
      memo.unshift(programAuthorName);
    }
    return memo;
  }, [
    filters,
    externalSourcesData.data,
    submittersData.data,
    programAuthorName,
  ]);

  const clickDropdownRef = React.useRef<ClickDropdownHandle>(null);

  // When a new dataset is fetched, the width of the dropdown may change causing it to
  // possibly overflow off of the screen. The use of setImmediate is necessary to wait
  // for the InfiniteSelect component to finish rendering before having the dropdown check
  // if its positioning needs to be corrected.
  React.useEffect(() => {
    setImmediate(() => clickDropdownRef.current?.correctDropdownOverflow());
  }, [externalSourcesData]);

  return (
    <ClickDropdown
      dropdownRenderProp={dropdown}
      dropdownClassName={`dropdown-align-${align}`}
      ref={clickDropdownRef}
    >
      <div>
        <TriggerButton
          onClose={resetSourceFilter}
          name="Sources"
          values={names}
        />
      </div>
    </ClickDropdown>
  );
};
