import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Screen } from 'components/publisher/theme/Screen';
import { Review } from 'components/publisher/pages/Review';
import { PublisherFooter } from 'components/publisher/theme/PublisherFooter/PublisherFooter';
import { useOrchestrationInsights } from 'hooks/orchestration-insights';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { OrchestrateSidebar } from 'App/Program/Editors/Publisher/Orchestrate/OrchestrateSidebar';
import { useQueryClient } from 'react-query';
import { usePublisher } from 'contexts/publisher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Compose } from './Compose';
import { Results } from './Results';
import { Orchestrate } from './Orchestrate';
import { Deliver } from './Deliver';
import { NewReview } from './NewReview';

const PublisherOrchestrateSidebar = () => {
  const { post } = usePublisher();
  const { settings } = useSettings();
  const insights = useOrchestrationInsights(
    settings,
    post?.content?.id || undefined
  );

  return (
    <OrchestrateSidebar post={post} settings={settings} insights={insights} />
  );
};

export const Publisher: React.FC<RouteComponentProps> = () => {
  const queryClient = useQueryClient();
  // ensure that we do not have any cached post data when first opening the publisher
  useEffect(() => {
    queryClient.removeQueries('publisher/content');
    return () => {
      queryClient.removeQueries('publisher/content');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id: programId } = useProgram();
  const {
    data: showStudioOrchestrateNewPages,
    isLoading: newDeliveryLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Orchestrate.NewPages');
  const {
    data: showNewReviewPage,
    isLoading: newReviewLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Publish.NewReviewPage');

  const { data } = useFeatureFlagsQuery(programId, 'Studio.Publish.NewEditors');

  const implementation = !data?.value ? 'old' : 'new';

  const footer = <PublisherFooter nextPage="review" />;

  return (
    <Router>
      <Screen path="/:id">
        <Compose path="compose/*" implementation={implementation} />
        {!newDeliveryLoading && !showStudioOrchestrateNewPages?.value && (
          <Orchestrate
            path="deliver/*"
            sidebar={<PublisherOrchestrateSidebar />}
            footer={footer}
          />
        )}
        {!newDeliveryLoading && showStudioOrchestrateNewPages?.value && (
          <Deliver
            path="deliver/*"
            sidebar={<PublisherOrchestrateSidebar />}
            footer={footer}
          />
        )}
        {!newReviewLoading && !showNewReviewPage?.value && (
          <Review path="review/*" />
        )}
        {!newReviewLoading && showNewReviewPage?.value && (
          <NewReview path="review/*" />
        )}
        <Results path="results/*" />
        <Redirect noThrow from="/" to="compose" />
        <Redirect noThrow from="*" to="/" default />
      </Screen>
      <Redirect noThrow from="/" to="new" />
      <Redirect noThrow from="*" to="/" default />
    </Router>
  );
};
