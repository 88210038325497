import * as React from 'react';
import cx from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useNavigationBlockerContext } from 'contexts/publisher/compose/navigation-blocker';
import { useCanvasStylePanel } from 'contexts/publisher/compose/canvas-style-panel';
import {
  RenderStatusContext,
  useRenderStatus,
} from 'contexts/publisher/compose/render-status';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { PublisherMode } from 'contexts/publisher';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { BlockRow } from '../instances/BlockRow';
import editorPlaceholder from './editor-placeholder.svg';
import styles from '../dnd.module.css';

// This file is full of jsx props-spreading because it does a lot
// of interfacing with the DND library, following examples in the docs.

const DropStart: React.FC = () => (
  <div className={styles.placeholder}>
    <img src={editorPlaceholder} alt="Drag blocks from the library" />
    <p>Create content by dragging blocks from the library</p>
  </div>
);

const DropList: React.FC<{
  children: (child: React.ReactElement, index: number) => React.ReactElement;
}> = ({ children }) => {
  const {
    publisherMode,
    selected,
    select,
    instances,
    remove,
    updateFieldsData,
  } = React.useContext(BlocksEditorContext);
  const { blocker } = useNavigationBlockerContext();
  const { setFlashMessage } = useFlashMessage();
  const { enable: showStylePanel } = useCanvasStylePanel();
  const isPublisherModeStandard = publisherMode === PublisherMode.standard;

  const selectBlock = React.useCallback(
    (event: React.MouseEvent, id: string) => {
      // do not trigger callback in case when modal editor is open
      // block selection works only when list of blocks is open and on the top
      if (document.getElementById('block-editor')) return;

      // do not trigger callback unless the publisher is in standard mode
      if (!isPublisherModeStandard) return;

      let node = event.target as HTMLElement;

      while (node) {
        if (node.classList?.contains('js-editor')) return;
        if (node.dataset?.rbdDraggableId === id) break;
        if (node.dataset?.name === 'insert-bar') return;
        if (
          Array.from(node.classList ?? []).some((className) =>
            /^dnd_menu/.test(className)
          )
        )
          return;
        node = node.parentNode as HTMLElement;
      }

      select(id);
      if (id && id !== selected?.id) setImmediate(showStylePanel);
      if (blocker.enabled) {
        setFlashMessage({ message: blocker.message, severity: 'error' });
      }
    },
    [
      isPublisherModeStandard,
      select,
      selected?.id,
      showStylePanel,
      blocker.enabled,
      blocker.message,
      setFlashMessage,
    ]
  );
  const { canEdit } = React.useContext(DesignPermissionContext);

  const contentBlockDragHandles = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.ContentBlockDragHandles'
  ).data?.value;

  return (
    <>
      {instances.map(({ block, id }, index) => (
        <Draggable
          key={id}
          draggableId={id}
          index={index}
          isDragDisabled={!canEdit}
        >
          {(dragProvided, { isDragging }) => (
            /* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
            <div
              onClick={(event) => selectBlock(event, id)}
              ref={dragProvided.innerRef}
              className={cx({
                [styles.selected]: selected?.id === id,
                [styles.unselected]: selected?.id !== id,
              })}
              {...dragProvided.draggableProps}
              {...(contentBlockDragHandles ? {} : dragProvided.dragHandleProps)}
              onMouseDown={(e) =>
                e.currentTarget.focus({ preventScroll: true })
              }
            >
              {children(
                <BlockRow
                  block={block}
                  blockId={id}
                  isDragging={isDragging}
                  removeByBlockId={remove}
                  onChangeData={updateFieldsData}
                  dragHandleProps={
                    (contentBlockDragHandles && dragProvided.dragHandleProps) ||
                    undefined
                  }
                />,
                index
              )}
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export const DroppableEditor: React.FC<{
  children: (child: React.ReactElement, index: number) => React.ReactElement;
}> = ({ children }) => {
  const blocks = React.useContext(BlocksEditorContext);
  const isStart = blocks.instances.length < 1;

  const className = cx(styles.instances, {
    [styles.start]: isStart,
    [styles.list]: !isStart,
  });

  const { enable, disable } = useNavigationBlockerContext();
  const renderStatus = useRenderStatus();

  React.useEffect(() => {
    if (renderStatus.isValid) {
      disable();
    } else {
      enable();
    }
  }, [disable, enable, renderStatus.isValid]);

  return (
    <Droppable droppableId={blocks.droppableId}>
      {(dropProvided) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <div
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          className={className}
          data-test="compose-canvas"
        >
          <RenderStatusContext.Provider value={renderStatus}>
            {isStart ? <DropStart /> : <DropList>{children}</DropList>}
          </RenderStatusContext.Provider>
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
    /* eslint-enable react/jsx-props-no-spreading */
  );
};
