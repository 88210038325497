import React, { useState } from 'react';
import cx from 'classnames';
import { Button } from 'DesignSystem/Form';
import { Box, ClickDropdown, Popover, Tooltip } from 'DesignSystem/Components';
import { styles as typography } from 'DesignSystem/Typography';
import { Person } from 'shared/icons';
import { VariableTextInput } from 'shared/VariableTextInput';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { useContextCommunication } from 'hooks/context_communication';
import { ContextCommunication } from 'models/context-communication';
import inputStyles from 'shared/TextInput/text-input.module.css';
import styles from './PersonalizationInput.module.css';

type PersonalizationInputProps = {
  value: string;
  placeholder?: string;
  inputPrefixText?: string;
  autoFillText?: string;
  onChange: (val: string) => void;
  maxSize?: number;
  errorMessage?: boolean;
  disabled?: boolean;
  id?: string;
};

export const PersonalizationInput: React.FC<PersonalizationInputProps> = ({
  value,
  onChange,
  placeholder,
  inputPrefixText,
  autoFillText,
  maxSize,
  disabled = false,
  id,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id: programId } = useProgram();
  const { liquidVariables } = usePublisher();
  const { data: communicationMentionsData4DropDown } = useContextCommunication(
    programId
  );
  const {
    data: communicationMentionsData4Validation,
  } = useContextCommunication(programId, false);
  const variables4Validation: ContextCommunication[] = [
    ...(communicationMentionsData4Validation ?? []),
    ...(liquidVariables ?? []),
  ];
  const [caretPosition, setCaretPosition] = useState(0);

  const dropdownRenderProp = (dismiss: () => void) => (
    <Box style={{ cursor: 'auto', position: 'relative' }}>
      <Popover centered padding={0}>
        <Box width={256} className={styles.popoverContainer}>
          {communicationMentionsData4DropDown?.map((variable) => (
            <Box
              style={{ display: 'block' }}
              className={styles.listItem}
              onClick={() => {
                const part1 = value.substring(0, caretPosition);
                const part2 = value.substring(caretPosition);
                const variableKey = `{{${variable.key}}}`;
                const newPosition = part1.length + variableKey.length + 1;

                onChange(`${part1} ${variableKey} ${part2}`.trim());
                setCaretPosition(newPosition);
                dismiss();
              }}
              key={`variable-select-${variable.key}`}
            >
              <Box className={cx(styles.variableName, typography.Bold)}>
                {variable.name}
              </Box>
              <Box
                className={cx(styles.variableDescription, typography.Caption)}
              >
                {variable.description}
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );

  return (
    <Box className={cx(inputStyles.input, styles.inputContainer)}>
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        placement="bottom-end"
        isOpen={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <Button
          className={styles.clearButton}
          label={
            <Tooltip content="Add Variables" placement="top">
              <div className={styles.dropdownButton}>
                <Person />
                <span
                  className={styles.dropdownButtonState}
                  style={
                    isOpen
                      ? { borderBottom: '4px solid #222' }
                      : { borderTop: '4px solid #333' }
                  }
                />
              </div>
            </Tooltip>
          }
        />
      </ClickDropdown>
      <Box style={{ display: 'flex' }}>
        {inputPrefixText && (
          <span className={styles.inputPrefixText}>{inputPrefixText}</span>
        )}
        <Box style={{ flex: '1' }}>
          <VariableTextInput
            text={value}
            placeholder={placeholder}
            autoFillText={autoFillText}
            setText={onChange}
            variables={variables4Validation || []}
            classNames={cx(inputStyles.input, styles.input)}
            variableTextClassName={styles.variableText}
            maxSize={maxSize}
            disableSuggest
            clearPadding
            disabled={disabled}
            onCaretMoved={setCaretPosition}
            id={id}
          />
        </Box>
      </Box>
    </Box>
  );
};
