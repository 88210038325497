import React from 'react';

import { useJourneyState } from 'contexts/journey';
import { StartStep } from 'models/journeys/journey';
import { Flex } from 'DesignSystem/Layout/Flex';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Box } from 'DesignSystem/Components';
import { JourneyModal, JourneyModalProps } from '../../../JourneyModal';
import { OneTimeBody } from './OneTimeBody';
import { EventBody } from './EventBody';
import { RecurringBody } from './RecurringBody';

export const PublishJourneyModal: React.FC<Pick<
  JourneyModalProps,
  'action' | 'actionsDisabled' | 'cancel'
>> = ({ action, actionsDisabled, cancel }) => {
  const { currentGraph } = useJourneyState();

  const { trigger, criterion } =
    currentGraph?.steps.find(
      (step): step is StartStep => step.type === 'start'
    ) ?? {};

  return (
    <JourneyModal
      name="Publish Journey"
      header="Publish Journey"
      body={
        <>
          {actionsDisabled ? (
            <Flex column>
              <LoadingSpinner />
              <Box padding={[8, 0, 0, 0]}>Publishing Journey</Box>
            </Flex>
          ) : (
            <>
              <Box margin={[0, 0, 16, 0]}>
                Publishing this journey will activate it based on the settings
                in the Start step
              </Box>
              {(trigger?.type === 'immediate' ||
                trigger?.type === 'scheduled') && (
                <OneTimeBody trigger={trigger} criterion={criterion} />
              )}
              {trigger?.type === 'event' && (
                <EventBody trigger={trigger} criterion={criterion} />
              )}
              {trigger?.type === 'recurring' && (
                <RecurringBody trigger={trigger} criterion={criterion} />
              )}
            </>
          )}
        </>
      }
      action={action}
      actionLabel="Publish"
      cancel={cancel}
      cancelLabel="Cancel"
      actionsDisabled={actionsDisabled}
    />
  );
};
