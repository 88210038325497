import { useMemo, useRef } from 'react';
import { FeedPreview } from 'models/feed-preview';
import { useProgram } from 'contexts/program';
import { getFeedPreview } from 'services/api-feed-preview';
import { PinnableContent } from 'models/topic';
import { useQuery } from 'react-query';

const emptyData: FeedPreview[] = [];

/**
 * While editing pinned posts we operate PinnableContent items, but for previewing we need FeedPreview items.
 * This hook fetches the FeedPreview items for the given topic and ids. It has internal caching to avoid calls
 * to the server when possible.
 * @param topicId - topic ID
 * @param pinnedContent - array of pinned content to convert to FeedPreview
 */
function usePinnedFeedPreview(
  topicId?: number | string,
  pinnedContent: PinnableContent[] = []
): { isLoading: boolean; data: FeedPreview[] } {
  const cacheRef = useRef<Record<number, FeedPreview>>({});
  const { id: programId } = useProgram();
  const ids = useMemo(() => pinnedContent.map((p) => p.id), [pinnedContent]);
  const { isLoading, data } = useQuery<FeedPreview[], Error>(
    ['feedPreviewsById', programId, topicId, ids],
    async () => {
      const cache = cacheRef.current;
      if (!topicId || !ids.length) {
        return [];
      }
      const missingIds = ids.filter((id) => !cache[id]);
      if (missingIds.length) {
        const missingPreviews = await getFeedPreview(
          programId,
          Number(topicId),
          {
            ids: missingIds,
          }
        );
        missingPreviews.forEach((preview) => {
          cache[preview.id] = preview;
        });
      }
      return ids.filter((id) => cache[id]).map((id) => cache[id]);
    }
  );
  return {
    isLoading,
    data: data || emptyData,
  };
}

export default usePinnedFeedPreview;
