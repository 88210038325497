import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClickDropdown } from 'DesignSystem/Components/ClickDropdown';
import { Box } from 'DesignSystem/Components/Box';
import { Popover } from 'DesignSystem/Components/Popover';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { useDebounce } from 'hooks/useDebounce';
import { useEmailAliasesInfiniteQuery } from 'hooks/email-alias';
import { useProgram } from 'contexts/program';
import { EmailAlias } from 'models/email-alias';
import { EmailSenderAlias } from 'models/publisher/settings';
import { Body, FieldLabel } from 'DesignSystem/Typography';
import { Tooltip } from 'DesignSystem/Components/Tooltip';
import { SearchInput } from 'shared/SearchInput';
import styles from './email-sender-alias.module.css';

interface SelectSenderAliasProps {
  disabled?: boolean;
  value?: EmailSenderAlias;
  onAliasChange: (value: EmailSenderAlias) => void;
  hasError?: boolean;
  asModal?: boolean;
}

type OptionType = {
  id: string;
  label: string;
  value?: EmailSenderAlias;
  defaultSender: boolean;
};

function buildOptions(data: EmailAlias[]): OptionType[] {
  return data.map(({ default: isDefault, id, senderEmail, name }) => ({
    id,
    label: isDefault
      ? `Default - ${name} <${senderEmail}>`
      : `${name} <${senderEmail}>`,
    value: { id, senderEmail, senderName: name },
    defaultSender: isDefault,
  }));
}

export const EmailSenderAliasField: React.FC<SelectSenderAliasProps> = ({
  disabled,
  value,
  onAliasChange,
  hasError,
  asModal = true,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const { id: programId } = useProgram();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedEmailAlias, setSelectedEmailAlias] = useState<
    EmailSenderAlias | undefined
  >(value);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useEmailAliasesInfiniteQuery({
    programId,
    search: useDebounce(searchText),
    status: ['active'],
  });

  useEffect(() => {
    setSelectedEmailAlias(value);
  }, [value]);

  const options = buildOptions(data || []);
  const rowIds = options.map(({ id }) => id);

  const renderSelectRow = useCallback(
    (rowId: string) => {
      const a = options.find(({ id }) => id === rowId);
      if (!a) return undefined;
      return (
        <Tooltip
          showDelay={1200}
          disabled={a.label.length <= 45}
          content={`${a.label}`}
          className={styles.Tooltip}
          maxWidth={1200}
        >
          <div className={styles.EmailAlias}>
            <Body>{a.label}</Body>
          </div>
        </Tooltip>
      );
    },
    [options]
  );

  const selectRow = useCallback(
    (rowId: string): void => {
      const a = options.find(({ id }) => id === rowId);
      if (!a?.value) return;
      setSelectedEmailAlias(a.value);
      onAliasChange(a.value);
    },
    [onAliasChange, options]
  );

  const selectedIds = selectedEmailAlias ? [selectedEmailAlias.id] : [];

  const selectEmailAliasDropdown = (dismiss: () => void) => (
    <Box margin={[-8, 0]} minWidth={400}>
      <Popover padding={4}>
        <InfiniteSelect
          existenceGranted={false}
          rowIds={rowIds}
          rowRenderProp={renderSelectRow}
          maxHeight={140}
          itemHeight={40}
          selectedIds={selectedIds}
          onSelectedIdsChange={() => {}}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          onHandleChange={(rowId: string) => {
            selectRow(rowId);
            dismiss();
          }}
          hoverFocus={false}
          clearDisabled
          searchTerm={searchText}
          onSearchTermChange={setSearchText}
          searchPlaceholder="Search email alias"
          noShadow
          multiSelect={false}
        />
      </Popover>
    </Box>
  );

  const closedInputValue =
    selectedEmailAlias &&
    `${selectedEmailAlias?.senderName} <${selectedEmailAlias?.senderEmail}>`;

  const openInputValue = searchText || '';

  return (
    <>
      <div data-test="Section-EmailSender">
        <FieldLabel>Email Alias</FieldLabel>
        <Box margin={[15, 0, 0, 0]}>
          <ClickDropdown
            dropdownRenderProp={selectEmailAliasDropdown}
            matchReferenceWidth
            disabled={disabled}
            onOpen={() => {
              setIsOpen(true);
              inputRef?.current?.focus();
            }}
            onClose={() => {
              setIsOpen(false);
              setSearchText('');
              inputRef?.current?.blur();
            }}
            asModal={asModal}
            autoUpdate
            referencePress={false}
            ignoreKeys
          >
            <div>
              <SearchInput
                errorMessage="Email must be present"
                hasError={hasError}
                ref={inputRef}
                placeholder={
                  isOpen ? 'Search email alias' : 'Select an Email Alias'
                }
                value={isOpen ? openInputValue : closedInputValue}
                onChange={setSearchText}
                hasClearButton={!isOpen && !!closedInputValue}
              />
            </div>
          </ClickDropdown>
        </Box>
      </div>
    </>
  );
};
