import React from 'react';
import { asserts } from 'utility/asserts';

export type ChannelSelectionContext = {
  /**
   * Controlled checked state of channels checkboxes.
   * Must be used in conjunction with `toggleChecked`.
   *
   * If `checked` is not provided, the component will manage its own state.
   */
  checked: (channel: keyof CheckedChannelsState) => boolean;
  /**
   * Event handler called when a channel checkbox is toggled.
   */
  toggleChecked: (channel: keyof CheckedChannelsState) => void;
  variant: 'configure' | 'test-send';
};

export type CheckedChannelsState = {
  email: boolean;
  notification_center: boolean;
  push: boolean;
};

type UseChannelSelectionStateOptions<
  TC extends Partial<CheckedChannelsState>
> = {
  initialCheckedChannels: TC;
};

type ChannelSelectionStateReturn<TC extends Partial<CheckedChannelsState>> = {
  /**
   * Check if a channel is in a checked state
   */
  checked: (channel: keyof TC) => boolean;
  /**
   * Toggle the checked state of a channel
   */
  toggleChecked: (channel: keyof TC) => boolean;
};

export function useChannelSelectionState<
  TC extends Partial<CheckedChannelsState>
>({
  initialCheckedChannels,
}: UseChannelSelectionStateOptions<TC>): ChannelSelectionStateReturn<TC> {
  const [checkedChannels, setCheckedChannels] = React.useState(
    initialCheckedChannels
  );

  function toggleChecked(channel: keyof TC) {
    setCheckedChannels((prev) => {
      return {
        ...prev,
        [channel]: !prev[channel],
      };
    });
    return !checkedChannels[channel];
  }

  function checked(channel: keyof TC) {
    return Boolean(checkedChannels[channel]);
  }

  return {
    checked,
    toggleChecked,
  };
}

export const ChannelSelectionContext = React.createContext<ChannelSelectionContext | null>(
  null
);
export function useChannelSelectionContext(): ChannelSelectionContext {
  const context = React.useContext<ChannelSelectionContext | null>(
    ChannelSelectionContext
  );
  asserts(
    context !== null,
    'useChannelSelection must be used within a ChannelSelectionProvider'
  );
  return context;
}
