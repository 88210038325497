import { ContentPermissions } from 'models/content-permission';
import { Permissions } from 'models/permissions';

export const defaultPermissions: Permissions = {
  contributorChannelIds: [],
  role: 'program_admin',
  roleV2: [],
  canPromoteTo: [],
  permissions: {
    addImportUsersAccess: true,
    advancedOrchestrationRulesAccess: true,
    audienceAccess: true,
    calendarAccess: true,
    campaignDurationAccess: true,
    campaignsAccess: true,
    canFilterByAuthor: true,
    capabilitiesAccess: true,
    confContentAccess: true,
    configureAccess: true,
    configureAccountAccess: true,
    configureActivityFeedAccess: true,
    configureAdvocacyAccess: true,
    configureAliasesAccess: true,
    configureAttributeManagementAccess: false,
    configureBoxKnowledgeManagementAccess: false,
    configureCampaignSettingsAccess: false,
    configureExperienceSettingsAccess: true,
    configureExperienceUserSearch: true,
    configureMicroappsAccess: true,
    configureRoleAccess: true,
    configureSetPersonalizedFields: true,
    configureTopicLimit: true,
    contentFeatureAccess: true,
    contributorAudiencesAccess: true,
    designsAccess: true,
    emailAliasAccess: true,
    emailSenderAliasAccess: true,
    featureEditAccess: false,
    feedAccess: true,
    hideUserAccess: false,
    initiativeAccess: true,
    insightsAccess: true,
    insightsPlusAccess: false,
    journeysAccess: false,
    libraryAccess: true,
    libraryCustomBlocksAccess: false,
    libraryFontsAccess: true,
    libraryImagesAccess: true,
    linkCustomSlug: false,
    manageAudienceAccess: true,
    manageTemplateAccess: true,
    moderateCommentAccess: true,
    moderateContentAccess: true,
    newAudienceAccess: true,
    peopleAccess: true,
    postAcknowledgmentAccess: true,
    publishAccess: true,
    publishCampaign: true,
    securityAccess: true,
    sendAssistantAccess: true,
    sendEmailAccess: true,
    sendPushAccess: true,
    setAsCriticalAccess: true,
    setFeatured: true,
    setVisibility: true,
    topicsAccess: true,
    updateAudienceAccess: true,
    userExportAccess: true,
    userImportAccess: true,
    workflowTemplatesAccess: true,
    workflowsAccess: true,
  },
};

export const defaultContentPermissions: ContentPermissions = {
  canEdit: false,
  canPublish: false,
  errors: [],
  restrictedFields: [],
};
