import React from 'react';
import * as Type from 'DesignSystem/Typography';
import { FieldInput } from 'DesignSystem/Form';
import { VideoFieldData } from 'models/donkey';
import {
  ExternalVideoStatus,
  Video as VideoObject,
  VideoProcessingStatus,
} from 'models/video';
import { Icon } from 'shared/Icon';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import { FieldFormProps } from '../../../../useFieldForm';
import { MediaUploader } from '../MediaUploader';
import { UploadedVideoPreview } from './UploadedVideoPreview';
import { ExternalVideo } from './ExternalVideo';
import { useVideoFile } from '../../hooks/useVideoFile';
import styles from '../video.module.css';

export type VideoFileProps = {
  video?: VideoObject;
  uploadProcessingStatus: VideoProcessingStatus;
  upload: (file: File) => void;
  externalVideoStatus: ExternalVideoStatus;
  setUrl: (url: string) => void;
  fieldData: FieldFormProps<VideoFieldData>['data'];
  onRemove: () => void;
  isLoadingVideoFromFeed: boolean;
  fieldHeader?: string;
  tooltipMessage?: string;
  lockPreview?: boolean;
  isCaptionUpdated?: boolean | undefined;
};

export const VideoFile: React.FC<VideoFileProps> = ({
  video,
  uploadProcessingStatus,
  upload,
  externalVideoStatus,
  setUrl,
  fieldData,
  onRemove,
  isLoadingVideoFromFeed,
  fieldHeader,
  tooltipMessage,
  lockPreview,
  isCaptionUpdated,
}) => {
  const {
    isUploading,
    isProcessing,
    maxVideoFileSize,
    showUploadVideo,
    showExternalVideo,
    errorMessage,
    menu,
    fileInput,
    externalInput,
  } = useVideoFile({
    fieldData,
    video,
    upload,
    setUrl,
    uploadProcessingStatus,
    externalVideoStatus,
  });

  const content = React.useMemo(() => {
    if (showUploadVideo || lockPreview)
      return (
        <UploadedVideoPreview
          video={video}
          processingStatus={uploadProcessingStatus}
          showProcessingBorder={false}
          processingCaptions={isCaptionUpdated}
        />
      );

    if (showExternalVideo)
      return (
        <ExternalVideo
          video={video}
          isLoading={externalVideoStatus.isLoading}
        />
      );

    if (isLoadingVideoFromFeed)
      return (
        <div
          className={styles.processingVideoContainer}
          style={{ height: '200px' }}
        >
          <Icon iconType="SVG" iconName="Video" size={50} />
        </div>
      );

    return null;
  }, [
    externalVideoStatus.isLoading,
    isLoadingVideoFromFeed,
    uploadProcessingStatus,
    showExternalVideo,
    showUploadVideo,
    lockPreview,
    video,
    isCaptionUpdated,
  ]);

  const headerTooltip = fieldHeader ? (
    <HoverTooltip
      align="left"
      content={<Tooltip description={tooltipMessage} />}
    />
  ) : (
    <></>
  );

  return (
    <>
      <FieldInput
        fieldSetClass={styles.videoPreviewContainer}
        legend={
          <>
            <Type.FieldLabel>{fieldHeader || 'Video File'}</Type.FieldLabel>
            {headerTooltip}
          </>
        }
      >
        <MediaUploader
          menu={menu}
          placeholderTitle="Add a Video"
          placeholderBody={`${maxVideoFileSize} max`}
          onRemove={onRemove}
          removeTitle="Remove Video File"
          disableSourceMenu={isUploading || isProcessing}
          isPreparing={isLoadingVideoFromFeed}
          isUploading={isUploading}
          isProcessing={isProcessing}
          lockVideoPreview={lockPreview}
        >
          {content}
        </MediaUploader>
        {errorMessage && (
          <Type.Body color={Type.color.redFull}>{errorMessage}</Type.Body>
        )}

        {fileInput}
        {externalInput}
      </FieldInput>
    </>
  );
};
