import { useProgram } from 'contexts/program';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
  useUpdateProgramCustomizations,
} from 'hooks/feature-flags';
import { FeatureFlag } from 'models/feature-flag';
import {
  EngagementBoost,
  defaultEngagementBoost,
  enabledEngagementBoost,
  withEngagementBoostDefaults,
  withModifiers,
} from 'models/publisher/engagement-boost';
import { useCallback, useEffect, useMemo, useState } from 'react';
import equal from 'fast-deep-equal';

export type EngagementBoostOptions = {
  id: number;
  label: string;
  checked: boolean;
};

export const useEngagementBoostDefaults = (
  onSaveSuccess?: () => void,
  onSaveError?: () => void
): {
  enabled: boolean;
  engBoostDefaultsValues: Array<EngagementBoostOptions>;
  engBoostDefaults: EngagementBoost;
  isDefault: boolean;
  setEngBoostDefaults: (val: EngagementBoost) => void;
  setToDefaults: () => void;
  onSave: () => void;
} => {
  const { id: programId } = useProgram();
  const { data: programCustomizations } = useProgramCustomizationsQuery(
    programId
  );
  const { update } = useUpdateProgramCustomizations(programId, {
    onSuccess: onSaveSuccess,
    onError: onSaveError,
  });
  const engBoostDefaultsEnabled = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  ).data?.value;
  const newDeliveryPageEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  ).data?.value;
  const initialEngBoostDefaults = useMemo(
    () =>
      withModifiers(
        [
          withEngagementBoostDefaults(
            programCustomizations?.engagementBoostDefaults?.value,
            engBoostDefaultsEnabled
          ),
        ],
        defaultEngagementBoost
      ),
    [
      engBoostDefaultsEnabled,
      programCustomizations?.engagementBoostDefaults?.value,
    ]
  );

  const [engBoostDefaults, setEngBoostDefaults] = useState(
    initialEngBoostDefaults
  );
  useEffect(() => {
    setEngBoostDefaults(initialEngBoostDefaults);
  }, [initialEngBoostDefaults, setEngBoostDefaults]);

  const engBoostDefaultsValues: Array<EngagementBoostOptions> = [
    {
      id: 1,
      label: 'Optimized Delivery',
      checked: initialEngBoostDefaults.optimizedDeliveryEnabled,
    },
    {
      id: 2,
      label: 'Automatic Retargeting',
      checked: initialEngBoostDefaults.retries === -1,
    },
  ];

  const isDefault = equal(engBoostDefaults, enabledEngagementBoost);
  const setToDefaults = () => setEngBoostDefaults(enabledEngagementBoost);
  const onSave = useCallback(() => {
    const json = JSON.stringify(engBoostDefaults);

    const featureFlag: FeatureFlag = {
      value: json,
      programId,
      type: 'engagement_boost_defaults',
      label: 'Engagement Boost Defaults',
    };

    update(featureFlag);
  }, [engBoostDefaults, programId, update]);

  return {
    enabled: newDeliveryPageEnabled && engBoostDefaultsEnabled,
    engBoostDefaultsValues,
    engBoostDefaults,
    isDefault,
    setEngBoostDefaults,
    setToDefaults,
    onSave,
  };
};
