import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useJourneyState } from 'contexts/journey';
import { JourneyMode } from 'models/journeys/journey';
import {
  useJourneyCreateDraftAction,
  useJourneyUnArchiveAction,
} from 'hooks/journeys/useJourneyActions';
import { asserts } from 'utility/asserts';
import { PublishFlow } from './PublishFlow';
import { PrimaryButton } from './Buttons';
import { CancelPublishing } from './CancelPublish';

type CallToActionProps = {
  openPublishErrorsModal: () => void;
};

// Switches between Journey actions as appropriate based on state and view mode
const CallToActionButton: React.FC<CallToActionProps> = ({
  openPublishErrorsModal,
}) => {
  const {
    journeyMode,
    journey,
    draftCreatingProcess,
    setDraftCreatingProcess,
  } = useJourneyState();
  const navigate = useNavigate();
  const { id: programId } = useProgram();

  const openDraft = () => {
    navigate(`/${programId}/app/journeys/${journey?.id}/edit`);
  };

  const {
    call: createDraft,
    isLoading: isDraftCreating,
  } = useJourneyCreateDraftAction(journey);

  useEffect(() => {
    setDraftCreatingProcess(isDraftCreating);
  }, [setDraftCreatingProcess, isDraftCreating]);

  const {
    call: unArchiveJourney,
    isLoading: isUnarchiving,
  } = useJourneyUnArchiveAction(journey, openDraft);

  if (journey?.state === 'processing') {
    asserts(
      journey?.id !== undefined,
      'A journey in processing state must have an id'
    );
    return <CancelPublishing id={journey.id} />;
  }

  if (journey?.state === 'archived') {
    return (
      <PrimaryButton
        label={isUnarchiving ? 'Unarchiving...' : 'Unarchive'}
        onClick={unArchiveJourney}
      />
    );
  }

  if (journeyMode === JourneyMode.View && journey?.draftGraph) {
    // Edit
    return <PrimaryButton label="Open Draft" onClick={openDraft} />;
  }

  if (journeyMode === JourneyMode.View && !journey?.draftGraph) {
    // Create Draft
    return (
      <PrimaryButton
        label={draftCreatingProcess ? 'Creating Draft...' : 'Edit'}
        onClick={createDraft}
        disabled={draftCreatingProcess}
      />
    );
  }

  if (journeyMode === JourneyMode.Edit) {
    // Publish/Promote
    return <PublishFlow openPublishErrorsModal={openPublishErrorsModal} />;
  }

  return null;
};

export default CallToActionButton;
