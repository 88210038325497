import React from 'react';
import * as FlexLayout from 'DesignSystem/Layout/Flex';
import { CommunicationStep } from 'models/journeys/journey';
import { useProgram } from 'contexts/program';
import {
  PreviewTab,
  PreviewType,
  usePreview,
} from 'contexts/publisher/compose/preview';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Switcher } from 'components/publisher/previews/Switcher';
import { Box } from 'DesignSystem/Components';
import compose from 'components/publisher/theme/compose.module.css';
import cx from 'classnames';
import { useFlashMessage } from 'contexts/flasher';
import { useDesignQuery } from 'hooks/design';
import { defaultPost } from 'models/publisher/post';
import styles from './CommunicationPreview.module.css';

export const CommunicationPreview: React.FC<{
  step: CommunicationStep;
}> = ({ step }) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();

  const { data, isLoading: isDesignLoading } = useDesignQuery({
    programId,
    id: step.designId ?? 'new',
  });

  const {
    tabs,
    tab,
    setTab,
    html,
    isLoading: isPreviewLoading,
    errors,
    webFontsEnabled,
    toggleWebFonts,
  } = usePreview(
    {
      blocks: data?.blocks ?? [],
      styles: data?.styles ?? defaultPost.styles,
      settings: defaultPost.settings,
    },
    [PreviewType.microapp]
  );

  const iframe = React.useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState(2000);

  const onLoad = () => {
    const scrollHeight = iframe.current?.contentDocument?.body?.scrollHeight;
    if (scrollHeight) setHeight(scrollHeight + 10);
  };

  React.useEffect(() => {
    if (!errors.length) return;

    setFlashMessage({
      severity: 'error',
      message: errors.map((e) => e.message).join('\n'),
    });
  }, [errors, setFlashMessage]);

  const handleSetTab = (newTab: PreviewTab) => {
    if ((newTab.name === PreviewType.email) === webFontsEnabled) {
      toggleWebFonts();
    }
    setTab(newTab);
  };

  return (
    <FlexLayout.Flex spread>
      <Box className={cx(styles.previewContainer, compose.main)}>
        {tab && (
          <tab.frame>
            {isPreviewLoading ? (
              <div
                className="flex-centered kai-flex-row"
                style={{
                  margin: '100px auto',
                }}
              >
                <LoadingSpinner />
              </div>
            ) : (
              <iframe
                title="Preview"
                srcDoc={html}
                onLoad={onLoad}
                ref={iframe}
                style={{
                  width: '100%',
                  height: `${height}px`,
                  minHeight: '100%',
                }}
              />
            )}
          </tab.frame>
        )}
        {!isDesignLoading && (
          <Switcher
            tab={tab}
            tabs={tabs}
            setTab={handleSetTab}
            className={styles.switcher}
          />
        )}
      </Box>
    </FlexLayout.Flex>
  );
};
