import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { FAIcon } from 'shared/Icon/FAIcon';
import { useInputHighlighter } from 'hooks/inclusive-language/useInputHighlighter';
import { useProgram } from 'contexts/program';
import { useContextCommunication } from 'hooks/context_communication';
import { VariableTextInput } from 'shared/VariableTextInput';
import { DateTime } from 'luxon';
import { DateTimeInput } from 'shared/DateTimeInput';
import { ClickDropdown } from 'shared/ClickDropdown';
import { ChevronDown } from 'shared/icons';
import { Notification } from 'models/notification';
import { MAX_PREVIEW_TEXT_SIZE } from 'models/context-communication';
import style from './notification-field.module.css';
import { InputTextField } from './InputTextField';

const useNotificationDateTime = (
  notification: Notification,
  lowerLimit: DateTime
) => {
  let dateTimeTitle;
  if (notification?.isPublishDateTime) {
    dateTimeTitle = 'Send at publish time';
  } else if (notification?.dateTime) {
    dateTimeTitle = notification.dateTime.toFormat('dd LLL, hh:mma');
  } else {
    dateTimeTitle = 'Select date';
  }
  // TODO: This logic belongs in settings-fields
  //    Should be -- When publishAt is updated,
  //    if notifcation[0].isPublishedAt is true,
  //    then should upate notifications[0].dateTime
  //    to match publicationAt
  //    Should be able to depend on notification[0].dateTime to be accurate if it exists
  const dateTime = notification?.dateTime ? notification.dateTime : lowerLimit;

  return {
    dateTime,
    dateTimeTitle,
  };
};

const NotificationDatePicker: React.FC<{
  disabled?: boolean;
  dateTime: DateTime;
  dateTimeTitle: string;
  lowerLimit: DateTime;
  onChangeDate: (date: DateTime) => void;
}> = ({ disabled, dateTime, dateTimeTitle, lowerLimit, onChangeDate }) => {
  const dropdownProp = () => {
    return (
      <DateTimeInput
        onChange={onChangeDate}
        value={dateTime}
        lowerLimit={lowerLimit}
      />
    );
  };

  return (
    <ClickDropdown disabled={disabled} dropdownRenderProp={dropdownProp} upward>
      <span>
        <div className={style.datePickerButton}>
          <span>{dateTimeTitle}</span>
          <ChevronDown />
        </div>
      </span>
    </ClickDropdown>
  );
};

const AltTextTooltip: React.FC<{ className?: string }> = React.memo(
  ({ className }) => (
    <div className={className}>
      <p>
        Preview text may be shown on email clients below the subject line. We
        recommend adding preview text to give users more information on what
        they can expect to find in your email.
      </p>
    </div>
  )
);

export const NotificationField: React.FC<{
  isDisabled: (n: Notification) => boolean;
  index?: number;
  isPreviewTextDisabled: boolean;
  notification: Notification;
  canDelete: boolean;
  showDatePicker: boolean;
  lowerLimit: DateTime;
  placeholder?: string;
  publishedAt?: DateTime;
  onChangeText: (value: string) => void;
  onChangePreviewText: (value: string) => void;
  onChangeDate: (value: DateTime) => void;
  onDelete: () => void;
}> = ({
  index,
  isDisabled,
  isPreviewTextDisabled,
  notification,
  canDelete,
  showDatePicker,
  lowerLimit,
  placeholder,
  onChangeText,
  onChangePreviewText,
  onChangeDate,
  onDelete,
}) => {
  const { dateTime, dateTimeTitle } = useNotificationDateTime(
    notification,
    lowerLimit
  );

  const realLowerLimit = lowerLimit.set({
    minute: lowerLimit.get('minute') - 15,
  });

  const { text, previewText } = notification;
  const { id: programId } = useProgram();

  const highlighter = useInputHighlighter<HTMLTextAreaElement>(
    programId,
    text,
    onChangeText
  );

  const { data: communicationMentionsData } = useContextCommunication(
    programId,
    false
  );

  const disabled = isDisabled(notification);

  const getErrors = (): string[] => {
    const checkErrors = [
      {
        // There is no hard limit on the notification text, but this is a quick suggestion
        // to prevent the user from adding long messages.
        condition: () => text.length > 120,
        errorMessage:
          'Your notification text or email subject limit may not appear correctly if you exceed the character limit.',
      },
      {
        condition: () => showDatePicker && dateTime < realLowerLimit,
        errorMessage: `Notification cannot be scheduled before publish time of ${realLowerLimit.toFormat(
          'dd LLL, hh:mma'
        )}`,
      },
    ];

    return checkErrors
      .filter((err) => err.condition())
      .map((err) => err.errorMessage);
  };

  // Using the index instead of the notification order to ensure the tooltip always appears on the top.
  const isFirstNotification = index === 0;

  return (
    <div className={style.notification}>
      <div ref={highlighter.legendRef} className={style.legend}>
        <FAIcon name="lightbulb" />
      </div>
      <Flex column spread className={style.textContainer}>
        <InputTextField
          label="Subject Line / Push Notification Text"
          disabled={disabled}
        >
          <VariableTextInput
            key={`text-${index}`}
            text={text}
            disabled={disabled}
            placeholder={placeholder}
            setText={onChangeText}
            variables={communicationMentionsData || []}
          />
        </InputTextField>
        <InputTextField
          label="Preview"
          disabled={disabled || isPreviewTextDisabled}
          tooltip={
            isFirstNotification ? (
              <AltTextTooltip className="tooltip-content" />
            ) : null
          }
        >
          <VariableTextInput
            key={`preview-${index}`}
            text={previewText || ''}
            disabled={disabled || isPreviewTextDisabled}
            setText={onChangePreviewText}
            variables={communicationMentionsData || []}
            maxSize={MAX_PREVIEW_TEXT_SIZE}
          />
        </InputTextField>
        <div className={style.error}>
          {getErrors().map((err) => (
            <p>
              <FAIcon name="exclamation-triangle" /> {err}
            </p>
          ))}
        </div>
      </Flex>
      <div className={style.datePicker}>
        {showDatePicker && (
          <NotificationDatePicker
            disabled={disabled}
            dateTime={dateTime}
            dateTimeTitle={dateTimeTitle}
            onChangeDate={onChangeDate}
            lowerLimit={realLowerLimit}
          />
        )}
      </div>

      <div className={style.deleteNotificationWrapper}>
        {!disabled && canDelete && (
          <button
            type="button"
            className={style.deleteNotification}
            onClick={onDelete}
          >
            <FAIcon name="trash" />
          </button>
        )}
      </div>
    </div>
  );
};
