import React, { useState } from 'react';
import {
  attachmentToField,
  DefinitionBlock,
  FieldType,
  imageToField,
  VideoFieldData,
  videoToField,
} from 'models/publisher/block';
import { useAttachmentUploader } from 'hooks/useAttachmentUploader';
import { useDesignContext } from 'contexts/design';
import { useFlashMessage } from 'contexts/flasher';
import { useProgramIdState } from 'contexts/program';
import { useVideoUploader } from 'hooks/useVideoUploader';
import { useContentImageUploader } from 'hooks/useContentImage';
import {
  blockNode,
  createDropzone,
  createLoading,
  DropzoneSettings,
  editors,
  isMouseOver,
  rect,
  removeAllDropzones,
  removeLoading,
} from './helpers';
import styles from './inline-dropzone.module.css';

type PropsType = {
  block: DefinitionBlock;
  blockId: string;
  onChange: (fieldName: string, data: FieldType) => void;
  onUploading: (isUploading: boolean) => void;
};

type ReturnType = {
  setup: () => void;
  uninstall: () => void;
};

type FuncType = (props: PropsType) => ReturnType;

export const useInlineDropzone: FuncType = ({
  block,
  blockId,
  onChange,
  onUploading,
}) => {
  const field = React.useRef<string>();
  const [programId] = useProgramIdState();
  const { active: isDesignAsset } = useDesignContext();

  const { setFlashMessage } = useFlashMessage();

  const onUpload = React.useCallback(
    (fieldData) => onChange(`${field.current}`, fieldData),
    [onChange]
  );

  const [videoId, setVideoId] = useState<number | undefined>();

  const attachmentUploader = useAttachmentUploader({
    programId,
    onUpload: (data) => onUpload(attachmentToField(data)),
    isDesignAsset,
  });
  const imageUploader = useContentImageUploader({
    onUpload: (data) => onUpload(imageToField(data)),
    onError: (message) => {
      setFlashMessage({
        severity: 'error',
        message,
      });
    },
  });
  const videoUploader = useVideoUploader({
    programId,
    isDesignAsset,
    videoId,
    onTranscoding: (video) => onUpload(videoToField(video)),
    onSuccess: (video) => {
      onUpload(
        // merge and prefer defined properties
        // eslint-disable-next-line prefer-object-spread
        Object.assign(
          {},
          videoToField(video),
          block.field_data.video as VideoFieldData
        )
      );
    },
  });

  const isUploading = React.useMemo(
    () =>
      attachmentUploader.isUploading ||
      imageUploader.isUploading ||
      videoUploader.isUploading,
    [
      attachmentUploader.isUploading,
      imageUploader.isUploading,
      videoUploader.isUploading,
    ]
  );

  React.useEffect(() => {
    const node = blockNode(blockId);
    if (!node) return;

    const videoIdFromDom = Number(
      node
        .querySelector('div[data-video-numeric-id]')
        ?.getAttribute('data-video-numeric-id')
    );

    if (block.field_data.video || videoIdFromDom) {
      field.current = 'video';
      const videoIdFromFieldDataOrDom =
        (block.field_data.video as VideoFieldData).video_id || videoIdFromDom;
      setVideoId(videoIdFromFieldDataOrDom);
      videoUploader.ensureVideoId(videoIdFromFieldDataOrDom);
    }

    if (isUploading) {
      onUploading(true);
      createLoading(node);
    } else if (!isUploading) {
      onUploading(false);
      removeLoading(node);
    }
  }, [
    block.field_data.video,
    blockId,
    isUploading,
    onUploading,
    videoId,
    videoUploader,
  ]);

  const removeDropZone = React.useCallback((node: Element) => {
    const dropZone = node.querySelector(`.${styles.dropzone}`);

    if (dropZone) {
      node.removeChild(dropZone);
      (node as HTMLDivElement).style.removeProperty('position');
    }
  }, []);

  const onDrop = React.useCallback(
    (settings: DropzoneSettings) => (event: Event) => {
      event.preventDefault();
      const dragEvent = event as DragEvent;
      const node = blockNode(blockId);

      if (!node) return;
      if (!dragEvent.dataTransfer) return;

      field.current = settings.field;
      const { items } = dragEvent.dataTransfer;
      const file = items[0].getAsFile();
      if (!file) return;

      switch (settings.type) {
        case 'attachment': {
          attachmentUploader.update(file);
          break;
        }
        case 'image': {
          imageUploader.uploadFile(file);
          break;
        }
        case 'video': {
          videoUploader.upload(file);
          break;
        }
        default:
      }

      editors(node).forEach(removeDropZone);
    },
    [attachmentUploader, blockId, imageUploader, removeDropZone, videoUploader]
  );

  const addDropZone = React.useCallback(
    (node: Element) => {
      if (node.querySelector(`.${styles.dropzone}`)) return;

      const dropzoneData = node.querySelector('.dropzone-data');
      if (!dropzoneData) return;

      createDropzone(node, dropzoneData, onDrop);
    },
    [onDrop]
  );

  const onDragEnter = React.useCallback(
    (event: Event) => {
      const node = blockNode(blockId);
      if (!node) return;

      const blockRect = rect(node);
      if (!blockRect) return;

      const dragEvent = event as DragEvent;

      if (isMouseOver(dragEvent.clientX, dragEvent.clientY, blockRect)) {
        editors(node).forEach(addDropZone);
      }
    },
    [addDropZone, blockId]
  );

  const onDragLeave = React.useCallback(
    (event: Event) => {
      const node = blockNode(blockId);
      if (!node) return;

      const blockRect = rect(node);
      if (!blockRect) return;

      const dragEvent = event as DragEvent;
      if (!isMouseOver(dragEvent.clientX, dragEvent.clientY, blockRect)) {
        editors(node).forEach(removeDropZone);
      }
    },
    [blockId, removeDropZone]
  );

  const onDragOver = React.useCallback(
    (event: Event) => event.preventDefault(),
    []
  );

  const setup = React.useCallback(() => {
    document.addEventListener('dragenter', onDragEnter);
    document.addEventListener('dragleave', onDragLeave);
    document.addEventListener('dragover', onDragOver);
    document.addEventListener('drop', removeAllDropzones);
  }, [onDragEnter, onDragLeave, onDragOver]);

  const uninstall = React.useCallback(() => {
    document.removeEventListener('dragenter', onDragEnter);
    document.removeEventListener('dragleave', onDragLeave);
    document.removeEventListener('dragover', onDragOver);
    document.removeEventListener('drop', removeAllDropzones);
  }, [onDragEnter, onDragLeave, onDragOver]);

  return {
    setup,
    uninstall,
  };
};
