import React, { FC, createContext, useContext } from 'react';
import { AboutDesignContext } from './TopicForm/components/DesignView/contexts/about';
import { useTopicFormCtx } from './TopicForm/context';
import {
  TopicErrorDefinition,
  TopicErrorDefinitionName,
  TopicErrorGroupName,
} from './topicErrorDefinitions';

export function useApplicableErrors(): TopicErrorDefinition[] {
  const { state, topicPublishError: publishError } = useTopicFormCtx();
  const { aboutPage } = useContext(AboutDesignContext);
  const errors = useContext(TopicErrorContext);
  const errorsAreVisible = useContext(TopicErrorVisibilityContext);

  if (!errorsAreVisible) return [];

  const applicableErrors = errors.filter((error) =>
    error.rule({ formState: state.fields, publishError, aboutPage })
  );
  return applicableErrors;
}

export function useApplicableErrorsInGroup(
  groupName: TopicErrorGroupName
): TopicErrorDefinition[] {
  return useApplicableErrors().filter(
    (error) => error.group.name === groupName
  );
}

export function useIsErrorApplicable(
  errorName: TopicErrorDefinitionName
): boolean {
  return (
    useApplicableErrors().filter((error) => error.name === errorName).length > 0
  );
}

export const TopicErrorContext = createContext<readonly TopicErrorDefinition[]>(
  []
);

export const TopicErrorProvider: React.FC<{
  errorDefinitions: readonly TopicErrorDefinition[];
}> = ({ children, errorDefinitions }) => {
  return (
    <TopicErrorContext.Provider value={errorDefinitions}>
      {children}
    </TopicErrorContext.Provider>
  );
};

export const TopicErrorVisibilityContext = createContext<boolean>(true);

export const TopicErrorsApplicableOnlyAfterPublishAttempt: FC = ({
  children,
}) => {
  const { state } = useTopicFormCtx();
  const errorsVisible = state.fields.publishDraftAttempted;

  return (
    <TopicErrorVisibilityContext.Provider value={errorsVisible}>
      {children}
    </TopicErrorVisibilityContext.Provider>
  );
};

export const TopicErrorsAreAlwaysAppliable: FC = ({ children }) => {
  return (
    // eslint-disable-next-line react/jsx-boolean-value
    <TopicErrorVisibilityContext.Provider value={true}>
      {children}
    </TopicErrorVisibilityContext.Provider>
  );
};
