import { useEffect } from 'react';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';

export const useForYouSkip = (): void => {
  const { id: programId } = useProgram();
  const {
    settings,
    fields: {
      includeInForYou: { set: setIncludeInForYou },
    },
  } = useSettings();

  const turnOffAndHideForYou = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ForYou.Skip.DefaultOn'
  ).data?.value;

  useEffect(() => {
    if (
      turnOffAndHideForYou &&
      (settings.includeInForYou === undefined || settings.includeInForYou)
    )
      setIncludeInForYou(false);
  });
};
