import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'shared/ProgressBar/';
import { equalsCaseInsensitive, titleCase } from 'utility/text';
import {
  BaseChannelInsight,
  ChannelInsight,
} from 'models/publisher/orchestration-insights';
import { Settings } from 'models/publisher/settings';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { usePermissions } from 'contexts/permissions';
import styles from './orchestration-insights.module.css';

const defaults: BaseChannelInsight[] = [
  {
    name: 'Feed',
    value: 0,
  },
  {
    name: 'Email',
    value: 0,
  },
  {
    name: 'Assistant',
    value: 0,
  },
  {
    name: 'Push',
    value: 0,
  },
];

const getInsight = (insights: Array<BaseChannelInsight>, name: string) =>
  insights.find((insight) => equalsCaseInsensitive(insight.name, name));

export const Channels: React.FC<{
  channelsInsights: Array<ChannelInsight>;
  settings: Settings;
}> = ({ channelsInsights, settings }) => {
  const emailOnly = useFeatureFlag('emailOnly');
  const {
    permissions: { sendAssistantAccess, sendEmailAccess, sendPushAccess },
  } = usePermissions();

  const audienceOnly =
    settings.audiences.length > 0 && settings.contentTopics.length === 0;
  const topicOnly =
    settings.audiences.length === 0 && settings.contentTopics.length > 0;
  const noTarget =
    settings.audiences.length === 0 && settings.contentTopics.length === 0;

  const generateInsights = useCallback(
    (): BaseChannelInsight[] =>
      defaults
        .map((insight) => {
          // when email only campaign then only display email channel
          if (emailOnly) {
            return equalsCaseInsensitive(insight.name, 'email')
              ? getInsight(channelsInsights, insight.name)
              : undefined;
          }

          if (audienceOnly && equalsCaseInsensitive(insight.name, 'feed')) {
            return settings.includeInForYou
              ? { ...insight, value: 100 }
              : { ...insight, value: 0 };
          }

          // if campaign is only configured to have a topic then feed should always display 100%...
          if (topicOnly && equalsCaseInsensitive(insight.name, 'feed')) {
            return { ...insight, value: 100 };
          }

          // ... otherwise feed should use channel insight value or if not present display 100% or 0% if no targets specified
          if (!topicOnly && equalsCaseInsensitive(insight.name, 'feed')) {
            return (
              getInsight(channelsInsights, insight.name) || {
                ...insight,
                value: noTarget ? 0 : 100,
              }
            );
          }

          // don't display all other channels if campaign is only configured to have a topic
          if (topicOnly) {
            return undefined;
          }

          // when the user does not have access to channels default should be a value of 0
          if (
            (!sendEmailAccess &&
              equalsCaseInsensitive(insight.name, 'email')) ||
            (!sendAssistantAccess &&
              equalsCaseInsensitive(insight.name, 'assistant')) ||
            (!sendPushAccess && equalsCaseInsensitive(insight.name, 'push'))
          ) {
            return { ...insight, value: 0 };
          }

          return getInsight(channelsInsights, insight.name) || insight;
        })
        .filter((insight): insight is BaseChannelInsight => Boolean(insight)),
    [
      channelsInsights,
      emailOnly,
      noTarget,
      topicOnly,
      sendAssistantAccess,
      sendEmailAccess,
      sendPushAccess,
      settings.includeInForYou,
      audienceOnly,
    ]
  );

  const [insights, setInsights] = useState<BaseChannelInsight[]>(
    generateInsights()
  );

  useEffect(() => {
    setInsights(generateInsights());
  }, [channelsInsights, emailOnly, generateInsights, topicOnly]);

  return (
    <div className={styles.insights}>
      {insights.map((insight) => (
        <div className={styles.row} key={insight.name}>
          <div className={styles.day}>{titleCase(insight.name)}</div>
          <ProgressBar
            className={styles.progressBar}
            size={15}
            orientation="horizontal"
            max={100}
            value={insight.value}
            color="var(--color-gray90)"
          />
          <div className={styles.value}>{insight.value.toFixed(0)}%</div>
        </div>
      ))}
    </div>
  );
};
