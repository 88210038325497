/* eslint-disable  max-classes-per-file */
/* eslint-disable  @typescript-eslint/no-use-before-define */

import { DateTime } from 'luxon';

export abstract class DateRange {
  abstract clone(): DateRange;

  abstract toAbsolute(): AbsoluteRange;

  abstract toMetabase(): string;

  key = '';

  label = '';

  allowFutureSelection = false;

  static buildFromKey(
    key: string | RelativeRangeKey | DateRange = '',
    allowFutureSelection = false
  ): DateRange {
    if (key instanceof DateRange) return key.clone();

    const format = 'yyyy-MM-dd';

    if (RelativeRange.isValidKey(key)) {
      return RelativeRange.build(key, allowFutureSelection);
    }

    if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
      return new AbsoluteRange(
        DateTime.fromFormat(key, format),
        DateTime.fromFormat(key, format),
        allowFutureSelection
      );
    }

    if (/^\d{4}(-\d\d){2}~\d{4}(-\d\d){2}$/.test(key)) {
      const [start, end] = key.split('~');
      return new AbsoluteRange(
        DateTime.fromFormat(start, format),
        DateTime.fromFormat(end, format),
        allowFutureSelection
      );
    }

    if (/^Q[1-4]-\d{4}$/.test(key)) {
      const thisQuarter = RelativeRange.build(
        'thisquarter',
        allowFutureSelection
      );
      if (thisQuarter.toMetabase() === key) {
        return thisQuarter;
      }

      return RelativeRange.build('lastquarter');
    }

    return RelativeRange.build('past30days~');
  }

  isValid(): boolean {
    return this.toAbsolute().start < this.toAbsolute().end;
  }
}

export class AbsoluteRange extends DateRange {
  start: DateTime;

  end: DateTime;

  constructor(
    start: DateTime,
    end: DateTime = start,
    allowFutureSelection = false
  ) {
    super();
    if (end < start)
      throw Error(
        `invalid range: The end (${end}) is before the start (${start})`
      );
    this.allowFutureSelection = allowFutureSelection;
    this.start = start.startOf('day');

    // `allowFutureSelection = false` will set the end date to today for all selections
    const nowDateTime = DateTime.now().endOf('day');
    const endDateTime = end.endOf('day');
    this.end =
      !this.allowFutureSelection && nowDateTime < endDateTime
        ? nowDateTime
        : endDateTime;

    this.key = this.toMetabase();
    this.label = this.format('M/d/yy');
  }

  toMetabase(): string {
    return this.format('yyyy-MM-dd', '~');
  }

  format(format = 'ccc, LLL d', join_text = ' - '): string {
    const start = this.start.toFormat(format);
    const end = this.end.toFormat(format);
    if (start === end) return start;
    return [start, end].join(join_text);
  }

  // this format matches that expected in `DateRange.buildFromKey`
  formatStandard(): string {
    return this.format('y-MM-dd', '~');
  }

  clone(): AbsoluteRange {
    return new AbsoluteRange(this.start, this.end, this.allowFutureSelection);
  }

  toAbsolute(): AbsoluteRange {
    return this;
  }

  // this will extend the "end" date to tomorrow if "end = today"
  // this is necessary in situations where stringified datetimes cannot be used
  // so a date range of today with datetime may be 1/1/2022 00:00 - 1/1/2022 23:59
  // stringifying that with only dates will yield 1/1/2022 - 1/1/2022 which may yield incorrect results
  // stringifying to 1/1/2022 - 1/2/2022 instead will yield equivalent results if only using dates
  extendEndDateFromTodayToTomorrowMaybe(): AbsoluteRange {
    const endDateStr = this.end.toFormat('y-MM-dd');
    const startDateStr = this.start.toFormat('y-MM-dd');
    const nowDateStr = DateTime.now().toFormat('y-MM-dd');
    if (endDateStr === nowDateStr && endDateStr === startDateStr) {
      this.end = this.end.plus({ day: 1 });
    }
    return this;
  }
}

export type TimezoneKey =
  | 'GMT'
  | 'ECT'
  | 'ART'
  | 'EAT'
  | 'NET'
  | 'PLT'
  | 'IST'
  | 'BST'
  | 'VST'
  | 'CTT'
  | 'JST'
  | 'ACT'
  | 'AET'
  | 'SST'
  | 'NST'
  | 'MIT'
  | 'AST'
  | 'PST'
  | 'PNT'
  | 'CST'
  | 'IET'
  | 'PRT'
  | 'CNT'
  | 'AGT'
  | 'BET'
  | 'CAT'
  | 'WET'; // luxon library doesn't have an offsetLongName for WET, which we are using as label.

// | 'UTC' | 'EET' | 'MET' | 'HST' | 'MST' | 'EST' * These are some of the redundant zones, we can add on later *

export type RelativeRangeKey =
  | 'today'
  | 'thisweek'
  | 'lastweek'
  | 'thismonth'
  | 'lastmonth'
  | 'past30days~'
  | 'past90days~'
  | 'thisquarter'
  | 'lastquarter'
  | 'lastyear'
  | 'thisyear'
  | 'past2years'
  | 'past2years~';

export type TimezoneDict = Record<
  TimezoneKey,
  { offsetNameLong: string; offset: string }
>;

export class RelativeRange extends DateRange {
  constructor(
    readonly key: RelativeRangeKey,
    readonly label: string,
    readonly allowFutureSelection = false,
    readonly toAbsolute: () => AbsoluteRange,
    readonly toMetabase: () => string = () => key
  ) {
    super();
    this.allowFutureSelection = allowFutureSelection;
  }

  clone(): RelativeRange {
    return (
      RelativeRange.build(this.key, this.allowFutureSelection) ||
      RelativeRange.build('past30days~', this.allowFutureSelection)
    );
  }

  // hacky way of checking if key matches the string union
  static isValidKey(key: string): key is RelativeRangeKey {
    const keys = [
      'today',
      'thisweek',
      'lastweek',
      'thismonth',
      'lastmonth',
      'past30days~',
      'past90days~',
      'thisquarter',
      'lastquarter',
      'thisyear',
      'lastyear',
      'past2years',
      'past2years~',
    ] as RelativeRangeKey[];
    return keys.includes(key as RelativeRangeKey);
  }

  static build(
    key: RelativeRangeKey,
    allowFutureSelection = false
  ): RelativeRange {
    const now = DateTime.now();

    switch (key) {
      case 'today':
        return new RelativeRange(
          'today',
          'Today',
          allowFutureSelection,
          () => new AbsoluteRange(now)
        );
      case 'thisweek':
        return new RelativeRange(
          'thisweek',
          'This week',
          allowFutureSelection,
          () => {
            if (now.weekdayShort === 'Sun') {
              return new AbsoluteRange(now);
            }
            return new AbsoluteRange(
              now.startOf('week').minus({ days: 1 }),
              now,
              allowFutureSelection
            );
          }
        );
      case 'lastweek':
        return new RelativeRange(
          'lastweek',
          'Last week',
          allowFutureSelection,
          () =>
            new AbsoluteRange(
              now.minus({ weeks: 1 }).startOf('week').minus({ days: 1 }),
              now.minus({ weeks: 1 }).endOf('week').minus({ days: 1 })
            )
        );
      case 'thismonth':
        return new RelativeRange(
          'thismonth',
          'This month',
          allowFutureSelection,
          () =>
            new AbsoluteRange(now.startOf('month'), now, allowFutureSelection)
        );
      case 'lastmonth':
        return new RelativeRange(
          'lastmonth',
          'Last month',
          allowFutureSelection,
          () =>
            new AbsoluteRange(
              now.minus({ months: 1 }).startOf('month'),
              now.minus({ months: 1 }).endOf('month')
            )
        );
      case 'past30days~':
        return new RelativeRange(
          'past30days~',
          'Last 30 days',
          allowFutureSelection,
          () => new AbsoluteRange(now.minus({ days: 30 }), now)
        );
      case 'past90days~':
        return new RelativeRange(
          'past90days~',
          'Last 90 days',
          allowFutureSelection,
          () => new AbsoluteRange(now.minus({ days: 90 }), now)
        );
      case 'thisquarter':
        return new RelativeRange(
          'thisquarter',
          'This quarter',
          allowFutureSelection,
          () => {
            return new AbsoluteRange(
              now.startOf('quarter'),
              now,
              allowFutureSelection
            );
          },
          () => {
            return `Q${now.quarter}-${now.year}`;
          }
        );
      case 'lastquarter':
        return new RelativeRange(
          'lastquarter',
          'Last quarter',
          allowFutureSelection,
          () => {
            return new AbsoluteRange(
              now.minus({ quarters: 1 }).startOf('quarter'),
              now.minus({ quarters: 1 }).endOf('quarter')
            );
          },
          () => {
            const startOfPreviousQuarter = now
              .minus({ quarters: 1 })
              .startOf('quarter');
            return `Q${startOfPreviousQuarter.quarter}-${startOfPreviousQuarter.year}`;
          }
        );
      case 'lastyear':
        return new RelativeRange(
          'lastyear',
          'Last year',
          allowFutureSelection,
          () =>
            new AbsoluteRange(
              now.minus({ year: 1 }).startOf('year'),
              now.minus({ year: 1 }).endOf('year'),
              allowFutureSelection
            )
        );
      case 'past2years~':
      case 'past2years':
        return new RelativeRange(
          'past2years',
          'Past 2 years',
          allowFutureSelection,
          () =>
            new AbsoluteRange(now.minus({ year: 2 }), now, allowFutureSelection)
        );
      case 'thisyear':
        return new RelativeRange(
          'thisyear',
          'This year',
          allowFutureSelection,
          () =>
            new AbsoluteRange(now.startOf('year'), now, allowFutureSelection)
        );
      default:
        return RelativeRange.build('today', allowFutureSelection);
    }
  }
}

export class Timezone extends DateRange {
  constructor(
    readonly key: TimezoneKey,
    readonly label: string,
    readonly offset: string,
    readonly allowFutureSelection = false,
    readonly toAbsolute: () => AbsoluteRange,
    readonly toMetabase: () => string = () => key
  ) {
    super();
    this.allowFutureSelection = allowFutureSelection;
  }

  clone(): Timezone {
    return (
      Timezone.build(this.key, this.allowFutureSelection) ||
      Timezone.build_from_offset(
        DateTime.local().toFormat('ZZZZ'),
        this.allowFutureSelection
      )
    );
  }

  static keys = [
    'GMT',
    'ECT',
    'ART',
    'EAT',
    'NET',
    'PLT',
    'IST',
    'BST',
    'VST',
    'CTT',
    'JST',
    'ACT',
    'AET',
    'SST',
    'NST',
    'MIT',
    'AST',
    'PST',
    'PNT',
    'CST',
    'IET',
    'PRT',
    'CNT',
    'AGT',
    'BET',
    'CAT',
    'WET',
  ] as TimezoneKey[];

  static timezoneDict = {
    GMT: { offsetNameLong: 'UTC', offset: '+0000' },
    ECT: { offsetNameLong: 'Central Eruropean Summer Time', offset: '+0200' },
    NET: { offsetNameLong: 'Eastern Eruropean Standard Time', offset: '+0200' },
    EAT: { offsetNameLong: 'East Africa Time', offset: '+0300' },
    ART: { offsetNameLong: 'Armenia Standard Time', offset: '+0400' },
    PLT: { offsetNameLong: 'Pakistan Standard Time', offset: '+0500' },
    IST: { offsetNameLong: 'India Standard Time', offset: '+0530' },
    BST: { offsetNameLong: 'Bangladesh Standard Time', offset: '+0600' },
    VST: { offsetNameLong: 'IndoChina Time', offset: '+0700' },
    CTT: { offsetNameLong: 'China Standard Time', offset: '+0800' },
    JST: { offsetNameLong: 'Japan Standard Time', offset: '+0900' },
    ACT: {
      offsetNameLong: 'Austrailian Central Standard Time',
      offset: '+0930',
    },
    AET: {
      offsetNameLong: 'Austrailian Eastern Daylight Time',
      offset: '+1100',
    },
    SST: { offsetNameLong: 'Solomon Islands Time', offset: '+1100' },
    NST: { offsetNameLong: 'Newzeland Daylight Time', offset: '+1300' },
    MIT: { offsetNameLong: 'Apia Standard Time', offset: '+1300' },
    AST: { offsetNameLong: 'Alaska Daylight Time', offset: '-0800' },
    PST: { offsetNameLong: 'Pacific Daylight Time', offset: '-0700' },
    PNT: { offsetNameLong: 'Mountain Standard Time', offset: '-0700' },
    CST: { offsetNameLong: 'Central Daylight Time', offset: '-0500' },
    IET: { offsetNameLong: 'Eastern Daylight Time', offset: '-0400' },
    PRT: { offsetNameLong: 'Atlantic Standard Time', offset: '-0400' },
    CNT: { offsetNameLong: 'Newfoundland Daylight Time', offset: '-0230' },
    AGT: { offsetNameLong: 'Argentina Standard Time', offset: '-0300' },
    BET: { offsetNameLong: 'Brasilia Standard Time', offset: '-0300' },
    CAT: { offsetNameLong: 'Central Africa Time', offset: '+0200' },
    WET: { offsetNameLong: 'Western European Time', offset: '+0100' },
  } as TimezoneDict;

  // | 'UTC' | 'EET' | 'MET' | 'HST' | 'MST' | 'EST'
  // hacky way of checking if key matches the string union
  static isValidKey(key: string): key is RelativeRangeKey {
    return Timezone.keys.includes(key as TimezoneKey);
  }

  static build(key: TimezoneKey, allowFutureSelection = false): Timezone {
    const now = DateTime.local();

    switch (key) {
      case 'WET':
        return new Timezone(
          key,
          'Western European Time',
          now.setZone(key).toFormat('ZZZ'),
          allowFutureSelection,
          () =>
            new AbsoluteRange(
              now.startOf('year'),
              now.endOf('year'),
              allowFutureSelection
            )
        );

      default:
        return new Timezone(
          key,
          Timezone.timezoneDict[key]?.offsetNameLong,
          Timezone.timezoneDict[key]?.offset,
          allowFutureSelection,
          () =>
            new AbsoluteRange(
              now.startOf('year'),
              now.endOf('year'),
              allowFutureSelection
            )
        );
    }
  }

  static build_from_offset(
    offsetLong: string,
    allowFutureSelection = false
  ): Timezone {
    const now = DateTime.local();
    const return_key_from_offset = (offsetString: string): TimezoneKey => {
      for (let i = 0; i < Timezone.keys.length; i += 1) {
        if (offsetString === now.setZone(Timezone.keys[i]).offsetNameLong) {
          return Timezone.keys[i];
        }
      }
      return 'GMT';
    };
    const key = return_key_from_offset(offsetLong);
    return new Timezone(
      key,
      Timezone.timezoneDict[key]?.offsetNameLong,
      Timezone.timezoneDict[key]?.offset,
      allowFutureSelection,
      () =>
        new AbsoluteRange(
          now.startOf('year'),
          now.endOf('year'),
          allowFutureSelection
        )
    );
  }
}
