import React from 'react';
import { MenuPlacement } from 'react-select';
import { CheckListSelect } from './CheckListSelect';

export const StringCustomSelect: React.FC<{
  onChange: (value: string[]) => void;
  value: string[];
  options: Array<{ value: string; label: string }>;
  menuPlacement?: MenuPlacement;
}> = ({ onChange, value, options, menuPlacement = 'auto' }) => {
  const loadOptions = (searchTerm: string) => {
    return Promise.resolve(
      options.filter((item) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const optionValue = value.map(
    (v) => options.find((option) => option.value === v) || options[0]
  );

  return (
    <CheckListSelect
      defaultOptions={options}
      loadOptions={loadOptions}
      onChange={onChange}
      value={optionValue}
      dynamicCount={false}
      menuPlacement={menuPlacement}
    />
  );
};
