import * as React from 'react';
import cx from 'classnames';
import { useScrollbarWidth } from 'hooks/useScrollbarWidth';

export const MainEditor: React.FC<{
  header: React.ReactElement;
  main: React.ReactElement;
  sidebar?: React.ReactElement;
  footer?: React.ReactElement;
  splitBars?: boolean;
  mainWrapperRef?: React.RefObject<HTMLDivElement>;
}> = ({ header, main, sidebar, footer, splitBars = false, mainWrapperRef }) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const scrollbarWidth = useScrollbarWidth(mainWrapperRef ?? wrapperRef);

  return (
    <div className={cx('main-editor', { 'split-bars': splitBars })}>
      <header>{header}</header>
      <div className="main-editor-wrapper" ref={mainWrapperRef ?? wrapperRef}>
        <main>{main}</main>
        {sidebar && (
          <div
            className="main-editor-sidebar-wrapper"
            style={{
              width: `calc(var(--main-editor-sidebar-width) - ${scrollbarWidth}px)`,
              right: scrollbarWidth,
            }}
          >
            {sidebar}
          </div>
        )}
      </div>
      <footer>{footer}</footer>
    </div>
  );
};
