import { useMemo } from 'react';
import { useApiQuery } from 'hooks/common';
import {
  AudienceInsight,
  fetchOrchestrationInsights,
  InsightsResponse,
} from 'services/api-orchestration-insights';
import {
  buildParams,
  DEFAULT_INSIGHTS,
  GaugeData,
  Insights,
  InsightsParams,
  performanceSuggestion,
  ScheduleInsightByChannel,
  ScheduleInsightItem,
} from 'models/publisher/orchestration-insights';
import { DateTime } from 'luxon';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { audiencesToCriterion } from 'models/publisher/criterion';
import { Settings } from 'models/publisher/settings';
import { useFeatureFlagsQuery } from './feature-flags';

function buildGauges(performance: {
  noise: number;
  resonance: number;
}): GaugeData[] {
  return [
    {
      label: 'Performance',
      value: performance.resonance,
      signal: performance.resonance > 50 ? 'success' : 'error',
    },
    {
      label: 'Fatigue',
      value: performance.noise,
      signal: performance.noise > 50 ? 'error' : 'success',
    },
  ];
}

const channelDescriptor = {
  assistant: 'an Assistant notification',
  email: 'an Email',
  push: 'a Push notification',
  feed: 'the post in the selected topics or the For You feed',
};

function buildFormattedScheduleInsights(
  insights: AudienceInsight[]
): ScheduleInsightByChannel[] {
  const days: Record<number, ScheduleInsightItem[]> = {};

  insights.forEach((channel) => {
    days[channel.day] = days[channel.day] ?? [];
    days[channel.day].push({
      percentage: Math.ceil(channel.value),
      channelDescriptor: channelDescriptor[channel.name],
    });
  });

  return Object.entries(days).map(([day, items]) => ({
    label: `Day ${day}`,
    items,
  }));
}

function buildFormattedInsights(
  params: InsightsParams,
  response: InsightsResponse
): Insights {
  /*
    This is not returned by the API, but we do want to display it if
    the feed channel is enabled.
  */
  const feedInsight: AudienceInsight = {
    day: 1,
    name: 'feed',
    value: 100,
    count: response.sampleSize,
  };
  const {
    audienceInsights: audienceInsightRecords,
    competitionInsights,
    higherPriority,
    channelsInsights,
    scheduleInsights,
    maxRetries,
  } = response;

  if (
    params.channels.includes('feed') &&
    !response.audienceInsights.some((insight) => insight.name === 'feed')
  ) {
    audienceInsightRecords.push(feedInsight);
  }

  const audienceInsights = buildFormattedScheduleInsights(
    audienceInsightRecords
  );

  return {
    performance: {
      gauges: buildGauges(response.performance),
      suggestion: performanceSuggestion(
        response.performance.resonance,
        response.performance.noise
      ),
    },
    insights: [...audienceInsights],
    scheduleInsights,
    competitions: competitionInsights,
    higherPriority,
    channelsInsights,
    maxRetries,
  };
}

function fetchInsights(params?: InsightsParams): Promise<Insights> | null {
  if (params) {
    return fetchOrchestrationInsights(params).then((response) =>
      buildFormattedInsights(params, response)
    );
  }
  return null;
}

export function useOrchestrationInsights(
  settings: Settings,
  contentId?: number
): Insights {
  const { id: programId } = useProgram();
  const { audiences, publishedAt } = useDebounce(settings, 1);
  const criterion = audiencesToCriterion(audiences);
  const start = useMemo(() => publishedAt ?? DateTime.now(), [publishedAt]);

  const { data: newDeliveryPage } = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  );

  const params = buildParams(
    programId,
    settings,
    start,
    criterion,
    contentId,
    !!newDeliveryPage?.value
  );

  let queryKeyParams: Partial<InsightsParams> | undefined = params;
  if (params && !publishedAt) {
    // When `publishedAt` is undefined, the `epoch_start` and `epoch_end` are
    // derived from the current DateTime. This causes react-query not to return
    // a cached response because the params have changed.
    //
    // The query key will be constructed with these properties removed so
    // rendering between components that use this hook will be able to
    // immediately render the cached data.

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { epoch_start, epoch_end, ...rest } = params;
    queryKeyParams = rest;
  }

  const { data, isLoading, isFetching } = useApiQuery(
    'orchestration-insights',
    fetchInsights,
    params,
    queryKeyParams
  );
  const response = data ?? DEFAULT_INSIGHTS;
  return { ...response, isLoading: isLoading || isFetching };
}
