import React from 'react';
import { Alert, AlertType, Box, FormModal } from 'DesignSystem/Components';
import { Placeholder } from 'DesignSystem/Components/Placeholder';
import { Button, FieldInput } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { FetchedCaption } from 'hooks/video';
import {
  Trash,
  Plus,
  ChevronUpAlt,
  ChevronDownAlt,
  ExclamationTriangleOutlined,
} from 'shared/icons';

import {
  EditorContent,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor,
} from '@tiptap/react';
import Document from '@tiptap/extension-document';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import * as TiptacText from '@tiptap/extension-text';
import { lowlight } from 'lowlight/lib/core';
import cx from 'classnames';
import ParseError from 'srt-validator/dist/utils/parse-error';
import styles from '../video.module.css';
import { useCaptionSettings } from '../../hooks/useCaptionSettings';

export type CodeBlockProps = {
  extension: {
    storage: {
      lines: number;
      errors: ParseError[];
    };
  };
};

export type CaptionSettingsModalProps = {
  onClose: () => void;
  onCaptionSelect: (captionFile: File) => void;
  onCaptionRemove: (url: string) => void;
  isCaptionsUploading: boolean;
  currentCaption?: FetchedCaption;
};

const CodeBlockLineNumbers = (
  lines: number,
  errors: ParseError[]
): JSX.Element[] => {
  const newLines: JSX.Element[] = [];
  const lineNumbers = errors.map((e) => e.lineNumber as number);

  const lineHasError = (ln: number) => {
    return lineNumbers.includes(ln);
  };

  const hasErrorsBefore = (ln: number) => {
    return lineNumbers.indexOf(ln) > 0;
  };

  const hasErrorsAfter = React.useCallback(
    (ln: number) => {
      return lineNumbers.indexOf(ln) < lineNumbers.length - 1;
    },
    [lineNumbers]
  );

  const scrollToPreviousError = (i: number) => {
    if (hasErrorsBefore(i)) {
      const prevError = errors
        .slice()
        .reverse()
        .find((e) => e.lineNumber < i);
      if (prevError) {
        const line = document.getElementById(
          `codeline-id-${prevError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  const scrollToNextError = (i: number) => {
    if (hasErrorsAfter(i)) {
      const nextError = errors.slice().find((e) => e.lineNumber > i);
      if (nextError) {
        const line = document.getElementById(
          `codeline-id-${nextError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  for (let i = 1; i <= lines; i += 1) {
    const codeNode: JSX.Element = (
      <code
        className={cx(styles.lineNumberItem, {
          [styles.subtitleError]: lineHasError(i),
        })}
        id={`codeline-id-${i}`}
        key={`codeline-key-${i}`}
      >
        {lineHasError(i) && (
          <Flex spread className={styles.subtitlesLineError}>
            <span>{errors.find((e) => e.lineNumber === i)?.message}</span>
            <Flex spread className={styles.buttonsWrapper}>
              <Button
                minimal
                layoutOnly
                disabled={!hasErrorsBefore(i)}
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsBefore(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                onClick={() => scrollToPreviousError(i)}
                icon={<ChevronUpAlt />}
              />
              <Button
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsAfter(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                minimal
                disabled={!hasErrorsAfter(i)}
                layoutOnly
                onClick={() => scrollToNextError(i)}
                icon={<ChevronDownAlt />}
              />
            </Flex>
          </Flex>
        )}
        <>{i}</>
      </code>
    );
    newLines.push(codeNode);
  }

  return newLines;
};

const CodeBlockComponent = (props: CodeBlockProps) => {
  const { extension } = props;
  const { storage } = extension;
  const lines = storage?.lines === undefined ? 1 : storage.lines;
  const { errors } = storage;
  const lineNumbers = CodeBlockLineNumbers(lines < 6 ? 6 : lines, errors);
  return (
    <NodeViewWrapper className="code-block">
      <Flex start alignStart className={styles.subtitlesEditorWrapper}>
        <pre id="code-block-line-numbers" className={styles.lineNumbers}>
          {lineNumbers}
        </pre>
        <pre id="code-block-content" className={styles.subtitlesWrapper}>
          <NodeViewContent as="code" />
        </pre>
      </Flex>
    </NodeViewWrapper>
  );
};

export const CaptionSettings: React.FC<CaptionSettingsModalProps> = ({
  onClose,
  onCaptionSelect,
  onCaptionRemove,
  isCaptionsUploading,
  currentCaption,
}) => {
  const {
    onFormSubmit,
    captionText,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    isDisabled,
    validationErrors,
  } = useCaptionSettings({
    onCaptionSelect,
    onCaptionRemove,
    isCaptionsUploading,
    currentCaption,
  });

  const scrollToError = (lineNumber: number) => {
    const line = document.getElementById(`codeline-id-${lineNumber}`);
    if (line) {
      line.scrollIntoView();
    }
  };
  const length = React.useMemo(() => captionText?.split('\n').length || 0, [
    captionText,
  ]);

  const editor = useEditor(
    {
      editable: false,
      editorProps: {
        attributes: {
          class: styles.captionsEditor,
        },
      },
      extensions: [
        Document.extend({
          content: 'codeBlock+',
        }),
        TiptacText.Text,
        CodeBlockLowlight.extend({
          name: 'codeBlock',
          group: 'codeBlock',
          addStorage() {
            return {
              lines: length,
              errors: validationErrors || [],
            };
          },
          addNodeView() {
            return ReactNodeViewRenderer(CodeBlockComponent);
          },
        }).configure({
          defaultLanguage: 'plaintext',
          exitOnArrowDown: false,
          exitOnTripleEnter: false,
          lowlight,
        }),
      ],
      content: {
        type: 'doc',
        content: [
          {
            type: 'codeBlock',
            content: [
              {
                type: 'text',
                text: captionText,
              },
            ],
          },
        ],
      },
    },
    [captionText, length]
  );

  return (
    <FormModal
      entityText="Caption Settings"
      actionText=""
      submitLabel="Apply"
      onSubmit={() => {
        onFormSubmit();
        onClose();
      }}
      onCancel={onClose}
      disabled={isDisabled}
    >
      <Box className={styles.subtitlesInputWrapper}>
        <FieldInput>
          {captionText ? (
            <Box color={Text.color.gray90} background={Text.color.gray00}>
              <EditorContent editor={editor} />
              <Flex
                spread
                color={Text.background.gray05}
                className={styles.replace}
              >
                <Button onClick={onReplaceClick} label="Replace" layoutOnly />
                <Button onClick={onRemoveClick} icon={<Trash />} layoutOnly />
              </Flex>
            </Box>
          ) : (
            <div className={styles.clickable}>
              <Placeholder onClick={onUploadClick}>
                <Text.Subheading block bold>
                  Add Captions File
                </Text.Subheading>
                <Button circle compact icon={<Plus />} />
                <Text.Body block color={Text.color.gray50}>
                  Use an existing subtitles file (eg. SRT, VTT).
                </Text.Body>
              </Placeholder>
            </div>
          )}
          {validationErrors && validationErrors.length > 0 && (
            <Alert
              addon={
                <Button
                  onClick={() => scrollToError(validationErrors[0].lineNumber)}
                  compact
                  className={styles.errorButton}
                  layoutOnly
                  label="Click here to see errors"
                />
              }
              type={AlertType.error}
              message={`${validationErrors.length} error${
                validationErrors.length > 1 ? 's' : ''
              } detected`}
              bgColor="red"
              title="Validation errors"
              icon={
                <div className={styles.alertIconWrapper}>
                  <ExclamationTriangleOutlined />
                </div>
              }
              enableIcon
              compact
            />
          )}
          {errorMessage && (
            <Text.Body color={Text.color.redFull}>{errorMessage}</Text.Body>
          )}
          {fileInput}
        </FieldInput>
      </Box>
    </FormModal>
  );
};
