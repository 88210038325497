/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { DateTime } from 'luxon';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Duration, DurationOptions, DurationUnit } from 'models/duration';
import styles from './component-layout.module.css';

type PropTypes = {
  value: Duration;
  onDurationChange: (d: Duration) => void;
  startDate: DateTime | null;
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
};

export const DurationSelector: React.FC<PropTypes> = ({
  value,
  onDurationChange,
  startDate,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
}) => {
  React.useEffect(() => {
    const d = value.value;
    onDurationChange({ ...value, value: d, ignoreYear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreYear]);

  const handleDurationChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const d = event.target.value;
      const newValue = d ? parseInt(d, 10) : 0;
      onDurationChange({ ...value, value: Math.max(newValue, 0), ignoreYear });
    },
    [value, ignoreYear, onDurationChange]
  );
  const decreaseDuration = React.useCallback(() => {
    const d = value.value ? Math.max(value.value - 1, 1) : 0;
    onDurationChange({ ...value, value: d, ignoreYear });
  }, [value, ignoreYear, onDurationChange]);

  const increaseDuration = React.useCallback(() => {
    const d = value.value ? Math.max(value.value + 1, 1) : 1;
    onDurationChange({ ...value, value: d, ignoreYear });
  }, [value, ignoreYear, onDurationChange]);

  const setUnit = React.useCallback(
    (d: DurationUnit) => {
      onDurationChange({ ...value, unit: d, ignoreYear });
    },
    [value, ignoreYear, onDurationChange]
  );

  const description = React.useCallback(() => {
    if (value) {
      const suffix = value.value && value.value > 1 ? 's' : '';
      const startDateString = startDate
        ? startDate.toFormat('MMM dd, yyyy')
        : 'today';

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const addedDuration: any = {};

      if (value.unit?.value === 'quarter' && value.value) {
        addedDuration.month = value.value * 3;
      } else if (value.unit) {
        addedDuration[`${value.unit.value}s`] = value.value;
      }

      const endDate = (startDate || DateTime.now())
        .plus(addedDuration)
        .toFormat('MMMM dd, yyyy');

      return `${value.value} ${value.unit?.value}${suffix} from ${startDateString} is ${endDate}`;
    }
    return (
      'No campaign intelligence will be attached to this post. It will\n' +
      'publish when scheduled, and have no further user prompting.'
    );
  }, [value, startDate]);

  return (
    <div className={styles.selectorWrapper}>
      <div className={styles.body}>
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.durationButton}
            onClick={decreaseDuration}
          >
            <FAIcon name="minus" />
          </button>
          <input
            type="number"
            value={`${value.value}`}
            onChange={handleDurationChange}
            onClick={() => false}
          />
          <button
            type="button"
            className={styles.durationButton}
            onClick={increaseDuration}
          >
            <FAIcon name="plus" />
          </button>
        </div>

        {ignoreYearFlag && (
          <div>
            <input
              type="checkbox"
              id="ignoreYear"
              defaultChecked={ignoreYear}
              onChange={toggleIgnoreYear}
            />
            <label htmlFor="ignoreYear">Ignore Year</label>
          </div>
        )}

        <div className={styles.labels}>
          {DurationOptions.map((d: DurationUnit) => (
            <button
              type="button"
              key={`duration-option-${d.value}`}
              onClick={() => setUnit(d)}
              className={d === value.unit ? styles.selectedUnit : undefined}
            >
              {d.label}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.description}>{description()}</div>
    </div>
  );
};
