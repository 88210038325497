import { useMemo } from 'react';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  defaultFirstNotification,
  Notification,
  sortNotifications,
} from 'models/notification';
import { Channel } from 'models/channel';

export const PREVIEW_TEXT_PLACEHOLDER = 'Type preview';
export const TEXT_PLACEHOLDER = 'Type subject';

export const useNotifications = (): {
  notifications: Notification[];
  defaultNotificationText: string;
  defaultNotificationPreviewText: string;
  firstNotification: Notification;
  reTargetingNotifications: Notification[];
  newNotificationOrder: number;
  onFirstNotificationChange: (notification: Notification) => void;
  onNotificationsChange: (ntfs: Notification[]) => void;
  persistedNotifications: () => Notification[];
  applyDefaultTexts: (
    notification: Notification,
    channel: Channel
  ) => Notification;
} => {
  const {
    options: { defaultNotificationText, defaultNotificationPreviewText },
    fields: { notifications: notificationsStore },
    settings: { notifications },
  } = useSettings();

  const newNotificationOrder = useMemo(
    () =>
      notifications?.length > 0
        ? notifications[notifications.length - 1].order + 1
        : 1,
    [notifications]
  );
  const firstNotification = useMemo(() => {
    return notifications[0] || defaultFirstNotification;
  }, [notifications]);

  const reTargetingNotifications = useMemo(() => notifications.slice(1), [
    notifications,
  ]);

  const onFirstNotificationChange = (notification: Notification) => {
    const ntfs = [...notifications];
    ntfs[0] = { ...firstNotification, ...notification };
    onNotificationsChange([...ntfs]);
  };
  const onNotificationsChange = (ntfs: Notification[]) => {
    notificationsStore.set(sortNotifications(ntfs));
  };
  const persistedNotifications = (): Notification[] => {
    return notifications.map((ntfs) => {
      return { ...ntfs, persisted: true };
    });
  };

  const applyDefaultTexts = (
    notification: Notification,
    channel: Channel
  ): Notification => {
    switch (channel) {
      case 'email':
        return {
          ...notification,
          text: defaultNotificationText,
          previewText: defaultNotificationPreviewText,
        };
      case 'push':
        return {
          ...notification,
          pushText: defaultNotificationText,
        };
      default:
        return notification;
    }
  };

  return {
    notifications,
    defaultNotificationText,
    defaultNotificationPreviewText,
    firstNotification,
    reTargetingNotifications,
    onNotificationsChange,
    onFirstNotificationChange,
    newNotificationOrder,
    applyDefaultTexts,
    persistedNotifications,
  };
};
