import React, { useCallback } from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { DateTimeField } from 'shared/forms/DateTimeField';
import { DateTime } from 'luxon';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Tooltip } from 'shared/Tooltip';
import { HoverTooltip } from 'shared/HoverTooltip';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import styles from '../settings-editor.module.css';
import { CampaignDuration } from './CampaignDuration';

export const Schedule: React.FC = () => {
  const { contentPermissions, settings, update, options } = useSettings();
  const onPublishedAtChange = useCallback(
    (publishedAt?: DateTime) => update({ publishedAt }),
    [update]
  );
  const { canEdit } = contentPermissions;

  const tooltip = (
    <Tooltip
      description="
          When the campaign will become visible for the audience and topics."
    />
  );

  const { publishedAt } = settings;

  return (
    <Section title="Schedule">
      <Row>
        {options.canChangePublicationDate && (
          <Block className={styles.scheduleBlock}>
            <div className={styles.fieldsetColumn}>
              <h4 className={styles.fieldsetColumnTitle}>
                <span>Publish</span>
                <HoverTooltip align="left" content={tooltip} />
              </h4>
              <DateTimeField
                upward
                disabledLabel="Now"
                enabledLabel={
                  publishedAt ? (
                    publishedAt.toFormat('dd LLL, hh:mma')
                  ) : (
                    <span className={styles.laterLabelWrapper}>
                      <span>Later</span>
                      <FAIcon name="chevron-down" />
                    </span>
                  )
                }
                value={publishedAt}
                onChange={onPublishedAtChange}
                disable={!canEdit}
              />
            </div>
          </Block>
        )}
        <CampaignDuration disable={!canEdit} />
      </Row>
    </Section>
  );
};
