import React from 'react';
import { useLibraryFonts } from 'hooks/useLibrary';

import { usePrevious } from 'hooks/usePrevious';
import { FontStylesheet } from 'models/library';
import { appendStylesheets } from 'utility/appendStylesheets';

type LibraryFontsCollection = () => {
  data: Array<FontStylesheet>;
  isLoading: boolean;
};

export const useLibraryFontsCollection: LibraryFontsCollection = () => {
  const { isLoading, data } = useLibraryFonts({
    filter: {
      type: 'search',
      search: '',
      status: ['published', 'archived'],
    },
    pageSize: 1000,
    includeDisabled: false,
  });
  const prevItems = usePrevious(data);

  React.useEffect(() => {
    appendStylesheets(data);
  }, [prevItems, data]);

  return { data, isLoading };
};
