import { QueryKey, useQuery, UseQueryResult } from 'react-query';
import { useProgram } from 'contexts/program';
import { FeedPreview } from 'models/feed-preview';
import { getFeedPreview } from 'services/api-feed-preview';

type UseFeedPreviewResult = UseQueryResult<FeedPreview[], Error>;

export const getFeedPreviewQueryKey = (
  programId: number,
  topicId?: string | number
): QueryKey => ['feedPreviews', programId, topicId];

export function useFeedPreviewQuery(
  topicId?: string | number
): UseFeedPreviewResult {
  const { id: programId } = useProgram();

  return useQuery(getFeedPreviewQueryKey(programId, topicId), () =>
    typeof topicId === 'number' || typeof topicId === 'string'
      ? getFeedPreview(programId, Number(topicId))
      : undefined
  );
}
