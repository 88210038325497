import React, { useEffect } from 'react';
import { useShortcutQuery, useUpdateShortcut } from 'hooks/shortcuts';
import { RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { Shortcut } from 'models/shortcut';
import { WithPermission } from 'shared/WithPermission';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { useFlashMessage } from 'contexts/flasher';
import { Audience } from 'models/audience';
import { audiencesToCriterion } from 'models/publisher/criterion';
import { Topic } from 'models/topic';
import { includes } from 'utility/objectUtils';
import { Spinner } from '../../Shared/Spinner';
import { GeneralCard } from '../create/GeneralCard';
import { LinksCard } from '../create/LinksCard';
import { TargetCard } from '../create/TargetCard';
import { HeaderActions } from '../HeaderActions';
import { emptyLinkItem, useLinkFields } from '../utils/hooks/use-link-fields';
import { ShortcutErrorsState } from '../utils/types';
import { EDITABLE_SHORTCUT_TYPES } from '../utils/constants';
import { useGeneralShortcutsFields } from '../utils/hooks/use-general-shortcuts-fields';

export const EditShortcut: React.FC<RouteComponentProps<{
  id: number;
  page: string;
}>> = ({ id, page }) => {
  const { mutateAsync: updateShortcutFn } = useUpdateShortcut();
  const { id: programId } = useProgram();
  const shortcutId = Number(id);
  const { data: shortcutsData, isLoading, errorMessage } = useShortcutQuery({
    programId,
    shortcutId,
  });
  const { setFlashMessage } = useFlashMessage();

  const [selectedTopics, setSelectedTopics] = React.useState<Array<Topic>>([]);
  const [audiences, setAudiences] = React.useState<Array<Audience>>([]);

  const {
    name,
    description,
    iconSrc,
    headerSrc,
    shortcutEnabled,
    handleNameChange,
    handleDescriptionChange,
    handleIconSrcChange,
    handleHeaderSrcChange,
    handleShortcutEnabledToggle,
    setShortcutEnabled,
  } = useGeneralShortcutsFields();

  const {
    links,
    errors: linkErrors,
    setLinks,
    onAddLink,
    onLinkChange,
    onLinkRemove,
  } = useLinkFields();

  const errors: ShortcutErrorsState = {
    links: linkErrors,
  };

  const isGeneral = page === 'General';
  const isLinks = page === 'Links';
  const isTarget = page === 'Target';
  const isLinkShortcut = shortcutsData?.type === 'link';

  useEffect(() => {
    const alreadySelectedAudiences = shortcutsData?.audienceList ?? [];
    if (shortcutsData?.type === 'link') {
      const alreadySelectedTopics =
        shortcutsData?.configuration?.channelsData ?? [];
      setLinks(shortcutsData?.configuration?.links ?? [{ ...emptyLinkItem }]);
      setSelectedTopics(alreadySelectedTopics);
    }

    handleNameChange(shortcutsData?.name ?? '');
    handleDescriptionChange(shortcutsData?.description ?? '');
    handleIconSrcChange(
      shortcutsData?.imageUrl || (shortcutsData?.service?.iconUrl ?? '')
    );
    handleHeaderSrcChange(
      shortcutsData?.bannerImageUrl ||
        (shortcutsData?.service?.bannerImageUrl ?? '')
    );
    setShortcutEnabled(shortcutsData?.status === 'active');
    setAudiences(alreadySelectedAudiences);
  }, [
    handleDescriptionChange,
    handleHeaderSrcChange,
    handleIconSrcChange,
    handleNameChange,
    isLinkShortcut,
    setLinks,
    setShortcutEnabled,
    shortcutsData,
  ]);

  const handleSelectedTopicsChange = (value: Array<Topic>) => {
    setSelectedTopics(value);
  };

  const handleSelectedAudiencesChange = (value: Array<Audience>) => {
    setAudiences(value);
  };

  const buildShortcutPayload = (): Partial<Shortcut> => {
    const channels = [...selectedTopics.map((topic) => String(topic.id))];
    const criterion = audiencesToCriterion(audiences);

    const basePayload = {
      id: shortcutsData?.id,
      type: shortcutsData?.type,
      name,
      criterion,
      description,
      imageUrl: iconSrc,
      bannerImageUrl: headerSrc,
      status: shortcutEnabled ? 'active' : 'inactive',
    } as const;

    if (shortcutsData?.type === 'link') {
      return {
        ...basePayload,
        type: shortcutsData?.type,
        configuration: {
          links: [...links],
          channels,
          linkToAssistant: false,
        },
      };
    }

    return basePayload;
  };

  const onSave = async () => {
    const payload = buildShortcutPayload();
    const update = { data: payload };
    try {
      await updateShortcutFn(update);
    } catch (e) {
      setFlashMessage({
        message: 'Could not update the shortcut',
        severity: 'error',
      });
    }
  };

  const isImageEditable = includes(
    EDITABLE_SHORTCUT_TYPES,
    shortcutsData?.type ?? ''
  );

  function handlerOrUndefined<T extends string | number>(
    handlerFn: (...args: T[]) => void,
    fieldType: 'image' | 'text' = 'text'
  ) {
    if (fieldType === 'image') {
      return isImageEditable ? handlerFn : undefined;
    }
    return isLinkShortcut ? handlerFn : undefined;
  }

  return (
    <WithPermission permissions={['configureExperienceSettingsAccess']}>
      <FormPage
        breadcrumbs={[
          { to: '../../../..', label: 'Configure' },
          { to: '../../..', label: 'Shortcuts' },
          { to: '../..', label: shortcutsData?.name || 'Edit Shortcut' },
          { label: page as string },
        ]}
        actionsOverride={<HeaderActions handleSave={onSave} />}
        title={shortcutsData?.name || 'Edit Shortcut'}
      >
        {isLoading && <Spinner />}
        {!isLoading && !errorMessage && shortcutsData && (
          <>
            {isGeneral && (
              <GeneralCard
                name={name}
                onNameChange={handlerOrUndefined(handleNameChange)}
                description={description}
                onDescriptionChange={handlerOrUndefined(
                  handleDescriptionChange
                )}
                setIconSrc={handlerOrUndefined(handleIconSrcChange, 'image')}
                iconSrc={iconSrc}
                setHeaderSrc={handlerOrUndefined(
                  handleHeaderSrcChange,
                  'image'
                )}
                headerSrc={headerSrc}
                isShortcutEnabled={shortcutEnabled}
                handleShortcutEnabledToggle={handleShortcutEnabledToggle}
              />
            )}
            {isLinks && (
              <LinksCard
                links={links}
                addLink={onAddLink}
                onChange={onLinkChange}
                handleRemoveLinkItem={onLinkRemove}
                errors={errors}
              />
            )}
            {isTarget && (
              <TargetCard
                handleSelectedTopicsChange={handleSelectedTopicsChange}
                selectedTopics={selectedTopics}
                handleSelectedAudiencesChange={handleSelectedAudiencesChange}
                selectedAudiences={audiences}
              />
            )}
          </>
        )}
      </FormPage>
    </WithPermission>
  );
};
