import React from 'react';
import cx from 'classnames';
import { AxisScale, scaleOrdinal } from 'd3';
import { Post } from '../../../../../../../models/publisher/post';
import { useProgram } from '../../../../../../../contexts/program';
import { useFeatureFlagsQuery } from '../../../../../../../hooks/feature-flags';
import { useCommentsAnalysisQuery } from '../../../../../../../hooks/comments-analysis';
import {
  BarChart,
  BarChartDimensions,
  BarData,
} from '../../../../../../../shared/Charts/BarChart';
import styles from '../MetricsCards.module.css';
import { LoadingChart } from '../../../../../../../shared/Charts/LoadingChart';

interface ICommentSentimentCard {
  post: Post;
}

const chartDimensions = {
  height: 285,
  width: 528,
  padding: {
    top: 24,
    bottom: 34,
    right: 10,
    left: 10,
  },
  bars: {
    padding: {
      inner: 0.35,
      outer: 0,
    },
  },
};

export const CommentSentimentCard: React.FC<ICommentSentimentCard> = ({
  post,
}) => {
  const { id: programId } = useProgram();
  const sentimentAnalysisEnabled = useFeatureFlagsQuery(
    programId,
    'EE.AI.SentimentAnalysis.Enabled'
  ).data?.value;
  const commentsAnalysisEnabled = Boolean(
    post.settings.isCommentable && sentimentAnalysisEnabled
  );
  const {
    data: commentsAnalysis,
    isLoading: isLoadingCommentsAnalysis,
  } = useCommentsAnalysisQuery({
    contentId: post.content.id,
    usePercentage: true,
    enabled: commentsAnalysisEnabled,
  });

  const graphData: BarData[] = [
    {
      label: 'Positive',
      value: commentsAnalysis?.polarity.positive || 0,
    },
    {
      label: 'Negative',
      value: commentsAnalysis?.polarity.negative || 0,
    },
    {
      label: 'Neutral',
      value: commentsAnalysis?.polarity.neutral || 0,
    },
    {
      label: 'Mixed',
      value: commentsAnalysis?.polarity.mixed || 0,
    },
  ];

  const color = scaleOrdinal()
    .domain(graphData.map((d) => d.label))
    .range([
      'url(#orangeBarGradient)',
      'url(#yellowBarGradient)',
      'url(#greenBarGradient)',
      'url(#blueBarGradient)',
    ]);

  const drawTopLabels = (
    bars: Array<BarData>,
    dimensions: BarChartDimensions,
    scaleX?: AxisScale<string> | AxisScale<number>,
    scaleY?: AxisScale<string> | AxisScale<number>
  ) => {
    if (scaleX === undefined || scaleY === undefined) {
      return <></>;
    }
    const labelColors = scaleOrdinal()
      .domain(graphData.map((d) => d.label))
      .range([
        'rgba(246, 164, 25, 1)',
        'rgba(250, 179, 0, 1)',
        'rgba(133, 214, 1, 1)',
        'rgba(25, 198, 236, 1)',
      ]);

    return bars.map(({ label, value }) => {
      const xPos = scaleX(label as never) || 0;
      const xWidth = scaleX.bandwidth?.() || 0;
      const yPos = scaleY(value as never) || 0;
      const labelColor = `${labelColors(label as never)}` || '#ABABAB';
      const fontSize = 15;

      return (
        <text
          x={xPos + xWidth / 2}
          y={yPos - 15}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={fontSize}
          fontWeight={600}
          fill={labelColor}
        >
          {value.toLocaleString('en', { maximumFractionDigits: 0 })}
        </text>
      );
    });
  };

  const drawBottomLabels = (
    bars: Array<BarData>,
    dimensions: BarChartDimensions,
    scaleX?: AxisScale<string> | AxisScale<number>
  ) => {
    return bars.map(({ label, value }) => {
      if (label === undefined || value === undefined || scaleX === undefined) {
        return <></>;
      }

      const xPos = scaleX(label as never) || 0;
      const xWidth = scaleX.bandwidth?.() || 0;
      const fontSize = 15;
      return (
        <React.Fragment key={`bottom-label-${label}`}>
          <text
            x={xPos + xWidth / 2}
            y={dimensions.height - fontSize * 1.1}
            textAnchor="middle"
            dominantBaseline="hanging"
            fontSize={fontSize}
            fill="black"
          >
            {label}
          </text>
        </React.Fragment>
      );
    });
  };

  const drawCustom = (
    bars: Array<BarData>,
    dimensions: BarChartDimensions,
    scaleX?: AxisScale<string> | AxisScale<number>,
    scaleY?: AxisScale<string> | AxisScale<number>
  ) => {
    return (
      <>
        <defs>
          <linearGradient
            id="orangeBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(245, 158, 11, 1)" />
            <stop offset="100%" stopColor="rgba(255, 208, 130, 0.93)" />
          </linearGradient>
          <linearGradient
            id="yellowBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(254, 209, 94, 1)" />
            <stop offset="100%" stopColor="rgba(255, 232, 174, 1)" />
          </linearGradient>
          <linearGradient
            id="greenBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(182, 228, 109, 1)" />
            <stop offset="100%" stopColor="rgba(219, 252, 166, 1)" />
          </linearGradient>
          <linearGradient
            id="blueBarGradient"
            x1="0%"
            x2="100%"
            y1="100%"
            y2="0%"
          >
            <stop offset="0%" stopColor="rgba(112, 214, 236, 1)" />
            <stop offset="100%" stopColor="rgba(182, 242, 255, 1)" />
          </linearGradient>
        </defs>
        {drawBottomLabels(bars, dimensions, scaleX)}
        {drawTopLabels(bars, dimensions, scaleX, scaleY)}
      </>
    );
  };

  return commentsAnalysisEnabled ? (
    <div className={cx(styles.metricCard, styles.metricCardHalf)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          What is the sentiment of comments?
        </h1>
      </div>
      {isLoadingCommentsAnalysis ? (
        <div className={styles.metricCardBody}>
          <LoadingChart chartDimensions={chartDimensions} />
        </div>
      ) : (
        <div className={styles.metricCardBody}>
          <BarChart
            data={graphData}
            chartDimensions={chartDimensions}
            customColors={color}
            customDrawing={drawCustom}
            hideXAxis
            hideYAxis
          />
        </div>
      )}
    </div>
  ) : null;
};
