import * as React from 'react';
import { useEffect, useMemo } from 'react';
import * as Reach from '@reach/router';
import { useLocation, useNavigate, useParams } from '@reach/router';
import { PageHeader } from 'DesignSystem/Layout/Pages';
import axios from 'axios';
import JSZip from 'jszip';
import {
  DataDownloadItem,
  DataDownloads,
} from '../components/DataDownloads/DataDownloads';
import { Box } from '../../../../../DesignSystem/Components';
import { SearchBar } from '../../../../../shared/SearchBar';
import { useProgram } from '../../../../../contexts/program';
import { useDataDownloads } from '../hooks/useDataDownloads';
import { fetchDirectDataDownloadPresignedUrls } from '../../../../../services/api-insights';
import { useCollectionTabs } from '../hooks/useCollectionTabs';
import { createFlashError } from '../../../../../models/flash-message';
import { useFlashMessage } from '../../../../../contexts/flasher';

export const DataDownload: React.FC<Reach.RouteComponentProps> = () => {
  const { folder } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { setFlashMessage } = useFlashMessage();
  const { id: programId } = useProgram();
  const { isLoading, data } = useDataDownloads(programId);
  const selectedFolder: string = folder;

  // Redirect if we navigate to a file that doesn't exist
  useEffect(() => {
    if (selectedFolder && !isLoading) {
      if (!Object.keys(data).includes(selectedFolder)) {
        navigate('../downloads');
      }
    }
  }, [navigate, data, selectedFolder, isLoading, location.pathname]);

  // Handle searching
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const searchableFiles = useMemo(
    () =>
      Object.keys(data)
        .filter((key) => {
          return selectedFolder === undefined || key === selectedFolder;
        })
        .flatMap((key) => data[key].files),
    [data, selectedFolder]
  );

  const filteredData: DataDownloadItem[] = useMemo(() => {
    let returnData = [];
    if (searchTerm.length === 0) {
      returnData = selectedFolder
        ? data[selectedFolder]?.files.map((f) => ({
            name: f.filename,
          })) ?? []
        : Object.keys(data).map((s) => {
            const f = data[s];
            return {
              name: s,
              files: f?.files,
              generated_at: f?.generated_at,
            };
          });
    } else {
      returnData = searchableFiles
        .filter((file) => file.filename.match(searchTerm) !== null)
        .map((f) => ({ name: f.filename }));
    }
    return returnData;
  }, [searchTerm, selectedFolder, data, searchableFiles]);

  // Are we currently looking at folders or files? This is the way it is because we assumed during planning
  //   that we would have a directory structure only 1 folder deep
  const isFolders = selectedFolder === undefined && searchTerm.length === 0;

  const downloadFiles = (selectedEntries: string[]) => {
    const zipped = new JSZip();
    let zipName = searchTerm?.length > 0 ? searchTerm : `data_download_`;

    // Build Zip
    const allFileDownloads = selectedEntries.map((index) => {
      const i = parseInt(index, 10);

      let filesToDownload = [];
      if (searchTerm?.length > 0) {
        filesToDownload = filteredData.map((f) => {
          const folderName = Object.keys(data).find((directory) =>
            data[directory].files.some((ff) => ff.filename === f.name)
          );
          return folderName ? `${folderName}/${f.name}` : undefined;
        });
      } else if (selectedFolder) {
        filesToDownload = [`${selectedFolder}/${filteredData[i].name}`];
      } else {
        filesToDownload =
          filteredData[i]?.files?.map((f) => {
            return `${filteredData[i].name}/${f.filename}`;
          }) ?? [];
      }

      if (selectedEntries.length === 1) {
        zipName = searchTerm?.length > 0 ? searchTerm : filteredData[i].name;
      }

      const fileDownloads = filesToDownload.map((pathToDownload) => {
        if (pathToDownload === undefined) {
          return Promise.reject(new Error('Undefined pathname'));
        }
        return fetchDirectDataDownloadPresignedUrls(programId, {
          filePath: pathToDownload,
        }).then((presignedUrl) => {
          return axios({
            url: presignedUrl,
            method: 'GET',
            responseType: 'blob',
          }).then((r) => {
            return zipped.file(`${pathToDownload}.csv.gz`, r.data);
          });
        });
      });
      return Promise.all(fileDownloads);
      // Download all the files and then zip them here
    });

    Promise.all(allFileDownloads)
      .then(() => {
        zipped
          .generateAsync({
            type: 'blob',
          })
          .then((zData) => {
            const zUrl = URL.createObjectURL(zData);

            const e = document.createElement('a');
            e.href = zUrl;
            e.setAttribute('download', `${zipName}.zip`);
            e.click();
          });
      })
      .catch(() => {
        setFlashMessage(
          createFlashError(
            'Error downloading one or more files, please refresh and try again later.'
          )
        );
      });
  };

  // Layout
  const collectionTabs = useCollectionTabs();
  const tabs = React.useMemo(
    () =>
      collectionTabs.map((tab) => ({
        to: `${selectedFolder !== undefined ? '..' : '.'}/collections/${
          tab.to
        }`,
        label: tab.label,
      })),
    [collectionTabs, selectedFolder]
  );

  return (
    <Box padding="0px 32px">
      <PageHeader
        title={selectedFolder ?? 'Data Export'}
        tabs={tabs}
        filterbar={
          <SearchBar
            onChange={setSearchTerm}
            placeholder="Search files"
            value={searchTerm}
            totalRecords={searchTerm !== '' ? filteredData.length : 0}
            hasClearButton
          />
        }
        breadcrumbs={
          selectedFolder
            ? [
                {
                  label: '..',
                  to: '..',
                },
                {
                  label: selectedFolder,
                },
              ]
            : []
        }
      />
      <DataDownloads
        isLoading={isLoading}
        data={filteredData}
        isFolders={isFolders}
        downloadFileHandler={downloadFiles}
      />
    </Box>
  );
};
