import * as React from 'react';
import * as Reach from '@reach/router';
import { useDownloadsFeature } from '../hooks/useDownloadsFeature';
import { ReportDownloads } from '../components/DownloadsList';
import { Loading } from '../../../../../components/publisher/blocks/instances/Loading';
import { DataDownload } from './DataDownload';
import { useFeatureFlagsQuery } from '../../../../../hooks/feature-flags';
import { useProgram } from '../../../../../contexts/program';

/**
 * Renders the download list or redirects
 */
export const DownloadsPage: React.FC<Reach.RouteComponentProps> = () => {
  const { enabled, loading } = useDownloadsFeature();
  const program = useProgram();
  React.useEffect(() => {
    if (!loading && !enabled) Reach.navigate('.');
  }, [enabled, loading]);
  const {
    data: dataDownloadFlag,
    isLoading: isLoadingDataDownloadFlag,
  } = useFeatureFlagsQuery(
    program.id,
    'Studio.Intelligence.DirectDataDownload.Enabled'
  );

  if (!enabled) return null;
  if (loading || isLoadingDataDownloadFlag) return <Loading />;
  return dataDownloadFlag?.value ? <DataDownload /> : <ReportDownloads />;
};
