import React from 'react';
import { LitmusRole } from 'models/role';
import { SVGIconName } from 'shared/Icon/SVGIcon/definitions';
import { isSvgIconName, SVGIcon } from 'shared/Icon/SVGIcon';
import { Body, Caption } from 'DesignSystem/Typography';
import { FlipSwitch } from 'shared/FlipSwitch';
import { alwaysEnabledPermissions } from './constant';
import styles from './form.module.css';

type PropsType = {
  permission: LitmusRole;
  subPermissionsMap: { [key: string]: LitmusRole[] };
  on: (permission: LitmusRole) => boolean;
  onChange: (name: string, value: true | undefined) => void;
  disabled?: boolean;
};

export const PermissionRow: React.FC<PropsType> = ({
  permission,
  subPermissionsMap,
  on,
  onChange,
  disabled,
}) => {
  function isDisabled(): boolean {
    return alwaysEnabledPermissions.includes(permission.name);
  }

  function PermissionIcon(role: LitmusRole) {
    if (role.parent) {
      return <></>;
    }
    const title =
      role.title === 'Campaigns & Calendar' ? 'Content' : role.title;
    const snakeCaseTitle = title.replace(' ', '_');

    let iconName: SVGIconName;
    if (isSvgIconName(snakeCaseTitle)) {
      iconName = snakeCaseTitle;
    } else if (isSvgIconName(`Conf_${snakeCaseTitle.toLowerCase()}`)) {
      iconName = `Conf_${snakeCaseTitle.toLowerCase()}` as SVGIconName;
    } else {
      iconName = 'Configure';
    }

    return <SVGIcon name={iconName} />;
  }

  const [subMenuOpen, setSubMenuOpen] = React.useState(() => {
    return on(permission);
  });
  const PermissionInfo = (
    <div className={styles.PermissionInfo}>
      {!permission.parent && (
        <div className={styles.PermissionIcon}>
          {PermissionIcon(permission)}
        </div>
      )}
      <div className={styles.PermissionCol}>
        <div className={styles.Title}>
          <Body bold>{permission.title}</Body>
        </div>
        <div>
          <Caption>{permission.description}</Caption>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.PermissionRow} key={permission.name}>
      <div className={styles.Permission}>
        {PermissionInfo}
        <div className={styles.PermissionCol}>
          <FlipSwitch
            theme={isDisabled() ? 'inactive' : 'default'}
            disabled={disabled || isDisabled()}
            htmlForId={`toggle-${permission.name}`}
            onChange={(value) => {
              setSubMenuOpen(!subMenuOpen);
              onChange(permission.name, value);
            }}
            on={on(permission)}
          />
        </div>
      </div>
      {subPermissionsMap[permission.name] && subMenuOpen && (
        <div className={styles.SubPermissionContainer}>
          {subPermissionsMap[permission.name].map((p) => (
            <PermissionRow
              key={p.name}
              permission={p}
              aria-label={`toggle ${p.name}`}
              on={on}
              onChange={onChange}
              subPermissionsMap={subPermissionsMap}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};
