import { UnitValue } from '../duration';

export type TimeUnit = 'd' | 'w' | 'M' | 'y' | 'q';
export type AdvanceQueryBuilderUnit = Exclude<TimeUnit, 'q'>;

export function isDate(value: string | undefined): boolean {
  return !!(value || '').match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
}

export const units: Record<TimeUnit, UnitValue> = {
  d: 'day',
  w: 'week',
  M: 'month',
  y: 'year',
  q: 'quarter',
};

export function decodeRelativeDate(
  value: string | undefined,
  future: boolean
): string | undefined {
  const match = (value || '').match(/^now([-+])([0-9]+)([dwMy])\/d$/);
  const [, sign, quantity, unit] = <
    [undefined, string, string, AdvanceQueryBuilderUnit]
  >(match || []);
  if (!units[unit] || (future && sign === '-') || (!future && sign === '+')) {
    return undefined;
  }
  return `${quantity} ${units[unit]}`;
}

export function isRelativeDate(
  value: string | undefined,
  future: boolean
): boolean {
  return decodeRelativeDate(value, future) !== undefined;
}

export function isToday(value: string | undefined): boolean {
  return value === 'now/d';
}
