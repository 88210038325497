import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { WithPermission } from 'shared/WithPermission';

export const Assistant: React.FC<RouteComponentProps> = () => (
  <WithPermission permissions={['configureExperienceSettingsAccess']}>
    <FormPage title="Assistant">
      Recreating a sub-optimal experience with some side.
      <div
        style={{
          height: '900px',
          width: '2500px',
          borderLeft: 'dashed 1px gray',
          borderTop: 'dashed 1px gray',
          marginLeft: '5px',
        }}
      />
      ⇣
      <br />
      ...and overflowing content.
    </FormPage>
  </WithPermission>
);
