import { useProgram } from 'contexts/program';
import { Post } from 'models/publisher/post';
import { sendTestPush } from 'services/api-post';

export const useSendTestPush = (
  subject: string,
  previewText: string,
  post: Post,
  userIds: number[],
  onSuccess?: () => void
): { sendPush: () => void; sendPushRequest: () => Promise<void> } => {
  const { id: programId } = useProgram();
  const sendPushRequest = () => {
    return sendTestPush(subject, previewText, post, programId, userIds);
  };
  const sendPush = () => {
    if (userIds && userIds.length > 0) {
      sendPushRequest().then(() => {
        if (onSuccess) onSuccess();
      });
    }
  };
  return {
    sendPush,
    sendPushRequest,
  };
};
