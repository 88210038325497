import * as React from 'react';
import cx from 'classnames';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';

import { useFlashMessage } from 'contexts/flasher';
import { Checkbox } from 'shared/Checkbox';
import { Fieldset } from 'shared/Fieldset';
import { Section } from 'shared/SectionBlocks';
import { useEffect, useRef } from 'react';

import {
  getFormattedName,
  HYBRID,
  OPTIMIZE,
  OVERRIDE,
} from 'models/delivery-type';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import styles from './engine-settings-editor.module.css';
import { EngineSetting } from './EngineSetting';

const HybridCheckbox: React.FC<{
  name: string;
  disabled: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
  description: string;
}> = ({ name, disabled, checked, onChange, description }) => {
  return (
    <p className={cx({ [styles.disableCheckbox]: disabled })}>
      <label className={styles.channelsLabel}>
        <Checkbox
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <div className={styles.reengageUsers}>{description}</div>
      </label>
    </p>
  );
};

export const EngineSettings: React.FC<{
  disabled: boolean;
}> = ({ disabled }) => {
  const {
    settings: { deliveryType, retries, duration, notifDeliveryTimesEnabled },
    fields: {
      deliveryType: { set: setDeliveryType },
      retries: { set: setRetries },
      notifDeliveryTimesEnabled: { set: setNotifDeliveryTimesEnabled },
    },
  } = useSettings();
  const { setFlashMessage } = useFlashMessage();

  const { id: programId } = useProgram();
  const SetNotifDeliveryTimesEnabled = !!useFeatureFlagsQuery(
    programId,
    'SetNotifDeliveryTimes.Enabled'
  ).data?.value;

  const retriesRef = useRef(retries);

  const retriesEnabled = retries !== 0;

  // Only display flash message if switching from non-override type to override
  // or visa versa
  useEffect(() => {
    if (retriesRef.current === retries) {
      return;
    }

    if (retriesEnabled && deliveryType && duration) {
      setFlashMessage({
        severity: 'none',
        message: `${getFormattedName(
          deliveryType,
          retries
        )} reenables the duration`,
        details: `The duration is updated to ${duration.value} days on the Setup screen.`,
      });
    } else if (!retriesEnabled && deliveryType) {
      setFlashMessage({
        severity: 'none',
        message: `${getFormattedName(
          deliveryType,
          retries
        )} disables the duration`,
        details: `The duration has been disabled.`,
      });
    }
    retriesRef.current = retries;
  }, [deliveryType, duration, setFlashMessage, retries, retriesEnabled]);

  return (
    <Section
      title="Engine"
      className={cx({
        [styles.disabledSetting]: disabled,
      })}
    >
      <Fieldset>
        <EngineSetting
          type={OPTIMIZE}
          onSelect={setDeliveryType}
          disabled={disabled}
          description="Personalize communication delivery and retargeting attempts based
        on user behavior"
          engineOptions={{
            Time: true,
            Day: true,
            Retarget: true,
            Channels: true,
          }}
        />
        <EngineSetting
          type={HYBRID}
          onSelect={setDeliveryType}
          disabled={disabled}
          description="Deliver this communication to the audience at publish time, and
        personalize retargeting attempts based on user behavior"
          engineOptions={{
            Time: false,
            Day: retriesEnabled || !notifDeliveryTimesEnabled,
            Retarget: retriesEnabled,
            Channels: true,
          }}
        >
          {SetNotifDeliveryTimesEnabled ? (
            <HybridCheckbox
              name="chk-specify-notif-timing"
              onChange={setNotifDeliveryTimesEnabled}
              checked={notifDeliveryTimesEnabled}
              disabled={disabled || deliveryType !== HYBRID}
              description="Specify timing of delivery for each configured message text"
            />
          ) : (
            <HybridCheckbox
              name="chk-reengage-users"
              onChange={(check) => setRetries(check ? -1 : 0)}
              checked={retriesEnabled}
              disabled={disabled || deliveryType !== HYBRID}
              description="Retarget users until reached"
            />
          )}
        </EngineSetting>
        <EngineSetting
          type={OVERRIDE}
          onSelect={setDeliveryType}
          disabled={disabled}
          description="Deliver this communication to the audience at publish time, and do
        not attempt retargeting"
          engineOptions={{
            Time: false,
            Day: false,
            Retarget: false,
            Channels: true,
          }}
        />
      </Fieldset>
    </Section>
  );
};
