import * as React from 'react';
import { useNavigate } from '@reach/router';
import { Flex } from 'DesignSystem/Layout/Flex';
import { NavigationFooter } from 'shared/NavigationFooter';
import { LowerBar, UpperBar } from 'shared/NavigationBars/FixedBars';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { Eye } from 'shared/icons';
import { Button } from 'DesignSystem/Form';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { TitleBar } from './TitleBar';
import styles from './designer.module.css';

export const SplitFooter: React.FC<React.ComponentProps<
  typeof NavigationFooter
>> = (props) => {
  const { exitHandler, action, exitPath } = props;

  const navigate = useNavigate();

  const designPermission = React.useContext(DesignPermissionContext);

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
    else if (exitPath) navigate(exitPath);
  }, [navigate, exitPath, exitHandler]);

  const save = React.useCallback(() => {
    if (action) {
      action();
    }
  }, [action]);

  return (
    <>
      <UpperBar>
        <TitleBar canEdit={designPermission.canEdit} />
        <Flex end>
          <Flex>
            <Button layoutOnly label={<Eye />} />
          </Flex>
          <CloseButton handleClose={handleClose} />
        </Flex>
      </UpperBar>

      <LowerBar>
        <Flex className={styles.footer} end>
          <Button label="Save" onClick={save} />
        </Flex>
      </LowerBar>
    </>
  );
};
