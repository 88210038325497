import React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { VIDEO } from 'models/publisher/block';
import { VideoFieldData } from 'models/donkey';
import { VideoProcessingStep } from 'models/video';
import { VideoFile } from './components/VideoFile';
import { VideoPreviewImage } from './components/VideoPreviewImage';
import { VideoCaptions } from './components/VideoCaptions';
import { FieldFormProps } from '../../useFieldForm';
import { useVideo } from './hooks/useVideo';
import { AltText } from './components/AltText';

export const Video: React.FC<FieldFormProps<VideoFieldData>> = (props) => {
  const { data: fieldData, onChange } = props;

  const {
    video,
    refetchVideo,
    uploadProcessingStatus,
    externalVideoStatus,
    upload,
    setUrl,
    updateCaption,
    resetVideoStatus,
    isReplacingJob,
    isLoadingVideoFromFeed,
    isCaptionUpdated,
  } = useVideo({
    fieldData,
    onChange,
    autoplay: false,
  });

  const isVideoLoading =
    uploadProcessingStatus.step === VideoProcessingStep.Uploading ||
    uploadProcessingStatus.step === VideoProcessingStep.Transcoding ||
    externalVideoStatus.isLoading;

  const isInitialUpload = !video || video.status !== 'completed';

  const showOldVideoPreview =
    (isVideoLoading && !isInitialUpload) ||
    (isVideoLoading && isReplacingJob(video?.jobs));

  const onRemove = React.useCallback(() => {
    onChange(VIDEO);
    resetVideoStatus();
  }, [onChange, resetVideoStatus]);

  // Display Current Video Preview Settings
  const currentVersionPreview = {
    lockPreview: true,
    processingStatus: {
      step: 3,
      transcodePercentComplete: 100,
    },
    fieldHeader: 'Current Version',
    infoMessage: `This version of the video will be shown on the experience until the new version has completed processing.
    To hide this version completely, move this content to draft until the new video is ready.`,
  };

  const newVersionPreview = showOldVideoPreview
    ? {
        fieldHeader: 'New Version',
        infoMessage: `Once this version has completed uploading and processing, 
    it will replace the current version of the video.`,
      }
    : undefined;

  const updateAltText = React.useCallback(
    (text) => {
      onChange({ ...fieldData, image_alt_text: text });
    },
    [fieldData, onChange]
  );

  return (
    <Flex column>
      <VideoFile
        video={video}
        uploadProcessingStatus={uploadProcessingStatus}
        externalVideoStatus={externalVideoStatus}
        upload={upload}
        setUrl={setUrl}
        fieldData={fieldData}
        onRemove={onRemove}
        isLoadingVideoFromFeed={isLoadingVideoFromFeed}
        fieldHeader={newVersionPreview?.fieldHeader}
        tooltipMessage={newVersionPreview?.infoMessage}
        isCaptionUpdated={isCaptionUpdated}
      />
      {showOldVideoPreview && (
        <VideoFile
          video={video}
          uploadProcessingStatus={currentVersionPreview.processingStatus}
          externalVideoStatus={externalVideoStatus}
          upload={upload}
          setUrl={setUrl}
          fieldData={fieldData}
          onRemove={onRemove}
          isLoadingVideoFromFeed={isLoadingVideoFromFeed}
          fieldHeader={currentVersionPreview.fieldHeader}
          lockPreview={currentVersionPreview.lockPreview}
          tooltipMessage={currentVersionPreview.infoMessage}
          isCaptionUpdated={isCaptionUpdated}
        />
      )}
      <AltText value={fieldData.image_alt_text} onChange={updateAltText} />
      <VideoPreviewImage
        video={video}
        refetchVideo={refetchVideo}
        fieldData={fieldData}
        onChange={onChange}
        isLoadingVideoFromFeed={isLoadingVideoFromFeed}
        isVideoLoading={isVideoLoading}
      />
      <VideoCaptions
        filename={video?.filename}
        fieldData={fieldData}
        onChange={onChange}
        onCaptionUpdated={updateCaption}
      />
    </Flex>
  );
};
