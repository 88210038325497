import React from 'react';

import { DisabledSelect } from '../Select';
import { DateRangeSelect } from './DateRangeSelect';
import { DateTimeSelect } from './DateTimeSelect';
import { DurationSelect } from './DurationSelect';
import { StringCustomSelect } from './StringCustomSelect';

function isAnniversaryLike(criterion: string): boolean {
  return (
    criterion === 'start_date' ||
    criterion === 'birth_date' ||
    criterion === 'promotion_date' ||
    criterion === 'requisition_approval_date'
  );
}

export const DateSelect: React.FC<{
  criterion: string;
  operator?: string;
  onChange: (value: string[]) => void;
  value: string[];
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
}> = ({
  operator,
  value,
  onChange,
  criterion,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
}) => {
  const handleOnChange = (e?: string) => {
    const safeE = e || '';
    const tVal: string = ignoreYear ? `${safeE}:ignore_year` : safeE;
    if (e) onChange([tVal]);
  };

  if (isAnniversaryLike(criterion) && operator === 'is') {
    return (
      <StringCustomSelect
        onChange={onChange}
        value={value}
        options={[
          { value: 'last_week', label: 'Last Week' },
          { value: 'this_week', label: 'This Week' },
          { value: 'next_week', label: 'Next Week' },
          { value: 'last_month', label: 'Last Month' },
          { value: 'this_month', label: 'This Month' },
          { value: 'next_month', label: 'Next Month' },
          { value: 'today', label: 'Today' },
          { value: 'tomorrow', label: 'Tomorrow' },
          { value: 'yesterday', label: 'Yesterday' },
        ]}
      />
    );
  }

  switch (operator) {
    case 'on':
    case 'since':
      return (
        <DateTimeSelect
          operator={operator}
          onChange={handleOnChange}
          value={value[0]}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
        />
      );
    case 'between':
      return <DateRangeSelect onChange={handleOnChange} value={value[0]} />;
    case 'last':
      return (
        <DurationSelect
          value={value[0]}
          onChange={handleOnChange}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
        />
      );
    case 'next':
      return (
        <DurationSelect
          value={value[0]}
          futureDuration
          onChange={handleOnChange}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
        />
      );
    case 'is': // aka relative
      return (
        <StringCustomSelect
          onChange={onChange}
          value={value}
          options={[
            { value: 'yesterday', label: 'Yesterday' },
            { value: 'today', label: 'Today' },
            { value: 'this_week', label: 'This Week' },
            { value: 'last_week', label: 'Last Week' },
            { value: 'this_month', label: 'This Month' },
            { value: 'last_month', label: 'Last Month' },
            { value: 'this_quarter', label: 'This Quarter' },
            { value: 'last_quarter', label: 'Last Quarter' },
            { value: 'this_year', label: 'This Year' },
            { value: 'last_year', label: 'Last Year' },
            { value: 'last_30_days', label: 'Last 30 Days' },
            { value: 'last_7_days', label: 'Last 7 Days' },
          ]}
        />
      );
    default:
      return <DisabledSelect placeholder={value.join(', ') || 'Value'} />;
  }
};
