import React, { useEffect } from 'react';
import Select, { ValueType } from 'react-select';
import { OptionType } from 'hooks/common';
import { selectComponents, selectStyles, selectTheme } from '../Select';

const options = [
  { value: 'on', label: 'on' },
  { value: 'between', label: 'between' },
  { value: 'since', label: 'since' },
  { value: 'last', label: 'last' },
  { value: 'next', label: 'next' },
  { value: 'is', label: 'relative' }, // functions the same as the "is" operator in string select
];

export const DateOperatorSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
  isDisabled?: boolean;
}> = ({ onChange, value, isDisabled }) => {
  const optionValue =
    options.find((option) => option.value === value) || options[0];
  const onOptionChange = (option: ValueType<OptionType>) => {
    const newOp = option as OptionType;
    onChange(newOp.value);
  };

  useEffect(() => {
    if (!value) {
      onChange(options[0].value);
    }
  }, [value, onChange]);

  return (
    <Select
      value={optionValue}
      options={options}
      onChange={onOptionChange}
      components={selectComponents}
      styles={selectStyles}
      theme={selectTheme}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  );
};
