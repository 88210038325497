import React from 'react';
import { Topic } from 'models/topic';
import { Pill } from 'DesignSystem/Components/Pill';
import cx from 'classnames';
import styles from './topics.module.css';

type PropsType = {
  topic: Topic;
  showDraftOnly?: boolean;
  className?: string;
};

export const TopicStatusPill: React.FC<PropsType> = ({
  topic,
  showDraftOnly,
  className,
}) => {
  let value = 'Active';
  let statusClassName = styles.activePill;

  if (topic.draft && !topic.archived) {
    value = 'Draft';
    statusClassName = styles.draftPill;
  } else if (showDraftOnly) {
    return null;
  } else if (topic.draftId && !topic.archived) {
    value = 'Active With Draft';
    statusClassName = styles.activeWithDraftPill;
  } else if (topic.draftId && topic.archived) {
    value = 'Archived With Draft';
    statusClassName = styles.archivedWithDraftPill;
  } else if (topic.draft && topic.archived) {
    value = 'Archived Draft';
    statusClassName = styles.archivedDraftPill;
  } else if (topic.archived) {
    value = 'Archived';
    statusClassName = styles.archivedPill;
  }

  return (
    <Pill
      value={value}
      render={(t) => t}
      className={cx(statusClassName, className)}
      singleLine
    />
  );
};
