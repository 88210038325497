import cx from 'classnames';
import React, { useCallback } from 'react';
import { Label } from 'models/label';
import { ClickDropdown } from 'shared/ClickDropdown';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import buttonStyles from 'shared/styles/buttons.module.css';
import { TextInput } from 'shared/TextInput';
import styles from './label-picker.module.css';

type PropsType = {
  options: Label[];
  value: Label;
  onChange: (newValue: Label) => void;
  maxLength?: number;
  disabled?: boolean;
};

export const LabelPicker: React.FC<PropsType> = ({
  options,
  value,
  onChange,
  maxLength,
  disabled,
}) => {
  const handleChange = useCallback(
    (v: string) => {
      if (v && v.length > 0) {
        onChange({ text: v });
      } else {
        onChange(options[0]);
      }
    },
    [onChange, options]
  );

  const isStandardValueSelected = options.some(
    (option) => option.text === value.text
  );

  const charactersCount = isStandardValueSelected ? 0 : value.text.length;

  const dropdown = (dismiss: () => void) => (
    // We're catching a bubbled event here.
    <div className={styles.dropdown}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <ul onClick={dismiss}>
        {options.map((label) => (
          <li key={label.text}>
            <button
              type="button"
              onClick={() => onChange(label)}
              className={buttonStyles.buttonReset}
              disabled={disabled}
            >
              {label.text}
            </button>
            {label.text === value.text && <SVGIcon name="Check" />}
          </li>
        ))}
      </ul>
      <div className={styles.inputContainer}>
        <TextInput
          placeholder="Your custom value"
          className={styles.input}
          onChange={handleChange}
          value={isStandardValueSelected ? '' : value.text}
          maximum={maxLength}
          disabled={disabled}
        />
        {maxLength && (
          <div
            className={cx(
              {
                [styles.warning]:
                  charactersCount >= maxLength - 10 &&
                  charactersCount < maxLength,
                [styles.error]: charactersCount >= maxLength,
              },
              styles.counter
            )}
          >
            {charactersCount} / {maxLength}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdown} disabled={disabled}>
      <div className={styles.container}>
        <div className={styles.value}>{value.text}</div>
        <span className={styles.append}>
          <SVGIcon name="ExpandList" />
        </span>
      </div>
    </ClickDropdown>
  );
};
