import React from 'react';
import { LitmusRole } from 'models/role';
import { useRolesInfiniteQuery } from 'hooks/roles';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { Section } from 'DesignSystem/Form';
import { Spinner } from '../../Shared/Spinner';
import styles from '../roles.module.css';
import { PermissionRow } from '../PermissionRow';

type PropsType = {
  role: Partial<LitmusRole>;
  programId: number;
};

export const Permissions: React.FC<PropsType> = ({ role, programId }) => {
  const { isLoading, data: permissions } = useRolesInfiniteQuery({
    programId,
    order: 'created_at_desc',
    type: 'secondary',
    hidden: false,
  });

  const { data: showActivitiesUI } = useFeatureFlagsQuery(
    programId,
    'Studio.Activities.UI'
  );

  const { data: showPersonalizedFieldsToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFields'
  );

  const { data: showAttributeManagementToggle } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.PersonalizedFieldsV2'
  );

  const { data: showTopicLimitToggle } = useFeatureFlagsQuery(
    programId,
    'EE.UserGeneratedContent.TopicLimitsEnabled'
  );

  const { data: customBlocksFlag } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CustomBlocks'
  );

  const { data: handleFeaturedPermission } = useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.Featured'
  );

  const { data: handleLinkCustomSlugPermission } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.CustomURLsEnabled'
  );

  const { data: configureAuthorAliases } = useFeatureFlagsQuery(
    programId,
    'Studio.Configure.AuthorAliases'
  );

  const { data: setChannelDefaultsEnabled } = useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  );

  const { data: engagementBoostDefaultEnabled } = useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  );

  const { data: deliveryThresholdEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  );

  const { data: designsEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.Designs'
  );

  const { data: insightsPlusEnabled } = useFeatureFlagsQuery(
    programId,
    'Studio.Insights.TableauIntegration'
  );

  const campaignSettingsEnabled =
    setChannelDefaultsEnabled?.value ||
    engagementBoostDefaultEnabled?.value ||
    deliveryThresholdEnabled?.value;

  const sortedPermissions = permissions.sort((a, b) => {
    if (a.sequence < b.sequence) return -1;
    if (a.sequence > b.sequence) return 1;
    return 0;
  });

  const mainPermissions = sortedPermissions
    .filter((p) => p.parent === null)
    .filter((p) => {
      if (p.name === 'journeys') return false;

      if (p.name === 'insights_plus_access') {
        return Boolean(insightsPlusEnabled?.value);
      }

      return true;
    });

  const subPermissionsMap: { [key: string]: LitmusRole[] } = {};
  sortedPermissions.forEach((sortedPermission) => {
    subPermissionsMap[sortedPermission.name] = sortedPermissions
      .filter((c) => {
        if (
          !showActivitiesUI?.value &&
          c.name === 'configure_admin_activity_feed'
        )
          return false;

        if (!designsEnabled?.value && c.name === 'manage_designs') return false;
        if (
          !showPersonalizedFieldsToggle?.value &&
          c.name === 'set_personalized_fields'
        )
          return false;

        if (
          !showAttributeManagementToggle?.value &&
          c.name === 'configure_attribute_management'
        )
          return false;

        if (!showTopicLimitToggle?.value && c.name === 'configure_topic_limit')
          return false;

        if (!configureAuthorAliases?.value && c.name === 'manage_aliases')
          return false;

        if (!handleFeaturedPermission?.value && c.name === 'set_featured')
          return false;

        if (
          !handleLinkCustomSlugPermission?.value &&
          c.name === 'link_custom_slug'
        )
          return false;

        if (
          !campaignSettingsEnabled &&
          c.name === 'configure_campaign_settings'
        )
          return false;

        if (c.name === 'journeys') return false;

        return true;
      })
      .filter(
        (subPermission) =>
          subPermission.parent === sortedPermission.name &&
          (subPermission.name !== 'library_custom_blocks' ||
            customBlocksFlag?.value)
      );
  });

  return (
    <Section
      title="Permissions"
      href={
        !role.isSystemRole
          ? `/${programId}/configure/roles/${
              role.id as number
            }/edit/permissions`
          : ''
      }
      className={styles.section}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        mainPermissions.map((permission) => (
          <PermissionRow
            key={permission.name}
            permission={permission}
            on={(p) => {
              return (
                role.aliases?.some((alias) => alias.name === p.name) || false
              );
            }}
            onChange={(f) => f}
            subPermissionsMap={subPermissionsMap}
            disabled
          />
        ))
      )}
    </Section>
  );
};
