import { useProgram } from 'contexts/program';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { Settings } from 'models/publisher/settings';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

export type DeliveryChannelOption = {
  id?: number;
  name: keyof Settings['deliveryChannels'];
  label: string;
  permitted: boolean;
};

/**
 * Returns DeliveryChannelOptions based on current hiding rules
 *
 * Hiding rules:
 *  - Channels that are not permitted AND not selected are hidden from the user
 *  - Otherwise show channels
 * @returns
 */
export const useChannelSettings = (): {
  deliveryChannelsOptions: DeliveryChannelOption[];
  orchestrateDeliveryForYou: boolean;
  turnOffAndHideForYou: boolean;
} => {
  const {
    settings: { deliveryChannels },
    contentPermissions: { restrictedFields },
  } = useSettings();

  const emailOnly = !!useFeatureFlag('emailOnly');

  const deliveryChannelsOptions: DeliveryChannelOption[] = [
    {
      id: 1,
      name: 'email',
      label: 'Email',
      permitted: !restrictedFields.includes(RestrictedFields.SEND_EMAIL),
    },
    {
      id: 2,
      name: 'assistant',
      label: 'Assistant',
      permitted:
        !restrictedFields.includes(RestrictedFields.SEND_TO_ASSISTANT) &&
        !emailOnly,
    },
    {
      id: 3,
      name: 'push',
      label: 'Push notification',
      permitted:
        !restrictedFields.includes(RestrictedFields.SEND_PUSH) && !emailOnly,
    },
  ];

  const { id: programId } = useProgram();
  const orchestrateDeliveryForYou = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ForYou.Skip'
  ).data?.value;
  const turnOffAndHideForYou = !!useFeatureFlagsQuery(
    programId,
    'Orchestrate.ForYou.Skip.DefaultOn'
  ).data?.value;

  return {
    deliveryChannelsOptions: deliveryChannelsOptions.filter(
      ({ name, permitted }) => permitted || deliveryChannels[name]
    ),
    orchestrateDeliveryForYou,
    turnOffAndHideForYou,
  };
};
