import React from 'react';
import { User } from 'models/user';
import { Icon } from 'shared/Icon';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { DateTime } from 'luxon';
import { Field, Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import styles from '../../../user-profile.module.css';
import {
  isDateOnlyNoTimezoneCriterion,
  isDayOnlyNoTimezoneCriterion,
} from '../../../../../../Editors/AudienceBuilder/CriterionSelect';

type AttributeType = {
  name: string;
  value: unknown;
};

const DATE_FORMAT = 'EEE MMM dd yyyy';
const DAY_FORMAT = 'MMM dd';

export const Attributes: React.FC<{
  data: User;
}> = ({ data }) => {
  const {
    department,
    jobTitle,
    workLocation,
    customAttributes,
    ...rest
  } = data;

  const toCamelCase = (str: string) => {
    return str.replace(/_(\w)?/g, (match) => match.substr(1).toUpperCase());
  };

  const formatLabel = (label: string) => {
    const [firstChar, ...remainingChars] = toCamelCase(label).split('');
    const str = `${firstChar.toUpperCase()}${remainingChars.join('')}`.replace(
      /(At)$/g,
      'On'
    );
    return str.replace(/[A-Z]/g, ' $&');
  };

  const formatValue = (name: string, value: unknown) => {
    if (typeof value !== 'string') return value;

    // lock zone to persisted UTC, do not convert to local TZ
    if (isDayOnlyNoTimezoneCriterion(name)) {
      return DateTime.fromISO(value as string, { zone: 'UTC' }).toFormat(
        DAY_FORMAT
      );
    }
    // lock zone to persisted UTC, do not convert to local TZ
    if (isDateOnlyNoTimezoneCriterion(name)) {
      return DateTime.fromISO(value as string, { zone: 'UTC' }).toFormat(
        DATE_FORMAT
      );
    }
    // format to a date,  converts to local TZ from source UTC
    if (value[value.length - 1] === 'Z' && Date.parse(value))
      return DateTime.fromISO(value as string).toFormat(DATE_FORMAT);
    return value;
  };

  const tryFormatCustomAttribute = (value: unknown) => {
    if (typeof value !== 'string') return value;

    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (datePattern.test(value)) {
      const isoDate = new Date(value).toISOString();
      return DateTime.fromISO(isoDate as string, { zone: 'UTC' }).toFormat(
        DATE_FORMAT
      );
    }
    return value;
  };

  const convertedData: { [key: string]: unknown } = data;
  const excludedKeys = [
    'admin_permission',
    'authentications',
    'channels',
    'contributorChannels',
    'permissions',
    'responses',
    'avatarUrl',
    'avatarColor',
    'status',
    'firstName',
    'lastName',
    'email',
    'username',
    'federatedIdentifier',
    'role',
    'department',
    'jobTitle',
    'workLocation',
  ];
  const [isShowAttributes, setIsShowAttributes] = React.useState(false);
  const attributeKeys: string[] = Object.keys(rest).filter(
    (key) => !excludedKeys.includes(key)
  );

  const defaultAttrs: AttributeType[] = [
    {
      name: 'department',
      value: department,
    },
    {
      name: 'location',
      value: workLocation,
    },
    {
      name: 'title',
      value: jobTitle,
    },
  ].reduce((validAttributes, attr) => {
    if (!attr.value) return validAttributes;
    return [
      ...validAttributes,
      {
        name: formatLabel(attr.name),
        value: formatValue(attr.name, attr.value),
      },
    ];
  }, [] as AttributeType[]);

  const otherAttrs: AttributeType[] = attributeKeys.reduce(
    (validAttributes, attrKey) => {
      if (!convertedData[attrKey]) return validAttributes;
      return [
        ...validAttributes,
        {
          name: formatLabel(attrKey),
          value: formatValue(attrKey, convertedData[attrKey]),
        },
      ];
    },
    [] as AttributeType[]
  );

  const customAttrs: AttributeType[] = (customAttributes as AttributeType[]).reduce(
    (validAttributes, attr) => {
      if (!attr.value) return validAttributes;
      return [
        ...validAttributes,
        {
          name: formatLabel(attr.name),
          value: tryFormatCustomAttribute(attr.value),
        },
      ];
    },
    [] as AttributeType[]
  );

  // The default attributes are listed first, followed by the rest sorted alphabetically
  const attributes = defaultAttrs.concat(
    otherAttrs.concat(customAttrs).sort((a, b) => (a.name > b.name ? 1 : -1))
  );

  const attributesToDisplay: AttributeType[] = isShowAttributes
    ? attributes || []
    : (attributes || []).slice(0, 3);

  return (
    <Section title="Attributes" className={styles.userPage}>
      {attributesToDisplay?.map((attribute) => (
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label={attribute.name}>
              {(attribute.value as string) || 'NA'}
            </Field>
          </div>
        </Fieldset>
      ))}
      <Row>
        {attributesToDisplay.length ? (
          <Block>
            {/* eslint-disable-next-line */}
            <div
              className={styles.showMore}
              onClick={() => setIsShowAttributes(!isShowAttributes)}
            >
              <span>
                <Icon
                  iconName={isShowAttributes ? 'angle-up' : 'angle-down'}
                  iconType="SVG"
                />
              </span>
              <span className={styles.showMoreText}>
                {isShowAttributes
                  ? 'hide attributes'
                  : `show all ${attributes?.length} attributes`}
              </span>
            </div>
          </Block>
        ) : (
          <Block>
            <div className={styles.empty}>
              This user does not have any attributes.
            </div>
          </Block>
        )}
      </Row>
    </Section>
  );
};
