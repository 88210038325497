import React from 'react';
import { Section } from 'DesignSystem/Form';
import { FormPage } from 'DesignSystem/Layout/Pages';
import { RouteComponentProps } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { color, Subheading, Body } from 'DesignSystem/Typography';
import { TextInput } from 'shared/TextInput';
import { useProgram } from 'contexts/program';
import { Program } from 'models/program';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { useActions } from './useAction';

import styles from './legal.module.css';

export const EditGdprPage: React.FC<RouteComponentProps> = () => {
  const program = useProgram();
  const { update } = useActions();
  const [data, setData] = React.useState(program);

  function onChange(updatedData: Partial<Program>) {
    setData({ ...data, ...updatedData });
  }

  return (
    <WithPermission permissions={['securityAccess']}>
      <FormPage
        title="GDPR Compliance"
        breadcrumbs={[
          { label: 'Configure', to: '../..' },
          { label: 'Legal', to: '..' },
          { label: 'GDPR Compliance Contacts' },
        ]}
        actions={[
          {
            label: 'Save',
            icon: <Icon name="SaveSuccess" />,
            onClick: () => {
              update(data);
            },
          },
        ]}
      >
        <Section>
          <Subheading bold>Contact Email(s)</Subheading>
          <Body color={color.gray60} block>
            Community contact email(s) when users request to export or erase
            their personal data. Use comma to separate different email
            addresses.
          </Body>
          <TextInput
            className={styles.textField}
            placeholder="Type Contact Email(s)"
            value={data.gdprComplianceContacts}
            onChange={(value) => onChange({ gdprComplianceContacts: value })}
          />
        </Section>
      </FormPage>
    </WithPermission>
  );
};
