import { useEffect, useState } from 'react';

const tableauDomain = process.env.REACT_APP_TABLEAU_DOMAIN;

export const useTableauLibrary = (): boolean => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    (async () => {
      await loadTableau();
      setReady(true);
    })();
  });
  return ready;
};

async function loadTableau() {
  const isReady = () => window.Tableau;
  const isEmpty = () => !isReady() && !document.body.dataset.tableau;

  return new Promise((ready, fail) => {
    if (isReady()) ready(true);
    else {
      if (isEmpty()) {
        document.body.dataset.tableau = 'ran';
        const script = document.createElement('script');
        script.type = 'module';
        script.async = true;
        script.innerText = `
        import {
          TableauEventType,
          TableauViz,
          FilterUpdateType,
        } from '${tableauDomain}/javascripts/api/tableau.embedding.3.7.0.min.js';

        window.Tableau = {
          TableauViz,
          TableauEventType,
          FilterUpdateType
        };
        `;
        document.head.appendChild(script);
      }
      let attempts = 200;
      const scriptFinder = setInterval(() => {
        if (window.Tableau) {
          clearInterval(scriptFinder);
          ready(true);
        } else if (attempts) {
          attempts -= 1;
        } else {
          clearInterval(scriptFinder);
          fail(new Error('too many attempts'));
        }
      }, 50);
    }
  });
}
