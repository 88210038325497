import * as React from 'react';
import cx from 'classnames';
import { InfiniteList, InfiniteLoadProps } from 'shared/InfiniteList';
import { DropdownMenu } from 'DesignSystem/Components/DropdownMenu';
import { Checkbox } from 'shared/Checkbox';
import styles from './infinite-select.module.css';

export type { InfiniteLoadProps };

export type DropdownProps = {
  selectedIds: Array<string>;
  onSelectedIdsChange: (newSelectedIds: Array<string>) => void;
  searchEnabled?: boolean;
  searchTerm?: string;
  searchPlaceholder?: string;
  onSearchTermChange?: (value: string) => void;
  hasClearSearchButton?: boolean;
  autofocus?: boolean;
  onHighlight?: (id: string) => void;
  itemClassName?: string;
  checkboxClassName?: string;
  clearDisabled?: boolean;
  hoverFocus?: boolean;
  onHandleChange?: (id: string) => void;
};

type DesignSystemAdditions = Partial<{
  dismissButton: string | React.ReactNode;
  onDismissRef: React.MutableRefObject<() => void>;
}>;

type StyleProps = {
  noShadow?: boolean;
};

type PropsType = {
  rowIds: Array<string>;
  rowRenderProp: (index: string) => React.ReactNode;
  maxHeight: number;
  itemHeight: number;
  className?: string;
  allOptionId?: string;
  existenceGranted?: boolean;
} & DropdownProps &
  InfiniteLoadProps &
  DesignSystemAdditions &
  StyleProps;

/**
 *
 * @param existenceGranted - [boolean=true] There can be cases when some ids from selectedIds never be part of rowIds.
 * It also doesn't exist when we fetch data using fetchNextPage.
 *
 */
export const InfiniteSelect: React.FC<PropsType> = (props) => {
  const {
    rowIds = [],
    existenceGranted = true,
    rowRenderProp,
    maxHeight,
    itemHeight,
    dismissButton,
    onDismissRef,
    // DropdownProps
    selectedIds,
    onSelectedIdsChange,
    searchEnabled,
    searchPlaceholder,
    searchTerm,
    hasClearSearchButton,
    onSearchTermChange,
    autofocus,
    onHighlight,
    itemClassName,
    checkboxClassName,
    // Infinite load props
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    threshold,
    overscan,
    clearDisabled,
    hoverFocus,
    onHandleChange,
    allOptionId,
    noShadow,
  } = props;

  const sortedIds = React.useMemo(() => {
    if (searchTerm) {
      return rowIds.filter((id) => !selectedIds.includes(id));
    }
    const filteredSelectedIds: Array<string> = [];
    const unselectedIds = [...rowIds.filter((id) => id !== allOptionId)];

    selectedIds
      .filter((id) => id)
      .filter((id) => id !== allOptionId)
      .forEach((id) => {
        const index = unselectedIds.indexOf(id);
        if (index >= 0) {
          filteredSelectedIds.push(unselectedIds.splice(index, 1)[0]);
        } else if (existenceGranted) {
          filteredSelectedIds.push(id);
        }
      });
    return [...filteredSelectedIds, ...unselectedIds];
  }, [searchTerm, rowIds, selectedIds, allOptionId, existenceGranted]);

  function handleClear() {
    onSelectedIdsChange([]);
  }

  function handleChange(id: string) {
    if (onHandleChange) {
      onHandleChange(id);
      return;
    }

    const newSelectedIds = [...selectedIds];
    const index = selectedIds.indexOf(id);
    if (index >= 0) {
      newSelectedIds.splice(index, 1);
    } else {
      newSelectedIds.push(id);
    }
    onSelectedIdsChange(newSelectedIds);
  }

  function handleSelect(index: number) {
    handleChange(sortedIds[index]);
  }

  function handleHighlight(index: number) {
    if (onHighlight) {
      onHighlight(sortedIds[index]);
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <DropdownMenu
      maxHeight={maxHeight}
      childrenContentHeight={itemHeight * sortedIds.length}
      clearEnabled={selectedIds.length > 0 && !clearDisabled}
      onClear={handleClear}
      searchEnabled={searchEnabled}
      searchTerm={searchTerm}
      hasClearButton={hasClearSearchButton}
      onSearchTermChange={onSearchTermChange}
      className={cx(styles.dropdown, {
        [styles.noShadow]: noShadow,
      })}
      searchPlaceholder={searchPlaceholder}
      dismissButton={dismissButton}
      onDismissRef={onDismissRef}
    >
      {(height: number) => (
        <InfiniteList
          noItemsComponent={
            <div className={styles.NoMatches}>No matches found.</div>
          }
          itemCount={sortedIds.length}
          itemHeight={itemHeight}
          height={height}
          highlightable
          onSelect={handleSelect}
          onHighlight={handleHighlight}
          autofocus={autofocus}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          threshold={threshold}
          overscan={overscan}
          hoverFocus={hoverFocus}
        >
          {(index) => {
            const id = sortedIds[index];
            return (
              <div key={id} className={cx(styles.row, itemClassName)}>
                <div className={cx(styles.rowCheckbox, checkboxClassName)}>
                  <Checkbox
                    checked={selectedIds.includes(id)}
                    onChange={() => handleChange(id)}
                  />
                </div>
                <div
                  className={styles.rowLabel}
                  onClick={() => handleChange(id)}
                  data-test="select-item"
                >
                  {rowRenderProp(id)}
                </div>
              </div>
            );
          }}
        </InfiniteList>
      )}
    </DropdownMenu>
  );
};
