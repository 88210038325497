import React, { BaseSyntheticEvent } from 'react';
import defaultStyles from './slider.module.css';

export const Slider: React.FC<{
  onChange: (size: number) => void;
  value?: number;
  min: number;
  max: number;
  step?: number;
  fireOnRelease?: boolean;
  styles?: { wrapper?: string; slider?: string; value?: string };
  disabled?: boolean;
}> = ({
  onChange,
  value,
  min,
  max,
  step = 1,
  fireOnRelease = false,
  styles = {},
  disabled,
}) => {
  const handleChange = (event: BaseSyntheticEvent): void => {
    const { target } = event;
    onChange(parseInt(target.value, 10));
  };

  return (
    <div className={styles.wrapper || defaultStyles.wrapper}>
      <input
        className={styles.slider || defaultStyles.slider}
        onChange={fireOnRelease ? undefined : handleChange}
        onMouseUp={fireOnRelease ? handleChange : undefined}
        onInput={handleChange}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        disabled={disabled}
      />
      <span className={styles.value || defaultStyles.value}>{value}</span>
    </div>
  );
};
