import React, { useEffect } from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import {
  ContentFilterBar,
  ContentFilterFetchProps,
} from 'components/content/ContentFilterBar/ContentFilterBar';
import {
  ContentFiltersContext,
  FiltersStateType,
} from 'contexts/content/filters';
import { useProgram } from 'contexts/program';
import { useDebounce } from 'hooks/useDebounce';
import { CommunicationStep } from 'models/journeys/journey';
import { FetchProps } from 'services/api-content';
import { AutoHeight } from 'shared/AutoHeight';
import { ROW_HEIGHT } from 'shared/BannerListItem';
import { InfiniteList } from 'shared/InfiniteList';
import { SearchBar } from 'shared/SearchBar';
import { useJourneyState } from 'contexts/journey';
import { JourneyContentListItem } from './JourneyContentListItem';
import { JourneyListItem, useJourneyContentList } from './useJourneyList';
import styles from './journey-content-list-drawer.module.css';
import { ContentListState } from './Drawer';

const MAX_SEARCH_BAR_WIDTH = 490;

export const JourneyContentList: React.FC<{
  onSelectContent: (content: Partial<CommunicationStep>) => void;
  contentType: JourneyListItem['type'];
}> = ({ onSelectContent, contentType }) => {
  return (
    <Flex column alignStart className={styles.journeyContentList}>
      {contentType === 'content' ? (
        <ContentInfiniteList onSelectContent={onSelectContent} />
      ) : (
        <TemplateInfiniteList onSelectContent={onSelectContent} />
      )}
    </Flex>
  );
};

const ContentInfiniteList: React.FC<{
  onSelectContent: (content: Partial<CommunicationStep>) => void;
}> = ({ onSelectContent }) => {
  const { filters, setValue, searchParams, setBooleanValue } = React.useContext(
    ContentFiltersContext
  );

  const [filterFetchProps, setFilterFetchProps] = React.useState<
    ContentFilterFetchProps
  >({
    search: searchParams.fieldValue,
  });

  const { contentListState } = useJourneyState();

  useEffect(() => {
    if (contentListState === ContentListState.Closed) {
      setFilterFetchProps({
        ...filterFetchProps,
        search: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentListState]);

  return (
    <>
      <ContentFilterBar
        fetchProps={filterFetchProps}
        onChange={setFilterFetchProps}
        filters={filters}
        setValue={setValue}
        setBooleanValue={setBooleanValue}
        dropdownAlignment="center"
        filtersLayout="journeys"
      />
      <JourneyContentInfiniteList
        filterFetchProps={filterFetchProps}
        filters={filters}
        onSelectContent={onSelectContent}
        contentType="content"
      />
    </>
  );
};

const TemplateInfiniteList: React.FC<{
  onSelectContent: (content: Partial<CommunicationStep>) => void;
}> = ({ onSelectContent }) => {
  const { filters, searchParams } = React.useContext(ContentFiltersContext);

  const [filterFetchProps, setFilterFetchProps] = React.useState<
    ContentFilterFetchProps
  >({
    search: searchParams.fieldValue,
  });

  const handleSearchChange = (value: string) =>
    setFilterFetchProps({ search: value });

  return (
    <>
      <SearchBar
        value={filterFetchProps.search}
        onChange={handleSearchChange}
        placeholder="Search campaigns"
        width={`${MAX_SEARCH_BAR_WIDTH}px`}
      />
      <JourneyContentInfiniteList
        filterFetchProps={filterFetchProps}
        filters={filters}
        onSelectContent={onSelectContent}
        contentType="template"
      />
    </>
  );
};

const JourneyContentInfiniteList: React.FC<{
  filterFetchProps: ContentFilterFetchProps;
  filters: FiltersStateType;
  onSelectContent: (content: Partial<CommunicationStep>) => void;
  contentType: 'content' | 'template';
}> = ({ filterFetchProps, filters, contentType, onSelectContent }) => {
  const { id: programId } = useProgram();

  const debouncedFilterFetchProps = useDebounce(filterFetchProps);
  const fetchProps: FetchProps & {
    includeVersions: 'new';
  } = {
    programId,
    sort: 'updated_at',
    sortDirection: 'desc',
    processingState: ['completed', 'processing'],
    pageSize: 20,
    includeVersions: 'new',
    ...debouncedFilterFetchProps,
  };

  const {
    items,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useJourneyContentList(fetchProps, filters, contentType);

  return (
    <div className={styles.journeyContents}>
      <AutoHeight>
        {(height: number) => (
          <InfiniteList
            itemCount={items.length}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            itemHeight={ROW_HEIGHT}
            height={height - 20}
          >
            {(index: number) => (
              <JourneyContentListItem
                content={items[index]}
                onSelectContent={onSelectContent}
              />
            )}
          </InfiniteList>
        )}
      </AutoHeight>
    </div>
  );
};
