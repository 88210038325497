import * as React from 'react';
import { Flex } from 'DesignSystem/Layout/Flex';
import styles from './navigation-bars.module.css';

export enum StatusColor {
  draft = 'var(--color-blueTintDark)',
  active = 'var(--color-greenTintDarkAlt)',
  archived = 'var(--color-gray10)',
  publishing = 'var(--color-blueTintDark)',
  paused = '#fffbe8',
}

export type TitleBarStatus = {
  status: string;
  statusColor: StatusColor;
};

export const StatusTag: React.FC<{ status: TitleBarStatus }> = ({ status }) => {
  return (
    <Flex
      className={styles.statusTag}
      style={{ backgroundColor: status.statusColor }}
    >
      {status.status}
    </Flex>
  );
};
