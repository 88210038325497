import React from 'react';
import {
  Checkbox,
  CheckboxLabel,
  Icon,
} from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Text } from 'DesignSystem/Typography';
import { ProgramOrAuthor } from 'hooks/useAuthorsList';
import { useUniqueId } from 'hooks/useUniqueId';
import { EmailSenderAlias } from 'models/publisher/settings';
import { Optional } from 'utility-types';
import { CheckboxGroup } from 'shared/CheckboxGroup';
import cx from 'classnames';
import {
  AuthorCombobox,
  EmailSenderAliasCombobox,
  TextInputField,
} from './inputs';
import styles from './channel-selection.module.css';
import {
  ChannelSelectionContext,
  CheckedChannelsState,
  useChannelSelectionContext,
  useChannelSelectionState,
} from './hooks';

export const ChannelSelection = Object.assign(ChannelSelectionRoot, {
  Email: EmailChannel,
  NotificationCenter: NotificationCenterChannel,
  PushNotification: PushNotificationChannel,
});

type ChannelSelectionProps<TC extends Partial<CheckedChannelsState>> = {
  children: React.ReactNode;
  initialCheckedChannels?: TC;
} & Optional<
  Pick<ChannelSelectionContext, 'checked' | 'toggleChecked' | 'variant'>
>;

const defaultCheckedChannels = {
  email: true,
  notification_center: true,
  push: true,
};

const defaultInputPlaceholder = 'Notification Placeholder';

export function ChannelSelectionRoot<TC extends Partial<CheckedChannelsState>>({
  children,
  initialCheckedChannels = defaultCheckedChannels as TC,
  variant = 'configure',
  ...contextValues
}: ChannelSelectionProps<TC>): JSX.Element {
  const { checked, toggleChecked } = useChannelSelectionState({
    initialCheckedChannels,
  });
  const contextProviderValues: ChannelSelectionContext = {
    checked: contextValues.checked ?? checked,
    toggleChecked: contextValues.toggleChecked ?? toggleChecked,
    variant,
  };
  return (
    <section>
      <div className={styles.channelSelectionHeader}>
        <Text
          className={{
            Subheading: true,
            Semibold: true,
          }}
        >
          Channels
        </Text>
      </div>
      <ChannelSelectionContext.Provider value={contextProviderValues}>
        <div
          className={cx(styles.channelSelectionGroups, {
            [styles.channelSelectionGroupsTestSend]: variant === 'test-send',
          })}
        >
          {children}
        </div>
      </ChannelSelectionContext.Provider>
    </section>
  );
}

type EmailGroupProps = {
  emailAlias?: EmailSenderAlias | undefined;
  onEmailAliasChange?: (alias: EmailSenderAlias) => void;

  subject: string;
  onSubjectChange: (subject: string) => void;

  previewText: string;
  onPreviewTextChange: (previewText: string) => void;
  errors?: {
    emailAlias: string[];
    subject: string[];
    previewText: string[];
  };
};

function EmailChannel({
  errors,
  onEmailAliasChange = () => {},
  emailAlias,
  onSubjectChange,
  subject,
  onPreviewTextChange,
  previewText,
}: EmailGroupProps) {
  const { variant } = useChannelSelectionContext();

  return (
    <ChannelCheckboxGroup
      label="Email"
      name="email"
      icon={<Icon size={24}>mail</Icon>}
    >
      {variant === 'configure' && (
        <EmailSenderAliasCombobox
          emailAlias={emailAlias}
          onAliasSelect={onEmailAliasChange}
        />
      )}

      <TextInputField
        label="Subject Line"
        value={subject}
        onChange={onSubjectChange}
        inputPrefixText={variant === 'test-send' ? '[Test Email]' : ''}
        placeholder={
          variant === 'test-send' ? 'Type subject' : defaultInputPlaceholder
        }
        errorMessage={errors?.subject?.join(', ')}
        /**
         * AI Generation Feature Required for Delivery page implementation
         */
        // post={post}
        // showAIGenerationButton={usingAIGeneration}
        // aiCommandType="delivery_preview"
        // context={{ subject: localNotification.text }}
      />

      <TextInputField
        label="Preview"
        value={previewText}
        onChange={onPreviewTextChange}
        placeholder={
          variant === 'test-send' ? 'Type preview' : defaultInputPlaceholder
        }
        errorMessage={errors?.previewText?.join(', ')}
        /**
         * AI Generation Feature Required for Delivery page implementation
         */
        // post={post}
        // showAIGenerationButton={usingAIGeneration}
        // aiCommandType="delivery_preview"
        // context={{ subject: localNotification.text }}
      />
    </ChannelCheckboxGroup>
  );
}

type NotificationGroupProps = {
  author?: ProgramOrAuthor;
  onAuthorChange: (author: ProgramOrAuthor) => void;
  initialAuthor?: ProgramOrAuthor;

  title: string;
  onTitleChange: (title: string) => void;

  markAsImportant: boolean;
  onMarkAsImportantChange: (important: boolean) => void;

  errors?: {
    title: string[];
  };
  children?: React.ReactNode;
};

function NotificationCenterChannel({
  author,
  onAuthorChange,
  initialAuthor,
  title,
  onTitleChange,
  markAsImportant,
  onMarkAsImportantChange,
  errors,
  children,
}: NotificationGroupProps) {
  const { variant } = useChannelSelectionContext();

  const markedImportantCheckboxId = useUniqueId();
  const markedImportantLabelId = `${markedImportantCheckboxId}__label`;
  const markedImportantDescriberId = `${markedImportantCheckboxId}__describer`;

  return (
    <ChannelCheckboxGroup
      label="Notification Center"
      name="notification_center"
      icon={<Icon size={24}>notifications</Icon>}
    >
      {variant === 'configure' && (
        <AuthorCombobox
          onAuthorSelect={onAuthorChange}
          author={author}
          initialAuthor={initialAuthor}
        />
      )}
      <TextInputField
        label="Title"
        value={title}
        onChange={onTitleChange}
        inputPrefixText={variant === 'test-send' ? '[Test Notification]' : ''}
        placeholder={
          variant === 'test-send' ? 'Type subject' : defaultInputPlaceholder
        }
        errorMessage={errors?.title?.join(', ')}
      />
      {children}
      <Checkbox
        checked={markAsImportant}
        onCheckedChange={onMarkAsImportantChange}
        aria-labelledby={markedImportantLabelId}
        aria-describedby={markedImportantDescriberId}
      >
        <Flex column alignStart>
          <CheckboxLabel
            id={markedImportantLabelId}
            htmlFor={markedImportantCheckboxId}
            label="Mark as Important"
          />
        </Flex>
      </Checkbox>
      <Text
        as="label"
        className={{ Caption: true, gray60: true }}
        id={markedImportantDescriberId}
      >
        This will highlight the communication in the Notification Center
      </Text>
    </ChannelCheckboxGroup>
  );
}

function PushNotificationChannel({
  pushMessage,
  onPushMessageChange,
  errors,
}: {
  pushMessage: string;
  onPushMessageChange: (message: string) => void;
  errors?: {
    pushMessage: string[];
  };
}): JSX.Element {
  const { variant } = useChannelSelectionContext();

  return (
    <ChannelCheckboxGroup
      label="Send a Push Notification"
      name="push"
      icon={<Icon size={24}>phone_iphone</Icon>}
    >
      <TextInputField
        label="Push Message"
        value={pushMessage}
        onChange={onPushMessageChange}
        errorMessage={errors?.pushMessage?.join(', ')}
        inputPrefixText={variant === 'test-send' ? '[Test Push]' : ''}
        placeholder={
          variant === 'test-send' ? 'Type message' : defaultInputPlaceholder
        }
      />
    </ChannelCheckboxGroup>
  );
}

type ChannelCheckboxGroupProps = {
  label: string;
  name: keyof CheckedChannelsState;
  icon?: React.ReactNode;
  children: React.ReactNode;
  onCheckedChange?: (checked: boolean | 'indeterminate') => void;
};

function ChannelCheckboxGroup({
  label,
  name,
  children,
  icon,
  onCheckedChange,
}: ChannelCheckboxGroupProps) {
  const { checked, toggleChecked } = useChannelSelectionContext();
  const isChecked = checked(name);

  return (
    <CheckboxGroup
      label={label}
      isChecked={isChecked}
      toggleChecked={(c) => {
        toggleChecked(name);
        onCheckedChange?.(c);
      }}
      icon={icon}
    >
      {children}
    </CheckboxGroup>
  );
}
