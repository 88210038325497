import * as React from 'react';
import {
  background,
  Background,
  Border,
  border,
  Color,
} from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components/Box';

export interface MoreInfoProps {
  backgroundStyle?: Background;
  borderStyle?: Border;
  padding?: number;
  textStyle?: Color;
  children?: JSX.Element | string;
}

export const MoreInfo: React.FC<MoreInfoProps> = (props) => {
  const { backgroundStyle, borderStyle, textStyle, padding, children } = props;

  return (
    <Box
      color={backgroundStyle || background.gray10}
      border={borderStyle || border.radius4}
      padding={padding}
    >
      <Box color={textStyle}>{children}</Box>
    </Box>
  );
};
