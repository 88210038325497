import React, { useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { DesignContext } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { useDesign } from 'hooks/design';
import { LoadingScreen } from 'shared/LoadingScreen';
import { useFlashMessage } from 'contexts/flasher';
import { useTopicFormCtx } from '../../context';

import { DesignerWrapper } from './DesignerWrapper';

export const AboutEditor: React.FC<RouteComponentProps<{ id: number }>> = ({
  id,
}) => {
  const { baseUrl } = useTopicFormCtx();

  const program = useProgram();

  const designContext = useDesign(program.id, Number(id));
  const { status, error } = { ...designContext };

  const { setFlashMessage } = useFlashMessage();
  useEffect(() => {
    if (!id) {
      setFlashMessage({
        message: 'Failed to load editor. Try again',
        severity: 'error',
      });
      navigate(baseUrl);
    }
  });
  if (!id) {
    return <LoadingScreen />;
  }

  if (status.isLoading && !status.hasLoaded) return <LoadingScreen />;

  if (error && error.length > 0) return <p>Error: {error}</p>;

  if (status.hasLoaded)
    return (
      <DesignContext.Provider value={designContext}>
        <DesignerWrapper />
      </DesignContext.Provider>
    );
  return <LoadingScreen />;
};
