import React from 'react';
import { usePermissions } from 'contexts/permissions';
import { Section } from 'shared/SectionBlocks';
import { Fieldset } from 'shared/Fieldset';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import styles from 'components/publisher/settings/SettingsEditor/settings-editor.module.css';
import { Archive } from 'components/publisher/settings/SettingsEditor/sections/Archive';
import { RouteComponentProps } from '@reach/router';
import { EngineSettings } from 'components/publisher/settings/SettingsEditor/EngineSettings';

import { ChannelSettings } from './ChannelSettings';
import { MessageSettings } from './MessageSettings';

export const Rules: React.FC<RouteComponentProps> = () => {
  const { settings, fields, contentPermissions } = useSettings();
  const { deliveryType } = fields;

  const {
    permissions: { advancedOrchestrationRulesAccess },
  } = usePermissions();

  const { canEdit } = contentPermissions;

  const orchestrationRulesEnabled =
    advancedOrchestrationRulesAccess && deliveryType.enabled;

  const emailAndPushChannelsDisabled =
    !settings.deliveryChannels.email && !settings.deliveryChannels.push;

  return (
    <>
      <EngineSettings disabled={!orchestrationRulesEnabled || !canEdit} />
      <ChannelSettings disabled={!orchestrationRulesEnabled || !canEdit} />
      <MessageSettings
        disabled={
          !orchestrationRulesEnabled || emailAndPushChannelsDisabled || !canEdit
        }
      />
      <Section title="Archive">
        <Fieldset>
          <div className={fieldsetStyles.fieldGroupContainer}>
            <div className={styles.fieldArchive}>
              <Archive disabled={!canEdit} />
            </div>
          </div>
        </Fieldset>
      </Section>
    </>
  );
};
