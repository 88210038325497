import React, { FC, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { WithPermission } from 'shared/WithPermission';
import { LoadingScreen } from 'shared/LoadingScreen';
import { useFlashServerErrors } from 'utility/errors';
import { useProgram } from '../../../../../contexts/program';
import { useEditTopicQuery } from '../../../../../hooks/topics';
import { TopicContext } from './context';

const TopicV2: FC<RouteComponentProps<{ id: string }>> = ({ id, children }) => {
  const { id: programId } = useProgram();
  const { data: topic, error } = useEditTopicQuery(programId, Number(id));
  const flashServerErrors = useFlashServerErrors();

  useEffect(() => {
    if (error) {
      flashServerErrors(error, 'Unable to edit topic');
      navigate(`/${programId}/configure/topics`);
    }
  }, [error, programId, flashServerErrors]);

  if (!topic) {
    return <LoadingScreen />;
  }

  return (
    <WithPermission permissions={['confContentAccess', 'topicsAccess']}>
      <TopicContext.Provider value={topic}>{children}</TopicContext.Provider>
    </WithPermission>
  );
};

export default TopicV2;
