import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Body, color, FieldLabel, Subheading } from 'DesignSystem/Typography';
import { Checkbox } from 'shared/Checkbox';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { EmailSenderAlias } from 'models/publisher/settings';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import styles from 'components/publisher/settings/SettingsEditor/settings-editor.module.css';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import cx from 'classnames';
import { Channel, DeliveryChannels, selectedChannels } from 'models/channel';
import { Icon } from 'shared/Icon';
import { Notification, useNotificationValidator } from 'models/notification';
import { ReactComponent as Phone } from 'shared/icons/IPhone.svg';
import { ReactComponent as Lightbulb } from 'shared/icons/LightBulb2.svg';
import { ReactComponent as Mail } from 'shared/icons/Mail2.svg';
import { datadogRum } from '@datadog/browser-rum';
import changeChannelsStyles from './change-channels.module.css';
import { EmailSenderAliasField } from './EmailSenderAliasField';
import { useChannelSettings } from './useChannelSettings';
import { SaveModalButtons } from '../Shared/SaveModalButtons';
import { NotificationField } from '../Notifications/NotificationField';

import {
  PREVIEW_TEXT_PLACEHOLDER,
  TEXT_PLACEHOLDER,
  useNotifications,
} from '../Notifications/useNotifications';
import { InfoTooltip } from '../Shared/InfoTooltip';

export const channelIcons = (
  name: string | keyof DeliveryChannels
): React.ReactNode => {
  switch (name) {
    case 'assistant':
      return <Lightbulb />;
    case 'email':
      return <Mail />;
    case 'push':
      return <Phone />;
    default:
      return undefined;
  }
};

export const ChangeChannels: React.FC<{
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  setDismissable?: (dismissable: boolean) => void;
}> = ({ disabled, onCancel, onSave, setDismissable }) => {
  const {
    contentPermissions: { restrictedFields },
    settings: { includeInForYou, deliveryChannels, emailSenderAlias },
    fields: { deliveryChannels: setDeliveryChannels },
  } = useSettings();
  const {
    deliveryChannelsOptions,
    orchestrateDeliveryForYou,
    turnOffAndHideForYou,
  } = useChannelSettings();

  const { data: defaultAddress } = useDefaultEmailAddress();

  const {
    firstNotification,
    reTargetingNotifications,
    notifications,
    applyDefaultTexts,
  } = useNotifications();

  const [localNotification, setLocalNotification] = useState<Notification>(
    firstNotification
  );
  const [emailAlias, setEmailAlias] = useState(emailSenderAlias);

  const [channelSelection, setChannelSelection] = useState<DeliveryChannels>(
    deliveryChannels
  );

  const [includeInForYouChecked, setIncludeInForYouChecked] = useState(
    !turnOffAndHideForYou && includeInForYou
  );

  const [saveEnabled, setSaveEnabled] = useState<boolean>(false); // enables save button when a change occurred

  // We may display default values, different from what has been saved, so allow them to save!
  const [formDirty, setFormDirty] = useState<boolean>(
    localNotification.text !== notifications[0].text ||
      localNotification.previewText !== notifications[0].previewText ||
      localNotification.pushText !== notifications[0].pushText
  );

  useEffect(() => {
    if (!emailAlias && defaultAddress) {
      setEmailAlias(defaultAddress);
    }
  }, [defaultAddress, emailAlias]);

  const onEmailAliasChange = useCallback(
    (alias?: EmailSenderAlias) => {
      setEmailAlias(alias);
      setFormDirty(true);
    },
    [setEmailAlias, setFormDirty]
  );

  const onChannelChange = useCallback(
    ({ name, value }: { name: Channel; value: boolean }) => {
      setChannelSelection((previousChannelOptions) => ({
        ...previousChannelOptions,
        [name]: value,
      }));
      if (value) {
        setLocalNotification(applyDefaultTexts(localNotification, name));
      }
      setFormDirty(true);
    },
    [setChannelSelection, setFormDirty, applyDefaultTexts, localNotification]
  );

  const handleForYouToggled = useCallback(
    (value: boolean) => {
      setIncludeInForYouChecked(value);
      setFormDirty(true);
    },
    [setIncludeInForYouChecked, setFormDirty]
  );

  const localSelectedChannels = selectedChannels(channelSelection);

  // disable dismiss if the form is clean
  useEffect(() => {
    if (setDismissable) setDismissable(!formDirty);
  }, [setDismissable, formDirty]);

  const validChannelSelection =
    localSelectedChannels.length > 0 ||
    !orchestrateDeliveryForYou ||
    (orchestrateDeliveryForYou && includeInForYouChecked);

  const validNotification = useNotificationValidator(
    [localNotification],
    localSelectedChannels
  );

  const notificationChanged =
    localNotification.text !== firstNotification.text ||
    localNotification.previewText !== firstNotification.previewText ||
    localNotification.pushText !== firstNotification.pushText;

  useEffect(
    () =>
      setSaveEnabled(
        formDirty && !disabled && validChannelSelection && validNotification
      ),
    [formDirty, disabled, validChannelSelection, validNotification]
  );

  const localOnSave = () => {
    if (!saveEnabled) return;

    if (notificationChanged) {
      localNotification.persisted = true;
    }

    setDeliveryChannels.set({
      deliveryChannels: channelSelection,
      emailSenderAlias: emailAlias,
      includeInForYou: includeInForYouChecked,
      notifications: [localNotification, ...reTargetingNotifications],
    });

    datadogRum.addAction('save_delivery_channels', {
      channelSelection,
      emailAlias,
      includeInForYouChecked,
      notifications: [localNotification, ...reTargetingNotifications],
    });

    if (onSave) {
      onSave();
    }
  };

  const { id } = useProgram();
  const { post } = usePublisher();
  const { data: AIGenerationFeatureFlag } = useFeatureFlagsQuery(
    id,
    'Studio.Publish.AI.CoverGeneration'
  );
  if (!AIGenerationFeatureFlag) return null;
  const usingAIGeneration = !!AIGenerationFeatureFlag.value;

  return (
    <>
      <Box>
        <Box margin={[0, 0, 16, 0]}>
          <Subheading bold>
            Channels
            <InfoTooltip message="Unless only one channel is selected, the orchestration engine will optimize which enabled channels to leverage for each campaign." />
          </Subheading>
        </Box>
        <Box margin={[24, 0, 0, 7]}>
          {deliveryChannelsOptions.map(({ name, label, permitted }) => (
            <>
              <Box
                key={name}
                id={name}
                margin={[0, 0, 20, 10]}
                style={{ display: 'flex' }}
              >
                <span
                  className={cx({ [styles.disableCheckbox]: !permitted })}
                  data-test={`channel-settings-${name}`}
                  key={`channel-settings-${name}`}
                >
                  <Checkbox
                    name={name}
                    onChange={(v) => onChannelChange({ name, value: v })}
                    checked={channelSelection[name]}
                    className={cx({
                      [styles.disabledSetting]: disabled || !permitted,
                    })}
                    disabled={disabled || !permitted}
                    label={
                      <Body>
                        <span className={changeChannelsStyles.Label}>
                          <span className={changeChannelsStyles.Icon}>
                            {channelIcons(name)}
                          </span>
                          <span>{label}</span>
                        </span>
                      </Body>
                    }
                  />
                </span>
              </Box>

              {name === 'email' && channelSelection[name] && (
                <Box margin={[16, 55, 0, 50]}>
                  <EmailSenderAliasField
                    onAliasChange={onEmailAliasChange}
                    value={emailAlias}
                    disabled={
                      disabled ||
                      !permitted ||
                      restrictedFields.includes(
                        RestrictedFields.EMAIL_SENDER_ALIAS
                      )
                    }
                  />
                  <Box dataTest="subject-line" margin={[16, 0, 0, 0]}>
                    <FieldLabel>Subject Line</FieldLabel>
                    <NotificationField
                      field={localNotification.text || ''}
                      onChange={(text) => {
                        setLocalNotification({
                          ...localNotification,
                          text,
                        });
                        setFormDirty(true);
                      }}
                      fieldName=""
                      placeholder={TEXT_PLACEHOLDER}
                      disabled={disabled || !permitted}
                      post={post}
                      showAIGenerationButton={usingAIGeneration}
                      aiCommandType="delivery_subject"
                    />
                  </Box>
                  <Box dataTest="preview" margin={[0, 0, 0, 0]}>
                    <FieldLabel>Preview</FieldLabel>
                    <NotificationField
                      field={localNotification.previewText || ''}
                      onChange={(previewText) => {
                        setLocalNotification({
                          ...localNotification,
                          previewText,
                        });
                        setFormDirty(true);
                      }}
                      fieldName=""
                      placeholder={PREVIEW_TEXT_PLACEHOLDER}
                      disabled={disabled || !permitted}
                      post={post}
                      showAIGenerationButton={usingAIGeneration}
                      aiCommandType="delivery_preview"
                      context={{ subject: localNotification.text }}
                    />
                  </Box>
                </Box>
              )}
              {name === 'push' && channelSelection[name] && (
                <Box dataTest="push-notification" margin={[16, 55, 0, 50]}>
                  <FieldLabel>Message</FieldLabel>
                  <NotificationField
                    field={localNotification.pushText || ''}
                    onChange={(pushText) => {
                      setLocalNotification({
                        ...localNotification,
                        pushText,
                      });
                      setFormDirty(true);
                    }}
                    fieldName=""
                    placeholder={TEXT_PLACEHOLDER}
                    disabled={disabled || !permitted}
                    post={post}
                    showAIGenerationButton={usingAIGeneration}
                    aiCommandType="delivery_mobile"
                  />
                </Box>
              )}
            </>
          ))}
        </Box>

        {!turnOffAndHideForYou && orchestrateDeliveryForYou && (
          <Box margin={[20, 0, 0, 0]}>
            <Subheading bold>For you</Subheading>
            <Box margin={[10, 0, 0, 0]} style={{ display: 'flex' }}>
              <Body>
                Ensures content sent via direct delivery is accessible in the
                Member Experience
              </Body>
            </Box>
            <Box style={{ display: 'flex' }}>
              <label
                style={{ marginTop: '20px', marginLeft: '10px' }}
                className={styles.channelsLabel}
              >
                <Checkbox
                  name="forYouCheckbox"
                  onChange={(value) => {
                    handleForYouToggled(value);
                    onChannelChange({ name: 'feed', value });
                  }}
                  checked={includeInForYouChecked}
                  className={cx({
                    [styles.disabledSetting]: disabled,
                  })}
                  disabled={disabled}
                  label={<Body>Include in &lsquo;For You&rsquo; Section</Body>}
                />
              </label>
            </Box>
          </Box>
        )}
        {!validChannelSelection && (
          <Box margin={[20, 0, 20, 0]} color={color.redFull}>
            <Icon iconName="warning" iconType="SVG" />
            Enable at least one channel{!turnOffAndHideForYou &&
              ' or For You'}{' '}
            to continue.
          </Box>
        )}
      </Box>

      <SaveModalButtons
        onCancel={() => {
          datadogRum.addAction('cancel_delivery_channels');
          if (onCancel) {
            onCancel();
          }
        }}
        onSave={localOnSave}
        disabled={!saveEnabled}
      />
    </>
  );
};
