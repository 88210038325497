import React, { useCallback, useMemo, useEffect } from 'react';
import { defaultSettings } from 'models/publisher/settings';
import { Permissions } from 'models/permissions';
import { SettingsEditorConfig } from 'contexts/publisher/orchestrate/settings';
import { CloudbeesFlags, FeatureFlags } from 'models/feature-flag';
import { ContentPermissions } from 'models/content-permission';
import { useContentEditable } from 'hooks/useContentEditable';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import * as settingsFields from './settings-fields';

const contextPrototype: SettingsEditorConfig = {
  permissions: {} as Permissions['permissions'],
  settings: defaultSettings,
  featureFlags: {} as FeatureFlags,
  cloudbeesFlags: {} as CloudbeesFlags,
  update: () => {},
  options: {
    canChangePublicationDate: true,
    canChangeArchiveDate: true,
    defaultNotificationText: '',
    defaultNotificationPreviewText: '',
  },
};

export const OrchestrateSettingsEditorContext = React.createContext(
  contextPrototype
);

export type SettingsFields = typeof settingsFields;

export type FieldKey = keyof SettingsFields;

export type Fields = { [k in FieldKey]: ReturnType<SettingsFields[k]> };

export type SettingsEditor = SettingsEditorConfig & {
  fields: Fields;
  syncDefaults: () => void;
  contentPermissions: ContentPermissions;
};

function buildFields(
  fields: typeof settingsFields,
  config: SettingsEditorConfig & { contentPermissions: ContentPermissions }
): Fields {
  return Object.fromEntries(
    Object.entries(fields).map(([key, build]) => [key, build(config)])
  ) as Fields; // Object.fromEntries/entries are hard to type correctly.
}

export const useSettings = (hookConfig?: {
  syncDefaults: boolean;
}): SettingsEditor => {
  const config = React.useContext(OrchestrateSettingsEditorContext);

  if (config === undefined) {
    throw new Error('Orchestrate settings context hooks require a provider');
  }

  const contentPermissions = useContentEditable(
    config.settings,
    config.permissions
  );

  const { id: programId } = useProgram();
  const setChannelDefaultsEnabled = useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  ).data;

  const engagementBoostDefaultEnabled = useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  ).data;

  const studioOrchestrateNewPages = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  ).data;

  const fields = useMemo(() => {
    const cloudbeesFlags: CloudbeesFlags = {
      setChannelDefaultsEnabled,
      engagementBoostDefaultEnabled,
      studioOrchestrateNewPages,
    };

    return buildFields(settingsFields, {
      ...config,
      ...{ contentPermissions },
      ...{ cloudbeesFlags },
    });
  }, [
    config,
    contentPermissions,
    setChannelDefaultsEnabled,
    engagementBoostDefaultEnabled,
    studioOrchestrateNewPages,
  ]);

  const syncDefaults = useCallback(() => {
    Object.entries(fields).forEach(([_, field]) => {
      if (field.sync !== undefined) field.sync();
    });
  }, [fields]);

  useEffect(() => {
    if (hookConfig?.syncDefaults) syncDefaults();
  }, [hookConfig, syncDefaults]);

  return {
    ...config,
    fields,
    syncDefaults,
    contentPermissions,
  };
};
