import React, { useMemo } from 'react';
import * as Type from 'DesignSystem/Typography';
import { navigate, Redirect, Router } from '@reach/router';
import { useDesignContext } from 'contexts/design';
import { useLibraryFontsCollection } from 'hooks/use-library-fonts-collection';
import { useDesignHooks } from 'components/publisher/theme/Design/useDesignHooks';
import { DesignComponent } from 'components/publisher/theme/Design/Component';
import { Preview } from 'components/publisher/pages/Preview';
import { Design } from 'components/publisher/pages/Design';
import { useFlashMessage } from 'contexts/flasher';
import { NavigationFooter } from 'shared/NavigationFooter';
import { LowerBar, UpperBar } from 'shared/NavigationBars/FixedBars';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Button } from 'DesignSystem/Form';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { useProgram } from 'contexts/program';
import { useQueryClient } from 'react-query';
import { getTopicAboutPageQueryKey } from 'hooks/topics';
import { PublisherType } from 'models/library';
import { useTopicFormCtx } from '../../context';
import styles from './designer.module.css';

export const DesignerWrapper: React.FC = () => {
  const { data: fonts, isLoading: fontsLoading } = useLibraryFontsCollection();

  const { baseUrl, selectTab } = useTopicFormCtx();

  const fontOptions = useMemo(() => {
    return fonts
      .filter((i) => i.is_enabled_for_program)
      .map((i) => {
        return {
          label: i.title,
          value: i.asset.value,
          id: i.id,
          url: i.asset.css?.url,
          is_enabled_for_program: i.is_enabled_for_program,
        };
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [fonts]);

  const { topic } = useTopicFormCtx();
  const { id: programId } = useProgram();
  const designContext = useDesignContext();
  const designEditor = useDesignHooks({
    design: designContext.design,
    fontOptions,
    update: designContext.update,
    publisherType: PublisherType.topicPages,
  });

  const { setFlashMessage } = useFlashMessage();
  const exitHandler = React.useCallback(() => {
    navigate(baseUrl);
    selectTab('about');
  }, [selectTab, baseUrl]);

  const queryClient = useQueryClient();
  const saveAndClose = React.useCallback(() => {
    // Cannot save a design without any blocks
    if (designContext.design.blocks.length === 0) {
      setFlashMessage({
        message: 'Cannot save an empty design',
        severity: 'error',
      });
      return;
    }
    designContext.save({
      onSuccess: () => {
        setFlashMessage({ message: 'About page saved', severity: 'info' });
        queryClient.invalidateQueries(
          getTopicAboutPageQueryKey(programId, topic.id)
        );
        exitHandler();
      },
    });
  }, [
    designContext,
    setFlashMessage,
    exitHandler,
    queryClient,
    topic.id,
    programId,
  ]);

  const navigationComponent = (
    <NavigationBars
      actionName="Save"
      canPerformAction={designContext.design.blocks.length > 0} // Disable save button if no blocks
      exitHandler={exitHandler}
      action={saveAndClose}
    />
  );

  if (fontsLoading) {
    return null;
  }

  return (
    <Router>
      <DesignComponent
        path="/"
        designPermission={{ canEdit: true, errors: [], isLoading: false }}
        designEditor={designEditor}
        navigationComponent={navigationComponent}
        omitMenuItems={['preview', 'card', 'personalizedFields']}
      >
        <Design path="design" implementation="new" />
        <Preview hideEmailPreview path="preview" />
        {/* The defaults for the directory */}
        <Redirect from="/" to="design" noThrow default />
      </DesignComponent>
    </Router>
  );
};

const NavigationBars: React.FC<React.ComponentProps<
  typeof NavigationFooter
>> = (props) => {
  const { exitHandler, action, exitPath, canPerformAction } = props;

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
    else if (exitPath) navigate(exitPath);
  }, [exitPath, exitHandler]);

  const save = React.useCallback(() => {
    if (action) {
      action();
    }
  }, [action]);

  return (
    <>
      <UpperBar>
        <Flex>
          <Type.Heading>Create Content</Type.Heading>
        </Flex>
        <Flex end>
          <CloseButton handleClose={handleClose} />
        </Flex>
      </UpperBar>

      <LowerBar>
        <Flex className={styles.footer} end>
          <Button label="Return to Topic" onClick={handleClose} secondary />
          <Button label="Save" onClick={save} disabled={!canPerformAction} />
        </Flex>
      </LowerBar>
    </>
  );
};
