import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { JourneyProvider } from 'contexts/journey';
import { useProgram } from 'contexts/program';
import { useJourneyQuery } from 'hooks/journeys/journeys';
import { LoadingScreen } from 'shared/LoadingScreen';
import { editableJourneyStates, JourneyMode } from 'models/journeys/journey';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { includes } from 'utility/objectUtils';
import { asserts } from 'utility/asserts';
import { JourneyCanvas } from '../JourneyCanvas';
import {
  DEFAULT_ERROR_MESSAGE,
  useJourneyErrorHandler,
} from '../error-handler-context';

export const JourneyViewPage: React.FC<RouteComponentProps<{
  journeyId: string;
}>> = ({ journeyId = '0' }) => {
  const { id: programId } = useProgram();
  const { data: journey, isLoading } = useJourneyQuery({
    programId,
    journeyId: Number(journeyId),
  });
  const { setErrorMessage } = useJourneyErrorHandler();

  const handleError = () => {
    navigate(`/${programId}/app/journeys/`);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!journey) {
    navigate(`/${programId}/app/journeys/`);
    setErrorMessage(DEFAULT_ERROR_MESSAGE);
    return null;
  }

  asserts(
    journey !== undefined,
    'An undefined journey cannot be edited or viewed'
  );

  const hasDraftButNoLive = journey.draftGraph && !journey.liveGraph;
  const isEditable = includes(editableJourneyStates, journey.state);

  if (hasDraftButNoLive && isEditable) {
    asserts(isEditable, 'Only editable journeys should be edited');
    asserts(
      hasDraftButNoLive,
      'Only draft journeys without a live version can be edited'
    );
    navigate(`/${programId}/app/journeys/${journey.id}/edit`, {
      replace: true,
    });
    return null;
  }

  asserts(
    !isEditable || !hasDraftButNoLive,
    'Journeys that are not editable or already have a live version should default to viewing mode, not editing'
  );

  return (
    <ErrorBoundary onError={handleError}>
      <JourneyProvider journey={journey} mode={JourneyMode.View}>
        <JourneyCanvas />
      </JourneyProvider>
    </ErrorBoundary>
  );
};
