import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from '../MetricsCards.module.css';
import {
  CampaignResultsData,
  PerformanceData,
} from '../../../../../../../services/api-insights';
import { NoDataChart } from '../../../../../../../shared/Charts/NoDataChart';
import { Loading } from '../../../../../../../components/publisher/blocks/instances/Loading';

interface IDeliveryChannelPerformanceCard {
  data: null | CampaignResultsData;
  isLoading: boolean;
}

const chartDimensions = {
  height: 240,
  width: 1118,
  padding: {
    top: 24,
    bottom: 24,
    left: 24,
    right: 0,
  },
};
export const DeliveryChannelPerformanceCard: React.FC<IDeliveryChannelPerformanceCard> = ({
  data,
  isLoading,
}) => {
  const tableData = data?.campaign?.performance;

  const buildTable = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }
    if (tableData === undefined) {
      return <></>;
    }
    const computedData: { [key: string]: { [key: string]: number } } = {
      conversionRate: {},
    };
    const channels = [
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'push',
        label: 'Push',
      },
      {
        key: 'assistant',
        label: 'Assistant',
      },
      {
        key: 'experience',
        label: 'Experience',
      },
    ];
    const bestDeliveryPerformance = { label: '', value: 0 };
    const bestDeliveryConversionRate = { label: '', value: 0 };
    channels.forEach(({ key }) => {
      if (
        bestDeliveryPerformance.value <
        tableData.delivered[key as keyof PerformanceData]
      ) {
        bestDeliveryPerformance.label = key;
        bestDeliveryPerformance.value =
          tableData.delivered[key as keyof PerformanceData];
      }

      const total =
        key === 'experience'
          ? tableData.previewed[key as keyof PerformanceData]
          : tableData.delivered[key as keyof PerformanceData];

      const conversionRate =
        total !== 0 && key !== 'experience'
          ? tableData.opened[key as keyof PerformanceData] / total
          : 0;
      computedData.conversionRate[key] = conversionRate;

      if (bestDeliveryConversionRate.value < conversionRate) {
        bestDeliveryConversionRate.label = key;
        bestDeliveryConversionRate.value = conversionRate;
      }
    });

    const hasBestDeliveryPerformance = (key: string) => {
      return bestDeliveryPerformance.label === key;
    };
    const hasBestConversionRate = (key: string) => {
      return bestDeliveryConversionRate.label === key;
    };

    return (
      <table className={styles.metricTable}>
        <thead>
          <tr className={styles.row}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th />
            <th>
              <span className={cx(styles.metricsPurpleText, styles.heading)}>
                Email
              </span>
            </th>
            <th>
              <span className={cx(styles.metricsPurpleText, styles.heading)}>
                Push
              </span>
            </th>
            <th>
              <span className={cx(styles.metricsPurpleText, styles.heading)}>
                Assistant
              </span>
            </th>
            <th>
              <span className={cx(styles.metricsPurpleText, styles.heading)}>
                Experience
              </span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className={styles.row}>
            <td className={styles.title}>Users Delivered</td>
            <td
              className={cx({
                [styles.metricsGreenText]: hasBestDeliveryPerformance('email'),
              })}
            >
              {tableData.delivered.email.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td
              className={cx({
                [styles.metricsGreenText]: hasBestDeliveryPerformance('push'),
              })}
            >
              {tableData.delivered.push.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td
              className={cx({
                [styles.metricsGreenText]: hasBestDeliveryPerformance(
                  'assistant'
                ),
              })}
            >
              {tableData.delivered.assistant.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td
              className={cx({
                [styles.metricsGreenText]: hasBestDeliveryPerformance(
                  'experience'
                ),
              })}
            >
              -
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.title}>Users Reached</td>
            <td>
              {tableData.previewed.email.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.previewed.push.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.previewed.assistant.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.previewed.experience.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.title}>Users Opened</td>
            <td>
              {tableData.opened.email.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.opened.push.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.opened.assistant.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
            <td>
              {tableData.opened.experience.toLocaleString('en', {
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr className={styles.row}>
            <td className={styles.title}>Delivery-to-Open Conversion Rate</td>
            <td
              className={cx(styles.bold, {
                [styles.metricsGreenText]: hasBestConversionRate('email'),
              })}
            >
              {Math.round(computedData.conversionRate.email * 100)}%
            </td>
            <td
              className={cx(styles.bold, {
                [styles.metricsGreenText]: hasBestConversionRate('push'),
              })}
            >
              {Math.round(computedData.conversionRate.push * 100)}%
            </td>
            <td
              className={cx(styles.bold, {
                [styles.metricsGreenText]: hasBestConversionRate('assistant'),
              })}
            >
              {Math.round(computedData.conversionRate.assistant * 100)}%
            </td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    );
  }, [isLoading, tableData]);

  return (
    <div className={cx(styles.metricCard, styles.metricCardFull)}>
      <div className={styles.metricCardHeader}>
        <h1 className={styles.metricCardText}>
          How has each delivery channel performed?
        </h1>
      </div>
      <div className={styles.metricCardBody}>
        {tableData === undefined ? (
          <NoDataChart chartDimensions={chartDimensions} />
        ) : (
          buildTable
        )}
      </div>
    </div>
  );
};
