import React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Features } from 'components/publisher/settings/SettingsEditor/Features';
import cx from 'classnames';
import blockStyles from 'shared/SectionBlocks/SectionBlocks.module.css';
import { Setup } from 'components/publisher/settings/SettingsEditor/Setup';
import { Rules } from 'components/publisher/settings/SettingsEditor/Rules';
import { NavLink } from 'shared/NavLink';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import { Box, Alert, AlertType } from 'DesignSystem/Components';
import { MoreInfoTooltip } from 'DesignSystem/Components/Alert/AddOn/MoreInfoTooltip';
import { Icon } from 'shared/Icon';
import { color } from 'DesignSystem/Typography';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { titleCase } from 'utility/text';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

export type OrchestrateProps = RouteComponentProps & {
  footer: React.ReactElement;
  sidebar: React.ReactElement;
};

export const Orchestrate: React.FC<OrchestrateProps> = ({
  footer,
  sidebar,
}) => {
  const {
    fields: { deliveryType },
    contentPermissions,
  } = useSettings({ syncDefaults: true });
  const { canEdit, errors, isLoading } = contentPermissions;
  const { PAGES } = useProductVocabulary();

  const header = (
    <>
      <h1 className="page-header">{titleCase(PAGES.PUBLISHER.ORCHESTRATE)}</h1>
      <nav>
        <NavLink to="setup">Setup</NavLink>
        <NavLink to="features">Features</NavLink>
        <NavLink to="rules">
          <div className={cx(blockStyles.row)}>
            Rules
            {!deliveryType.enabled && (
              <HoverTooltip
                align="left"
                content={
                  <Tooltip description="An audience is required in order to use these settings." />
                }
              />
            )}
          </div>
        </NavLink>
      </nav>
    </>
  );

  const newEditorsFlag = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const main = (
    <>
      {!canEdit && !isLoading && (
        <Box margin={[0, 0, 20, 0]}>
          <Alert
            type={AlertType.error}
            message="You do not have permission to edit this campaign"
            enableIcon
            addon={
              <MoreInfoTooltip
                padding={10}
                align="left"
                message="Why is this?"
                icon={<Icon iconName="info-circle" />}
                textStyle={color.orangeShadeLight}
                tooltip={
                  <>
                    {errors.map((error) => (
                      <div>{error}</div>
                    ))}
                  </>
                }
              />
            }
          />
        </Box>
      )}
      <Router>
        <Features path="features" />
        <Rules path="rules" />
        <Setup path="setup" />
        <Redirect from="/" to="setup" noThrow />
        <Redirect from="*" to="/" noThrow default />
      </Router>
    </>
  );

  return (
    <MainEditor
      splitBars={newEditorsFlag}
      header={header}
      main={main}
      sidebar={sidebar}
      footer={footer}
    />
  );
};
