import * as React from 'react';
import { editableTextToQueryType } from 'models/audience';
import { useValueSuggestionsQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { fetchValueSuggestions } from 'services/api-audiences';
import debouncePromise from 'debounce-promise';
import { splitWords } from 'utility/text';
import { OptionType } from 'hooks/common';
import { MenuPlacement } from 'react-select';
import { CheckListSelect } from './CheckListSelect';

export const StringSuggestedSelect: React.FC<{
  criterion: string;
  onChange: (value: string[]) => void;
  value: string[];
  capitalizeLabel?: boolean;
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  menuPlacement?: MenuPlacement;
  filterCallback?: (optionValue: string) => boolean;
}> = ({
  criterion,
  onChange,
  value,
  capitalizeLabel,
  editableTextToQuery,
  audienceName,
  menuPlacement = 'auto',
  filterCallback,
}) => {
  const { id: programId } = useProgram();
  const scope = `${editableTextToQuery('*')}`;

  const filterOptions = (optionsToFilter: OptionType[] | undefined) => {
    if (!optionsToFilter) return [];

    return optionsToFilter.filter((option) => {
      return filterCallback ? filterCallback(option.value) : true;
    });
  };

  const options = filterOptions(
    useValueSuggestionsQuery(programId, criterion, '*', scope, audienceName)
      .data
  );

  const loadOptions = debouncePromise(async (searchTerm: string) => {
    const suggestions = await fetchValueSuggestions(
      programId,
      criterion,
      searchTerm,
      scope
    );
    return filterOptions(suggestions);
  }, 300);
  const optionValue = value.map((v) => ({ label: v, value: v }));
  const dynamicCount = criterion !== 'group';

  return (
    <>
      <CheckListSelect
        defaultOptions={options?.slice(0, 100) || []}
        loadOptions={loadOptions}
        onChange={onChange}
        value={optionValue}
        hasMore={options && options.length > 100}
        criterion={criterion}
        dynamicCount={dynamicCount}
        capitalizeLabel={capitalizeLabel}
        editableTextToQuery={editableTextToQuery}
        audienceName={audienceName}
        menuPlacement={menuPlacement}
      />
    </>
  );
};

/**
 * Specialization of `StringSuggestedSelect` that adds an audience name/title
 * translation step so when editing a query that includes an audience the
 * initial display shows the human-readable title instead of the `name` key.
 */
export const AudienceTitleSelect: React.FC<{
  onChange: (value: string[]) => void;
  value: string[];
  capitalizeLabel?: boolean; // has no effect
  editableTextToQuery: editableTextToQueryType;
  audienceName?: string;
  menuPlacement?: MenuPlacement;
}> = ({
  onChange,
  value,
  editableTextToQuery,
  audienceName,
  menuPlacement = 'auto',
}) => {
  const criterion = 'group';
  const { id: programId } = useProgram();
  const [optionsByValue, setOptionsByValue] = React.useState<{
    [key: string]: string;
  }>({});
  const missingValues = value.filter((v: string) => !optionsByValue[v]);
  const valueSuggestionTerm = missingValues.length
    ? `^(${missingValues.join('|')})`
    : '*';
  const scope = `${editableTextToQuery('*')}`;
  const { isLoading, data: options } = useValueSuggestionsQuery(
    programId,
    criterion,
    valueSuggestionTerm,
    scope,
    audienceName,
    true
  );

  const addOptionsByValue = (opts: OptionType[] | undefined) => {
    setOptionsByValue(() =>
      (opts || []).reduce((acc, op) => {
        return {
          ...acc,
          [op.value]: op.label,
        };
      }, optionsByValue)
    );
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    addOptionsByValue(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, options]);

  const loadOptions = debouncePromise(
    (searchTerm: string) =>
      fetchValueSuggestions(programId, criterion, searchTerm, scope),
    300
  );

  const optionValue = value.map((v: string) => ({
    label: optionsByValue[v] || splitWords(v),
    value: v,
  }));

  return (
    <>
      <CheckListSelect
        defaultOptions={options?.slice(0, 100) || []}
        loadOptions={loadOptions}
        onLoadOptions={addOptionsByValue}
        onChange={onChange}
        value={optionValue}
        hasMore={options && options.length > 100}
        criterion={criterion}
        dynamicCount={false}
        editableTextToQuery={editableTextToQuery}
        audienceName={audienceName}
        menuPlacement={menuPlacement}
      />
    </>
  );
};
