import React, { ChangeEvent, useEffect, useState } from 'react';
import { useProgram } from 'contexts/program';
import {
  useAudienceUsersQuery,
  useAudienceCriteriaQuery,
} from 'hooks/audience';
import { useDebounce } from 'hooks/useDebounce';
import { Audience, editableTextToQueryType } from 'models/audience';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { filterCriteria } from './CriterionSelect';
import styles from './AudienceBuilder.module.css';
import { CriteriaMenuItem } from './CriteriaMenuItem';

export const AdvancedBuilder: React.FC<{
  onChange: (value: string) => void;
  value: string | undefined;
  canEdit?: boolean;
  audience: Audience;
  editableTextToQuery: editableTextToQueryType;
  setAdvancedQueryValid: (val: boolean) => void;
}> = ({
  value,
  onChange,
  canEdit,
  editableTextToQuery,
  setAdvancedQueryValid,
}) => {
  const [expression, setExpression] = useState(value);
  const debouncedExpression = useDebounce(expression, 500);
  const query = `${editableTextToQuery(debouncedExpression || '')}`;

  const { id: programId } = useProgram();
  const { isLoading, errorMessage } = useAudienceUsersQuery(programId, query);
  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setExpression(e.target.value);
  };

  const { data } = useAudienceCriteriaQuery(programId);
  const criteria = filterCriteria(data, permissionsEnabled);

  useEffect(() => {
    setAdvancedQueryValid(!errorMessage && !isLoading);
    if (!errorMessage && !isLoading) {
      onChange(expression || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, errorMessage, setAdvancedQueryValid, debouncedExpression]);

  return (
    <div className={styles.advancedBuilderContainer}>
      {errorMessage ? (
        <div className={styles.expressionStatusError}>Invalid query</div>
      ) : (
        <div className={styles.expressionStatus}>
          Query resolves successfully
        </div>
      )}
      <div className={styles.advancedBuilderInput}>
        {canEdit && (
          <div className={styles.criteriaMenu}>
            {criteria.map((n) => (
              <CriteriaMenuItem
                expression={expression}
                criteria={n}
                setExpression={setExpression}
              />
            ))}
          </div>
        )}
        <textarea
          value={expression}
          disabled={!canEdit}
          className={styles.textbox}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
