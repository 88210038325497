import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ContentFeatures } from './sections/ContentFeatures';
import { Administration } from './sections/Administration';

export const Features: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <ContentFeatures />
      <Administration />
    </>
  );
};
