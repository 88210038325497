import React, { useCallback } from 'react';
import { fetchAuthorPage } from 'services/api-authors';
import { ClickDropdown } from 'shared/ClickDropdown';
import { useInfiniteApiQuery } from 'hooks/common';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { RadioSelectField } from 'shared/forms/RadioSelectField';
import { Author, hasAvatar, isAuthor } from 'models/author';
import { ProgramAuthor } from 'models/programAuthor';
import { Icon } from 'shared/Icon';
import { useUser } from 'contexts/user';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { DefaultAvatar } from 'shared/icons';
import { useChannelContributorsQuery } from 'hooks/channel-contributors';
import cx from 'classnames';
import styles from '../administration-select.module.css';

const AvatarIcon: React.FC<{ author: Author | ProgramAuthor }> = ({
  author,
}) => (
  <>
    {hasAvatar(author) ? (
      <div
        className={styles.avatar}
        style={{
          backgroundImage: `url(${author.avatarUrl})`,
        }}
      />
    ) : (
      <div className={styles.avatar}>
        <DefaultAvatar />
      </div>
    )}
  </>
);

export const AuthorSelect: React.FC<{
  value: Author | ProgramAuthor | null;
  onChange: (change: { value: Author | ProgramAuthor }) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  const { id: programId, programAuthor } = useProgram();
  const [search, setSearch] = React.useState<string>();
  const { role, contributorChannelIds } = usePermissions();
  const user = useUser();
  const channelContributors = useChannelContributorsQuery();
  const { data: featureFlags } = useProgramCustomizationsQuery(programId);

  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  const {
    data: authors,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery('authors', fetchAuthorPage, {
    useAuthorAliases,
    programId,
    statuses: useAuthorAliases ? ['active'] : [],
    query: search,
  });

  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;

  // TODO this can all be moved server-side? (and tested)
  const filteredAuthors = React.useMemo(() => {
    let memo: (Author | ProgramAuthor)[] = [];

    if (permissionsEnabled && !isLoading && !useAuthorAliases) {
      const hasSelf = authors.find(({ userId }) => userId === user.id);
      if (!hasSelf)
        memo.unshift({
          userId: user.id,
          displayName: user.displayName ?? '',
          defaultDisplayName: user.displayName ?? '',
          avatarUrl: user.avatarUrl,
          email: user.email,
        });
    }

    if (
      role === 'channel_contributor' &&
      !featureFlags?.channelContributorSelectAuthorsEnabled?.value
    ) {
      memo = authors.filter((a) => a.userId === user.id);
      const accessibleContributors = channelContributors.data
        ? channelContributors.data.filter((d) =>
            contributorChannelIds?.includes(d.channelId)
          )
        : [];

      if (accessibleContributors.length > 0) {
        memo = authors.filter(
          (a) =>
            a.userId &&
            accessibleContributors
              .map((c) => c.contributorId)
              .includes(a.userId)
        );
      }
    } else {
      memo = authors;
    }
    if (programAuthor && !isLoading && !useAuthorAliases) {
      memo.unshift(programAuthor);
    }

    return memo;
  }, [
    permissionsEnabled,
    isLoading,
    useAuthorAliases,
    role,
    featureFlags?.channelContributorSelectAuthorsEnabled?.value,
    programAuthor,
    authors,
    user.id,
    user.displayName,
    user.avatarUrl,
    user.email,
    channelContributors.data,
    contributorChannelIds,
  ]);

  const userId = React.useCallback(
    (author: ProgramAuthor | Author) =>
      `${isAuthor(author) ? author.userId : undefined}`,
    []
  );

  const rowIds = filteredAuthors.map((author) => userId(author));

  const onSelectChange = useCallback(
    (selectedId: string) => {
      const nextValue =
        filteredAuthors.find((author) => userId(author) === selectedId) ||
        filteredAuthors[0];
      onChange({ value: nextValue });
    },
    [onChange, filteredAuthors, userId]
  );

  function renderSelectRow(id: string) {
    const a = filteredAuthors.find((item) => userId(item) === id);
    if (!a) return null;

    return (
      <div className={styles.dropdownItem}>
        <AvatarIcon author={a} />
        <span>{a.displayName}</span>
      </div>
    );
  }

  const dropdown = (
    <div className={styles.dropdown}>
      <RadioSelectField
        rowIds={rowIds}
        rowRenderProp={renderSelectRow}
        maxHeight={500}
        itemHeight={29}
        selectedId={
          isAuthor(value) && value.userId ? value.userId.toString() : rowIds[0]
        }
        onSelectedIdChange={onSelectChange}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        overscan={10}
        searchEnabled
        searchTerm={search}
        onSearchTermChange={setSearch}
        searchPlaceholder="Search author"
      />
    </div>
  );

  return (
    <ClickDropdown dropdownRenderProp={dropdown} disabled={disabled}>
      <button
        type="button"
        className={cx({
          [styles.button]: true,
          [styles.disabledSetting]: disabled,
        })}
      >
        {value && value.displayName.length > 0 && (
          <div className={styles.selectedItem}>
            <AvatarIcon author={value} />
            <div className={styles.displayName}>{value.displayName}</div>
          </div>
        )}
        {value && value.displayName.length === 0 && 'Select'}
        <Icon iconName="ExpandList" iconType="SVG" />
      </button>
    </ClickDropdown>
  );
};
