import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { DateTimeInput } from 'shared/DateTimeInput';
import cx from 'classnames';
import { ClickDropdown } from 'shared/ClickDropdown';
import styles from './date-time-field.module.css';

type DateTimeFieldProps = {
  onChange: (newValue?: DateTime) => void;
  value?: DateTime;
  disabledLabel?: string;
  enabledLabel: React.ReactNode;
  upward?: boolean;
  disable?: boolean;
};

export const DateTimeField: React.FC<DateTimeFieldProps> = ({
  disabledLabel,
  enabledLabel,
  onChange,
  value,
  upward = false,
  disable = false,
}) => {
  const [isCalendarOpen, setCalendarState] = React.useState<boolean>(false);

  const handleEnable = useCallback(() => {
    if (!value) onChange(DateTime.now());
    setCalendarState(!isCalendarOpen);
  }, [value, onChange, setCalendarState, isCalendarOpen]);

  const handleClose = useCallback(() => {
    setCalendarState(false);
  }, [setCalendarState]);

  const handleDisable = useCallback(() => {
    onChange(undefined);
    setCalendarState(false);
  }, [onChange, setCalendarState]);

  const handleDateTimeChange = useCallback(
    (date: DateTime) => {
      if (value) {
        onChange(
          value.set({
            day: date.day,
            month: date.month,
            year: date.year,
            hour: date.hour,
            minute: date.minute,
          })
        );
      }
    },
    [value, onChange]
  );

  const dropdownRenderProp = () => (
    <>
      {value && <DateTimeInput onChange={handleDateTimeChange} value={value} />}
    </>
  );

  return (
    <div className={cx(styles.wrapper, { [styles.hasValue]: !!value })}>
      {disabledLabel ? (
        <button
          type="button"
          onClick={handleDisable}
          className={cx(styles.disableButton, styles.tab, {
            [styles.selected]: !value,
          })}
          disabled={disable}
        >
          {disabledLabel}
        </button>
      ) : null}
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        onOpen={handleEnable}
        onClose={handleClose}
        isOpen={isCalendarOpen}
        upward={upward}
        disabled={disable}
      >
        <button
          type="button"
          onClick={handleEnable}
          className={cx(styles.enableButton, styles.tab, {
            [styles.selected]: !!value,
          })}
          disabled={disable}
        >
          {enabledLabel}
        </button>
      </ClickDropdown>
    </div>
  );
};
