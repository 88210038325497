import React, { useCallback, useMemo } from 'react';
import { useProgram } from 'contexts/program';
import { useInitiativesInfiniteQuery } from 'hooks/initiatives';
import { InfiniteSelect } from 'shared/InfiniteSelect';
import { Initiative } from 'models/initiative';
import { MultiValueTextInput } from 'shared/MultiValueTextInput';
import { FocusDropdown } from 'shared/FocusDropdown';
import cx from 'classnames';
import styles from './initiative-select.module.css';

export const InitiativeSelect: React.FC<{
  value: Initiative[];
  onChange: (initiatives: Initiative[]) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  const { id: programId } = useProgram();
  const [search, setSearch] = React.useState('');

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInitiativesInfiniteQuery({
    programId,
    pageSize: 20,
    query: search,
  });

  const initiativesByRowId: { [rowId: string]: Initiative } = useMemo(() => {
    const map: { [key: string]: Initiative } = {};
    data.forEach((initative) => {
      if (initative.isActive) map[initative.id] = initative;
    });
    // retain the currently selected initatives
    value.forEach((initiative) => {
      if (!map[initiative.id] && initiative.isActive)
        map[initiative.id] = initiative;
    });

    return map;
  }, [data, value]);

  const rowIds = data.filter((d) => d.isActive).map((d) => d.id.toString());

  const selectedIds = value.filter((v) => v.isActive).map(({ id }) => `${id}`);

  const onSelectedIdsChange = useCallback(
    (ids: string[]) => onChange(ids.map((id) => initiativesByRowId[id])),
    [onChange, initiativesByRowId]
  );

  const renderRow = (rowId: string) => {
    const item = initiativesByRowId[rowId];
    return item ? (
      <div>
        <strong className={styles.title}>{item.name}</strong>
      </div>
    ) : null;
  };

  const handleRemove = React.useCallback(
    (index: number) => {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(index, 1);
      onSelectedIdsChange(newSelectedIds);
    },
    [onSelectedIdsChange, selectedIds]
  );

  const selectedValues = React.useCallback(
    () => selectedIds.map((id) => initiativesByRowId[id].name),
    [initiativesByRowId, selectedIds]
  );

  const dropdown = (
    <div>
      <InfiniteSelect
        className={styles.dropdown}
        rowIds={rowIds}
        rowRenderProp={renderRow}
        maxHeight={400}
        itemHeight={30}
        selectedIds={selectedIds}
        onSelectedIdsChange={onSelectedIdsChange}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        searchTerm={search}
        onSearchTermChange={setSearch}
      />
    </div>
  );

  const clearSearchOnClose = React.useCallback(() => setSearch(''), []);

  return (
    <FocusDropdown
      dropdownRenderProp={dropdown}
      dropdownClassName={cx('dropdown-align-left', styles.selector)}
      onClose={clearSearchOnClose}
      keyPressActivated
      disabled={disabled}
    >
      {(onFocus, ref) => (
        <MultiValueTextInput
          textValue={search}
          onTextValueChange={setSearch}
          selectedValues={selectedValues()}
          onRemoveSelectedValueAt={handleRemove}
          callToAction="None"
          onFocus={onFocus}
          inputRef={ref}
          disabled={disabled}
        />
      )}
    </FocusDropdown>
  );
};
