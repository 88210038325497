import {
  authenticationHooks,
  ClientError,
  ServerError,
} from '@socialchorus/iam-react';
import { setActor } from 'services/global-actor-storage';

const REDIRECT_LOOP_TIMEOUT = 15000;
const REDIRECT_LOOP_MAX = 4;

function requiredEnvar(name: string): string {
  if (process.env[name] === undefined) {
    throw new Error(`missing required envar ${name}`);
  }
  return process.env[name] as string;
}

const { useActor, useAuthenticationCallback, useLogout } = authenticationHooks({
  callbackUrl: `${window.location.origin}/auth/callback`,
  clientId: requiredEnvar('REACT_APP_IAM_CLIENT_ID'),
  scopes: ['admin_login', 'openid'],
  serverUrl: requiredEnvar('REACT_APP_IAM_DOMAIN'),
});

// Wrap the useActor hook to create a version which also stores the received actor in the
// global-actor-storage.
//
// TODO: update all API code to call useActor() when credentials are needed, and remove
// global-actor-storage.
function useActorWithGlobalStorage(
  options: Parameters<typeof useActor>[0] = {}
): ReturnType<typeof useActor> {
  const result = useActor(options);
  if (!result.isLoading) {
    setActor(result.actor);
  }
  return result;
}

// simple data wrapper to get dumb parsing/data type cleanup
// out of #restartAuthAllowed
class RetryCounter {
  expiry: number;

  retries: number;

  defaulted: boolean;

  constructor(rawItemStr: string | null | undefined) {
    if (rawItemStr) {
      const item = JSON.parse(rawItemStr);
      this.expiry = item.expiry;
      if (!this.expiry) {
        this.expiry = Date.now() + REDIRECT_LOOP_TIMEOUT;
      }
      this.retries = item.retries;
      this.defaulted = false;
    } else {
      this.expiry = Date.now() + REDIRECT_LOOP_TIMEOUT;
      this.retries = 1;
      this.defaulted = true;
    }
  }

  expired(): boolean {
    return Date.now() > this.expiry;
  }

  incrementCount(): void {
    this.retries += 1;
  }

  countExceeded(maxCount: number): boolean {
    return this.retries > maxCount;
  }

  toString(): string {
    return JSON.stringify({
      expiry: this.expiry,
      retries: this.retries,
    });
  }
}

function clearAuthRetry(): void {
  global.localStorage.removeItem('server_auth_restarted');
}

// function detect if we can restart auth (sent to wrong program or client error)
//
function restartAuthAllowed(error: Error): boolean {
  if (error instanceof ClientError && error.shouldRestart) {
    return true;
  }

  const retryCounter = new RetryCounter(
    global.localStorage.getItem('server_auth_restarted')
  );

  if (
    error instanceof ServerError &&
    error.message.startsWith('Callback validation failed: invalid_user_role') &&
    retryCounter.defaulted
  ) {
    // request option 1
    global.localStorage.setItem(
      'server_auth_restarted',
      retryCounter.toString()
    );
    return true;
  }
  if (
    error instanceof ServerError &&
    error.message.startsWith('Callback validation failed: invalid_user_role')
  ) {
    if (!retryCounter.expired()) {
      if (!retryCounter.countExceeded(REDIRECT_LOOP_MAX)) {
        // request 2
        retryCounter.incrementCount();
        global.localStorage.setItem(
          'server_auth_restarted',
          retryCounter.toString()
        );
        return true;
      }
      // request 4
      return false;
    }

    // request 3
    global.localStorage.removeItem('server_auth_restarted');
    return false;
  }

  return false;
}

export { ClientError, ServerError } from '@socialchorus/iam-react';
export {
  useActorWithGlobalStorage as useActor,
  useAuthenticationCallback,
  useLogout,
  restartAuthAllowed,
  clearAuthRetry,
};
