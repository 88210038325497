import qs from 'qs';
import {
  CustomBlock,
  FontStylesheet,
  LibraryCategory,
  LibraryFilter,
  LibraryImage,
  LibraryItem,
  Template,
  UpsertableItem,
} from 'models/library';
import { Optional } from 'utility-types';
import { TemplateContainerType } from 'models/template';
import { fqApiUrl, PaginationData } from './common';
import { bossanovaDomain, request } from './api-shared';

type TemplateRequestType = {
  programId: number;
  templateId: number | 'new';
  containerType: TemplateContainerType;
};

type FontRequestType = {
  programId: number;
  fontId: number | 'new';
};

export type ApiItemsResponse = {
  data: Array<{
    id: string;
    attributes: Omit<LibraryItem, 'id'>;
  }>;
  meta: PaginationData;
};

export type ApiCategoriesResponse = {
  data: Array<{
    id: number;
    attributes: LibraryCategory;
  }>;
};

export type ApiLibraryItemResponse = {
  data: {
    id: string;
    attributes: Omit<UpsertableItem, 'id'>;
  };
};

export type ApiLibraryDeletedItemResponse = {
  data: string;
};

export type ApiLibraryCategoryResponse = {
  data: {
    id: number;
    attributes: Omit<LibraryCategory, 'id'>;
  };
};

export const getTemplate = async (
  props: TemplateRequestType
): Promise<ApiLibraryItemResponse> => {
  const url = `${bossanovaDomain}/samba/programs/${
    props.programId
  }/library/items/${props.templateId}?${qs.stringify({
    container_type: props.containerType,
  })}`;
  const response = await request(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching content: ${response.status}`);
};

export const getFont = async (
  props: FontRequestType
): Promise<ApiLibraryItemResponse> => {
  const url = `${bossanovaDomain}/samba/programs/${props.programId}/library/items/${props.fontId}`;
  const response = await request(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return response.json();
  }

  throw new Error(`Error fetching font: ${response.status}`);
};

export type LibraryItemsParams = {
  filter: LibraryFilter;
  sortBy?: string;
  includeDisabled?: boolean;
  onlyDisabled?: boolean;
  pageSize?: number;
  permittedToUseOnly?: boolean;
  restrictedOnly?: boolean;
  excludeCategoriesIds?: number[];
};

type FetchItemsParams = {
  programId: number;
  type: LibraryItem['type'];
  page?: number;
} & LibraryItemsParams;

export async function fetchItems(
  params: FetchItemsParams
): Promise<ApiItemsResponse> {
  const qsParams = {
    type: [params.type],
    category_ids: [] as number[],
    q: '',
    per_page: params.pageSize || 100,
    page: params.page || 1,
    status: ['published'],
    include_disabled: false,
    only_disabled: false,
    ids: [] as string[],
    permitted_to_use_only: false,
    restricted_only: false,
    scope: '',
    sort_by: '',
    exclude_category_ids: params.excludeCategoriesIds,
  };
  if (params.filter.type === 'category')
    qsParams.category_ids = [params.filter.id];
  if (params.filter.type === 'search') {
    qsParams.q = params.filter.search;
    if (params.filter.status) {
      qsParams.status = params.filter.status;
    }
    if (params.filter.scope) {
      qsParams.scope = params.filter.scope;
    }
  }
  if (params.filter.type === 'ids') {
    qsParams.ids = params.filter.ids;
  }
  if (params.sortBy) {
    qsParams.sort_by = params.sortBy;
  }
  if (params.includeDisabled) {
    qsParams.include_disabled = true;
  }
  if (params.onlyDisabled) {
    qsParams.only_disabled = true;
  }
  if (params.permittedToUseOnly) {
    qsParams.permitted_to_use_only = true;
  }
  if (params.restrictedOnly) {
    qsParams.restricted_only = true;
  }

  const queryString = qs.stringify(qsParams, { arrayFormat: 'comma' });
  const url = fqApiUrl(
    `samba/programs/${params.programId}/library/items?${queryString}`
  );
  const response = await request(url);
  if (response.status === 200) {
    const { data, meta } = await response.json();
    return {
      data,
      meta: {
        totalRecords: meta.total_records,
        currentPage: meta.page.number,
      },
    };
  }
  throw new Error(`Error fetching library items`);
}

export async function fetchCategories(params: {
  programId: number;
  type: LibraryItem['type'];
}): Promise<ApiCategoriesResponse> {
  const queryString = qs.stringify({
    type: params.type,
  });
  const url = fqApiUrl(
    `samba/programs/${params.programId}/library/categories?${queryString}&limit=100`
  );
  const response = await request(url);
  if (response.status === 200) {
    return response.json();
  }
  throw new Error(`Error fetching library categories`);
}

function postHeaders() {
  return {
    'Content-Type': 'application/json; charset=UTF-8',
    'x-requested-with': 'XMLHttpRequest',
  };
}

export type UpsertType = {
  programId: number;
  item:
    | Optional<Template, 'id'>
    | Optional<LibraryImage, 'id'>
    | Optional<LibraryCategory, 'id'>
    | Optional<CustomBlock, 'id'>
    | Optional<FontStylesheet, 'id'>;
};

export type CategoryUpsertType = {
  programId: number;
  item: LibraryCategory;
};

type UpsertFetchProps = {
  url: string;
  body: ReturnType<typeof upsertBody>;
  method?: string;
  successCode?: number;
};

function upsertBody(item: UpsertType['item']) {
  return {
    data: {
      attributes: { ...item, status: item.status || 'published' },
    },
  };
}

async function upsertFetch(
  props: UpsertFetchProps
): Promise<ApiLibraryItemResponse> {
  const { url, body, method = 'POST', successCode = 200 } = props;

  const response = await request(url, {
    method,
    body: JSON.stringify(body),
    headers: postHeaders(),
  });

  if (response.status === successCode) {
    return response.json();
  }

  const errorResponse = await response.json();
  throw new Error(errorResponse.errors.join('\n'));
}

async function upsertFetchCategory(
  props: UpsertFetchProps & { onSuccess?: () => void }
): Promise<ApiLibraryCategoryResponse> {
  const { url, body, method = 'POST', successCode = 200, onSuccess } = props;

  const response = await request(url, {
    method,
    body: JSON.stringify(body),
    headers: postHeaders(),
  });

  if (response.status === successCode) {
    if (onSuccess) {
      onSuccess();
    }
    return response.json();
  }

  const errorResponse = await response.json();
  throw new Error(errorResponse.errors.join('\n'));
}

export const createLibraryItem = async (
  props: UpsertType
): Promise<ApiLibraryItemResponse> => {
  const queryString = '';
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/items?${queryString}`,
    body: upsertBody(props.item),
    successCode: 201,
  });
};

export const updateLibraryItem = async (
  props: UpsertType
): Promise<ApiLibraryItemResponse> => {
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/items/${props.item.id}`,
    body: upsertBody(props.item),
    method: 'PUT',
  });
};

export const deleteLibraryItem = async (
  props: UpsertType
): Promise<ApiLibraryItemResponse> => {
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/items/${props.item.id}`,
    body: upsertBody(props.item),
    method: 'DELETE',
  });
};

export const enableItemForProgram = async (
  props: UpsertType
): Promise<ApiLibraryItemResponse> => {
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/items/${props.item.id}/enable`,
    body: upsertBody(props.item),
    method: 'POST',
  });
};

export const disableItemForProgram = async (
  props: UpsertType
): Promise<ApiLibraryItemResponse> => {
  return upsertFetch({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/items/${props.item.id}/disable`,
    body: upsertBody(props.item),
    method: 'POST',
  });
};

export const createLibraryCategory = async (
  props: CategoryUpsertType
): Promise<ApiLibraryCategoryResponse> => {
  const queryString = '';
  return upsertFetchCategory({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/categories?${queryString}`,
    body: upsertBody(props.item),
    successCode: 200,
  });
};

export const updateLibraryCategory = async (
  props: CategoryUpsertType
): Promise<ApiLibraryCategoryResponse> => {
  return upsertFetchCategory({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/categories/${props.item.id}`,
    body: upsertBody(props.item),
    method: 'PUT',
  });
};

export const deleteLibraryCategory = async (
  props: CategoryUpsertType & { onSuccess?: () => void }
): Promise<ApiLibraryCategoryResponse> => {
  return upsertFetchCategory({
    url: `${bossanovaDomain}/samba/programs/${props.programId}/library/categories/${props.item.id}`,
    body: upsertBody(props.item),
    method: 'DELETE',
    onSuccess: props.onSuccess,
  });
};
