import lucene from 'lucene';
import {
  decodeRelativeDate,
  isDate,
  isRelativeDate,
  isToday,
  TimeUnit,
  units,
} from './expression/date';
import { UnitValue } from './duration';

export type Expression =
  | ComplexExpression
  | LiteralExpression
  | SimpleExpression;

export type SimpleExpression = {
  type: 'simple';
  criterion: string;
  operator: string;
  values: string[];
};

export type ComplexExpressionTerm = {
  expression: Expression;
  key: number;
};

export type ComplexExpression = {
  type: 'complex';
  operator: string;
  terms: ComplexExpressionTerm[];
};

export type LiteralExpression = {
  type: 'literal';
  text: string;
};

export function emptyExpression(
  opts: Partial<SimpleExpression> = {}
): SimpleExpression {
  return {
    type: 'simple',
    criterion: '',
    operator: '',
    values: [],
    ...opts,
  };
}

export function defaultComplexExpression(
  expression: Expression = emptyExpression()
): ComplexExpression {
  return {
    type: 'complex',
    operator: 'OR',
    terms: [
      {
        key: 0,
        expression,
      },
    ],
  };
}

export function literalExpression(text: string): LiteralExpression {
  return { type: 'literal', text };
}

export function isComplexExpression(
  complexExpression: ComplexExpression | unknown
): complexExpression is ComplexExpression {
  const exp = complexExpression as ComplexExpression;

  return exp.type === 'complex';
}

export function isSimpleExpression(
  simpleExpression: SimpleExpression | unknown
): simpleExpression is SimpleExpression {
  const exp = simpleExpression as SimpleExpression;

  return exp.type === 'simple';
}

function isEmpty(expression: Expression): boolean {
  switch (expression.type) {
    case 'simple':
      return expression.values.length === 0;
    case 'complex':
      return !expression.terms.find((term) => !isEmpty(term.expression));
    case 'literal':
      return expression.text === '';
    default:
      throw new Error(`unknown expression type`);
  }
}

function joinWithOperator(elements: string[], operator: string, depth: number) {
  const joined = elements.join(` ${operator} `);
  return depth > 0 && elements.length > 1 ? `(${joined})` : joined;
}

const unitLabels: Array<{ key: TimeUnit; value: UnitValue }> = (Object.keys(
  units
) as Array<TimeUnit>).map((key) => ({
  key,
  value: units[key],
}));

function parseDuration(val: string) {
  const parts = val.split(' ');
  const unit = parts[1];
  if (unit === 'quarter') return `${+parts[0] * 3}M`;
  const unitObj = unitLabels.find((u) => u.value === unit);
  return `${parts[0]}${unitObj?.key}`;
}

function safeValue(criterion: string, value: string): string {
  const val = lucene.term.escape(value);
  return criterion === 'group' ? val : `"${val}"`;
}

function getIgnoreYearCriterionName(criterion: string, ignoreYear = false) {
  if (criterion === 'birth_date') {
    return ignoreYear ? 'birthday' : criterion;
  }

  if (criterion === 'birthday') {
    return ignoreYear ? criterion : 'birth_date';
  }

  if (criterion === 'start_date') {
    return ignoreYear ? 'startday' : criterion;
  }

  return ignoreYear ? `${criterion}__anniversary` : criterion;
}

/**
 * Returns possibly-modified criterion name based on on the criterion value.
 *
 * Birthdates are handled as a special case because they're handled with a
 * custom criteria on Gov.
 *
 * @param criterion Criterion name
 * @param val Criterion value
 */
function buildDateCriterionNameVal(criterion: string, val: string) {
  const [sVal, tmp] = val.split(':');
  const ignoreYear = !!(tmp && tmp === 'ignore_year');
  const sCrit = getIgnoreYearCriterionName(criterion, ignoreYear);

  return { sCrit, sVal };
}

function simpleExpressionToText(
  expression: SimpleExpression,
  depth: number
): string {
  if (!expression || expression.values.length === 0) {
    return '';
  }

  const { criterion, operator, values } = expression;

  let query;
  switch (operator) {
    case 'NOT':
      query = joinWithOperator(
        values.map((val) => `(NOT ${criterion}:${safeValue(criterion, val)})`),
        'AND',
        depth
      );
      break;
    case 'greater_than':
      query = joinWithOperator(
        values.map((val) => `${criterion}:{${lucene.term.escape(val)} TO *}`),
        'OR',
        depth
      );
      break;
    case 'less_than':
      query = joinWithOperator(
        values.map((val) => `${criterion}:{* TO ${lucene.term.escape(val)}}`),
        'OR',
        depth
      );
      break;

    //
    // DATES: ON|BETWEEN/SINCE
    //
    case 'on':
    case 'between':
      query = joinWithOperator(
        values.map((val) => {
          const { sCrit, sVal } = buildDateCriterionNameVal(criterion, val);
          return `${sCrit}:${sVal}`;
        }),
        'OR',
        depth
      );
      break;

    case 'since':
      query = joinWithOperator(
        values.map((val) => {
          const { sCrit, sVal } = buildDateCriterionNameVal(criterion, val);
          return `${sCrit}:[${sVal} TO "now/d"]`;
        }),
        'OR',
        depth
      );
      break;

    //
    // DATES: NEXT/LAST
    //
    case 'last':
      query = joinWithOperator(
        values.map((val) => {
          const { sCrit, sVal } = buildDateCriterionNameVal(criterion, val);
          const dur = parseDuration(sVal);

          return `${sCrit}:["now-${dur}/d" TO "now/d"]`;
        }),
        'OR',
        depth
      );
      break;
    case 'next':
      query = joinWithOperator(
        values.map((val) => {
          const { sCrit, sVal } = buildDateCriterionNameVal(criterion, val);
          const dur = parseDuration(sVal);

          return `${sCrit}:["now/d" TO "now+${dur}/d"]`;
        }),
        'OR',
        depth
      );
      break;

    //
    case 'contains':
      query = joinWithOperator(
        values.map((val) => `${criterion}:*${lucene.term.escape(val)}*`),
        'OR',
        depth
      );
      break;
    default:
      query = joinWithOperator(
        values.map((val) => `${criterion}:${safeValue(criterion, val)}`),
        'OR',
        depth
      );
  }

  return query;
}

export function expressionToText(expression: Expression, depth = 0): string {
  if (isEmpty(expression)) {
    return '';
  }

  switch (expression.type) {
    case 'simple':
      return simpleExpressionToText(expression, depth);
    case 'complex':
      return joinWithOperator(
        expression.terms
          .filter((term) => !isEmpty(term.expression))
          .map((term) => expressionToText(term.expression, depth + 1)),
        expression.operator,
        depth
      );
    case 'literal':
      return expression.text;
    default:
      throw new Error(`unknown expression type`);
  }
}

/**
 * AST Types: we use an intermediate AST structure when parsing queries from
 * Lucene, before converting to an Expression. This makes it easier to separate
 * out concerns of translating from the Lucene library's AST to something that
 * better matches our conceptual model.
 */
type AstNode = AstBranch | AstLeaf;

type AstBranch = {
  type: 'branch';
  operator: string;
  nodes: AstNode[];
};

type AstLeaf = {
  type: 'leaf';
  criterion: string;
  value: string;
  operator: string;
};

/**
 * Turn a lucene-parsed structure into our custom AST.
 *   1. Maintain branch/leaf structure while removing irrelevant fields.
 *   2. Validate operator agreement: operator "AND NOT" is only valid in an
 *      expression starting with "NOT" or nested under another "AND NOT".
 *   3. Flatten nested trees of the same operator into a single Branch.
 */
function luceneToAst(
  // FIXME: Could refactor using the lucene package's types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  luceneAst: any,
  parentField?: string,
  parentIsNegated = false
): AstNode {
  if (
    luceneAst.field &&
    (luceneAst.term || luceneAst.term_min || luceneAst.term_max)
  ) {
    let criterion = luceneAst.field;
    if (parentField && criterion === '<implicit>') {
      criterion = parentField;
    }
    let value;
    let operator;
    if (luceneAst.term_min === '*') {
      value = lucene.term.unescape(luceneAst.term_max);
      operator = 'less_than';
    } else if (luceneAst.term_max === '*') {
      value = lucene.term.unescape(luceneAst.term_min);
      operator = 'greater_than';
    } else if (isDate(luceneAst.term)) {
      value = luceneAst.term;
      operator = 'on';
    } else if (isDate(luceneAst.term_min) && isDate(luceneAst.term_max)) {
      value = `[${luceneAst.term_min} TO ${luceneAst.term_max}]`;
      operator = 'between';
    } else if (isDate(luceneAst.term_min) && isToday(luceneAst.term_max)) {
      value = luceneAst.term_min;
      operator = 'since';
    } else if (
      isRelativeDate(luceneAst.term_min, false) &&
      isToday(luceneAst.term_max)
    ) {
      value = decodeRelativeDate(luceneAst.term_min, false);
      operator = 'last';
    } else if (
      isToday(luceneAst.term_min) &&
      isRelativeDate(luceneAst.term_max, true)
    ) {
      value = decodeRelativeDate(luceneAst.term_max, true);
      operator = 'next';
    } else {
      value = lucene.term.unescape(luceneAst.term);
      operator = 'is';
    }
    return {
      type: 'leaf',
      criterion,
      operator,
      value,
    };
  }
  const isNegated = parentIsNegated || luceneAst.start === 'NOT';
  let nodes: AstNode[] = [];
  if (luceneAst.operator && isNegated !== (luceneAst.operator === 'AND NOT')) {
    throw new Error('operator disagreement');
  }
  if (luceneAst.left) {
    nodes.push(luceneToAst(luceneAst.left, luceneAst.field || parentField));
  }
  if (luceneAst.right) {
    const newNode = luceneToAst(
      luceneAst.right,
      luceneAst.field || parentField,
      isNegated
    );
    if (
      newNode.type === 'branch' &&
      !luceneAst.right.parenthesized &&
      newNode.operator === luceneAst.operator
    ) {
      nodes = nodes.concat(newNode.nodes);
    } else {
      nodes.push(newNode);
    }
  }
  const operator = luceneAst.operator || (isNegated ? 'AND NOT' : 'OR');
  return { type: 'branch', operator, nodes };
}

/**
 * Turn our custom AST into an Expression.
 *   1. Try to find a simple expression (multiple leaves with same criterion)
 *   2. Otherwise find a complex expression, or let any further exception bubble
 *      up which will trigger fallback to a literal expression.
 */
function astToExpression(ast: AstNode): Expression {
  try {
    return astToSimple(ast);
  } catch {
    return astToComplex(ast);
  }
}

function astToSimple(ast: AstNode): SimpleExpression {
  if (ast.type === 'leaf') {
    return {
      type: 'simple',
      criterion: ast.criterion,
      operator: ast.operator,
      values: [ast.value],
    };
  }
  if (!['OR', 'AND NOT'].includes(ast.operator)) {
    throw new Error('unsupported operator for complex expression');
  }
  let criterion: string | undefined;
  let operator = '';
  const values: string[] = [];
  ast.nodes.forEach((node) => {
    if (node.type !== 'leaf') {
      throw new Error('complex child');
    }
    criterion = criterion || node.criterion;
    operator = operator || node.operator;
    values.push(node.value);
    if (node.value.startsWith('*') && node.value.endsWith('*')) {
      operator = 'contains';
      values[0] = values[0].replace(/^\*/, '').replace(/\*$/, '');
    }
    if (operator !== 'is' && values.length > 1) {
      throw new Error('complex child');
    }
    if (criterion !== node.criterion) {
      throw new Error('complex child');
    }
  });
  if (values.length === 0 || criterion === undefined) {
    throw new Error('empty children');
  }

  if (ast.operator !== 'OR') {
    operator = 'NOT';
  }

  return { type: 'simple', criterion, operator, values };
}

function astToComplex(ast: AstNode): ComplexExpression {
  if (ast.type !== 'branch') {
    throw new Error('unexpected leaf for complex expression');
  }
  if (['AND', 'OR'].includes(ast.operator)) {
    return {
      type: 'complex',
      operator: ast.operator,
      terms: ast.nodes.map((n, key) => ({
        key,
        expression: astToExpression(n),
      })),
    };
  }
  throw new Error('unsupported operator for complex expression');
}

const isAndOr = (op: string) => op === 'AND' || op === 'OR';

function flattenAst(operator: string) {
  // if a branch node has the same operator as its parent, it is unnecessary.
  // factor it out by replacing it with its children
  const flatten = (n: AstNode): AstNode | AstNode[] => {
    return n.type === 'branch' && n.operator === operator ? n.nodes : n;
  };
  return isAndOr(operator) ? flatten : (n: AstNode) => n;
}

function noRedundancy(ast: AstNode): AstNode {
  // if a branch node has a single child node it is redundant
  const redundant =
    ast.type === 'branch' && ast.nodes.length === 1 && isAndOr(ast.operator);
  return redundant ? noRedundancy((ast as AstBranch).nodes[0]) : ast;
}

function simplifyAst(ast: AstNode): AstNode {
  const flatten = flattenAst(ast.operator);
  if (ast.type === 'branch')
    return {
      ...ast,
      nodes: ast.nodes
        .map(noRedundancy)
        .map(noRedundancy)
        .map(simplifyAst)
        .flatMap(flatten),
    } as AstBranch;
  return ast;
}

function unwrapExpr(expression: Expression): Expression {
  if (expression.type === 'complex' && expression.terms.length === 1)
    return expression.terms[0].expression;
  return expression;
}

function validateForBuilder(expression: Expression) {
  if (expressionDepth(expression) > 2) {
    throw new Error('expression too deep to show in Builder');
  }
  return expression;
}

/**
 * Parse a text query into an Expression structure.
 *   1. Convert text -> Lucene AST -> our custom AST -> Expression.
 *   2. Fall back to LiteralExpression if parsing fails for any reason.
 */
export function textToExpression(query?: string, simplify?: true): Expression {
  if (query === undefined || query === '*' || query === '') {
    return emptyExpression();
  }

  const sanitized_query = query.replace(/:""/g, ":''");
  try {
    let ast = luceneToAst(lucene.parse(sanitized_query));
    if (simplify) ast = simplifyAst(ast);
    let expr = astToExpression(ast);
    if (simplify) expr = unwrapExpr(expr);
    return validateForBuilder(expr);
  } catch (e) {
    return literalExpression(sanitized_query);
  }
}

function expressionDepth(expression: Expression): number {
  let childDepths: number[] = [];
  switch (expression.type) {
    case 'simple':
      return 0;
    case 'complex':
      childDepths = expression.terms.map((term) =>
        expressionDepth(term.expression)
      );
      return Math.max(...childDepths) + 1;
    default:
      return 0;
  }
}

export function normalizeExpressionDepth(
  expression: Expression,
  depth = 2
): Expression {
  if (expression.type === 'literal') {
    return expression;
  }

  if (expressionDepth(expression) >= depth) {
    if (expression.type === 'simple') return expression;

    return {
      ...expression,
      terms: expression.terms.map((t) => ({
        ...t,
        expression: normalizeExpressionDepth(t.expression, depth - 1),
      })),
    };
  }

  return defaultComplexExpression(
    normalizeExpressionDepth(expression, depth - 1)
  );
}

export function normalizeExpressionOperator(
  expression: Expression
): Expression {
  if (expression.type === 'complex' && expression.terms.length === 1) {
    return {
      ...expression,
      operator: 'AND',
    };
  }

  return expression;
}

export function normalizeExpression(expression: Expression): Expression {
  return normalizeExpressionOperator(normalizeExpressionDepth(expression));
}

export function updateComplexExpression(
  value: ComplexExpression,
  setValue: (expression: ComplexExpression) => void
): {
  addExpression: (expression: Expression) => void;
  removeExpression: (index: number) => void;
  setExpression: (index: number, expression: Expression) => void;
  setOperator: (operator: string) => void;
} {
  return {
    addExpression: (expression: Expression) => {
      const maxKey = Math.max(...value.terms.map((t) => t.key));
      setValue({
        ...value,
        terms: [
          ...value.terms,
          {
            key: maxKey + 1,
            expression,
          },
        ],
      });
    },

    removeExpression: (index: number) => {
      const terms = value.terms.filter((_, idx) => idx !== index);

      setValue({
        ...value,
        terms,
      });
    },

    setExpression: (index: number, expression: Expression) => {
      setValue({
        ...value,
        terms: [
          ...value.terms.slice(0, index),
          {
            key: value.terms[index].key,
            expression,
          },
          ...value.terms.slice(index + 1),
        ],
      });
    },

    setOperator: (operator: string) => setValue({ ...value, operator }),
  };
}

export function stripEnclosingParens(
  query: string,
  singleTermsOnly?: boolean
): string {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsed: any = lucene.parse(query);
    if (
      parsed.left !== undefined &&
      parsed.operator === undefined &&
      parsed.right === undefined &&
      parsed.left.parenthesized === true &&
      !(singleTermsOnly && parsed.left.operator)
    ) {
      const trimmedQuery = query.trim();
      return trimmedQuery.substring(1, trimmedQuery.length - 1);
    }
    return query;
  } catch (err) {
    return query;
  }
}
