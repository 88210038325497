import React, { useCallback } from 'react';
import { Block, Row, Section } from 'shared/SectionBlocks';
import { FlipSwitch } from 'shared/FlipSwitch';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { Field, Fieldset } from 'shared/Fieldset';
import { SlugInput } from 'shared/forms/SlugInput';
import cx from 'classnames';
import { Tooltip } from 'shared/Tooltip';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';

import { FEATURED_LABELS, Settings } from 'models/publisher/settings';
import { Label } from 'models/label';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { ExpandableTextarea } from 'shared/forms/Textarea/Expandable';
import { acknowledgementLabelUtils } from 'shared/AcknowledgementConfig';
import styles from '../settings-editor.module.css';
import { LabelPicker } from './ContentRules/LabelPicker';

export const ContentFeatures: React.FC = () => {
  const {
    permissions: {
      contentFeatureAccess,
      postAcknowledgmentAccess,
      setFeatured,
    },
  } = usePermissions();
  const {
    contentTranslationsEnabled,
    commentingEnabled,
    contentSharingEnabled,
    emailOnly,
    resourcesEnabled,
    smartCampaignRequiredAllTypesEnabled,
  } = useFeatureFlags();
  const { contentPermissions, settings, update } = useSettings();
  const { canEdit } = contentPermissions;
  const { id: programId } = useProgram();
  const handleFeaturedPermission =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Featured').data
      ?.value as boolean) || false;

  const onSlugChange = useCallback(
    (slug) => {
      update({ slug });
    },
    [update]
  );
  const onButtonClick = useCallback(
    (field: keyof Settings) => {
      update({ [field]: !settings[field] });
    },
    [update, settings]
  );

  const onShareMessageChange = useCallback(
    (shareMessage) => {
      update({ shareMessage });
    },
    [update]
  );

  const onFeaturedLabelChange = useCallback(
    (value?: Label) => {
      update({ contentLabel: value });
    },
    [update]
  );

  const {
    labels: acknowledgementLabels,
    defaultLabel: defaultAcknowledgementLabel,
    validateLabel: validateAcknowledgementLabel,
    maxLength: acknowledgementLabelMaxLength,
  } = acknowledgementLabelUtils();

  const onAcknowledgeLabelChange = useCallback(
    (value: Label) => {
      if (validateAcknowledgementLabel(value)) {
        update({ acknowledgementLabel: value });
      }
    },
    [update, validateAcknowledgementLabel]
  );

  const toggleAcknowledge = useCallback(() => {
    update({
      acknowledge: !settings.acknowledge,
      acknowledgementLabel: settings.acknowledge
        ? undefined
        : defaultAcknowledgementLabel,
    });
  }, [update, settings.acknowledge, defaultAcknowledgementLabel]);

  const toggleFeatured = useCallback(() => {
    update({
      isFeatured: !settings.isFeatured,
      contentLabel: settings.isFeatured ? undefined : FEATURED_LABELS[0],
    });
  }, [update, settings]);

  const featuredEnabled =
    settings.contentTopics.length > 0 && contentFeatureAccess;

  const featuredToggle = (
    <div
      className={cx(styles.iconFieldWrapper, {
        [styles.disableSetting]: settings.contentTopics.length === 0,
      })}
    >
      <button
        onClick={toggleFeatured}
        type="button"
        disabled={
          (handleFeaturedPermission && !setFeatured) ||
          !contentFeatureAccess ||
          !canEdit
        }
      >
        <div className={styles.fieldIcon}>
          <span>
            <SVGIcon name="Featured" />
          </span>
          <span>Featured</span>
        </div>
      </button>
      <FlipSwitch
        on={settings.isFeatured}
        onChange={toggleFeatured}
        disabled={
          (handleFeaturedPermission && !setFeatured) ||
          !featuredEnabled ||
          !canEdit
        }
        theme={!featuredEnabled || !canEdit ? 'inactive' : 'default'}
      />
    </div>
  );

  const resourcesToggle = (
    <div
      className={cx(styles.iconFieldWrapper, {
        [styles.disableSetting]: settings.contentTopics.length === 0,
      })}
    >
      <button
        type="button"
        onClick={() => onButtonClick('isResource')}
        disabled={!canEdit}
      >
        <div className={styles.fieldIcon}>
          <span>
            <SVGIcon name="Resource" />
          </span>
          <span>Resources</span>
        </div>
      </button>
      <FlipSwitch
        on={settings.isResource}
        onChange={() => onButtonClick('isResource')}
        disabled={settings.contentTopics.length === 0 || !canEdit}
        theme={
          settings.contentTopics.length === 0 || !canEdit
            ? 'inactive'
            : 'default'
        }
      />
    </div>
  );
  return (
    <Section className={styles.contentRulesSection} title="Features">
      <Row className={styles.toggles}>
        <Block>
          <div className={styles.fieldset}>
            {!!commentingEnabled && (
              <div className={styles.iconFieldWrapper}>
                <button
                  type="button"
                  onClick={() => onButtonClick('isCommentable')}
                  disabled={!canEdit}
                >
                  <div className={styles.fieldIcon}>
                    <span>
                      <SVGIcon name="CommentRound" />
                    </span>
                    <span>Commentable</span>
                  </div>
                </button>
                <FlipSwitch
                  onChange={() => onButtonClick('isCommentable')}
                  on={settings.isCommentable}
                  disabled={!canEdit}
                />
              </div>
            )}
            {!!contentTranslationsEnabled && (
              <div className={styles.iconFieldWrapper}>
                <button
                  onClick={() => onButtonClick('isTranslatable')}
                  type="button"
                  disabled={!canEdit}
                >
                  <div className={styles.fieldIcon}>
                    <span>
                      <SVGIcon name="Translatable" />
                    </span>
                    <span>Translatable</span>
                  </div>
                </button>
                <FlipSwitch
                  on={settings.isTranslatable}
                  onChange={() => onButtonClick('isTranslatable')}
                  disabled={!canEdit}
                />
              </div>
            )}

            {!!contentSharingEnabled && (
              <div className={styles.iconFieldWrapper}>
                <button
                  onClick={() => onButtonClick('isShareable')}
                  type="button"
                  disabled={settings.acknowledge || !canEdit}
                >
                  <div className={styles.fieldIcon}>
                    <span>
                      <SVGIcon name="Upload" />
                    </span>
                    <span>Shareable</span>
                  </div>
                </button>
                <FlipSwitch
                  on={settings.isShareable}
                  onChange={() => onButtonClick('isShareable')}
                  disabled={settings.acknowledge || !canEdit}
                  theme={
                    !settings.acknowledge || !canEdit ? 'default' : 'inactive'
                  }
                />
              </div>
            )}
            {!emailOnly &&
              (settings.contentTopics.length === 0 ? (
                <HoverDropdown
                  dropdownRenderProp={() => (
                    <Tooltip title="Add topics to feature this campaign" />
                  )}
                >
                  {featuredToggle}
                </HoverDropdown>
              ) : (
                featuredToggle
              ))}

            {!!resourcesEnabled && (
              <>
                {settings.contentTopics.length === 0 ? (
                  <HoverDropdown
                    dropdownRenderProp={() => (
                      <Tooltip title="Add topics to make this a resource" />
                    )}
                  >
                    {resourcesToggle}
                  </HoverDropdown>
                ) : (
                  resourcesToggle
                )}
              </>
            )}
            {!!smartCampaignRequiredAllTypesEnabled && (
              <div className={styles.iconFieldWrapper}>
                <button
                  type="button"
                  onClick={toggleAcknowledge}
                  disabled={
                    !postAcknowledgmentAccess ||
                    settings.isShareable ||
                    !canEdit
                  }
                >
                  <div className={styles.fieldIcon}>
                    <span>
                      <SVGIcon name="HandPointer" />
                    </span>
                    <span>Acknowledge</span>
                  </div>
                </button>
                <FlipSwitch
                  on={settings.acknowledge}
                  onChange={toggleAcknowledge}
                  disabled={
                    !postAcknowledgmentAccess ||
                    settings.isShareable ||
                    !canEdit
                  }
                  theme={
                    postAcknowledgmentAccess && !settings.isShareable && canEdit
                      ? 'default'
                      : 'inactive'
                  }
                />
              </div>
            )}
          </div>
        </Block>
      </Row>
      {settings.isShareable && (
        <Fieldset dataTest="shareable-inputs">
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Shareable Message">
              <ExpandableTextarea
                maximum={280}
                value={settings.shareMessage}
                onChange={onShareMessageChange}
                disabled={!canEdit}
              />
            </Field>
            <Field label="Public URL slug">
              <SlugInput
                value={settings.slug}
                onChange={onSlugChange}
                disabled={!canEdit}
              />
            </Field>
          </div>
        </Fieldset>
      )}
      {settings.contentLabel && (
        <Fieldset dataTest="featured-inputs">
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Featured Label">
              <LabelPicker
                options={FEATURED_LABELS}
                value={settings.contentLabel}
                onChange={onFeaturedLabelChange}
                maxLength={40}
                disabled={!canEdit}
              />
            </Field>
          </div>
        </Fieldset>
      )}
      {settings.acknowledgementLabel && (
        <Fieldset dataTest="acknowledge-inputs">
          <div className={fieldsetStyles.fieldGroupContainer}>
            <Field label="Acknowledge Button">
              <LabelPicker
                options={acknowledgementLabels}
                value={settings.acknowledgementLabel}
                onChange={onAcknowledgeLabelChange}
                maxLength={acknowledgementLabelMaxLength}
                disabled={!canEdit}
              />
            </Field>
          </div>
        </Fieldset>
      )}
    </Section>
  );
};
