import React from 'react';
import { DisabledSelect } from '../Select';
import { EqualitySelect } from './EqualitySelect';
import { isDateCriterion, isRecognizedCriterion } from '../CriterionSelect';
import { DateOperatorSelect } from './DateOperatorSelect';
import { ComparisonSelect } from './ComparisonSelect';

export const OperatorSelect: React.FC<{
  criterion?: { id: string; type: string };
  value?: string;
  onChange: (value?: string) => void;
  isDisabled?: boolean;
}> = ({ criterion, value, onChange, isDisabled }) => {
  if (!criterion || !isRecognizedCriterion(criterion.id)) {
    return <DisabledSelect placeholder={value || 'is'} />;
  }
  if (isDateCriterion(criterion.id, criterion.type)) {
    return (
      <DateOperatorSelect
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
      />
    );
  }

  switch (criterion.id) {
    case 'topics.followed':
    case 'shares.count.all_time':
    case 'shares.count.last_month':
    case 'shares.count.last_quarter':
    case 'shares.count.last_week':
    case 'shares.count.last_year':
    case 'shares.count.this_month':
    case 'shares.count.this_quarter':
    case 'shares.count.this_week':
    case 'shares.count.this_year':
      return (
        <ComparisonSelect
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      );
    case 'verified_email':
    case 'reachable':
    case 'reachable_by_desktop':
    case 'reachable_by_email':
    case 'reachable_by_mobile_app':
    case 'reachable_by_push_notification':
    case 'experience_status':
    case 'provisioning_status':
    case 'role':
    case 'group': // temporarily disable contains for group criterion
      return (
        <EqualitySelect
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          showContains={false}
        />
      );
    default:
      return (
        <EqualitySelect
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      );
  }
};
