import React, { useCallback } from 'react';
import { TextInput } from 'shared/TextInput';
import styles from './slug-input.module.css';

type PropsType = {
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
};

function formattedSlug(slug: string) {
  return (slug || '')
    .substring(0, 1000)
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
}

export const SlugInput: React.FC<PropsType> = ({
  value,
  onChange,
  disabled,
}) => {
  const handleChange = useCallback(
    (changedValue) => onChange(formattedSlug(changedValue)),
    [onChange]
  );

  return (
    <TextInput
      className={styles.slugInput}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};
