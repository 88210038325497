import * as React from 'react';
import cx from 'classnames';
import { Tooltip } from 'shared/Tooltip';
import { HoverTooltip } from 'shared/HoverTooltip';
import styles from './input-text-field.module.css';

type PropsType = {
  label: string | React.ReactNode;
  help?: React.ReactNode;
  className?: string;
  tooltip?: string | React.ReactNode;
  labelFor?: string;
  error?: string;
  disabled?: boolean;
};

export const InputTextField: React.FC<PropsType> = ({
  label,
  help,
  tooltip,
  className,
  children,
  labelFor,
  disabled,
}) => {
  const dropdown = React.useCallback(() => <Tooltip description={tooltip} />, [
    tooltip,
  ]);
  return (
    <div
      className={cx(styles.field, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div className={styles.labelContainer}>
        <div className={styles.label}>
          <label htmlFor={labelFor}>{label}</label>
          {tooltip && <HoverTooltip content={dropdown} align="right" />}
        </div>
        <div className={styles.help}>{help}</div>
      </div>
      <div className={styles.input}>{children}</div>
    </div>
  );
};
