import React, { FC, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { TopicContext } from './context';
import TopicForm from './TopicForm';

const TopicEdit: FC<RouteComponentProps> = ({ uri }) => {
  const topic = useContext(TopicContext);
  return <TopicForm topic={topic} baseUrl={uri || ''} />;
};

export default TopicEdit;
