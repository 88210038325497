import { Edge, Node } from 'reactflow';
import { JourneyGraph, Step } from 'models/journeys/journey';
import { edgesFromStep } from './edge';
import { nodeFromStep } from './node';

interface GraphData {
  nodes: Node[];
  edges: Edge[];
}

export const buildGraph = (journeyGraph: JourneyGraph): GraphData => {
  const { nodes, edges } = getNodesAndEdges(journeyGraph);
  const { nodeX, nodeY } = buildTreeLayoutInfo(journeyGraph);

  return {
    nodes: nodes.map((node) => {
      return {
        ...node,
        position: { x: nodeX[node.id], y: nodeY[node.id] },
      };
    }),
    edges,
  };
};

export const getNodesAndEdges = (journeyGraph: JourneyGraph): GraphData => {
  const edges: Edge[] = [];
  const nodes: Node[] = [];
  journeyGraph.steps.forEach((step) => {
    nodes.push(nodeFromStep(step));
    edges.push(...edgesFromStep(step));
  });

  return { nodes, edges };
};

export const stepsById = (steps: Step[]): Record<string, Step> => {
  return steps.reduce(
    (rec: Record<string, Step>, step: Step) => ({ ...rec, [step.id]: step }),
    {}
  );
};

/**
 * Create a new Step object from an existing step with the currentTargetId
 * edge targetId updated to newTargetid
 *
 * @param step existing Step object
 * @param currentTargetId targetId for edge to replace
 * @param newTargetid new targetId to set on the updated edge
 * @returns new Step object with updated values
 */
export const reassignStepEdge = <T extends Step>(
  step: T,
  currentTargetId: string,
  newTargetid: string
): T => {
  const newNexts = step.next.map((n) =>
    n.targetId === currentTargetId ? { ...n, targetId: newTargetid } : n
  );
  return { ...step, next: newNexts };
};

const buildTreeLayoutInfo = (
  journeyGraph: JourneyGraph
): {
  nodeX: Record<string, number>;
  nodeY: Record<string, number>;
} => {
  const rootId = journeyGraph.rootStepId;
  const startStep = journeyGraph.steps.find((step) => step.type === 'start');
  const nodeRecord = stepsById(journeyGraph.steps);

  const leafCount: Record<string, number> = {};
  const nodeIdsByPath: Record<number, string[]> = {};

  const nodeX: Record<string, number> = {};
  const nodeY: Record<string, number> = {};

  const Y_SPACING = 106;
  const ARROW_SPACING = 48;

  const METRICS_DIVIDER = 33;
  const METRIC_HEIGHT = 32;
  const METRIC_MARGIN = 8;
  const linesOfMetrics = journeyGraph.isLive ? 3 : 0;

  const metricsHeightOffset =
    METRICS_DIVIDER + linesOfMetrics * METRIC_HEIGHT - METRIC_MARGIN;

  // let linesOfMetrics: number | undefined;
  let yOffset = 0;

  const walkTree = (stepId: string, x = 0, y = 0) => {
    nodeX[stepId] = x;

    // Collect nodes for a decision path
    nodeIdsByPath[y] ||= [];
    nodeIdsByPath[y].push(stepId);

    const nextNodes = nodeRecord[stepId].next;
    const xSpacing =
      nodeRecord[stepId].type === 'decision'
        ? 548 + ARROW_SPACING
        : 348 + ARROW_SPACING;

    if (!nextNodes || nextNodes.length === 0) {
      leafCount[stepId] = 1;

      // Increase the y-offset by half of the height difference due to metrics
      // Skip this for the first path as the y-origin should be at 0
      if (y !== 0) {
        yOffset += metricsHeightOffset / 2;
      }

      // Assign the y-position to all nodes of the path
      nodeIdsByPath[y].forEach((id) => {
        nodeY[id] = yOffset;
      });
    } else {
      let yIncrement = 0;

      nextNodes?.forEach((edge, index) => {
        // Each "nextNode" with an index > 0 is a new decision path
        // Increase the yOffset by the default Y_SPACING + half of the
        // metrics height difference
        if (index > 0) {
          yOffset += Y_SPACING + metricsHeightOffset / 2;
        }

        walkTree(edge.targetId, x + xSpacing, y + yIncrement);
        leafCount[stepId] ||= 0;
        leafCount[stepId] += leafCount[edge.targetId];
        yIncrement += leafCount[edge.targetId];
      });
    }
  };

  walkTree(startStep ? startStep.id : rootId);
  return { nodeX, nodeY };
};
