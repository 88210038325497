/* eslint-disable no-underscore-dangle */
import FroalaEditor from 'froala-editor';

type PosType = 'after' | 'before' | 'end' | 'start';
type SibType = 'next' | 'previous';
type MaybeNode = Node | null;
interface FUFroalaEditor extends FroalaEditor {
  _fu_lastcmd?: string;
  _fu_liquidid?: string;
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const Firefox = /\bGecko\/\d/.test(navigator.userAgent);
export const Safari = /\bAppleWebKit\/\d/.test(navigator.userAgent);

// liquid variable node helpers
const varAttr = 'data-liquid-unique-id';
const sepClass = 'liquid-separator-dummy-class';
const varSelector = `span[${varAttr}]`;
const sepSelector = `span.${sepClass}`;
const isvar = (node: MaybeNode) =>
  node?.nodeType === Node.ELEMENT_NODE &&
  (node as Element).hasAttribute(varAttr);
const issep = (node: MaybeNode) =>
  node?.nodeType === Node.ELEMENT_NODE &&
  (node as Element).className === sepClass;

const cursor = (position: PosType, node?: MaybeNode, parent?: MaybeNode) => {
  if (node || parent) {
    // trying to put the cursor after or at the start of the first node falls
    // back to the end of the parent node, and vice-versa
    let pos = position;
    if (!node) pos = pos === 'after' || pos === 'start' ? 'end' : 'start';
    const target = (node || parent) as Element;
    const range = document.createRange();
    // cursor inside or outside target?
    if (pos === 'after' || pos === 'before') range.selectNode(target);
    else range.selectNodeContents(target);
    // cursor at beginning or end of target?
    range.collapse(pos === 'before' || pos === 'start');
    window.getSelection()?.setPosition(range.startContainer, range.startOffset);
  }
};

// put the cursor directly after a new/updated variable
export const setCursor = (
  editor: FUFroalaEditor,
  id: string,
  type?: 'insert' | 'delayed'
): void => {
  if ((Firefox || Safari) && type === 'insert') {
    editor._fu_lastcmd = 'Liquid'; // eslint-disable-line no-param-reassign
    editor._fu_liquidid = id; // eslint-disable-line no-param-reassign
  } else {
    editor.$el
      .find(`span[${varAttr}="${id}"]`)
      .each((_: number, node: HTMLElement) => {
        setImmediate(() => cursor('start', node.nextSibling, node.parentNode));
      });
  }
  if (!(Firefox || Safari) || type !== 'delayed') editor.edit.on();
};

const simpleFix = (editor: FUFroalaEditor): void => {
  editor.events.on(
    'edit.on',
    () => {
      editor.$el.find(sepSelector).remove(); // separators are ff only
      editor.$el.find(varSelector).each((_: number, span: HTMLElement) => {
        if (span.childNodes.length === 1) span.append('\u200b');
        span.setAttribute('contenteditable', 'false');
      });
    },
    false
  );
};

const complicatedFix = (editor: FUFroalaEditor): void => {
  const arrows = (node: Node) => {
    const element = (node.nodeType !== Node.ELEMENT_NODE
      ? node
      : node.parentElement) as Element;
    const rtl = getComputedStyle(element).direction === 'rtl';
    return {
      backward: rtl ? 'ArrowRight' : 'ArrowLeft',
      forward: rtl ? 'ArrowLeft' : 'ArrowRight',
    };
  };

  const findCursor = (then: (node: Node, offset: number) => void) => {
    const { anchorNode: anchor, anchorOffset: offset, isCollapsed } =
      window.getSelection() || {};
    if (isCollapsed && anchor) then(anchor, offset as number);
  };

  const ifCursorOnSeparator = (
    then: (node: Element, offset: number) => void
  ) => {
    findCursor((anchorNode, offset) => {
      let node = anchorNode;
      if (node.nodeType !== Node.ELEMENT_NODE)
        node = node.parentElement as Element;
      if (issep(node)) then(node as Element, offset);
    });
  };

  // eslint-disable-next-line consistent-return
  const climb = (node: MaybeNode, prop: SibType): MaybeNode => {
    const parent = node?.parentNode;
    const sibling = node?.[`${prop}Sibling`];
    if (sibling) return sibling;
    if (parent && !editor.$el.is(node as Element)) return climb(parent, prop);
    return null;
  };

  const onFocus = (event: Partial<FocusEvent>) => {
    const target = event.target as Element;
    if (editor._fu_lastcmd === arrows(target).forward)
      cursor('start', target.nextSibling, target.parentNode);
    else cursor('end', target.previousSibling, target.parentNode);
  };

  // if a separator has contents besides \u200a, replace it with its contents
  const noSeparatorContents = (
    separator?: Element,
    then?: (children: Node[]) => void
  ) => {
    const fix = (node: Node) => {
      const children = Array.from(node.childNodes);
      if (children.length > 1 || node.textContent !== '\u200a')
        (node as Element).replaceWith(...children);
      return children;
    };
    if (separator)
      setImmediate(() => {
        const children = fix(separator);
        then?.(children);
      });
    else
      editor.$el.find(sepSelector).each((_: number, node: HTMLElement) => {
        fix(node);
      });
  };

  // add/remove separators so that they sit between adjacent liquid variables or
  // at the beginning and end of paragraphs, and nowhere else
  const setSeparators = () => {
    const html = `<span class="${sepClass}">\u200a</span>`;
    setImmediate(() => {
      noSeparatorContents();
      // insert separators between two variables or variable and paragraph edge
      editor.$el.find(varSelector).each((_: number, node: HTMLElement) => {
        const { nextSibling: next, previousSibling: prev } = node;
        node.setAttribute('contenteditable', 'false');
        node.setAttribute('tabindex', '-1');
        node.removeEventListener('focus', onFocus);
        node.addEventListener('focus', onFocus);
        if (!prev || isvar(prev)) node.insertAdjacentHTML('beforebegin', html);
        if (!next || isvar(next)) node.insertAdjacentHTML('afterend', html);
      });
      // remove separators that are in the wrong spot
      editor.$el.find(sepSelector).each((_: number, node: HTMLElement) => {
        const { nextSibling: next, previousSibling: prev } = node;
        if (prev && next && (!isvar(prev) || !isvar(next))) node.remove();
      });
      //  reposition the cursor after certain events
      switch (editor._fu_lastcmd) {
        case 'Backspace':
        case 'Cut':
        case 'Delete':
        case 'Enter':
        case 'Redo':
        case 'Undo':
          findCursor((node, pos) => {
            if (node.childNodes.length) {
              const prev = node.childNodes[pos - 1];
              const next = node.childNodes[pos];
              if (isvar(prev) && isvar(next))
                cursor('start', next.nextSibling, node);
              else if (isvar(prev) || (!prev && issep(next)))
                cursor('start', next);
              else if (isvar(next) || (!next && issep(prev)))
                cursor('end', prev);
            }
          });
          break;
        case 'Liquid':
          setCursor(editor, editor._fu_liquidid || '', 'delayed');
          delete editor._fu_liquidid; // eslint-disable-line no-param-reassign
          break;
        default:
      }
    });
  };

  const onCommand = (command: string) => () => {
    editor._fu_lastcmd = command; // eslint-disable-line no-param-reassign
    setSeparators();
  };

  const onInput = () => {
    ifCursorOnSeparator((separator) => {
      noSeparatorContents(separator, (children) => {
        const first = children[0];
        const last = children[children.length - 1];
        // remove the original space if they put content in the sep span
        const fix = (node: Node, exp: RegExp) => {
          const text = node.textContent || '';
          // eslint-disable-next-line no-param-reassign
          if (text.length > 1) node.textContent = text.replace(exp, '');
        };
        if (first?.nodeType === Node.TEXT_NODE) fix(first, /^\u200a/);
        if (last?.nodeType === Node.TEXT_NODE) fix(last, /\u200a$/);
        cursor('end', last);
      });
    });
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const { key, ctrlKey, metaKey, shiftKey } = event;
    editor._fu_lastcmd = key; // eslint-disable-line no-param-reassign
    if (key === 'Enter') {
      setSeparators();
    } else if (key === 'Backspace' || key === 'Delete') {
      ifCursorOnSeparator((node) => node.remove());
      setSeparators();
    } else if (key === 'ArrowRight' || key === 'ArrowLeft') {
      ifCursorOnSeparator((separator) => {
        // put the cursor after the next variable or before the prevous one
        const forward = key === arrows(separator).forward;
        const {
          nextSibling: next,
          previousSibling: prev,
          parentElement: parent,
        } = separator;
        if (forward) {
          if (next) cursor('start', next?.nextSibling, parent);
          else cursor('start', climb(parent as Element, 'next'));
        } else {
          // eslint-disable-next-line no-lonely-if
          if (prev) cursor('end', prev?.previousSibling, parent);
          else cursor('end', climb(parent as Element, 'previous'));
        }
        event.stopImmediatePropagation();
        event.preventDefault();
      });
    } else if ((key === 'z' || key === 'Z') && (ctrlKey || metaKey)) {
      onCommand('Undo');
    } else if (
      (key === 'y' || key === 'Y') &&
      (ctrlKey || (shiftKey && metaKey))
    ) {
      onCommand('Redo');
    }
  };

  editor.events.on('commands.redo', onCommand('Redo'), false);
  editor.events.on('commands.undo', onCommand('Undo'), false);
  editor.events.on('edit.on', setSeparators, false);
  editor.events.on('input', onInput, false);
  editor.events.on('keydown', onKeyDown, true);
  editor.events.on('window.cut', onCommand('Cut'), false);
  setSeparators();
};

export const fixCursedCursor = Firefox || Safari ? complicatedFix : simpleFix;
