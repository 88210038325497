import * as React from 'react';
import {
  MoreInfo,
  MoreInfoProps,
} from 'DesignSystem/Components/Alert/AddOn/MoreInfo';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';

interface MoreInfoTooltipProps {
  message?: string;
  icon?: React.ReactNode;
  tooltip?: React.ReactNode | string;
  align: 'left' | 'right';
}

export const MoreInfoTooltip: React.FC<MoreInfoTooltipProps & MoreInfoProps> = (
  props
) => {
  const {
    backgroundStyle,
    borderStyle,
    textStyle,
    padding,
    message,
    icon,
    tooltip,
    align,
  } = props;

  return (
    <MoreInfo
      backgroundStyle={backgroundStyle}
      borderStyle={borderStyle}
      textStyle={textStyle}
      padding={padding}
    >
      <HoverTooltip
        content={<Tooltip description={tooltip} />}
        align={align}
        icon={icon}
        message={message}
      />
    </MoreInfo>
  );
};
