import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Body, border, Caption } from 'DesignSystem/Typography';
import { ReactComponent as Phone } from 'shared/icons/IPhone.svg';
import { ReactComponent as Mail } from 'shared/icons/Mail2.svg';
import { Notification } from 'models/notification';
import { SubjectLinePreview } from './SubjectLinePreview';
import styles from './NotificationCard.module.css';

interface NotificationCardProps {
  notification: Notification;
  cardTitle?: string;
  disableEmail?: boolean;
  disablePush?: boolean;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  cardTitle = undefined,
  disableEmail = false,
  disablePush = false,
}) => {
  if (disableEmail && disablePush) return null;

  const emailText = notification.text;
  const emailPreview = notification.previewText || '';
  const pushText = notification.pushText || emailText;
  return (
    <Box margin={[20, 0, 0, 0]}>
      {cardTitle && (
        <Box margin={[0, 0, 10, 0]}>
          <Body>{cardTitle}</Body>
        </Box>
      )}
      {!disableEmail && (
        <Box
          height="78px"
          border={[border.solid, border.width1, border.radius8, border.gray10]}
          margin={[0, 0, 16, 0]}
          style={{ display: 'flex' }}
        >
          <Box className={styles.Icon} margin={['auto', 20.5]}>
            <Mail />
          </Box>
          <Box
            className={styles.DescriptionWrapper}
            margin={['auto', 20.5, 'auto', 0]}
          >
            <Body>
              <SubjectLinePreview subjectText={emailText} />
            </Body>
            <Box className={styles.Description}>
              <Caption>
                <SubjectLinePreview subjectText={emailPreview} />
              </Caption>
            </Box>
          </Box>
        </Box>
      )}
      {!disablePush && (
        <Box
          height="78px"
          border={[border.solid, border.width1, border.radius8, border.gray10]}
          margin={[0, 0, 16, 0]}
          style={{ display: 'flex' }}
        >
          <Box className={styles.Icon} margin={['auto', 20.5]}>
            <Phone />
          </Box>
          <Box
            className={styles.DescriptionWrapper}
            margin={['auto', 20.5, 'auto', 0]}
          >
            <Body>
              <SubjectLinePreview subjectText={pushText} />
            </Body>
          </Box>
        </Box>
      )}
    </Box>
  );
};
