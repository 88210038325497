import cx from 'classnames';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { FormModal, Tooltip } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import formStyles from 'DesignSystem/Form/forms.module.css';
import { useUniqueId } from 'hooks/useUniqueId';
import { PinnableContent } from 'models/topic';
import { PostsFinder } from './PostsFinder';
import { MAX_PINNED_POSTS } from '../constants';

type PostsFinderModalProps = {
  selected: PinnableContent[];
  onSubmit: (selectedPosts: PinnableContent[]) => void;
  onCancel: () => void;
};

export function PostsFinderModal({
  selected,
  onSubmit: onSubmitProp,
  onCancel,
}: PostsFinderModalProps): ReactElement {
  const [selectedPostsCount, setSelectedPostsCount] = useState(selected.length);
  // With useState, we keep getting re-renders because `onSelected` keeps getting called by the child
  // To be investigated later, right now this works
  const selectedPosts = useRef<PinnableContent[]>([]);

  const setSelectedPosts = useCallback((posts: PinnableContent[]) => {
    selectedPosts.current = posts;
    setSelectedPostsCount(posts.length);
  }, []);

  const onSubmit = useCallback(() => {
    onSubmitProp(selectedPosts.current);
  }, [onSubmitProp, selectedPosts]);

  const tooltipId = useUniqueId();
  const addPostsDisabled =
    selectedPostsCount > MAX_PINNED_POSTS || selectedPostsCount < 1;

  return (
    <FormModal
      entityText="pinned posts"
      actionText="add"
      description="Select up to 5 posts to pin"
      width="90vw"
      minPadding={0}
      onCancel={onCancel}
      submitButton={
        <Tooltip
          id={tooltipId}
          content={`Up to ${MAX_PINNED_POSTS} posts can be pinned`}
          placement="top"
          disabled={!addPostsDisabled}
        >
          <Button
            className={cx({
              [formStyles.ButtonDisabled]: addPostsDisabled,
            })}
            onClick={!addPostsDisabled ? onSubmit : undefined}
            label="Add"
            aria-disabled={addPostsDisabled}
            aria-describedby={addPostsDisabled ? tooltipId : undefined}
          />
        </Tooltip>
      }
    >
      <PostsFinder selected={selected} onSelected={setSelectedPosts} />
    </FormModal>
  );
}
