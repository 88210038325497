import React from 'react';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { Screen } from 'components/templates/Screen';
import { TemplateEditor } from 'components/templates/EditorPages/Template';
import { Compose } from 'components/templates/EditorPages/Compose';
import { Orchestrate } from 'App/Program/Editors/Publisher/Orchestrate';
import { TemplateFooter } from 'components/templates/TemplateFooter';
import { usePublisher } from 'contexts/publisher';
import { useOrchestrationInsights } from 'hooks/orchestration-insights';
import { OrchestrateSidebar } from 'App/Program/Editors/Publisher/Orchestrate/OrchestrateSidebar';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { Deliver } from '../Publisher/Deliver';

const orchestrateFooter = <TemplateFooter nextPage="template" />;
const TemplateOrchestrateSidebar = () => {
  const { post } = usePublisher();
  const { settings } = useSettings();
  const insights = useOrchestrationInsights(settings);

  return (
    <OrchestrateSidebar post={post} settings={settings} insights={insights} />
  );
};

export const Template: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const usingNewDelivery = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  ).data?.value;

  return (
    <Router>
      <Screen path="/:id">
        <TemplateEditor path="template" />
        <Compose path="compose/*" />
        {!usingNewDelivery && (
          <Orchestrate
            path="deliver/*"
            sidebar={<TemplateOrchestrateSidebar />}
            footer={orchestrateFooter}
          />
        )}
        {usingNewDelivery && (
          <Deliver
            path="deliver/*"
            sidebar={<TemplateOrchestrateSidebar />}
            footer={orchestrateFooter}
          />
        )}

        <Redirect noThrow from="/" to="template" />
        <Redirect noThrow from="*" to="/" default />
      </Screen>
      <Redirect noThrow from="/" to="new" />
      <Redirect noThrow from="*" to="/" default />
    </Router>
  );
};
