import * as React from 'react';
import cx from 'classnames';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import { RelativeRangePanel } from './RelativeRangePanel';
import { SummaryControl } from './SummaryControl';
import { ClearButton } from './ClearButton';
import { DoneButton } from './DoneButton';
import { MonthlyCalendars } from './MonthlyCalendars';
import styles from './component-layout.module.css';
import { TimezonePanel } from './TimezonePanel';
import { TimezoneButton } from './TimezoneButton';

type PropsType = {
  actions: DateRangeInputActionsType;
  allowEmpty: boolean;
  allowFutureSelection: boolean;
  className?: string;
  state: DateRangeInputStateType;
  shouldUseTimezone?: boolean;
};

export const ComponentLayout: React.FC<PropsType> = (props) => {
  const {
    actions,
    allowEmpty,
    allowFutureSelection,
    className,
    state,
    shouldUseTimezone = false,
  } = props;
  const [timezonePanelDisplay, setTimezonePanelDisplay] = React.useState<
    string
  >('none');
  const [timezoneLayout, setTimezoneLayout] = React.useState<string>(
    styles.layout
  );

  const handleTimezoneToggle = () => {
    setTimezonePanelDisplay(timezonePanelDisplay === 'none' ? 'block' : 'none');
    setTimezoneLayout(
      timezoneLayout === styles.layout ? styles.timezoneLayout : styles.layout
    );
  };

  return (
    <div className={cx(timezoneLayout, className)}>
      {shouldUseTimezone && (
        <div
          className={styles.range}
          style={{ display: `${timezonePanelDisplay}` }}
        >
          <TimezonePanel
            onChange={actions.setTimezone}
            allowFutureSelection={allowFutureSelection}
            selectedPresetKey={state.timezone.key}
          />
        </div>
      )}
      <div className={styles.range}>
        <RelativeRangePanel
          onChange={actions.setDateRange}
          allowFutureSelection={allowFutureSelection}
          selectedPresetKey={state.dateRange.key}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.summary}>
          <SummaryControl
            dateRange={state.dateRange}
            selectedDate={state.selectedDate}
            actions={actions}
          />
        </div>
        <div className={styles.calendars}>
          <MonthlyCalendars
            calendarMonth={state.calendarMonth}
            dateRange={state.dateRange}
            selectedDate={state.selectedDate}
            allowFutureSelection={allowFutureSelection}
            actions={actions}
          />
        </div>
        <div className={styles.buttons}>
          {shouldUseTimezone && (
            <TimezoneButton
              onClick={handleTimezoneToggle}
              timezone={state.timezone}
            />
          )}
          <DoneButton onClick={actions.onDone} />
          {!allowEmpty ? null : <ClearButton onClick={actions.onClear} />}
        </div>
      </div>
    </div>
  );
};
