import React, { useCallback } from 'react';
import { Button } from 'shared/Button';

import { HYBRID } from 'models/publisher/settings';
import { DateTime } from 'luxon';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Notification, updateDateTime } from 'models/notification';
import { useNotifications } from 'App/Program/Editors/Publisher/Deliver/Notifications/useNotifications';
import { NotificationField } from './NotificationField';
import style from './notification-field.module.css';

type Props = {
  onChange: (notifications: Notification[]) => void;
  notifications: Notification[];
  disabled?: boolean;
  placeholder: string;
  notifDeliveryTimesEnabled: boolean;
};

export const NotificationFields: React.FC<Props> = ({
  notifications,
  onChange,
  disabled,
  placeholder,
  notifDeliveryTimesEnabled,
}) => {
  const { settings } = useSettings();
  const {
    defaultNotificationText,
    defaultNotificationPreviewText,
  } = useNotifications();

  const handleDateChange = useCallback(
    (dateTime: DateTime, index: number) => {
      const newNotifications = [...notifications];
      const notif = newNotifications[index];
      newNotifications[index] = updateDateTime(notif, dateTime, false);
      onChange(newNotifications);
    },
    [notifications, onChange]
  );

  const handleTextChange = useCallback(
    (text: string, index: number) => {
      const newNotifications = [...notifications];
      newNotifications[index].text = text;
      newNotifications[index].persisted = true;
      onChange(newNotifications);
    },
    [notifications, onChange]
  );

  const handlePreviewTextChange = useCallback(
    (previewText: string, index: number) => {
      const newNotifications = [...notifications];
      newNotifications[index].previewText = previewText;
      newNotifications[index].persisted = true;
      onChange(newNotifications);
    },
    [notifications, onChange]
  );

  const deleteNotification = useCallback(
    (index: number) => {
      const newNotifications = [...notifications];
      newNotifications.splice(index, 1);
      onChange(newNotifications);
    },
    [notifications, onChange]
  );

  const addNotification = useCallback(() => {
    const newNotifications = [
      ...notifications,
      {
        text: defaultNotificationText,
        previewText: defaultNotificationPreviewText,
        order:
          notifications?.length > 0
            ? notifications[notifications.length - 1].order + 1
            : 1,
      },
    ];
    onChange(newNotifications);
  }, [
    defaultNotificationText,
    defaultNotificationPreviewText,
    notifications,
    onChange,
  ]);

  const showDatePicker =
    notifDeliveryTimesEnabled && settings.deliveryType === HYBRID;

  const isDisabled = (notif: Notification): boolean =>
    !!(
      disabled ||
      (settings.publicationState === 'published' &&
        notif?.dateTime &&
        notif.dateTime < DateTime.now()) ||
      (settings.publicationState === 'published' && !!notif?.isPublishDateTime)
    );

  const isPreviewTextDisabled = !settings.deliveryChannels.email;

  const lowerLimit = settings.publishedAt || DateTime.now();

  // We will use an id instead if these notifications get more complex
  /* eslint-disable react/no-array-index-key */
  return (
    <div className={style.notificationField}>
      {notifications.map((notification, index) => (
        <NotificationField
          key={`notificationField-${index}`}
          index={index}
          isDisabled={isDisabled}
          isPreviewTextDisabled={isPreviewTextDisabled}
          showDatePicker={showDatePicker}
          notification={notification}
          lowerLimit={lowerLimit}
          placeholder={placeholder}
          onChangeText={(value = '') => handleTextChange(value, index)}
          onChangePreviewText={(value = '') =>
            handlePreviewTextChange(value, index)
          }
          onChangeDate={(newDateTime) => handleDateChange(newDateTime, index)}
          onDelete={() => deleteNotification(index)}
          canDelete={notifications.length > 1}
        />
      ))}
      <div className={style.addContainer}>
        {settings.retries !== 0 && (
          <Button
            onClick={addNotification}
            type="tertiary"
            disabled={disabled}
            iconType="SVG"
            iconName="Plus"
            compact
          >
            <span>Notification</span>
          </Button>
        )}
      </div>
    </div>
  );
};
