import React from 'react';
import { Button, FieldInput } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { VideoFieldData } from 'models/donkey';
import { useVideoCaption } from '../../hooks/useVideoCaption';
import { FieldFormProps } from '../../../../useFieldForm';
import { List } from './List';

export const VideoCaptions: React.FC<{
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  onCaptionUpdated: () => void;
  filename?: string;
}> = ({ fieldData, onChange, onCaptionUpdated, filename }) => {
  const {
    fetchedCaptions,
    onListItemEdit,
    onListItemRemove,
    openSettings,
    CaptionSettings,
    isDisabled,
    captionsError,
  } = useVideoCaption({
    fieldData,
    onChange,
    onCaptionUpdated,
  });

  return (
    <>
      <FieldInput>
        <Text.FieldLabel>Captions</Text.FieldLabel>
        <List
          captions={fetchedCaptions}
          onEdit={onListItemEdit}
          onRemove={onListItemRemove}
          filename={filename || ''}
        />
        <br />
        <Button
          block
          icon={
            <Text.PageHeading color={Text.color.gray00}>+</Text.PageHeading>
          }
          label="Add Captions"
          onClick={openSettings}
          disabled={isDisabled}
        />
        {captionsError && (
          <Text.Body color={Text.color.redFull}>{captionsError}</Text.Body>
        )}

        {CaptionSettings}
      </FieldInput>
    </>
  );
};
