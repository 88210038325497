import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import {
  useAudienceEdit,
  useAudienceArchive,
  useAudienceUnarchive,
  useAudienceCreateSnapshot,
  useAudienceSaveAs,
  useFavoriteAudiencesQuery,
  useAddAudienceToFavorites,
  useRemoveAudienceFromFavorites,
} from 'hooks/audience';
import {
  Audience,
  canEditAny,
  audienceQuery,
  canEditQuery,
} from 'models/audience';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { FAIcon } from 'shared/Icon/FAIcon';
import { useQueryClient } from 'react-query';
import { useProgram } from 'contexts/program';
import { AudienceProvider } from 'contexts/audience';
import { AdvancedBuilder } from './AdvancedBuilder';
import { AudienceInfo } from './AudienceInfo';
import { ExpressionBuilder } from './ExpressionBuilder';
import { MoreButtonMenu } from './MoreButtonMenu';
import { PreviewUsers } from './PreviewUsers';
import { SaveAsModal } from './SaveAsModal';
import styles from './AudienceBuilder.module.css';

function audienceTitleValid(title: string): boolean {
  return title?.trim() !== '';
}

function basicBuilderQueryValid(query: string): boolean {
  return query.trim() !== '*' && query.trim() !== '';
}

function advancedBuilderQueryValid(query: string): boolean {
  return query.trim() !== '';
}

export const EditAudience: React.FC<{
  audience: Audience;
  onChange?: (data: Audience) => void; // called as audience changes during editing
  onSave?: (data: Audience) => void; // called when audience is successfully saved
}> = ({ audience, onChange, onSave }) => {
  const navigate = useNavigate();

  const { data, setData, isSaving, isSaved, save } = useAudienceEdit(audience, {
    onSuccess: onSave,
  });

  const queryText = data?.query || '';
  const [preview, setPreview] = useState(false);
  const [advancedBuilderMode, setAdvancedBuilderMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { id: programId } = useProgram();
  const { data: favoriteAudiences } = useFavoriteAudiencesQuery(programId);
  const queryClient = useQueryClient();

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [advancedQueryValid, setAdvancedQueryValid] = useState(true);
  const [summaryQueryValid, setSummaryQueryValid] = useState(true);

  const favoriteAudience = audience.id
    ? favoriteAudiences?.includes(Number(audience.id))
    : false;

  const { addToFavorites } = useAddAudienceToFavorites(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['favoriteAudiences']);
    },
  });

  const { removeFromFavorites } = useRemoveAudienceFromFavorites(audience, {
    onSuccess: () => {
      queryClient.invalidateQueries(['favoriteAudiences']);
    },
  });

  const { archive } = useAudienceArchive(audience, {
    onSuccess: () => {
      navigate(`/${audience.programId}/app/people/audiences`, {
        replace: true,
      });
    },
  });

  const { unarchive } = useAudienceUnarchive(audience, {
    onSuccess: () => {
      navigate(`/${audience.programId}/app/people/audiences`, {
        replace: true,
      });
    },
  });

  const { saveAs } = useAudienceSaveAs(audience, {
    onSuccess: (newAudience: Audience) => {
      navigate(
        `/${newAudience.programId}/app/people/audiences/${newAudience.id}`
      );
    },
  });

  const { createSnapshot } = useAudienceCreateSnapshot(audience, {
    onSuccess: (groupId: string) => {
      navigate(`/${audience.programId}/app/people/audiences/${groupId}`);
    },
  });

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    save();
  };

  const handleFavorite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (favoriteAudience) removeFromFavorites();
    else addToFavorites();
  };

  const togglePreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPreview(!preview);
    setAdvancedQueryValid(true);
  };

  const toggleMode = () => {
    setPreview(false);
    setAdvancedBuilderMode(!advancedBuilderMode);
    setAdvancedQueryValid(true);
  };

  const cancelSaveAsModal = () => {
    setIsOpen(false);
  };

  const openSaveAsModal = () => {
    setIsOpen(true);
  };

  const setAudience = (a: Audience) => {
    setData(a);
    if (onChange) {
      onChange(a);
    }
  };

  const {
    getExpression,
    setExpression,
    getEditableText,
    setEditableText,
    editableTextToQuery,
  } = audienceQuery(data, setAudience);

  const updateSummaryQueryStatus = (status: boolean) => {
    setSummaryQueryValid(status);
  };

  const editor = advancedBuilderMode ? (
    <div>
      <AdvancedBuilder
        value={getEditableText()}
        onChange={setEditableText}
        canEdit={canEditQuery(data)}
        audience={data}
        editableTextToQuery={editableTextToQuery}
        setAdvancedQueryValid={setAdvancedQueryValid}
      />
    </div>
  ) : (
    <div>
      <ExpressionBuilder
        isAlone
        depth={0}
        onChange={setExpression}
        value={getExpression()}
        onDelete={() => {}}
        canEdit={canEditQuery(audience)}
        editableTextToQuery={editableTextToQuery}
        audienceName={audience.title}
        updateSummaryQueryStatus={updateSummaryQueryStatus}
      />
    </div>
  );

  useEffect(() => {
    // This is a limited query validity check; it
    // does _not_ indicate _syntactic_ validity,
    // notably for the advanced query. The AQB
    // relies on server-side validation, hence
    // the `advancedQueryValid` state/setter.
    const queryValid = advancedBuilderMode
      ? advancedBuilderQueryValid
      : basicBuilderQueryValid;

    const newSaveDisabled =
      isSaving ||
      isSaved ||
      !advancedQueryValid ||
      !audienceTitleValid(data.title) ||
      !queryValid(getEditableText()) ||
      !summaryQueryValid;

    setSaveDisabled(newSaveDisabled);
  }, [
    advancedBuilderMode,
    advancedQueryValid,
    isSaving,
    isSaved,
    data.title,
    getEditableText,
    summaryQueryValid,
  ]);

  return (
    <>
      <div className={styles.header}>
        <AudienceInfo
          audience={data}
          setAudience={setAudience}
          isSaved={isSaved}
        />

        <div className={styles.actionWrapper}>
          <div className={styles.buttons}>
            <button
              onClick={handleFavorite}
              type="button"
              className={styles.btnFavorite}
              disabled={!data.id}
            >
              <FAIcon name={favoriteAudience ? 'star' : 'star-o'} />
            </button>

            <div className={styles.moreButtonWrapper}>
              <MoreButtonMenu
                archived={data.state === 'disabled'}
                onArchive={archive}
                onUnarchive={unarchive}
                onOpenSaveAsModal={openSaveAsModal}
                onCreateSnapshot={createSnapshot}
                onAdvancedQueryBuilder={toggleMode}
                advancedBuilder={advancedBuilderMode}
                updatedAt={data.updatedAt}
                audience={data}
                setAudience={setAudience}
                editableTextToQuery={editableTextToQuery}
                canEdit={canEditQuery(audience)}
              />
            </div>

            <button
              type="button"
              className={styles.btnPreview}
              onClick={togglePreview}
              disabled={queryText === '' || queryText === '*'}
            >
              <SVGIcon name="Preview" /> {preview ? 'Edit' : 'Preview'}
            </button>

            {canEditAny(audience) && (
              <>
                <button
                  type="button"
                  disabled={saveDisabled}
                  className={styles.btnSave}
                  onClick={handleSave}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
              </>
            )}
          </div>
          {!summaryQueryValid && !advancedBuilderMode && (
            <div className={styles.expressionStatusRelativeError}>
              Invalid query
            </div>
          )}
        </div>
        <div style={{ clear: 'both' }} />
      </div>
      <div className={styles.audienceBuilder}>
        {preview ? (
          <PreviewUsers audience={data} query={queryText} />
        ) : (
          <AudienceProvider audience={audience}>{editor} </AudienceProvider>
        )}
      </div>
      <div>
        {isOpen ? (
          <SaveAsModal
            audience={data}
            onCancel={cancelSaveAsModal}
            onSaveAs={saveAs}
          />
        ) : null}
      </div>
    </>
  );
};
