import camelcaseKeys from 'camelcase-keys';
import { FeedPreview } from 'models/feed-preview';
import { bossanovaDomain, prepareQueryString, request } from './api-shared';

interface Query {
  ids?: number[];
  pinned?: boolean;
}

export async function getFeedPreview(
  programId: number,
  topicId: number,
  query: Query = {}
): Promise<FeedPreview[]> {
  const queryString = prepareQueryString(query);
  const url = `${bossanovaDomain}/v2/programs/${programId}/feed_preview/${topicId}?${queryString}`;
  const response = await request(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 200) {
    return transformFeedPreviewResponse((await response.json()).data);
  }

  throw new Error(`Error fetching feed preview: ${response.status}`);
}

function transformFeedPreviewResponse(data: unknown): FeedPreview[] {
  if (!Array.isArray(data)) {
    throw new Error('Feed preview response is not an array');
  }

  return data.map((item) => ({
    id: item.id,
    ...camelcaseKeys(item.attributes, {
      deep: true,
    }),
  }));
}
