import React, { useState, useEffect } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Body, color } from 'DesignSystem/Typography';
import { DismissType } from 'shared/ClickDropdown';
import { Pills } from 'DesignSystem/Components/Pills';
import { useDefaultEmailAddress } from 'hooks/email-alias';
import { Button } from 'DesignSystem/Form';
import {
  Gray10PillClass,
  GreenTintDarkPillClass,
} from 'DesignSystem/Components/Pill';
import { ReactComponent as CircleCheck } from 'shared/icons/CheckCircle.svg';

import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import {
  DeliveryChannelOption,
  useChannelSettings,
} from './useChannelSettings';
import { ChangeChannels, channelIcons } from './ChangeChannels';
import { ContentTitle, SettingsCard, Divisor } from '../SettingsCard';
import { NotificationCard } from '../Notifications/NotificationCard';
import { useNotifications } from '../Notifications/useNotifications';
import { InfoTooltip } from '../Shared/InfoTooltip';

interface DirectDeliverySectionProps {
  disabled?: boolean;
}

export const ChannelsSection: React.FC<DirectDeliverySectionProps> = ({
  disabled,
}) => {
  const {
    deliveryChannelsOptions,
    orchestrateDeliveryForYou,
    turnOffAndHideForYou,
  } = useChannelSettings();

  const {
    settings: { deliveryChannels, includeInForYou, emailSenderAlias },
    fields: {
      includeInForYou: { set: setIncludeInForYou },
    },
  } = useSettings();
  const { firstNotification } = useNotifications();

  const { data: defaultAddress } = useDefaultEmailAddress();

  const changeChannels = (
    dismiss: DismissType,
    setDismissable?: (dismissable: boolean) => void
  ) => (
    <ChangeChannels
      disabled={disabled}
      onCancel={dismiss}
      onSave={dismiss}
      setDismissable={setDismissable}
    />
  );

  const renderPill = (value: DeliveryChannelOption) => <>&nbsp;{value.label}</>;
  const pillIcons = (value: DeliveryChannelOption) => channelIcons(value.name);

  const enabledChannels = deliveryChannelsOptions.filter(
    (c) => deliveryChannels[c.name]
  );

  useEffect(() => {
    if (
      turnOffAndHideForYou &&
      (includeInForYou === undefined || includeInForYou)
    )
      setIncludeInForYou(false);
  });

  const hasChannels = enabledChannels.length > 0;
  const forYouChecked =
    !turnOffAndHideForYou && includeInForYou && orchestrateDeliveryForYou;
  const emailChannel = deliveryChannels?.email;
  const pushChannel = deliveryChannels?.push;

  const subjectLineTitle = React.useMemo(() => {
    if (emailChannel && pushChannel) {
      return 'Subject Line and Push Message';
    }
    if (emailChannel) {
      return 'Subject Line';
    }
    if (pushChannel) {
      return 'Push Message';
    }
    return '';
  }, [emailChannel, pushChannel]);

  const [editing, setEditing] = useState(false);

  const defaultAddressMessage = defaultAddress
    ? `${defaultAddress.senderName} <${defaultAddress.senderEmail}>`
    : 'No default alias selected';

  return (
    <>
      <Divisor />
      <SettingsCard
        dataTest="channels-settings"
        isEditing={editing}
        showEditButton={hasChannels || forYouChecked}
        editRenderProp={changeChannels}
        onEditingToggle={(value: boolean) => {
          setEditing(value);
        }}
        disabled={disabled}
      >
        {(toggleEditing: () => void) => (
          <>
            <Box margin={[0, 0, 0, 0]}>
              <ContentTitle>
                Channels
                <InfoTooltip message="Unless only one channel is selected, the orchestration engine will optimize which enabled channels to leverage for each campaign." />
              </ContentTitle>
              {!forYouChecked && !hasChannels && !editing && (
                <Box>
                  <Button
                    onClick={toggleEditing}
                    label="Add Channels"
                    disabled={disabled}
                  />
                </Box>
              )}

              {(hasChannels || forYouChecked) && (
                <>
                  <Box>
                    {hasChannels ? (
                      <Pills
                        render={renderPill}
                        pillIcon={pillIcons}
                        values={enabledChannels}
                        pillClassName={Gray10PillClass}
                        small
                        dataTest="channel-pills"
                        padding={[0, 8]}
                      />
                    ) : (
                      <div>
                        <Body italic color={color.gray60}>
                          No channels selected
                        </Body>
                      </div>
                    )}
                  </Box>

                  {forYouChecked && (
                    <Box margin={[hasChannels ? 10 : 20, 0, 0, 0]}>
                      <Pills
                        values={[{ id: 1 }]}
                        render={() => <>Include in &lsquo;For You&rsquo;</>}
                        pillIcon={() => <CircleCheck />}
                        pillClassName={GreenTintDarkPillClass}
                        small
                        padding={[0, 8]}
                      />
                    </Box>
                  )}

                  {emailChannel && (
                    <Box margin={[30, 0, 0, 0]}>
                      <ContentTitle>Email Alias</ContentTitle>
                      <Box margin={[16, 0, 0, 0]}>
                        <Body>
                          {emailSenderAlias
                            ? `${emailSenderAlias.senderName} <${emailSenderAlias?.senderEmail}>`
                            : defaultAddressMessage}
                        </Body>
                      </Box>
                    </Box>
                  )}

                  {(emailChannel || pushChannel) && (
                    <Box margin={[30, 0, 0, 0]}>
                      <ContentTitle>{subjectLineTitle}</ContentTitle>
                      <NotificationCard
                        notification={firstNotification}
                        disablePush={!pushChannel}
                        disableEmail={!emailChannel}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </SettingsCard>
    </>
  );
};
