import * as React from 'react';
import videojs from 'video.js';
import { Video, CaptionLocaleUrl } from 'models/video';
import { useUniqueId } from 'hooks/useUniqueId';

/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const UploadedVideoPlayer: React.FC<{
  video: Video;
}> = (props) => {
  const { video } = props;
  const id = useUniqueId();

  React.useEffect(() => {
    const videoPlayer =
      videojs.getPlayer(id) ||
      videojs(id, {
        fluid: true,
        html5: {
          vhs: {
            enableLowInitialPlaylist: true,
          },
        },
      });
    const captions: Array<CaptionLocaleUrl> = video.captionUrls || [];

    // check if the browser is IE
    if (
      /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf('Trident/') > -1
    ) {
      videoPlayer.src({
        src: `${video.mp4Url}?${video.secureQueryParams}`,
        type: 'video/mp4',
      });
    } else {
      videoPlayer.src({
        src: `${video.url}?${video.secureQueryParams}`,
        type: 'application/x-mpegURL',
      });
    }

    videoPlayer.poster(video.previewImageUrl || '');

    // have every request pass the signature
    (videojs as any).Vhs.xhr.beforeRequest = (options: any) => {
      const params = video.secureQueryParams;
      const result = { ...options };
      if (options.uri.indexOf(params) < 0) {
        result.uri = `${options.uri}?${params}`;
      }
      return result;
    };

    videoPlayer.ready(() => {
      captions.forEach((caption) => {
        videoPlayer.addRemoteTextTrack(
          {
            kind: 'captions',
            language: caption.language,
            label: caption.label,
            src: `${caption.url}?${video.secureQueryParams}`,
          },
          false
        );
      });
    });

    // TODO:
    // This may not be necessary any more.  It may have been a bug in the particular version
    // of video.js we were using in bossanova.  It doesn't play nicely with typescript anyway.

    // this requires a bit of explanation --
    // the playlist index.m3u8 refers to a caption playlist caption-{}.vtt
    // after ready but before play, that playlist is built and added
    // however, it is always malformed, with cue times being NaN.
    // the file nor the videojs webvtt parser is at issue, something else is going on
    // this removes all caption data added through the playlist, relying on the fact that
    // they are of kind "subtitles"
    // instead, the captions manually added through the 'ready' callback will be used

    // videoPlayer.on('play', () => {
    //   const trackList = videoPlayer.remoteTextTracks();
    //   for (let i = 0; i < trackList.length; i++) {
    //     const track = trackList[i];
    //     if (track.kind === 'subtitles') {
    //       videoPlayer.removeRemoteTextTrack(track);
    //     }
    //   }
    // });

    // Prevent clicks from bubbling past the video player
    const stopClickPropagation = (event: MouseEvent) => event.stopPropagation();
    videoPlayer.on('click', stopClickPropagation);
    return () => videoPlayer.off('click', stopClickPropagation);
  }, [
    id,
    video.captionUrls,
    video.mp4Url,
    video.previewImageUrl,
    video.secureQueryParams,
    video.url,
  ]);

  if (!video) {
    return <></>;
  }

  return (
    <div>
      <video
        id={id}
        className="video-js vjs-big-play-centered"
        style={{ width: '100%', height: '100%' }}
        poster={video.previewImageUrl}
        controls
      />
    </div>
  );
};
