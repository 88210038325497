import React from 'react';
import {
  ComplexExpression,
  defaultComplexExpression,
  expressionToText,
  normalizeExpression,
  stripEnclosingParens,
  textToExpression,
} from 'models/expression';
import { FlipSwitch } from 'shared/FlipSwitch';
import { AISparkles, Close, ExclamationTriangleOutlined } from 'shared/icons';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Button } from 'DesignSystem/Form';
import { Textarea } from 'shared/forms/Textarea';
import { SuggestQueryResponse, useSuggestQuery } from 'hooks/audience';
import { useProgram } from 'contexts/program';
import { OptionType } from 'hooks/common';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { CollectFeedbackRow } from 'App/Program/Main/Feedback/CollectFeedbackRow';
import { FeedbackStyle } from '@socialchorus/critique-ui';
import {
  FeedbackProviderInMemory,
  useFeedback,
} from 'App/Program/Main/Feedback/FeedbackProviderInMemory';
import { filterCriteria } from '../CriterionSelect';
import styles from '../AudienceBuilder.module.css';

const useFilterCriteria = (prog: number): Array<OptionType> => {
  const permissions = useFeatureFlagsQuery(prog, 'Studio.Permissions.Service');
  const criteria = filterCriteria([], !!permissions.data?.value).flatMap(
    ({ options }) => options
  );
  return criteria;
};

// as few parentheses as possible
const trimParens = (input: string, leaveOneSet?: boolean) => {
  let result = leaveOneSet ? `(${input})` : input;
  for (let len = 0; len !== result.length; len = result.length)
    result = stripEnclosingParens(result, leaveOneSet);
  return result;
};

const ErrorMessage: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  return (
    <div className={styles.errorBanner} id="errorBanner">
      <div className={styles.sidestripe} />
      <div className={styles.bannerContent}>
        {/* TODO: Make the icons the correct color and width */}
        <ExclamationTriangleOutlined className={styles.warningSign} />
        <div className={styles.errorText}>
          Your prompt didn&apos;t return any results
        </div>
        <Close className={styles.xSign} onClick={onClose} />
      </div>
    </div>
  );
};

export const AIToggle: React.FC<{
  value: boolean;
  onChange: (v?: true) => void;
}> = ({ value, onChange }) => {
  return (
    <div id="AIAssistantToggle" className={styles.AIAssistantToggle}>
      <div id="sparkleContainer" className={styles.toggleItem3}>
        <AISparkles />
      </div>
      <div id="toggleText" className={styles.toggleItem2}>
        AI Assistant
      </div>
      <div className={styles.toggleItem} id="toggle">
        <FlipSwitch onChange={onChange} on={value} />
      </div>
    </div>
  );
};

export const AIAssistant: React.FC<{
  value: ComplexExpression;
  onChange: (value: ComplexExpression) => void;
}> = ({ value, onChange }) => {
  return (
    <FeedbackProviderInMemory>
      <AIAssistantInner value={value} onChange={onChange} />
    </FeedbackProviderInMemory>
  );
};

const AIAssistantInner: React.FC<{
  value: ComplexExpression;
  onChange: (value: ComplexExpression) => void;
}> = ({ value, onChange }) => {
  const { id: programId } = useProgram();
  const { requestFeedback } = useFeedback();

  const [showError, setShowError] = React.useState(false);
  const [prompt, setPrompt] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const baseQuery =
    trimParens(expressionToText(value), true) || 'status:not_blocked';
  const keyOffset =
    value.terms.reduce((max, term) => Math.max(max, term.key), 0) + 1;

  const onSuccess = (data: SuggestQueryResponse) => {
    const { task_id, output } = data;
    setIsLoading(false);
    if (output.includes("Your prompt didn't return any results")) {
      setShowError(true);
    } else {
      requestFeedback('Studio.AI.AudienceBuilder', task_id);
      const query = trimParens(
        output
          .replace(/ AND status:not_blocked/i, '')
          .replace(/status:not_blocked AND /i, '')
      );
      let expr = normalizeExpression(textToExpression(query, true));
      if (expr.type !== 'complex') expr = defaultComplexExpression(expr);
      if (expr.terms.length) {
        // eslint-disable-next-line no-param-reassign, no-return-assign
        expr.terms.forEach((term) => (term.key += keyOffset));
        onChange(expr);
        setPrompt('');
      }
    }
  };

  const { generate } = useSuggestQuery(
    programId,
    useFilterCriteria(programId),
    {
      onSuccess,
      onError: () => {
        setIsLoading(false);
        setShowError(true);
      },
    }
  );

  const handleGenerateClick = () => {
    setShowError(false);
    setIsLoading(true);
    generate(prompt, baseQuery);
  };

  return (
    <div className={styles.criteriaTerm} id="AIHelperBox">
      <div className={styles.assistantHeader}>
        <div className={styles.assistantTitle}>AI Assistant</div>
        <div className={styles.assistantFeedback}>
          <CollectFeedbackRow type={FeedbackStyle.ButtonRow} />
        </div>
      </div>
      <div className={styles.promptTextboxContainer}>
        <Textarea
          className={styles.promptTextbox}
          placeholder="What type of audience do you want to create?"
          value={prompt}
          onChange={(v) => setPrompt(v.substr(0, 2500))}
        />
      </div>
      <div className={styles.AIHelperBottomRow}>
        {showError && <ErrorMessage onClose={() => setShowError(false)} />}
        {/* TODO: make it blue */}
        <Button
          className={styles.generateButton}
          label="Generate"
          disabled={isLoading}
          onClick={handleGenerateClick}
        />
        {isLoading && !showError && (
          <div className={styles.spinner}>
            <LoadingSpinner size="medium" />
          </div>
        )}
      </div>
    </div>
  );
};
