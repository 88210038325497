import * as React from 'react';
import { FAIcon } from 'shared/Icon/FAIcon';
import { Checkbox } from 'shared/Checkbox';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { DeliveryType } from 'models/delivery-type';
import styles from './engine-settings-editor.module.css';

export const EngineSetting: React.FC<{
  type: DeliveryType;
  onSelect: (value: DeliveryType) => void;
  disabled: boolean;
  description: string;
  engineOptions: { [key: string]: boolean };
}> = ({ type, onSelect, disabled, description, engineOptions, children }) => {
  const {
    settings: { deliveryType },
  } = useSettings();

  const capitilizedType = type.charAt(0).toUpperCase() + type.slice(1);
  const dataTest = `Engine-${capitilizedType}`;
  const id = `delivery-type-selector-${type}`;

  return (
    <div className={styles.itemWrapper} data-test={dataTest}>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          id={id}
          checked={deliveryType === type}
          type="radio"
          onSelect={() => onSelect(type)}
          name="delivery-type-selector"
          disabled={disabled}
        />
      </div>
      <div className={styles.ruleItemTitle}>
        <label htmlFor={id}>
          <h4>{capitilizedType}</h4>
          <div>{description}</div>
        </label>
        {children}
      </div>

      <div className={styles.ruleItemOptions}>
        {Object.entries(engineOptions).map(([optionType, isChecked]) => {
          return (
            <div>
              <FAIcon
                name={isChecked ? 'check-circle' : 'times-circle-o'}
                key={optionType}
              />{' '}
              {optionType}
            </div>
          );
        })}
      </div>
    </div>
  );
};
