import React, { useCallback } from 'react';
import cx from 'classnames';
import { Section } from 'shared/SectionBlocks';
import blockStyles from 'shared/SectionBlocks/SectionBlocks.module.css';
import { SVGIcon } from 'shared/Icon/SVGIcon';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Tooltip } from 'shared/Tooltip';
import { HoverDropdown } from 'shared/hover-dropdown/HoverDropdown';
import { RestrictedFields } from 'hooks/publisher/settings/restrictedFields';

import { PRIORITIES, PRIORITIES_BY_NAME, PriorityName } from 'models/priority';
import styles from '../settings-editor.module.css';

export const Priority: React.FC = () => {
  const { settings, update, contentPermissions } = useSettings();
  const { canEdit, restrictedFields } = contentPermissions;

  const disabled = !settings.audiences?.length || !canEdit;

  const setPriority = useCallback(
    (priorityName: PriorityName, priorityDisabled: boolean) => {
      if (!disabled && !priorityDisabled)
        update({
          priority: PRIORITIES_BY_NAME[priorityName],
        });
    },
    [update, disabled]
  );

  return (
    <Section title="Priority">
      <div className={cx(blockStyles.row, styles.prioritiesContainer)}>
        {PRIORITIES.map((priority) => {
          const priorityDisabled =
            priority.value === 'CRITICAL'
              ? disabled ||
                restrictedFields.includes(RestrictedFields.SET_CRITICAL)
              : disabled;
          return (
            <HoverDropdown
              closeDelay={0}
              openDelay={300}
              dropdownRenderProp={() =>
                (!priorityDisabled && (
                  <Tooltip
                    className={styles.hoverTooltip}
                    title={priority.label}
                    description={priority.description}
                  />
                )) ||
                null
              }
              key={priority.value}
            >
              <button
                type="button"
                disabled={priorityDisabled}
                className={cx(styles.priorityBlock, blockStyles.block, {
                  [blockStyles.selected]:
                    priority.value === settings.priority?.value,
                })}
                onClick={() => setPriority(priority.value, priorityDisabled)}
                value={priority.value}
              >
                <div className={blockStyles.priorityIcon}>
                  <SVGIcon name={priority.icon} />
                </div>
                <div>{priority.label}</div>
              </button>
            </HoverDropdown>
          );
        })}
      </div>
    </Section>
  );
};
