import { BoxContentExplorer } from '@socialchorus/box-components';
import { RouteComponentProps, useLocation } from '@reach/router';
import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { PageHeader, PageTemplate } from 'DesignSystem/Layout/Pages';
import { useBoxToken } from 'shared/Box/useBoxToken';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { WithPermission } from 'shared/WithPermission';
import styles from './styles.module.css';

export const BoxManageContent: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const { token, isLoading } = useBoxToken({
    resourceType: 'folder',
    resourceId: '0',
  });

  return (
    <WithPermission
      permissions={['configureBoxKnowledgeManagementAccess']}
      operation="all"
    >
      <PageTemplate title="Box Knowledge Management" noNavigation={false}>
        <div className={styles.ContentPage}>
          <PageHeader
            title="Box Knowledge Management"
            description="Create and manage Box folders. Link an audience to folders."
            tabs={[
              { to: 'box-manage-folders', label: 'Manage Folders' },
              { to: 'box-manage-content', label: 'Manage Box Content' },
            ]}
            breadcrumbs={[
              { to: '..', label: 'Configure' },
              { label: 'Box Knowledge Management' },
            ]}
          />
          {isLoading ? (
            <div className={styles.Loading}>
              <LoadingSpinner />
            </div>
          ) : (
            token && (
              <div className={styles.ContentExplorerContainer}>
                <MemoryRouter initialEntries={[location]} initialIndex={0}>
                  <BoxContentExplorer token={token} folderId="0" />
                </MemoryRouter>
              </div>
            )
          )}
        </div>
      </PageTemplate>
    </WithPermission>
  );
};
