import * as React from 'react';
import videojs from 'video.js';
import { Video, CaptionLocaleUrl } from 'models/video';
import cx from 'classnames';
import styles from './video-player.module.css';

type PropsType = {
  video: Video;
  id?: string;
};

/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const Player: React.FC<PropsType> = (props) => {
  const { video, id = 'video' } = props;

  React.useEffect(() => {
    const videoPlayer = videojs(id, {
      fluid: true,
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
        },
      },
    });
    const captions: Array<CaptionLocaleUrl> = video.captionUrls || [];

    // check if the browser is IE
    if (
      /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
      navigator.userAgent.indexOf('Trident/') > -1
    ) {
      videoPlayer.src({
        src: `${video.mp4Url}?${video.secureQueryParams}`,
        type: 'video/mp4',
      });
    } else {
      videoPlayer.src({
        src: `${video.url}?${video.secureQueryParams}`,
        type: 'application/x-mpegURL',
      });
    }

    // have every request pass the signature
    (videojs as any).Vhs.xhr.beforeRequest = (options: any) => {
      const params = video.secureQueryParams;
      const result = { ...options };
      if (options.uri.indexOf(params) < 0) {
        result.uri = `${options.uri}?${params}`;
      }
      return result;
    };

    videoPlayer.ready(() => {
      captions.forEach((caption) => {
        videoPlayer.addRemoteTextTrack(
          {
            kind: 'captions',
            language: caption.language,
            label: caption.label,
            src: `${caption.url}?${video.secureQueryParams}`,
          },
          false
        );
      });
    });

    // TODO:
    // This may not be necessary any more.  It may have been a bug in the particular version
    // of video.js we were using in bossanova.  It doesn't play nicely with typescript anyway.

    // this requires a bit of explanation --
    // the playlist index.m3u8 refers to a caption playlist caption-{}.vtt
    // after ready but before play, that playlist is built and added
    // however, it is always malformed, with cue times being NaN.
    // the file nor the videojs webvtt parser is at issue, something else is going on
    // this removes all caption data added through the playlist, relying on the fact that
    // they are of kind "subtitles"
    // instead, the captions manually added through the 'ready' callback will be used

    // videoPlayer.on('play', () => {
    //   const trackList = videoPlayer.remoteTextTracks();
    //   for (let i = 0; i < trackList.length; i++) {
    //     const track = trackList[i];
    //     if (track.kind === 'subtitles') {
    //       videoPlayer.removeRemoteTextTrack(track);
    //     }
    //   }
    // });
  }, [video, id]);

  return (
    <video
      id={id}
      className={cx('video-js', styles.player)}
      poster={video.customPreviewImageUrl || video.previewImageUrl}
      controls
    />
  );
};
