import * as React from 'react';
import { Tooltip } from 'DesignSystem/Components/Tooltip';
import styles from './hover-tooltip.module.css';
import { InfoSquareOutline } from '../icons';
import { DismissType } from '../hover-dropdown/HoverDropdown';

type HoverTooltipPropsType = {
  align: 'left' | 'right';
  content: React.ReactNode | ((dismiss: DismissType) => React.ReactNode);
  icon?: React.ReactNode;
  message?: string;
};

export const HoverTooltip: React.FC<HoverTooltipPropsType> = ({
  content,
  icon,
  message,
}) => {
  return (
    <div>
      <Tooltip
        offset={10}
        type="blank"
        hideDelay={0}
        showDelay={0}
        placement="bottom-end"
        content={typeof content === 'function' ? content(() => {}) : content}
      >
        <div className={styles.helpIcon}>
          {icon || <InfoSquareOutline />}
          {message ? ` ${message}` : null}
        </div>
      </Tooltip>
    </div>
  );
};
