import React, { useEffect, useMemo, useRef } from 'react';
import {
  axisBottom,
  axisLeft,
  AxisScale,
  interpolateSpectral,
  quantize,
  scaleBand,
  scaleLinear,
  ScaleOrdinal,
  scaleOrdinal,
  select,
  transition,
} from 'd3';
import { ChartDimensions, DEFAULT_ANIMATION_TIME } from './Util';
import { NoDataChart } from './NoDataChart';

export type BarData = { label?: string; value?: number };
export type BarChartDimensions = ChartDimensions & {
  bars: {
    padding: {
      inner: number;
      outer: number;
    };
  };
};

export type CustomDrawingFunction = (opts: {
  bars?: Array<BarData>;
  chartDimensions?: BarChartDimensions;
  scaleX?: AxisScale<string> | AxisScale<number>;
  scaleY?: AxisScale<string> | AxisScale<number>;
  colorScale?: ScaleOrdinal<string, unknown> | ScaleOrdinal<number, unknown>;
}) => React.ReactFragment;

interface IHorizontalBarChartBarChart {
  hideXAxis?: boolean;
  hideYAxis?: boolean;
  data: Array<BarData>;
  domainOverride?: [number, number];
  customColors?: ScaleOrdinal<string, unknown>;
  customDrawing?: CustomDrawingFunction;
  chartDimensions?: BarChartDimensions;
  units?: string;
}

export const HorizontalBarChart: React.FC<IHorizontalBarChartBarChart> = ({
  hideXAxis = false,
  hideYAxis = false,
  data,
  units,
  customColors,
  customDrawing,
  domainOverride,
  chartDimensions = {
    height: 480,
    width: 640,
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    bars: {
      padding: {
        inner: 0,
        outer: 0,
      },
    },
  },
}) => {
  const {
    padding,
    width,
    height,
    bars: { padding: barPadding },
  } = chartDimensions;

  const svgRef = useRef<SVGSVGElement>(null);
  const xAxis = useRef<SVGGElement>(null);
  const yAxis = useRef<SVGGElement>(null);

  const domain = useMemo(
    () => domainOverride ?? [0, Math.max(...data.map((d) => d?.value ?? 0), 1)],
    [data, domainOverride]
  );

  const x = useMemo(
    () =>
      scaleBand(
        data.map((d) => d?.label ?? ''),
        [padding.top, height - padding.bottom]
      )
        .paddingInner(barPadding.inner)
        .paddingOuter(barPadding.outer),
    [
      barPadding.inner,
      barPadding.outer,
      data,
      height,
      padding.bottom,
      padding.top,
    ]
  );
  const y = useMemo(
    () => scaleLinear(domain, [0, width - padding.left - padding.right]),
    [domain, padding.left, padding.right, width]
  );

  useEffect(() => {
    if (xAxis.current === null) {
      return;
    }
    select(xAxis.current).call(axisLeft(x));
  }, [xAxis, x]);

  useEffect(() => {
    if (yAxis.current === null) {
      return;
    }
    select(yAxis.current).call(axisBottom(y));
  }, [yAxis, y]);

  const colors =
    customColors ??
    scaleOrdinal()
      .domain(data.map((d) => d?.label ?? ''))
      .range(
        quantize(
          (t) => interpolateSpectral(t * 0.8 + 0.1),
          data.length
        ).reverse()
      );

  const drawCustom = useMemo(() => {
    return customDrawing
      ? customDrawing({
          bars: data,
          chartDimensions,
          scaleX: x,
          scaleY: y,
          colorScale: colors,
        })
      : undefined;
  }, [chartDimensions, colors, customDrawing, data, x, y]);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = select(svgRef.current);

    // Animate bars
    const bars = svg.selectAll('.bar');

    if (bars === null) return;
    bars.data(data).join(
      (enter) =>
        enter
          .append('rect')
          .attr('class', 'bar')
          .attr('x', padding.left)
          .attr('y', (d) => x(d.label ?? '') || 0)
          .attr('height', x.bandwidth())
          .attr('width', 0)
          .attr('fill', (d, i) => `${colors(i as never)}` || '#ABABAB')
          .attr('visibility', (d) =>
            d.label?.startsWith('placeholder') ? 'hidden' : 'visible'
          )
          .call((innerEnter) =>
            innerEnter
              .transition(transition().duration(DEFAULT_ANIMATION_TIME))
              .attr('width', (d) => y(d.value ?? 0) || 0)
          ),
      (update) =>
        update.call((innerUpdate) =>
          innerUpdate
            .transition(transition().duration(1000))
            .attr('y', (d) => x(d.label ?? '') || 0)
            .attr('width', (d) => y(d.value ?? 0) || 0)
            .attr('fill', (d, i) => `${colors(i as never)}` || '#ABABAB')
            .attr('visibility', (d) =>
              d.label?.startsWith('placeholder') ? 'hidden' : 'visible'
            )
        ),
      (exit) => exit.remove()
    );

    // Animate labels
    svg
      .selectAll('.bar-label')
      .data(data)
      .join(
        (enter) =>
          enter
            .append('text')
            .attr('class', 'bar-label')
            .attr('x', padding.left)
            .attr('y', (d) => (x(d.label ?? '') || 0) + x.bandwidth() / 2)
            .attr('text-anchor', 'start')
            .attr('dominant-baseline', 'middle')
            .attr('fontSize', 15)
            .attr('visibility', (d) =>
              d.label?.startsWith('placeholder') ? 'hidden' : 'visible'
            )
            .attr('fill', 'black')
            .text(
              (d) =>
                `${(d.value ?? 0).toLocaleString('en', {
                  maximumFractionDigits: 0,
                })} ${units}${d.value !== 1 ? 's' : ''}`
            )
            .call((innerEnter) =>
              innerEnter
                .transition(transition().duration(DEFAULT_ANIMATION_TIME))
                .attr('x', (d) => padding.left + (y(d.value ?? 0) || 0) + 15)
            ),
        (update) =>
          update.call((innerUpdate) =>
            innerUpdate
              .transition(transition().duration(1000))
              .attr('y', (d) => (x(d.label ?? '') || 0) + x.bandwidth() / 2)
              .attr('x', (d) => padding.left + (y(d.value ?? 0) || 0) + 15)
              .text(
                (d) =>
                  `${(d.value ?? 0).toLocaleString('en', {
                    maximumFractionDigits: 0,
                  })} ${units}${d.value !== 1 ? 's' : ''}`
              )
              .attr('visibility', (d) =>
                d.label?.startsWith('placeholder') ? 'hidden' : 'visible'
              )
          ),
        (exit) => exit.remove()
      );
  }, [data, x, y, colors, padding.left, units]);

  return data === undefined ? (
    <NoDataChart chartDimensions={chartDimensions} />
  ) : (
    <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`}>
      {!hideXAxis && (
        <g ref={xAxis} transform={`translate(${padding.left},0)`} />
      )}
      {!hideYAxis && (
        <g ref={yAxis} transform={`translate(0,${height - padding.bottom})`} />
      )}
      {drawCustom}
    </svg>
  );
};
