import { AboutPage } from 'models/about-page';
import { ConflictError } from 'services/Errors/ConflictError';
import { FormState, SidebarName, TabName } from './TopicForm/context';

export type TopicErrorGroup = {
  readonly screen: string;
  readonly tab?: TabName;
  readonly name: TopicErrorGroupName;
};
export interface TopicErrorDefinitionRuleArgs {
  formState: FormState['fields'];
  publishError?: Error;
  aboutPage?: AboutPage;
}
export interface TopicErrorDefinition {
  readonly name: TopicErrorDefinitionName;
  readonly text: string;
  readonly rule: (args: TopicErrorDefinitionRuleArgs) => boolean;
  readonly group: TopicErrorGroup;
  readonly sidebar: SidebarName | null;
}

export type TopicErrorDefinitionName =
  | 'Auto-Follow Not Applicable'
  | 'Audience Not Applicable'
  | 'Missing Auto-follow Audience'
  | 'Missing Targeted Audience'
  | 'Missing Shortcuts'
  | 'Missing Preview'
  | 'Missing Content'
  | 'Duplicate Name'
  | 'Duplicate Shortcut Name';

export type TopicErrorGroupName =
  | 'Settings'
  | 'Design'
  | 'Design - Shortcuts'
  | 'Design - About';

const settingsGroup = Object.freeze<TopicErrorGroup>({
  name: 'Settings',
  screen: 'settings',
});
const designGroup = Object.freeze<TopicErrorGroup>({
  name: 'Design',
  screen: 'design',
});
const designShortcutsGroup = Object.freeze<TopicErrorGroup>({
  name: 'Design - Shortcuts',
  screen: 'design',
  tab: 'shortcuts',
});
const designAboutGroup = Object.freeze<TopicErrorGroup>({
  name: 'Design - About',
  screen: 'design',
  tab: 'about',
});

export const autoFollowNotApplicable = Object.freeze<TopicErrorDefinition>({
  name: 'Auto-Follow Not Applicable',
  text: 'Auto-follow enabled but Show in Experience is disabled',
  rule: ({ formState }) => formState.autoFollow && !formState.published,
  group: settingsGroup,
  sidebar: null,
});

export const audienceNotApplicable = Object.freeze<TopicErrorDefinition>({
  name: 'Audience Not Applicable',
  text: 'Recommended Topics cannot have a targeted audience',
  rule: ({ formState }) =>
    formState.published && formState.isPromoted && formState.targeted,
  group: settingsGroup,
  sidebar: null,
});

export const missingAutoFollowAudience = Object.freeze<TopicErrorDefinition>({
  name: 'Missing Auto-follow Audience',
  text: 'No auto-follow audience selected',
  rule: ({ formState }) =>
    formState.autoFollow &&
    formState.autoFollowCriterion &&
    formState.autoFollowAudience.length === 0,
  group: settingsGroup,
  sidebar: null,
});

export const missingTargetedAudience = Object.freeze<TopicErrorDefinition>({
  name: 'Missing Targeted Audience',
  text: 'No targeted audience selected',
  rule: ({ formState }) =>
    formState.targeted && formState.audience.length === 0,
  group: settingsGroup,
  sidebar: null,
});

export const missingShortcuts = Object.freeze<TopicErrorDefinition>({
  name: 'Missing Shortcuts',
  text: 'Add at least one Shortcut',
  rule: ({ formState }) =>
    formState.enabledTabs.includes('shortcuts') &&
    formState.shortcuts.length === 0,
  group: designShortcutsGroup,
  sidebar: null,
});

export const missingPreview = Object.freeze<TopicErrorDefinition>({
  name: 'Missing Preview',
  text: 'Preview required',
  rule: ({ formState, aboutPage }) =>
    formState.enabledTabs.includes('about') &&
    aboutPage?.previewContent.length === 0,
  group: designAboutGroup,
  sidebar: 'about',
});

export const missingContent = Object.freeze<TopicErrorDefinition>({
  name: 'Missing Content',
  text: 'Preview required',
  rule: ({ formState, aboutPage }) =>
    formState.enabledTabs.includes('about') &&
    aboutPage?.pageContent.length === 0,
  group: designAboutGroup,
  sidebar: null,
});

export const duplicateName = Object.freeze<TopicErrorDefinition>({
  name: 'Duplicate Name',
  text: 'The name of this topic is already in use',
  rule: ({ publishError }) => publishError instanceof ConflictError,
  group: designGroup,
  sidebar: 'editHeader',
});

export const duplicateShortcutName = Object.freeze<TopicErrorDefinition>({
  name: 'Duplicate Shortcut Name',
  text: 'Each shortcut must have a unique name',
  rule: ({ formState }) =>
    formState.shortcuts.some(
      (shortcut, index) =>
        formState.shortcuts.findIndex((s) => s.name === shortcut.name) !== index
    ),
  group: designShortcutsGroup,
  sidebar: 'shortcuts',
});

export const topicErrorDefinitions = Object.freeze([
  autoFollowNotApplicable,
  audienceNotApplicable,
  missingAutoFollowAudience,
  missingTargetedAudience,
  missingShortcuts,
  missingPreview,
  missingContent,
  duplicateName,
  duplicateShortcutName,
]);
