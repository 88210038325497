import React from 'react';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { useProgram } from 'contexts/program';
import { usePublisher } from 'contexts/publisher';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body } from 'DesignSystem/Typography';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { blockHasPersonalizationVariables } from 'models/publisher/block';
import { PreviewAs } from 'models/variable-previews';
import { Checkbox } from 'shared/Checkbox';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';

export const VariablePreviewOptions: React.FC = () => {
  const { previewAs, setPreviewAs } = React.useContext(PreviewContext);

  const { data: variablesFlag } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.LiquidVariables'
  );

  const publisher = usePublisher();

  const showVariablesPreview = React.useMemo<boolean>(() => {
    return (
      !!variablesFlag?.value &&
      publisher.post.blocks.some(blockHasPersonalizationVariables)
    );
  }, [publisher.post.blocks, variablesFlag?.value]);

  const onRadioChange = React.useCallback(
    (value: PreviewAs) => (checked: boolean) => {
      setPreviewAs(checked ? value : undefined);
    },
    [setPreviewAs]
  );

  if (!showVariablesPreview) return null;

  return (
    <>
      <Flex start>
        <Body uppercase bold block>
          Variable preview
        </Body>
        <HoverTooltip
          align="left"
          content={
            <Tooltip
              title="What are default values?"
              description="Default values are displayed when a user does not
                have a value stored for the selected variable or when viewing
                content when not logged in, such as in a public Microapp."
            />
          }
        />
      </Flex>
      <label htmlFor="preview_variables_self">
        <Flex start style={{ marginBottom: '8px' }}>
          <Checkbox
            id="preview_variables_self"
            type="radio"
            style={{ marginRight: '12px' }}
            checked={previewAs === 'self'}
            onChange={onRadioChange('self')}
          />
          Preview as yourself
        </Flex>
      </label>
      <label htmlFor="preview_variables_default">
        <Flex start style={{ marginBottom: '8px' }}>
          <Checkbox
            id="preview_variables_default"
            type="radio"
            style={{ marginRight: '12px' }}
            checked={previewAs === 'anonymous'}
            onChange={onRadioChange('anonymous')}
          />
          Preview with defaults
        </Flex>
      </label>
    </>
  );
};
