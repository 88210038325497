import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Select, { components, ValueType } from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';
import DateRangeInput from 'shared/DateRangeInput';
import { AbsoluteRange, DateRange } from 'shared/DateRangeInput/DateRange';
import { selectComponents, selectStyles } from '../Select';
import styles from './DateSelect.module.css';

export const DateRangeSelect: React.FC<{
  onChange: (value?: string) => void;
  value?: string;
}> = ({ onChange, value }) => {
  const DATE_FORMAT = 'yyyy-MM-dd';
  const parseValue = (val: string) => {
    const str = val.replace('[', '').replace(']', '');
    const parts = str.split('TO');
    if (parts.length < 2) return AbsoluteRange.buildFromKey('lastweek');

    const date1 = DateTime.fromFormat(parts[0].trim(), DATE_FORMAT);
    const date2 = DateTime.fromFormat(parts[1].trim(), DATE_FORMAT);
    return new AbsoluteRange(date1, date2, true);
  };

  const initialValue = value
    ? parseValue(value)
    : DateRange.buildFromKey('lastweek');
  const [selectValue, setSelectValue] = useState<
    ValueType<{ label: string; value: string }>
  >();
  const [dateRange, setDateRange] = useState<DateRange>(initialValue);
  useEffect(() => {
    if (value) {
      const range = `[${dateRange
        .toAbsolute()
        .start.toFormat('yyyy-MM-dd')} TO ${dateRange
        .toAbsolute()
        .end.toFormat('yyyy-MM-dd')}]`;
      setSelectValue({
        label: range,
        value: range,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /** Control the open/close state of the modal. */
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  /** Respond to DoneButton click */
  const onDone = (e: DateRange) => {
    const range = `[${e
      .toAbsolute()
      .start.toFormat('yyyy-MM-dd')} TO ${e
      .toAbsolute()
      .end.toFormat('yyyy-MM-dd')}]`;
    onChange(range);
    setDateRange(e);
    setSelectValue({ value: range, label: range });

    /** set to false to close Modal */
    setMenuIsOpen(() => false);
  };

  const Menu: React.FC<MenuProps<{ value: string; label: string }>> = (
    props
  ) => {
    return (
      <div>
        <div className={styles.offsetSpacerRange} />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <components.Menu {...props} className={styles.rangeMenuWrapper}>
          <DateRangeInput
            onDone={onDone}
            initialValue={dateRange}
            allowFutureSelection
            shouldUseTimezone
          />
        </components.Menu>
      </div>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select
      value={selectValue}
      options={[]}
      components={{ ...selectComponents, Menu }}
      styles={selectStyles}
      menuIsOpen={menuIsOpen}
      onMenuClose={() => setMenuIsOpen(() => false)}
      onMenuOpen={() => setMenuIsOpen(() => true)}
    />
  );
};
