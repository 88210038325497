import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { EmailSenderAlias } from 'models/publisher/settings';
import { useInfiniteApiQuery } from 'hooks/common';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import {
  ApiContactAddress,
  fetchContactAddresses,
} from 'services/api-contact-address';
import { Field } from 'shared/Fieldset';
import { RadioSelectField } from 'shared/forms/RadioSelectField';
import { Icon } from 'shared/Icon';
import { ClickDropdown } from 'shared/ClickDropdown';
import settingsEditorStyles from 'components/publisher/settings/SettingsEditor/settings-editor.module.css';
import styles from './administration-select.module.css';

type OptionType = {
  id: string;
  label: string;
  value: EmailSenderAlias;
  defaultSender: boolean;
};

function buildOptions(data: ApiContactAddress[]): OptionType[] {
  return data.map(({ id, senderEmail, senderName, name, defaultSender }) => ({
    id,
    label: defaultSender
      ? `Default - ${name} <${senderEmail}>`
      : `${name} <${senderEmail}>`,
    value: { id, senderEmail, senderName },
    defaultSender,
  }));
}

export const EmailSenderAliasField: React.FC<{
  value?: EmailSenderAlias;
  onChange: (newValue?: EmailSenderAlias) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled = false }) => {
  const { id: programId } = useProgram();
  const {
    permissions: { emailSenderAliasAccess },
  } = usePermissions();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteApiQuery('contact-addresses', fetchContactAddresses, {
    programId,
    status: 'active',
    pageSize: 100,
    perPage: 100,
  });

  const options = buildOptions(data || []);
  const rowIds = options.map(({ id }) => id);

  const defaultSender = useMemo(
    () => options.find((option) => option.defaultSender),
    [options]
  );

  const onSelectChange = useCallback(
    (selectedId: string) => {
      const sender =
        options.find(({ id }) => id === selectedId) ?? defaultSender;
      onChange((sender as OptionType).value);
    },
    [onChange, options, defaultSender]
  );

  const renderSelectRow = useCallback(
    (rowId: string) => {
      const a = options.find(({ id }) => id === rowId);
      if (!a) return null;

      return (
        <div className={styles.dropdownFilterItem}>
          <span>{a.label}</span>
        </div>
      );
    },
    [options]
  );

  const dropdown = (
    <div className={cx(styles.dropdown, styles.senderDropdown)}>
      <RadioSelectField
        rowIds={rowIds}
        rowRenderProp={renderSelectRow}
        maxHeight={500}
        itemHeight={25}
        selectedId={(value && String(value.id)) || defaultSender?.id || ''}
        onSelectedIdChange={onSelectChange}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        overscan={10}
      />
    </div>
  );

  const displayedSender = useMemo(() => {
    if (value) {
      return value.senderEmail;
    }
    if (defaultSender) {
      return defaultSender.value.senderEmail;
    }
    if (!defaultSender && options.length > 0) {
      return 'Select an email alias from the list';
    }
    return 'No email sender aliases to choose from';
  }, [options, value, defaultSender]);

  const blockDisabled = disabled || !emailSenderAliasAccess;
  return (
    <div data-test="Section-EmailSender">
      <Field
        label="Email sender alias"
        className={cx({
          [settingsEditorStyles.disabledSetting]: blockDisabled,
        })}
      >
        <div className={styles.selectorContainer}>
          <ClickDropdown dropdownRenderProp={dropdown} disabled={blockDisabled}>
            <button type="button" className={styles.button}>
              <div className={styles.selectedItem}>
                <div className={styles.displayName}>
                  {isLoading ? 'Loading...' : displayedSender}
                </div>
              </div>
              <Icon iconName="ExpandList" iconType="SVG" />
            </button>
          </ClickDropdown>
        </div>
      </Field>
    </div>
  );
};
