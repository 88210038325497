import React from 'react';
import cx from 'classnames';
import { BlocksEditorContext } from 'contexts/publisher/compose/blocks';
import { useStyleEditor } from 'contexts/publisher/compose/style';
import { StyleOptions, supportsLinkStyling } from 'models/publisher/block';
import { deepMerge } from 'utility/deep-merge';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import styles from './styles.module.css';
import { ColorPicker, FontPicker, SizeSlider } from './Pickers';

const checkAvailability = (
  values: string[],
  options: StyleOptions[keyof StyleOptions] = []
) => options.some((option: string) => values.includes(option));

const textFields = [
  'body',
  'buttonFg',
  'button',
  'caption',
  'headlines',
  'link',
  'subhead',
  'subtitle',
  'title',
];
const colorFields = ['buttonBg', 'highlights'];
const otherFields = ['radiuses', 'buttonRadiuses', 'margins', 'spacing'];

interface GlobalTabProps {
  disabled?: boolean;
}

export const GlobalTab: React.FC<GlobalTabProps> = (props) => {
  const { disabled } = props;
  const newEditor = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  ).data?.value;

  const editor = useStyleEditor();
  const { instances } = React.useContext(BlocksEditorContext);
  const blocks = React.useMemo(
    () => instances.map((instance) => instance.block),
    [instances]
  );

  const allStyleOptions = React.useMemo(() => {
    let cumulative: StyleOptions = {};
    blocks.forEach((block) => {
      cumulative = deepMerge(cumulative, block.style_options);
    });
    return cumulative;
  }, [blocks]);

  const showFontColors = React.useMemo(
    () => checkAvailability(textFields, allStyleOptions.colors),
    [allStyleOptions]
  );

  const showMoreColors = React.useMemo(
    () => checkAvailability(colorFields, allStyleOptions.colors),
    [allStyleOptions]
  );

  const showFontSizes = React.useMemo(
    () => checkAvailability(textFields, allStyleOptions.sizes),
    [allStyleOptions]
  );

  const showFontFaces = React.useMemo(
    () => checkAvailability(textFields, allStyleOptions.fonts),
    [allStyleOptions]
  );

  const showOther = React.useMemo(
    () => checkAvailability(otherFields, allStyleOptions.sizes),
    [allStyleOptions]
  );

  const linkBlocksPresence = React.useMemo(
    () => blocks.some((block) => supportsLinkStyling(block)),
    [blocks]
  );

  return (
    <>
      <div className={cx(styles.styles, styles.sidebarPadding)}>
        <fieldset
          className={newEditor ? styles.mediumFieldsetPadding : undefined}
        >
          <legend className={styles.fieldsetLegend}>Background color</legend>
          <ColorPicker
            availableStyles={{ colors: ['page'] }}
            field="page"
            name="Background"
            editor={editor}
            disabled={disabled}
          />
          <ColorPicker
            availableStyles={{ colors: ['canvas'] }}
            field="canvas"
            editor={editor}
            disabled={disabled}
          />
        </fieldset>
        {showFontColors && (
          <fieldset
            className={newEditor ? styles.mediumFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Font colors</legend>
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="headlines"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="subhead"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="title"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="subtitle"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="body"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="caption"
              disabled={disabled}
            />
            <ColorPicker
              availableStyles={allStyleOptions}
              field="buttonFg"
              name="Button text"
              editor={editor}
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="link"
              disabled={disabled}
            />
          </fieldset>
        )}
        {showMoreColors && (
          <fieldset
            className={newEditor ? styles.mediumFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>More colors</legend>
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="highlights"
              disabled={disabled}
            />
            <ColorPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="buttonBg"
              name="Button background"
              disabled={disabled}
            />
          </fieldset>
        )}
        {showFontSizes && (
          <fieldset
            className={newEditor ? styles.mediumFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Font Sizes</legend>
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="headlines"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="subhead"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="title"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="subtitle"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="body"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="caption"
              min={6}
              disabled={disabled}
            />
            <SizeSlider
              editor={editor}
              availableStyles={allStyleOptions}
              field="button"
              min={6}
              disabled={disabled}
            />
            {linkBlocksPresence && (
              <SizeSlider
                editor={editor}
                availableStyles={allStyleOptions}
                field="link"
                min={6}
                disabled={disabled}
              />
            )}
          </fieldset>
        )}
        {showFontFaces && (
          <fieldset
            className={newEditor ? styles.mediumFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Font Face</legend>
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="headlines"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="subhead"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="title"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="subtitle"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="body"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="caption"
              disabled={disabled}
            />
            <FontPicker
              editor={editor}
              availableStyles={allStyleOptions}
              field="button"
              disabled={disabled}
            />
            {linkBlocksPresence && (
              <FontPicker
                editor={editor}
                availableStyles={allStyleOptions}
                field="link"
                disabled={disabled}
              />
            )}
          </fieldset>
        )}
        {showOther && (
          <fieldset
            className={newEditor ? styles.mediumFieldsetPadding : undefined}
          >
            <legend className={styles.fieldsetLegend}>Other</legend>
            <SizeSlider
              availableStyles={allStyleOptions}
              field="radiuses"
              name="Round image corners"
              editor={editor}
              min={0}
              max={300}
              disabled={disabled}
            />
            <SizeSlider
              availableStyles={allStyleOptions}
              field="buttonRadiuses"
              name="Button rounding"
              editor={editor}
              min={0}
              max={100}
              disabled={disabled}
            />
            <SizeSlider
              availableStyles={allStyleOptions}
              field="margins"
              name="Page margins"
              editor={editor}
              disabled={disabled}
            />
          </fieldset>
        )}
      </div>
    </>
  );
};
