import React from 'react';
import { useMutation } from 'react-query';
import {
  UploadCaptionOptions,
  uploadCaptions,
  removeCaptions as removeCaptionsApi,
  RemoveCaptionsOptions,
} from 'services/api-assets';
import { FetchedCaption, useCaptions } from 'hooks/video';
import { Video } from 'models/video';
import { useProgram } from 'contexts/program';
import { useDesignContext } from 'contexts/design';
import { VideoFieldData } from 'models/donkey';
import { videoToField } from 'models/publisher/block';
import { FieldFormProps } from '../../../useFieldForm';
import { useFetchVideoFromFeed } from './useFetchVideoFromFeed';
import { CaptionSettings } from '../components/VideoCaptions/CaptionSettings';

export const useVideoCaption: ({
  fieldData,
  onChange,
  onCaptionUpdated,
}: {
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  onCaptionUpdated: () => void;
}) => {
  fetchedCaptions: FetchedCaption[];
  captionsError: string | undefined;
  onListItemEdit: (index: number) => void;
  onListItemRemove: (index: number) => void;
  openSettings: () => void;
  isDisabled: boolean;
  CaptionSettings: JSX.Element | null;
} = ({ fieldData, onChange, onCaptionUpdated }) => {
  const { id: programId } = useProgram();
  const { active: isDesignAsset } = useDesignContext();

  const { videoFromFeed } = useFetchVideoFromFeed(
    fieldData.video_id,
    isDesignAsset
  );

  const handleCaptionUpdated = React.useCallback(
    (updatedVideo: Video) => {
      if (videoFromFeed) {
        videoFromFeed.status = updatedVideo.status;
      }
      onChange(videoToField(updatedVideo));
      onCaptionUpdated();
    },
    [onCaptionUpdated, onChange, videoFromFeed]
  );

  const {
    mutate: addCaptions,
    isLoading: isCaptionsUploading,
    error: captionsError,
  } = useMutation<Video, Error, UploadCaptionOptions>(uploadCaptions, {
    onSuccess: handleCaptionUpdated,
  });

  const { mutate: removeCaptions } = useMutation<
    Video,
    Error,
    RemoveCaptionsOptions
  >(removeCaptionsApi, {
    onSuccess: handleCaptionUpdated,
  });

  const onCaptionSelect = React.useCallback(
    (file: File) => {
      if (!videoFromFeed) {
        return;
      }
      addCaptions({
        videoId: videoFromFeed.id,
        programId,
        isDesignAsset,
        file,
      });
    },
    [addCaptions, isDesignAsset, programId, videoFromFeed]
  );

  const captionQueries = useCaptions(videoFromFeed);
  const fetchedCaptions = React.useMemo(() => {
    const memo: FetchedCaption[] = [];

    captionQueries.forEach((query) => {
      if (query.data) memo.push(query.data);
    });

    return memo;
  }, [captionQueries]);

  const removeCaptionsFn = React.useCallback(
    (url) => {
      if (!videoFromFeed) return;
      removeCaptions({
        programId,
        isDesignAsset,
        videoId: videoFromFeed.id,
        url,
      });
    },
    [isDesignAsset, programId, removeCaptions, videoFromFeed]
  );

  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  const [selectedCaption, setSelectedCaption] = React.useState<
    FetchedCaption | undefined
  >(undefined);

  const onListItemEdit = React.useCallback(
    (index: number) => {
      setSelectedCaption(fetchedCaptions[index]);
      setShowSettings(true);
    },
    [fetchedCaptions]
  );

  const onListItemRemove = React.useCallback(
    (index: number) => {
      const { url } = fetchedCaptions[index];
      removeCaptionsFn(url);
      fetchedCaptions.splice(index, 1);
    },
    [fetchedCaptions, removeCaptionsFn]
  );

  const isDisabled = videoFromFeed?.sourceType !== 'admin_created';

  const openSettings = React.useCallback(() => {
    // Captions cannot be applied to external videos

    if (isDisabled || showSettings) return;
    setShowSettings(true);
  }, [isDisabled, showSettings]);

  const closeSettings = React.useCallback(() => {
    setShowSettings(false);
    setSelectedCaption(undefined);
  }, []);

  const Component = React.useMemo(
    () =>
      showSettings ? (
        <CaptionSettings
          onClose={closeSettings}
          onCaptionSelect={onCaptionSelect}
          onCaptionRemove={removeCaptionsFn}
          isCaptionsUploading={isCaptionsUploading}
          currentCaption={selectedCaption}
        />
      ) : null,
    [
      closeSettings,
      isCaptionsUploading,
      onCaptionSelect,
      removeCaptionsFn,
      selectedCaption,
      showSettings,
    ]
  );

  return {
    fetchedCaptions,
    captionsError: captionsError?.message,
    onListItemEdit,
    onListItemRemove,
    openSettings,
    CaptionSettings: Component,
    isDisabled,
  };
};
