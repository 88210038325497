import snakecaseKeys from 'snakecase-keys';
import { deepCamelcaseKeys } from '../api-shared';

interface Model {
  id?: unknown;
  [key: string]: unknown;
}

function transformToJsonApiObject(
  model: Model,
  type?: string
): Record<string, unknown> {
  return {
    id: model.id,
    type,
    attributes: snakecaseKeys(model),
  };
}

export function toJsonApiObject(
  model: Model | Model[],
  type?: string
): Record<string, unknown> {
  if (Array.isArray(model)) {
    return {
      data: model.map((item) => transformToJsonApiObject(item, type)),
    };
  }

  return {
    data: transformToJsonApiObject(model, type),
  };
}

function transformFromJsonApiObject<T>(
  obj: unknown,
  transformKeys = deepCamelcaseKeys
): T {
  if (!obj || !('attributes' in obj)) {
    throw new Error('fromJsonApiObject: obj does not have attributes');
  }

  const apiObj = obj as { attributes: Record<string, unknown>; id?: unknown };

  return transformKeys({
    ...apiObj.attributes,
    id: apiObj.id,
  }) as T;
}

export function fromJsonApiObject<T>(
  obj: unknown,
  transformKeys = deepCamelcaseKeys
): T {
  if (!obj) {
    throw new Error('fromJsonApiObject: obj is null or undefined');
  }

  if (!('data' in obj)) {
    throw new Error('fromJsonApiObject: obj does not have data key');
  }

  const apiObj = obj as { data: unknown };

  if (Array.isArray(apiObj.data)) {
    return apiObj.data.map((item) =>
      transformFromJsonApiObject(item, transformKeys)
    ) as T;
  }

  return transformFromJsonApiObject(apiObj.data, transformKeys);
}
