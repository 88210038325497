import * as React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import styles from 'components/publisher/theme/compose.module.css';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import { useOneOf } from 'hooks/useOneOf';
import { Canvas } from 'components/publisher/blocks/Canvas';
import { Blocks } from './Blocks';
import { PersonalizedFields } from './PersonalizedFields';
import { Tabs } from './Tabs';
import { GlobalSidebar } from './GlobalSidebar';
import { CanvasBlockPanel, CanvasFieldsPanel } from './CanvasPanels';
import { SelectedSidebar } from './SelectedSidebar';

type DesignProps = RouteComponentProps & {
  implementation: 'old' | 'new';
};

export const Design: React.FC<DesignProps> = ({ implementation }) => {
  const { canEdit } = React.useContext(DesignPermissionContext);
  const tab = useOneOf({
    names: ['global', 'selected'],
    initial: 'global',
  });

  return (
    <>
      <Helmet>
        <title>Style</title>
      </Helmet>
      <div className={styles.container}>
        {implementation === 'old' && canEdit && (
          <OldEditorSidebar>
            <Tabs tab={tab} />
          </OldEditorSidebar>
        )}
        <Canvas />
        {implementation === 'new' && canEdit && (
          <>
            <GlobalSidebar />
            <CanvasBlockPanel>
              <Blocks />
            </CanvasBlockPanel>
            <CanvasFieldsPanel>
              <PersonalizedFields />
            </CanvasFieldsPanel>
            <SelectedSidebar />
          </>
        )}
      </div>
    </>
  );
};

const OldEditorSidebar: React.FC = ({ children }) => (
  <div className={cx(styles.sidebar, styles.styleSidebar)}>{children}</div>
);
