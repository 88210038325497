import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ContentOrPollBanner } from 'components/content/ContentBanner';
import { useBulkSelect } from 'contexts/bulk-select';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useContentsInfiniteQuery } from 'hooks/content';
import { useReachByContentQueries } from 'hooks/reach';
import { PinnableContent } from 'models/topic';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { Content, isContent } from 'models/content';
import { Post } from '../types';
import styles from '../posts.module.css';

type PostsFinderViewProps = Pick<
  ReturnType<typeof useContentsInfiniteQuery>,
  'isLoading' | 'isFetchingNextPage' | 'fetchNextPage' | 'hasNextPage'
> & {
  content: Post[];
  reachByContent: ReturnType<typeof useReachByContentQueries>;
  onSelected: (selected: PinnableContent[]) => void;
  height: number;
};

export function PostsFinderView({
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  content,
  reachByContent,
  onSelected,
  height: containerHeight,
}: PostsFinderViewProps): ReactElement {
  const { selectedIds } = useBulkSelect();

  const parentRef = useRef<HTMLDivElement>(null);
  const [selectedContent, setSelectedContent] = useState<
    Record<string, PinnableContent>
  >({});

  useEffect(() => {
    function getContentRowId(id: number) {
      return `content-${id}`;
    }

    const selectedIdsSet = new Set(selectedIds);
    const fetchedSelectedContent = content.filter(
      (item): item is Content =>
        selectedIdsSet.has(getContentRowId(item.id)) && isContent(item)
    );

    // Update selected content if they are fetched and remove contents that are no longer selected
    setSelectedContent((prev) => ({
      ...Object.fromEntries(
        Object.entries(prev).filter(([id]) => selectedIdsSet.has(id))
      ),
      ...Object.fromEntries(
        fetchedSelectedContent.map((item) => [getContentRowId(item.id), item])
      ),
    }));
  }, [content, selectedIds]);

  useEffect(() => {
    onSelected(Object.values(selectedContent));
  }, [onSelected, selectedContent]);

  return (
    <InfiniteContainer
      parentRef={parentRef}
      style={{
        height: containerHeight,
        maxWidth: '100%',
      }}
    >
      {(height) => (
        <div
          style={{
            marginTop: 40,
          }}
        >
          <ListHeader />
          <InfiniteBannerList
            isLoading={isLoading}
            itemCount={content.length}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            height={height}
            parentRef={parentRef}
          >
            {(index: number) => (
              <ContentOrPollBanner
                item={content[index]}
                reachByContent={reachByContent}
                disableCommentsLinks
                disableContentActions
              />
            )}
          </InfiniteBannerList>
        </div>
      )}
    </InfiniteContainer>
  );
}

function ListHeader(): ReactElement {
  const { selectedCount } = useBulkSelect();

  return (
    <div className={styles.postFinderHeader}>
      <span>{selectedCount} selected</span>
    </div>
  );
}
