import React from 'react';
import { usePortal } from 'hooks/usePortal';
import { createPortal } from 'react-dom';
import { TemplateSelector } from 'shared/TemplateSelector';
import { Sidebar } from 'shared/TemplateSelector/Sidebar';
import { MainEditor } from 'shared/layouts/MainEditor/MainEditor';
import { NavigationFooter } from 'shared/NavigationFooter';
import { SplitFooter } from 'App/Program/Editors/Publisher/Footer/SplitFooter';
import { Footer } from 'App/Program/Editors/Publisher/Footer';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { titleCase } from 'utility/text';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import styles from './templates-modal.module.css';

export const TemplatesModal: React.FC<{ hide: () => void }> = ({ hide }) => {
  const portal = usePortal('campaign-templates');

  const { id: programId } = useProgram();
  const { PAGES } = useProductVocabulary();

  const { data: showStudioOrchestrateNewPages } = useFeatureFlagsQuery(
    programId,
    'Studio.Orchestrate.NewPages'
  );

  const { data: showStudioPublishNewEditors } = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.NewEditors'
  );

  let FooterElement;
  if (showStudioOrchestrateNewPages && showStudioPublishNewEditors) {
    // Wait until we know which footer to render
    FooterElement = NavigationFooter;
    if (showStudioOrchestrateNewPages.value) FooterElement = Footer;
    if (showStudioPublishNewEditors.value) FooterElement = SplitFooter;
  }

  const additionalProps = showStudioOrchestrateNewPages?.value
    ? { disableSchedule: true, canPerformAction: false }
    : [];

  const footer = FooterElement && (
    <FooterElement
      leftMenuItems={{}}
      links={[
        {
          disabled: true,
          href: '#compose',
          name: titleCase(
            showStudioOrchestrateNewPages?.value
              ? PAGES.PUBLISHER.DESIGN
              : PAGES.PUBLISHER.COMPOSE
          ),
        },
        {
          disabled: true,
          href: '#deliver',
          name: titleCase(
            showStudioOrchestrateNewPages?.value
              ? PAGES.PUBLISHER.DELIVER
              : PAGES.PUBLISHER.ORCHESTRATE
          ),
        },
        {
          disabled: true,
          href: '#review',
          name: titleCase(PAGES.PUBLISHER.REVIEW),
        },
      ]}
      canPerformAction
      exitHandler={hide}
      hideMenu
      title="Untitled"
      action={() => {}}
      actionName="Choose Template"
      actionClassName={styles.chooseButton}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...additionalProps}
    />
  );

  const mainWrapperRef = React.useRef<HTMLDivElement>(null);

  return createPortal(
    <div className={styles.modal}>
      <MainEditor
        splitBars={!!showStudioPublishNewEditors?.value}
        header={
          showStudioPublishNewEditors?.value ? (
            <></>
          ) : (
            <h1 className="page-header">Create...</h1>
          )
        }
        main={<TemplateSelector parentRef={mainWrapperRef} />}
        sidebar={<Sidebar />}
        footer={footer}
        mainWrapperRef={mainWrapperRef}
      />
    </div>,
    portal.target
  );
};
