import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { useBoxFoldersInfiniteQuery } from 'hooks/box-knowledge-mangement';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { BoxKMBanner } from './BoxFolderBanner';
import styles from './box.km.module.css';

export const BoxManageFoldersList: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const infiniteQuery = useBoxFoldersInfiniteQuery({
    programId,
  });

  return (
    <WithPermission
      permissions={['configureBoxKnowledgeManagementAccess']}
      operation="all"
    >
      <div className={styles.List}>
        <ListPage
          title="Box Knowledge Management"
          description="Create and manage Box folders. Link an audience to folders."
          tabs={[
            { to: 'box-manage-folders', label: 'Manage Folders' },
            { to: 'box-manage-content', label: 'Manage Box Content' },
          ]}
          breadcrumbs={[
            { to: '..', label: 'Configure' },
            { label: 'Box Knowledge Management' },
          ]}
          infiniteQuery={infiniteQuery}
          actions={[
            {
              icon: <Icon name="Plus" fill="currentColor" />,
              label: 'Folder',
              to: './folders/new',
            },
          ]}
          emptyList={
            <div>
              No folders yet. When you create a folder, you will see it here.
            </div>
          }
          renderRow={(item, index) => (
            <BoxKMBanner boxFolderData={item} index={index} />
          )}
        />
      </div>
    </WithPermission>
  );
};
