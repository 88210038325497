import React from 'react';
import { Checkbox } from 'shared/Checkbox';
import { DateTime } from 'luxon';
import { DateTimeInput } from 'shared/DateTimeInput';
import { ClickDropdown } from 'shared/ClickDropdown';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { ChevronDown } from 'shared/icons';
import cx from 'classnames';
import styles from '../settings-editor.module.css';

interface ArchiveProps {
  disabled?: boolean;
}

export const Archive: React.FC<ArchiveProps> = (props) => {
  const { disabled } = props;
  const { settings, update, options } = useSettings();
  const [isDatePickerVisible, setDatePickerStatus] = React.useState<boolean>(
    !!settings.archiveAt
  );

  const doNotArchive = React.useCallback(() => {
    update({ archiveAt: undefined });
    setDatePickerStatus(false);
  }, [update]);

  const archiveAtDate = React.useCallback(
    (value?: DateTime) => {
      update({ archiveAt: value });
    },
    [update]
  );

  const openDatePicker = React.useCallback(() => {
    setDatePickerStatus(true);
  }, []);

  const { archiveAt } = settings;

  const dropdownProp = () => (
    <DateTimeInput
      onChange={archiveAtDate}
      value={archiveAt || DateTime.now()}
    />
  );

  return (
    <div
      className={cx({
        [styles.disabledSetting]: disabled,
      })}
    >
      <label className={styles.archiveLabel} htmlFor="do_not_archive">
        <Checkbox
          id="do_not_archive"
          checked={!archiveAt && !isDatePickerVisible}
          type="radio"
          onSelect={doNotArchive}
          name="archive_option"
          disabled={disabled}
        />
        <div className={styles.archiveOption}>Do not archive</div>
      </label>
      {options.canChangeArchiveDate && (
        <label className={styles.archiveLabel} htmlFor="disable">
          <Checkbox
            id="disable"
            checked={!!archiveAt || isDatePickerVisible}
            type="radio"
            onSelect={openDatePicker}
            name="archive_option"
            disabled={disabled}
          />
          <div className={styles.archiveOption}>Specify archive date</div>
        </label>
      )}
      {options.canChangeArchiveDate && isDatePickerVisible && (
        <div className={styles.datePickerContainer}>
          <ClickDropdown
            dropdownRenderProp={dropdownProp}
            disabled={disabled}
            upward
          >
            <span className={styles.datePicker}>
              <div className={styles.datePickerButton}>
                <span>
                  {archiveAt
                    ? archiveAt.toFormat('dd LLL, hh:mma')
                    : 'Select date'}
                </span>
                <ChevronDown />
              </div>
            </span>
          </ClickDropdown>
        </div>
      )}
    </div>
  );
};
