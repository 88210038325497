import React from 'react';
import { FormSection, FormSubsection } from 'DesignSystem/Form';
import { TextInput } from 'shared/TextInput';
import { FlipSwitch } from 'shared/FlipSwitch';
import { useShortcutQuery } from 'hooks/shortcuts';
import { useProgram } from 'contexts/program';
import { useMatch } from '@reach/router';
import { includes } from 'utility/objectUtils';
import { useFlashMessage } from 'contexts/flasher';
import ImageUploadInput from './ImageUploadInput';
import styles from './create.module.css';
import { EDITABLE_SHORTCUT_TYPES } from '../utils/constants';

export const GeneralCard: React.FC<{
  name: string;
  onNameChange?: (value: string) => void;
  description: string;
  onDescriptionChange?: (value: string) => void;
  setIconSrc?: (value: string) => void;
  iconSrc: string;
  setHeaderSrc?: (value: string) => void;
  headerSrc: string;
  isShortcutEnabled: boolean;
  handleShortcutEnabledToggle: () => void;
}> = ({
  name,
  onNameChange,
  description,
  onDescriptionChange,
  setIconSrc,
  iconSrc,
  setHeaderSrc,
  headerSrc,
  isShortcutEnabled,
  handleShortcutEnabledToggle,
}) => {
  const { id: programId } = useProgram();
  const { setFlashMessage } = useFlashMessage();
  const match = useMatch('/:programId/configure/shortcuts/:id/*');
  const shortcutId = Number(match?.id);
  const { data: shortcutsData } = useShortcutQuery({
    programId,
    shortcutId,
  });

  const isInternalShortcutType =
    includes(EDITABLE_SHORTCUT_TYPES, shortcutsData?.type ?? '') ||
    shortcutsData?.type === undefined;

  return (
    <FormSection title="General">
      <FormSubsection title="Shortcut Enabled" divider={false}>
        <FlipSwitch
          on={isShortcutEnabled}
          onChange={handleShortcutEnabledToggle}
          size="xl"
          shape="rounded"
        />
      </FormSubsection>
      <FormSubsection title="Name" divider={false}>
        <TextInput
          onChange={onNameChange}
          disabled={!onNameChange}
          value={name}
          className={styles.textInput}
        />
      </FormSubsection>
      <FormSubsection title="Description" divider={false}>
        <TextInput
          onChange={onDescriptionChange}
          disabled={!onDescriptionChange}
          value={description}
          className={styles.textInput}
        />
      </FormSubsection>
      {setIconSrc && (
        <FormSubsection title="Icon" divider={false}>
          <ImageUploadInput
            imageSrc={iconSrc}
            setImageSrc={setIconSrc}
            isDefaultImage={shortcutsData?.service?.iconUrl === iconSrc}
            fileRemoveHandler={() => {
              if (isInternalShortcutType) {
                const defaultIcon = shortcutsData?.service?.iconUrl ?? '';
                setIconSrc(defaultIcon);
                if (defaultIcon) {
                  setFlashMessage({
                    message:
                      'The icon has been reset to the default icon, please save your changes.',
                    severity: 'info',
                  });
                }
              }
            }}
            imageAlt="shortcut icon"
          />
        </FormSubsection>
      )}
      {setHeaderSrc && (
        <FormSubsection title="Header">
          <ImageUploadInput
            imageSrc={headerSrc}
            setImageSrc={setHeaderSrc}
            isDefaultImage={
              shortcutsData?.service?.bannerImageUrl === headerSrc
            }
            fileRemoveHandler={() => {
              if (isInternalShortcutType) {
                const defaultHeader =
                  shortcutsData?.service?.bannerImageUrl ?? '';
                setHeaderSrc(defaultHeader);
                if (defaultHeader) {
                  setFlashMessage({
                    message:
                      'The header has been reset to the default header, please save your changes.',
                    severity: 'info',
                  });
                }
              }
            }}
            imageAlt="shortcut header"
            imagePreviewStyle={{
              height: '360px',
              width: '100%',
              maxWidth: '720px',
            }}
          />
        </FormSubsection>
      )}
    </FormSection>
  );
};
