import React, { useState } from 'react';
import Select from 'react-select';
import { useProgram } from 'contexts/program';
import { PreviewContext } from 'contexts/publisher/compose/preview';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Body } from 'DesignSystem/Typography';
import { MAIcon } from 'shared/MAIcon';
import { User, fullName } from 'models/user';
import { hasAudience, hasTopic } from 'models/publisher/settings';
import { combinedAudiencesQuery } from 'models/audience';
import { useTopicSubscribersAudience } from 'hooks/audience';
import { useUsersInfiniteQuery } from 'hooks/user';
import styles from './personalized-field-select.module.css';

export type PreviewUser = User & { label: string; value: string };

export const PersonalizedFieldVariablePreviewOptions: React.FC = () => {
  const { id: programId } = useProgram();
  const { settings } = useSettings();

  const withAudience = hasAudience(settings);
  const withTopic = hasTopic(settings);
  const { audiences, contentTopics } = settings;

  const topicAudiences = useTopicSubscribersAudience(programId, contentTopics);
  const allAudiences = [...topicAudiences, ...audiences];
  const query = combinedAudiencesQuery(allAudiences);

  const {
    isLoading,
    data,
    fetchNextPage,
    hasNextPage,
  } = useUsersInfiniteQuery({ programId, query, audienceQuery: true });

  function mapUserToPreview(user: User): PreviewUser {
    return {
      id: user.id,
      accessibleBrands: user.accessibleBrands,
      accessiblePrograms: user.accessiblePrograms,
      label: fullName(user),
      value: fullName(user),
    };
  }

  const audienceUsers = React.useMemo(() => {
    if (data && (withAudience || withTopic))
      return data.map((u: User) => mapUserToPreview(u));

    return [];
  }, [data, withAudience, withTopic]);

  const [selectedUser, setSelectedUser] = useState();
  const { setPreviewAs } = React.useContext(PreviewContext);

  const setPreviewUser = React.useCallback((user) => {
    setSelectedUser(user);
  }, []);

  const onScroll = React.useCallback(() => {
    if (hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  const onSelectChange = React.useCallback(
    (value) => {
      setPreviewUser(value);
      setPreviewAs(value.id);
    },
    [setPreviewAs, setPreviewUser]
  );

  return (
    <div className={styles.variableSelect}>
      <Flex start>
        <Body uppercase bold block>
          Personalized Fields
        </Body>
      </Flex>
      {!isLoading && audienceUsers && audienceUsers.length === 0 && (
        <>
          <Alert
            type={AlertType.info}
            compact
            enableIcon
            icon={<MAIcon name="info" />}
            message={
              <Box>
                To preview personalized fields you must first assign at least
                one audience or topic in the Deliver page with your targeted
                users.
              </Box>
            }
          />
          <br />
        </>
      )}
      <div className={styles.userLabel}> User ID </div>
      <Select
        onMenuScrollToBottom={onScroll}
        isLoading={isLoading}
        onChange={onSelectChange}
        value={selectedUser}
        options={audienceUsers}
        placeholder="Click to search"
      />
    </div>
  );
};
