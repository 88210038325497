import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { usePermissions } from 'contexts/permissions';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { isTopic, Topic } from 'models/topic';
import { Audience } from 'models/audience';
import { FocusDropdown } from 'shared/FocusDropdown';
import { Block, Row } from 'shared/SectionBlocks';
import { SearchInputInnerButton as SearchInputWithChildren } from 'shared/SearchInputInnerButton';
import { Icon } from 'shared/Icon';
import { useToggle } from 'hooks/useToggle';
import { SlideIn } from 'shared/Overlay/SlideIn';
import { ReactComponent as Plus } from 'shared/icons/Plus.svg';
import buttonStyles from 'shared/styles/buttons.module.css';

import { InfiniteSelect } from './InfiniteSelect';
import { ListReturnType, useTopicsList } from './useTopicsList';
import { useAudienceList } from './useAudienceList';
import { useRecentTopics } from './useRecentTopics';
import { useRecentAudiences } from './useRecentAudiences';
import { Builder } from './Builder';
import styles from './target-select.module.css';

function itemDisplayName(item: Audience | Topic) {
  return 'title' in item ? item.title : item.name;
}

function itemDisplayCount(item: Audience | Topic) {
  return isTopic(item) ? item.followerCount : item.totalUsers;
}

function pillDisplayValues(item: Audience | Topic) {
  return {
    name: itemDisplayName(item),
    userCount: itemDisplayCount(item),
  };
}

const AddAudienceButton: React.FC<{
  onClick: () => void;
  disabled: boolean;
}> = ({ onClick, disabled }) => {
  return (
    <button
      className={styles.header_add_audience}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <Plus width={14} height={14} />
      <div>Audience</div>
    </button>
  );
};

export const TargetSelect: React.FC<{
  selectedTopics: Array<Topic>;
  selectedAudiences: Array<Audience>;
  onTopicsChange: (value: Array<Topic>) => void;
  onAudienceChange: (value: Array<Audience>) => void;
}> = ({
  selectedTopics,
  selectedAudiences,
  onTopicsChange,
  onAudienceChange,
}) => {
  const [searchText, setSearchText] = useState('');
  const [category, setCategory] = useState<'topic' | 'audience' | undefined>();
  const {
    permissions: { audienceAccess, updateAudienceAccess },
  } = usePermissions();

  const { contentPermissions } = useSettings();
  const { canEdit } = contentPermissions;
  const [shouldStyleInput, setShouldStyleInput] = useState(false);
  const {
    value: builderEnabled,
    disable: disableBuilder,
    enable: enableBuilder,
  } = useToggle();
  const recentTopics = useRecentTopics(3);
  const recentAudiences = useRecentAudiences(3);

  function stripSearchText(text: string) {
    if (text.startsWith('@') || text.startsWith('#'))
      return text.slice(1, text.length);

    return text;
  }

  function search(text: string) {
    if (text.startsWith('@') && category !== 'audience')
      setCategory('audience');
    else if ((text.startsWith('#') && category !== 'topic') || !category)
      setCategory('topic');

    setSearchText(text);
  }

  const topicsData = useTopicsList(
    selectedTopics,
    stripSearchText(searchText),
    onTopicsChange
  );

  const audiencesData = useAudienceList(
    selectedAudiences,
    stripSearchText(searchText),
    onAudienceChange,
    []
  );

  // eslint-disable-next-line
  const rowRenderer = useCallback(
    (dataByRowId: ListReturnType['dataByRowId']) => (rowId: string) => {
      const item = dataByRowId[rowId];
      return item ? (
        <div className={styles.dropdown_row} data-test="TargetSelect-Row">
          <div className={styles.dropdown_row_symbol_col}>
            {isTopic(item) ? '#' : '@'}
          </div>
          <div className={styles.dropdown_row_details_col}>
            <div className={styles.dropdown_row_title}>
              {itemDisplayName(item)}
            </div>
            <div className={styles.dropdown_row_description}>
              {item.description}
            </div>
            <div className={styles.dropdown_row_count}>
              <Icon
                iconName="People"
                iconType="SVG"
                useCurrentColor
                size={18}
                title="People"
              />
              <span style={{ paddingLeft: '5px' }}>
                {itemDisplayCount(item)}
              </span>
            </div>
          </div>
        </div>
      ) : null;
    },
    []
  );

  const onClose = useCallback(() => {
    setSearchText('');
    setShouldStyleInput(false);
    setCategory(undefined);
  }, []);

  const selectRow = (
    selectedIds: ListReturnType['selectedIds'],
    onSelectedIdsChange: ListReturnType['onSelectedIdsChange'],
    updateCache: (id: string) => void,
    close: () => void
  ) => (id: string) => {
    setSearchText('');
    close();
    updateCache(id);
    if (selectedIds.includes(id)) return;
    const newSelectedIds = [...selectedIds];
    const index = selectedIds.indexOf(id);
    if (index >= 0) {
      newSelectedIds.splice(index, 1);
    } else {
      newSelectedIds.push(id);
    }
    onSelectedIdsChange(newSelectedIds);
  };

  // eslint-disable-next-line
  const dropdownInfiniteList = useCallback(
    (
      listData: ListReturnType,
      renderRow: (rowId: string) => React.ReactElement | null,
      close: () => void
    ) => {
      return (
        <InfiniteSelect
          checkboxClassName={styles.dropdown_row_checkbox}
          rowIds={listData.dataRowIds}
          rowRenderProp={renderRow}
          maxHeight={400}
          itemHeight={85}
          selectedIds={listData.selectedIds}
          onSelectedIdsChange={listData.onSelectedIdsChange}
          fetchNextPage={listData.fetchNextPage}
          hasNextPage={listData.hasNextPage}
          isFetchingNextPage={listData.isFetchingNextPage}
          isLoading={listData.isLoading}
          searchTerm={listData.search}
          onHandleChange={selectRow(
            listData.selectedIds,
            listData.onSelectedIdsChange,
            listData.updateCache,
            close
          )}
          className={styles.infinite_dropdown}
          hoverFocus={false}
          clearDisabled
        />
      );
    },
    []
  );

  // eslint-disable-next-line
  const RecentDropdown = (close: () => void) => (
    <>
      <div
        className={styles.recent_category_label}
        style={{ paddingTop: '20px' }}
      >
        RECENT TOPICS
      </div>
      <InfiniteSelect
        checkboxClassName={styles.dropdown_row_checkbox}
        rowIds={recentTopics.dataRowIds}
        rowRenderProp={rowRenderer(recentTopics.dataByRowId)}
        maxHeight={255}
        itemHeight={85}
        selectedIds={[]}
        onSelectedIdsChange={topicsData.onSelectedIdsChange}
        className={styles.infinite_dropdown}
        onHandleChange={selectRow(
          topicsData.selectedIds,
          topicsData.onSelectedIdsChange,
          topicsData.updateCache,
          close
        )}
        hoverFocus={false}
        clearDisabled
      />
      {audienceAccess && (
        <>
          <div className={styles.recent_category_label}>RECENT AUDIENCES</div>
          <InfiniteSelect
            checkboxClassName={styles.dropdown_row_checkbox}
            rowIds={recentAudiences.dataRowIds}
            rowRenderProp={rowRenderer(recentAudiences.dataByRowId)}
            maxHeight={255}
            itemHeight={85}
            selectedIds={[]}
            onSelectedIdsChange={audiencesData.onSelectedIdsChange}
            className={styles.infinite_dropdown}
            onHandleChange={selectRow(
              audiencesData.selectedIds,
              audiencesData.onSelectedIdsChange,
              audiencesData.updateCache,
              close
            )}
            hoverFocus={false}
            clearDisabled
          />
        </>
      )}
    </>
  );

  // eslint-disable-next-line
  const NoCategoryDropDown = (
    <div className={styles.no_selection_container}>
      <Icon
        iconName="Logo"
        iconType="SVG"
        useCurrentColor
        size={100}
        title="Search"
      />
      <div style={{ paddingTop: '20px' }}>Find a topic or audience...</div>
    </div>
  );

  const dropdown = useCallback(
    (close: () => void) => {
      if (category === 'audience' && audienceAccess)
        return dropdownInfiniteList(
          audiencesData,
          rowRenderer(audiencesData.dataByRowId),
          close
        );
      if (category === 'topic')
        return dropdownInfiniteList(
          topicsData,
          rowRenderer(topicsData.dataByRowId),
          close
        );

      if (
        (recentTopics.data.length > 0 && !recentTopics.isLoading) ||
        (recentAudiences.data.length > 0 && !recentAudiences.isLoading)
      )
        return RecentDropdown(close);

      return NoCategoryDropDown;
    },
    [
      audienceAccess,
      category,
      audiencesData,
      topicsData,
      NoCategoryDropDown,
      RecentDropdown,
      recentTopics.data,
      recentTopics.isLoading,
      dropdownInfiniteList,
      rowRenderer,
      recentAudiences,
    ]
  );

  const SearchPlaceholder = ({
    onClick,
    disabled,
  }: {
    onClick: () => void;
    disabled: boolean;
  }) => (
    <div className="kai-flex-row full-width">
      <Icon
        iconName="Magnify"
        iconType="SVG"
        useCurrentColor
        size={18}
        title="Search"
      />
      <div style={{ padding: '0 10px' }} className="flex-grow">
        Select a {/* eslint-disable-next-line */}
        <button
          type="button"
          className={buttonStyles.buttonReset}
          style={{ color: 'var(--color-brandTintDark)' }}
          onClick={() => {
            setCategory('topic');
            onClick?.();
          }}
          disabled={disabled}
        >
          #topic
        </button>{' '}
        {audienceAccess && (
          <>
            or{' '}
            <button
              type="button"
              className={buttonStyles.buttonReset}
              style={{ color: 'var(--color-brandTintDark)' }}
              onClick={() => {
                setCategory('audience');
                onClick?.();
              }}
              disabled={disabled}
            >
              @audience
            </button>
          </>
        )}
      </div>
    </div>
  );

  const CategoryButtons = ({ disabled }: { disabled: boolean }) => (
    <div className={styles.category_search_selector_container}>
      <button
        className={cx(styles.category_search_button, {
          [styles.active_search_button]: category === 'topic',
        })}
        type="button"
        onClick={() => setCategory('topic')}
        disabled={disabled}
      >
        #
      </button>
      {audienceAccess && (
        <button
          className={cx(styles.category_search_button, {
            [styles.active_search_button]: category === 'audience',
          })}
          type="button"
          onClick={() => setCategory('audience')}
          disabled={disabled}
        >
          @
        </button>
      )}
    </div>
  );

  // eslint-disable-next-line
  const NoCategoryHeader = (
    <div className={styles.header}>
      <div className={styles.header_top_row}>
        <div>SELECT A...</div>
        {updateAudienceAccess && (
          <AddAudienceButton onClick={enableBuilder} disabled={!canEdit} />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          padding: '0 10px',
        }}
      >
        <button
          className={styles.category_row_selector}
          type="button"
          onClick={() => setCategory('topic')}
          data-test="TargetSelect-SelectTopics"
          disabled={!canEdit}
        >
          #Topic
        </button>
        {audienceAccess && (
          <button
            className={styles.category_row_selector}
            type="button"
            onClick={() => setCategory('audience')}
            data-test="TargetSelect-SelectAudiences"
            disabled={!canEdit}
          >
            @Audience
          </button>
        )}
      </div>
    </div>
  );

  // eslint-disable-next-line
  const CategorySelectedRow = (
    <div className={styles.header}>
      <div className={styles.header_top_row}>
        <div>{category?.toUpperCase()} RESULTS</div>
        {updateAudienceAccess && (
          <AddAudienceButton onClick={enableBuilder} disabled={!canEdit} />
        )}
      </div>
    </div>
  );

  const TopRow = useMemo(() => {
    if (category === 'audience' || category === 'topic')
      return CategorySelectedRow;

    return NoCategoryHeader;
  }, [category, NoCategoryHeader, CategorySelectedRow]);

  const ListWrapper = (close: () => void) => {
    return (
      <>
        <div>{TopRow}</div>
        <div>{dropdown(close)}</div>
      </>
    );
  };

  const selectedValuesPillList = useCallback(
    (
      values: Array<{ name: string; userCount: number }>,
      remove: (index: number) => void
    ) =>
      values.map(({ name, userCount }, index) => {
        return (
          <div
            className={styles.pill_list_selected}
            key={name}
            data-test="selected-item"
          >
            <span>
              {name} ({userCount})
            </span>
            <button
              type="button"
              className={styles.pill_list_remove}
              onClick={() => remove(index)}
              disabled={!canEdit}
            >
              <Icon iconName="Times" size={12} iconType="SVG" />
            </button>
          </div>
        );
      }),
    [canEdit]
  );

  const addAudience = useCallback(
    (audience) => onAudienceChange([...selectedAudiences, audience]),
    [onAudienceChange, selectedAudiences]
  );

  return (
    <>
      <Row>
        <Block
          className={cx(styles.target_input_container, {
            [styles.active_dropdown]: shouldStyleInput,
          })}
        >
          <FocusDropdown
            dropdownRenderProp={ListWrapper}
            dropdownClassName={cx(styles.dropdown)}
            onOpen={() => setShouldStyleInput(true)}
            onClose={onClose}
            keyPressActivated
          >
            {(showDropdown, ref) => (
              <SearchInputWithChildren
                value={searchText}
                onChange={search}
                onFocus={showDropdown}
                inputRef={ref}
                panelClassName={styles.search_panel}
                disabled={!canEdit}
              >
                {!shouldStyleInput && !category && (
                  <SearchPlaceholder
                    onClick={showDropdown}
                    disabled={!canEdit}
                  />
                )}
                {shouldStyleInput && <CategoryButtons disabled={!canEdit} />}
              </SearchInputWithChildren>
            )}
          </FocusDropdown>
        </Block>
      </Row>
      <Row>
        <Block>
          <div className={styles.selected_items_row}>
            <div className={styles.selected_items_row_label}>Topics</div>
            <div
              className={styles.selected_items_row_values}
              data-test="selected-topics"
            >
              {selectedTopics.length > 0 ? (
                selectedValuesPillList(
                  selectedTopics.map(pillDisplayValues),
                  topicsData.handleRemove
                )
              ) : (
                <div className={styles.pill_list_placeholder}>
                  No topic selected
                </div>
              )}
            </div>
          </div>
          {audienceAccess && (
            <div className={styles.selected_items_row}>
              <div className={styles.selected_items_row_label}>Audiences</div>
              <div
                className={styles.selected_items_row_values}
                data-test="selected-audiences"
              >
                {selectedAudiences.length > 0 ? (
                  selectedValuesPillList(
                    selectedAudiences.map(pillDisplayValues),
                    audiencesData.handleRemove
                  )
                ) : (
                  <div className={styles.pill_list_placeholder}>
                    No audience selected
                  </div>
                )}
              </div>
            </div>
          )}
        </Block>
      </Row>
      {builderEnabled && (
        <SlideIn name="audience-builder">
          {(slideOut) => (
            <Builder
              onCloseBuilder={() => slideOut(disableBuilder)}
              onUseBuiltAudience={addAudience}
            />
          )}
        </SlideIn>
      )}
    </>
  );
};
