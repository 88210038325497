import { DateTime } from 'luxon';
import { pluralize } from 'utility/text';

const SECONDS_IN_DAY = 86400;

export type UnitValue = 'day' | 'week' | 'month' | 'quarter' | 'year';

export type DayValue = 1 | 7 | 14 | 30;

export type DurationUnit = {
  label: string;
  value: UnitValue;
  multiplier: number;
  max: number;
};

export type Duration = {
  label: string;
  value: number;
  unit: DurationUnit;
  ignoreYear?: boolean;
};

// TODO: See Advanced Conditional use case https://www.digitalocean.com/community/tutorials/how-to-use-generics-in-typescript
//    key should be same as value?
export const DURATIONS_BY_UNIT: Record<UnitValue, DurationUnit> = {
  day: {
    label: 'Days',
    value: 'day',
    multiplier: 1,
    max: 365,
  },
  week: {
    label: 'Weeks',
    value: 'week',
    multiplier: 7,
    max: 52,
  },
  month: {
    label: 'Months',
    value: 'month',
    multiplier: 30,
    max: 12,
  },
  quarter: {
    label: 'Quarters',
    value: 'quarter',
    multiplier: 91, // TODO: Why 91??
    max: 4,
  },
  year: {
    label: 'Year',
    value: 'year',
    multiplier: 365, // TODO: Confirm this?
    max: 1,
  },
};

export const DurationOptions = Object.values(DURATIONS_BY_UNIT);

export const DURATION_DAYS_BY_VALUE: Record<DayValue, Duration> = {
  1: { label: '1 d', value: 1, unit: DURATIONS_BY_UNIT.day },
  7: { label: '7 d', value: 7, unit: DURATIONS_BY_UNIT.day },
  14: { label: '14 d', value: 14, unit: DURATIONS_BY_UNIT.day },
  30: { label: '30 d', value: 30, unit: DURATIONS_BY_UNIT.day },
};
export const durationDaysByCustomValue = (value: number): Duration => ({
  label: `${value} d`,
  value,
  unit: DURATIONS_BY_UNIT.day,
});

export const DURATION_DAYS = Object.values(DURATION_DAYS_BY_VALUE);

export const isDayValue = (value: number): value is DayValue => {
  if (value !== 1 && value !== 7 && value !== 14 && value !== 30) {
    throw new Error(`dayValue: ${value} is not of type DayValue`);
  }
  return true;
};

const isDurationUnit = (
  durationUnit: DurationUnit | unknown
): durationUnit is DurationUnit => {
  const durUnt = durationUnit as DurationUnit;
  if (
    !durUnt ||
    typeof durUnt.label !== 'string' ||
    typeof durUnt.value !== 'string' ||
    typeof durUnt.multiplier !== 'number' ||
    typeof durUnt.max !== 'number'
  ) {
    throw new Error(`durationUnit: ${durUnt} is not of type DurationUnit`);
  }
  return true;
};

export const isDuration = (
  duration: Duration | unknown
): duration is Duration => {
  const dur = duration as Duration;
  if (
    !dur ||
    typeof dur.label !== 'string' ||
    typeof dur.value !== 'number' ||
    !isDurationUnit(dur.unit) ||
    (dur.ignoreYear && typeof dur.ignoreYear !== 'boolean')
  ) {
    throw new Error(`duration: ${dur} is not of type Duration`);
  } else {
    return true;
  }
};

export function isDurationArray(
  durations: Parameters<typeof isDuration>[0][]
): durations is Duration[] {
  return durations.every(isDuration);
}

// TODO: Better way to do this? Override equals? Impl trait on type?
export const isSameDuration = (one: Duration, another?: Duration): boolean => {
  return one.value === another?.value && one.unit.value === another.unit.value;
};

export const addDuration = (
  dateTime: DateTime,
  duration: Duration
): DateTime => {
  return dateTime.plus({ [duration.unit.value]: duration.value });
};

export const buildDuration = (
  value: number,
  unitValue: UnitValue
): Duration => {
  return {
    value,
    label: `${value} ${pluralize(unitValue)}`,
    unit: DURATIONS_BY_UNIT[unitValue],
  };
};

export const secondsCount = (duration: Duration): number => {
  return duration.value * duration.unit.multiplier * SECONDS_IN_DAY;
};
