import { Link } from '@reach/router';
import {
  Avatar,
  FeedCardGrid,
  FeedCardGridItem,
  FeedCard,
  Tag,
  Button,
  Icon,
  IconButton,
} from '@socialchorus/shared-ui-components';
import React, { FC } from 'react';
import { useProgram } from 'contexts/program';
import { FeedPreview } from 'models/feed-preview';
import NonEditablePlaceholder from '../../components/NonEditablePlaceholder';
import styles from './styles.module.css';

export function formatPublishDate(date: Date): string {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

type FeedCardProps = Parameters<typeof FeedCard.Image>[0];

interface PostProps {
  content: FeedPreview;
  isPinned?: boolean;
}
export const Post: FC<PostProps> = ({ content, isPinned }) => {
  const {
    title,
    summary,
    contentAuthor,
    contentType,
    displaySettings,
    contentChannels,
  } = content;
  const { avatarUrl, displayName } = contentAuthor;
  const imageUrl =
    content.backgroundImageUrls?.[0]?.url || content.backgroundImageUrl;

  let contentImage: FeedCardProps['image'];
  if (imageUrl) {
    contentImage = {
      url: imageUrl,
    };
  } else if (
    contentType === 'note' &&
    !contentImage &&
    displaySettings?.messageCardColor
  ) {
    contentImage = {
      type: 'note-icon',
      color: displaySettings.messageCardColor,
    };
  }

  return (
    <FeedCardGridItem>
      <FeedCard.Root layout="horizontal">
        {isPinned ? (
          <FeedCard.Label>
            <Icon size={18}>push_pin</Icon>
            Pinned
          </FeedCard.Label>
        ) : undefined}

        <FeedCard.Main>
          <FeedCard.Content>
            <FeedCard.Title title={title} description={summary} />
            <FeedCard.Description title={title} description={summary} />
          </FeedCard.Content>

          <FeedCard.Tags
            display={1}
            popoverTitle="Topics"
            popoverWidth="200px"
            tags={contentChannels.map((channel) => (
              <Tag key={channel.id} label={channel.name} />
            ))}
          />
        </FeedCard.Main>
        <FeedCard.Image image={contentImage} />

        <FeedCard.Footer>
          <FeedCard.Metadata
            additionalMetadata={
              (content.publishedAt && [
                formatPublishDate(new Date(content.publishedAt)),
              ]) ||
              undefined
            }
            authorAvatarComponent={
              <Avatar imgSrc={avatarUrl} shape="circle" size="small" />
            }
            authorName={displayName}
          />
          <FeedCard.Actions>
            <Button
              key="like"
              aria-label="Like"
              label="Like"
              leftIcon={<Icon size={20}>thumb_up</Icon>}
              size="compact"
              variant="text"
              className={styles.cardActionButton}
            />
            <Button
              key="comment"
              aria-label="Comment"
              label="Comment"
              leftIcon={<Icon size={20}>chat_bubble</Icon>}
              size="compact"
              variant="text"
              className={styles.cardActionButton}
            />
            <IconButton key="bookmark" iconName="bookmark" size="compact" />
          </FeedCard.Actions>
        </FeedCard.Footer>
      </FeedCard.Root>
    </FeedCardGridItem>
  );
};

const EmptyFeed: FC = () => {
  const { id: programId } = useProgram();
  return (
    <NonEditablePlaceholder
      title="No Posts Yet"
      description={
        <>
          There are no posts in this topic yet. Posts can be added from{' '}
          <Link to={`/${programId}/app/content`}>Campaign Page</Link>
        </>
      }
    />
  );
};

interface PostsFeedProps {
  contents: FeedPreview[];
  pinned?: boolean;
}
export const PostsFeed: FC<PostsFeedProps> = ({ contents, pinned }) => {
  return contents.length === 0 ? (
    <EmptyFeed />
  ) : (
    <div className={styles.feedWrapper}>
      <FeedCardGrid columns={1}>
        {contents.map((content) => (
          <Post key={content.id} content={content} isPinned={pinned} />
        ))}
      </FeedCardGrid>
    </div>
  );
};
