import React from 'react';
import {
  EmptyBanner,
  EmptyBannerDeleteItem,
} from 'shared/BannerListItem/EmptyBanner';
import * as Text from 'DesignSystem/Typography';
import { MenuItemsType } from 'shared/BannerListItem';
import { DateTime } from 'luxon';
import { BoxFolderData } from 'services/api-box-mangement';
import { Flex } from 'DesignSystem/Layout/Flex';
import { Box } from 'DesignSystem/Components';
import styles from './box.km.module.css';
import { useActions } from './useActions';

type PropsType = {
  boxFolderData: BoxFolderData;
  index: number;
};

export const BoxKMBanner: React.FC<PropsType> = ({ boxFolderData: data }) => {
  const {
    deleteBoxFolderMapping: { deleteAction },
  } = useActions();
  const [showingDeleteModal, setShowDeleteModal] = React.useState(false);
  const toggleDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const menuItems: MenuItemsType = [
    {
      title: 'Edit',
      href: `/${data.programId}/configure/box-manage-folders/folders/${data.boxFolderId}/edit`,
    },
    {
      title: 'Delete',
      danger: true,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDeleteModal(true);
      },
    },
  ];

  const deleteItem: EmptyBannerDeleteItem = {
    deleteAction: () => deleteAction(data.boxFolderId),
    resource: `${data.name}`,
    showingDeleteModal,
    toggleDeleteModal,
    actionHelperText:
      'The folder and all its contents will no longer be available in the Firstup Platform. This cannot be undone. The folder will need to be reconfigured to make its content available again.',
    deleteConfirmText: 'Yes, Delete',
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <EmptyBanner
      isSelectable={false}
      menuItems={menuItems}
      rowId={String(data.id)}
      deleteItem={deleteItem}
    >
      <Flex spread className={styles.Banner}>
        <Flex spread className={styles.BannerContent}>
          <Box className={styles.NameCol}>
            <Text.Subheading bold block>
              <span title={data.name}>{data.name}</span>
            </Text.Subheading>
            <Box className={styles.Description}>
              <Text.Caption>
                <span title={data.description}>{data.description}</span>
              </Text.Caption>
            </Box>
          </Box>
          {data.createdAt && (
            <Box className={styles.CreatorCol}>
              <div className={styles.ColHeader}>Date created</div>
              <Text.Body>
                {DateTime.fromJSDate(
                  new Date(data.createdAt as string)
                ).toFormat('DD')}
              </Text.Body>
            </Box>
          )}
          <Box className={styles.DateCol}>
            <div className={styles.ColHeader}>Created by</div>
            <Text.Body>
              {data.createdBy && <Text.Body>{data.createdBy}</Text.Body>}
            </Text.Body>
          </Box>
        </Flex>
      </Flex>
    </EmptyBanner>
  );
};
