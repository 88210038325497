import * as React from 'react';
import { useContentImageUploader } from 'hooks/useContentImage';
import { useLinkFetcher } from 'hooks/useLinkFetcher';
import { ImageData, isImageData } from 'models/image';
import { SocialData } from 'models/publisher/block';
import { LOGOS } from 'utility/social-network-logos';

export type SocialDataItemForm = ReturnType<typeof useSocialDataItemForm>;
type SocialFetcher = ReturnType<typeof useSocialFetcher>;
type SocialTitleInput = ReturnType<typeof useTitleInput>;
type SocialUrlInput = ReturnType<typeof useUrlInput>;
type SocialImageInput = ReturnType<typeof useImageInput>;
type SocialInput<T, F = T> = {
  value: T;
  onChange(data: T | F): void;
  onReset(): void;
};

export function useSocialDataItemForm({
  initial,
  onSave,
}: {
  initial: SocialData;
  onSave(link: SocialData | SocialData[]): void;
}): ReturnType<typeof useFormSubmission> & {
  image: SocialImageInput;
  url: SocialUrlInput;
  title: SocialTitleInput;
  fetcher: SocialFetcher;
} {
  const form = useFormSubmission({ initial, onSave });
  const fetcher = useSocialFetcher(form);
  const image = useImageInput(fetcher, form);
  const url = useUrlInput(fetcher, form);
  const title = useTitleInput(fetcher);
  return { ...form, image, url, title, fetcher };
}

function useFormSubmission({
  initial,
  onSave,
}: {
  initial: SocialData;
  onSave(link: SocialData | SocialData[]): void;
}): {
  disabled: boolean;
  error?: string;
  saveChanges(): void;
  initial?: SocialData;
  isLoading: boolean;
  onUrlChange(url: string): void;
  setError(error: string): void;
  setLink(data: SocialData): void;
  setPostProcessedImage(imageData: ImageData): void;
  setIsLoading(value: boolean): void;
} {
  const [link, setLink] = React.useState<SocialData>(initial);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(link?.uuid === '');

  const setPostProcessedImage = (imageData: ImageData) => {
    setLink({
      ...link,
      image: imageData,
    });
  };

  const saveChanges = React.useCallback(() => {
    onSave({
      ...link,
      url: link.requestedUrl || link.url,
    });
  }, [link, onSave]);

  const onUrlChange = React.useCallback((url: string) => {
    setDisabled(!url);
  }, []);

  React.useEffect(() => {
    setDisabled(!!error || !link?.url || isLoading);
  }, [error, link?.url, isLoading]);

  return {
    saveChanges,
    disabled,
    error,
    setError,
    setLink,
    setIsLoading,
    onUrlChange,
    setPostProcessedImage,
    initial,
    isLoading,
  };
}
const isValidUrl = (url: string): boolean => {
  const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,3})(\/.*)?$/;
  return pattern.test(url);
};
const getHostname = (url: string) => {
  try {
    const fixedUrl =
      url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `https://${url}`;
    return new URL(fixedUrl).hostname.replace(/^www\./, '');
  } catch {
    return null;
  }
};

// Sets the link fetching type and handles errors
const useSocialFetcher = ({
  initial,
  onError = () => {},
  setLink,
}: {
  initial?: SocialData;
  onError?: (message: string) => void;
  setLink: (link: SocialData) => void;
}): ReturnType<typeof useLinkFetcher> & {
  link: SocialData;
} => {
  const fetcher = useLinkFetcher({ initial, type: 'social' });

  React.useEffect(() => {
    if (fetcher.error) onError(fetcher.error);
    else onError('');
  }, [onError, fetcher.error]);

  React.useEffect(() => {
    const link = fetcher?.link;
    if (fetcher.hasChanges && link && isValidUrl(link.url)) {
      if (
        (!link?.image?.url || link?.image?.url === LOGOS.generic.image_url) &&
        link.url
      ) {
        const logoRecord = Object.entries(LOGOS).find(([_key, { domains }]) => {
          const hostname = getHostname(link.url);
          return domains.some((domain) => hostname === domain);
        });
        if (logoRecord) {
          link.image.url = logoRecord[1].image_url;
        }
      }
      setLink({
        ...(link as SocialData),
        image: {
          ...link.image,
          url: link?.image?.url || LOGOS.generic.image_url,
        },
      });
    }
  }, [fetcher.hasChanges, fetcher.link, fetcher.error, setLink]);

  return { ...fetcher, link: fetcher.link as SocialData };
}; // end useSocialFetcher

const useImageInput = (
  fetcher: SocialFetcher,
  form: ReturnType<typeof useFormSubmission>
): SocialInput<ImageData, File> & { isUploading: boolean } => {
  const { link, setImage, isLoading } = fetcher;
  const { setIsLoading } = form;
  const { image: value } = link;

  const { uploadUrl, uploadFile, isUploading } = useContentImageUploader({
    onUpload: fetcher.setImage,
  });

  const setLinkImage = React.useCallback(
    (data: ImageData | File) => {
      if (isImageData(data)) uploadUrl(data.url);
      else uploadFile(data);
    },
    [uploadUrl, uploadFile]
  );

  React.useEffect(() => {
    setIsLoading(isLoading || isUploading);
  }, [setIsLoading, isLoading, isUploading]);

  const [autoRun, setAutoRun] = React.useState('');

  React.useEffect(() => {
    if (fetcher.link.uuid !== autoRun) {
      const linkImg = fetcher.link.images[0];
      if (linkImg) {
        setLinkImage({ ...linkImg, processed: linkImg.processed ?? false });
        setAutoRun(fetcher.link.uuid);
      }
    }
  }, [setLinkImage, autoRun, fetcher.link.uuid, fetcher.link.images, fetcher]);

  const [initial] = React.useState(value);
  const onReset = React.useCallback(() => setImage(initial), [
    initial,
    setImage,
  ]);
  return { value, onReset, onChange: setLinkImage, isUploading };
};

const useUrlInput = (
  fetcher: SocialFetcher,
  form: ReturnType<typeof useFormSubmission>
): SocialInput<string> => {
  const { link, setUrl, reset } = fetcher;
  const { onUrlChange } = form;
  const value = link ? link.requestedUrl || link.url : '';

  const onReset = () => {
    reset();
    setUrl('');
    onUrlChange(value);
  };

  const onChange = (url: string) => {
    if (!url) {
      reset();
      onUrlChange(url);
    } else {
      setUrl(url);
      onUrlChange(url);
    }
  };

  return { value, onReset, onChange };
};

const useTitleInput = (fetcher: SocialFetcher): SocialInput<string> => {
  const { link, setTitle } = fetcher;
  const value = link ? link.title.value : '';

  const onReset = React.useCallback(() => setTitle(''), [setTitle]);
  const onChange = React.useCallback(
    (title: string) => {
      setTitle(title);
    },
    [setTitle]
  );
  return { value, onReset, onChange };
};
