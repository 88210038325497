import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useProgram } from 'contexts/program';
import { Box } from 'DesignSystem/Components';
import { Body, color, Subheading } from 'DesignSystem/Typography';
import { useSendTestEmail } from 'hooks/publisher';
import { usePublisher } from 'contexts/publisher';
import { useCurrentUserQuery } from 'hooks/user';
import { Pills } from 'DesignSystem/Components/Pills';
import { User } from 'models/user';
import { MAX_NOTIFICATION_LENGTH } from 'models/notification';
import { FormModalSectioned } from 'shared/FormModalSectioned';
import { useSendTestPush } from 'hooks/useSendTestPush';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFlashMessage } from 'contexts/flasher';
import { ExclamationTriangleOutlined } from 'shared/icons';
import { useNotifications } from '../../../Deliver/Notifications/useNotifications';
import { SelectUsers } from './SelectUsers';
import styles from './send-test-campaign-modal.module.css';
import { ChannelSelection } from '../../../../../../../components/channel-selection/channel-selection';
import { CheckedChannelsState } from '../../../../../../../components/channel-selection/hooks';

const ErrorBox: React.FC<PropsWithChildren<{
  showIf: boolean;
  marginTop: number;
}>> = ({ children, showIf, marginTop }) => {
  if (!showIf) return null;

  return (
    <Box role="alert" style={{ display: 'flex' }} margin={[marginTop, 0, 0, 0]}>
      <Box margin={['auto', 9, 'auto', 0]}>
        <ExclamationTriangleOutlined />
      </Box>
      <Body color={color.redFull}>{children}</Body>
    </Box>
  );
};

export const SendTestCampaignModal: React.FC<{
  deliveryType: 'campaign' | 'communication';
  hasPushChannel: boolean;
  hasEmailChannel: boolean;
  toggleShow: () => void;
}> = ({ deliveryType, hasPushChannel, hasEmailChannel, toggleShow }) => {
  const { firstNotification } = useNotifications();
  const { post } = usePublisher();
  const currentUser = useCurrentUserQuery().data;
  const { setFlashMessage } = useFlashMessage();

  const [localEmailText, setLocalEmailText] = useState(firstNotification.text);
  const [localEmailPreview, setLocalEmailPreview] = useState(
    firstNotification.previewText || ''
  );
  const [localPushText, setLocalPushText] = useState(
    firstNotification.pushText || ''
  );

  const [useEmailChannel, setUseEmailChannel] = useState(hasEmailChannel);
  const [usePushChannel, setUsePushChannel] = useState(hasPushChannel);

  const [users, setUsers] = React.useState<User[]>([]);
  const { id: programId } = useProgram();
  const preferOutlook365 = Boolean(
    useFeatureFlagsQuery(programId, 'Studio.Publish.PreferOutlook365').data
      ?.value
  );

  const { sendEmailRequest } = useSendTestEmail(
    localEmailText,
    localEmailPreview,
    post,
    users.map(({ id }) => id),
    preferOutlook365
  );

  const { sendPushRequest } = useSendTestPush(
    localPushText,
    '', // push messages do not have a preview
    post,
    users.map(({ id }) => id)
  );

  useEffect(() => {
    if (currentUser) {
      setUsers([currentUser]);
    }
  }, [currentUser]);

  const renderPill = ({
    id,
    avatarUrl,
    firstName,
    lastName,
    displayName,
  }: User) => (
    <div style={{ display: 'flex' }}>
      {avatarUrl && (
        <img
          className={styles.UserAvatar}
          alt={`user-${id}-avatar`}
          src={avatarUrl}
        />
      )}
      <span className={styles.UserName}>
        {displayName || `${firstName} ${lastName}`}
      </span>
    </div>
  );

  const onSubmit = () => {
    const requests = [
      useEmailChannel && sendEmailRequest(),
      usePushChannel && sendPushRequest(),
    ].filter(Boolean) as Array<Promise<void>>;

    Promise.all(requests).then(() => {
      if (!users || users.length === 0) {
        return;
      }
      let additionalMessage = '';
      if (users.length > 1) {
        additionalMessage = ` and ${users.length - 1} other recipient(s)`;
      }
      const { firstName, displayName } = users[0];
      const message = `Test messages sent to ${
        firstName || displayName
      }${additionalMessage}.`;
      setFlashMessage({
        severity: 'info',
        message,
      });
    });

    toggleShow();
  };

  const emailOnly = !!useFeatureFlag('emailOnly');

  const emailValid =
    !useEmailChannel ||
    (localEmailText.length !== 0 &&
      localEmailText.length <= MAX_NOTIFICATION_LENGTH &&
      localEmailPreview.length <= MAX_NOTIFICATION_LENGTH);

  const pushValid =
    !usePushChannel ||
    (localPushText.length !== 0 &&
      localPushText.length <= MAX_NOTIFICATION_LENGTH);

  const isBothChannelsNotSelected = !useEmailChannel && !usePushChannel;
  const isNoUsersSelected = users.length === 0;
  const userLimitReached = users.length > 100;
  const disableForm =
    isNoUsersSelected ||
    !emailValid ||
    !pushValid ||
    isBothChannelsNotSelected ||
    userLimitReached;

  const isChannelSelected = (channel: keyof CheckedChannelsState): boolean => {
    switch (channel) {
      case 'email':
        return useEmailChannel;
      case 'push':
        return usePushChannel;
      default:
        return false;
    }
  };

  const toggleChannelSelection = (channel: keyof CheckedChannelsState) => {
    switch (channel) {
      case 'email':
        setUseEmailChannel((selected) => !selected);
        break;
      case 'push':
        setUsePushChannel((selected) => !selected);
        break;
      default:
        break;
    }
  };

  return (
    <FormModalSectioned
      entityText={`test ${deliveryType}`}
      actionText="send"
      submitLabel="Send"
      disabled={disableForm}
      onSubmit={onSubmit}
      width={680}
      innerPadding={0}
      onCancel={toggleShow}
    >
      <Box padding={[0, 8]} margin={[-8, 0]}>
        <ChannelSelection
          variant="test-send"
          checked={isChannelSelected}
          toggleChecked={toggleChannelSelection}
        >
          <ChannelSelection.Email
            subject={localEmailText}
            onSubjectChange={(subject) => setLocalEmailText(subject)}
            previewText={localEmailPreview}
            onPreviewTextChange={(previewText) =>
              setLocalEmailPreview(previewText)
            }
          />
          {!emailOnly && (
            <ChannelSelection.PushNotification
              pushMessage={localPushText}
              onPushMessageChange={(message) => setLocalPushText(message)}
            />
          )}
          <ErrorBox showIf={isBothChannelsNotSelected} marginTop={14}>
            {`Please select at least one channel to send test ${deliveryType}`}
          </ErrorBox>
        </ChannelSelection>

        <Box margin={[12, 0, 0, 48]}>
          <Subheading bold>Recipients</Subheading>
          <Box margin={[8, 0, 0, 0]}>
            <SelectUsers selectedUsers={users} onUserChange={setUsers} />
            <ErrorBox showIf={userLimitReached} marginTop={14}>
              {`Limit exceeded: ${users.length} recipient selected. Please select no more than 100 recipients`}
            </ErrorBox>
            <Pills
              values={users}
              render={renderPill}
              onClose={({ id }) =>
                setUsers(users.filter(({ id: uId }) => id !== uId))
              }
              padding={[0, 16, 0, 4]}
            />
          </Box>
          <ErrorBox showIf={isNoUsersSelected} marginTop={2}>
            Please select at least one recipient to send test campaign
          </ErrorBox>
        </Box>
      </Box>
    </FormModalSectioned>
  );
};
