import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { Subheading } from 'DesignSystem/Typography';
import { DropdownRenderPropType } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form/InputElements';
import cx from 'classnames';
import { Icon } from 'shared/Icon';
import styles from './settings-card.module.css';

interface SettingsCardProps {
  isEditing?: boolean;
  disabled?: boolean;
  editRenderProp?: DropdownRenderPropType;
  showEditButton?: boolean;
  topBorder?: boolean;
  editingMargin?: number | string | Array<number | string>;
  onEditingToggle?: (value: boolean) => void;
  dataTest?: string;
}

/**
 * SettingsCard works as a wrapper for each Card in the new orchestration page. It handles the position of the Edit button,
 * image, and toggling the visibility of each.
 *
 * @param icon {string} Path to icon to show when there is no content filled in yet
 * @param internal {boolean} is this card inside another one?
 * @param isFilled {boolean} Has the user entered some info here yet?
 * @param dropdownRenderProp {DropdownRenderPropType} render prop for the drop down assigned to the Edit button
 * @param title {string} Title to use in this section
 * @param caption {string} Description to display under the caption
 * @param showBorder {boolean} Should display the border (default: true)
 * @param autoUpdate {boolean} Should update its position if the content changes size
 * @returns
 */
export const SettingsCard: React.FC<SettingsCardProps> = ({
  children,
  disabled,
  editRenderProp,
  isEditing = false,
  showEditButton = true,
  topBorder = false,
  onEditingToggle = undefined,
  editingMargin,
  dataTest = undefined,
}) => {
  const [editing, setEditing] = useState(isEditing);

  const handleSetEditing = (value: boolean) => {
    setEditing(value);
    if (onEditingToggle) onEditingToggle(value);
  };

  const onEditClick = () => {
    handleSetEditing(true);
  };

  let content = null;

  if (editing) {
    content =
      typeof editRenderProp === 'function'
        ? editRenderProp(
            () => {
              handleSetEditing(false);
            },
            () => {},
            () => {}
          )
        : editRenderProp;
  } else {
    content = (
      <>
        {typeof children === 'function'
          ? children(() => {
              handleSetEditing(true);
            })
          : children}
        {showEditButton && (
          <Button
            onClick={onEditClick}
            compact
            secondary
            className={styles.EditButton}
            label={
              <>
                <Icon iconName="pencil" iconType="SVG" />
                Edit
              </>
            }
            disabled={disabled}
          />
        )}
      </>
    );
  }

  return (
    <Box
      relative
      dataTest={dataTest}
      className={cx(styles.SettingsCard, {
        [styles.TopBorder]: topBorder,
        [styles.Editing]: editing,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(editing && editingMargin ? { margin: editingMargin } : {})}
    >
      <>{content}</>
    </Box>
  );
};

export const ContentTitle: React.FC = ({ children }) => (
  <Box margin={[0, 0, 22, 0]}>
    <Subheading bold>{children}</Subheading>
  </Box>
);

export const Divisor: React.FC = () => <Box className={styles.Divisor} />;
