import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';

export function useDownloadsFeature(): { loading: boolean; enabled: boolean } {
  const program = useProgram();
  const {
    data: downloadFlag,
    isLoading: isLoadingDownloadFlag,
  } = useFeatureFlagsQuery(program.id, 'Studio.Insights.ReportDownloads');
  const {
    data: dataDownloadFlag,
    isLoading: isLoadingDataDownloadFlag,
  } = useFeatureFlagsQuery(
    program.id,
    'Studio.Intelligence.DirectDataDownload.Enabled'
  );
  const isLoading = isLoadingDownloadFlag && isLoadingDataDownloadFlag;
  const enabled = !!dataDownloadFlag?.value || !!downloadFlag?.value;
  const { role, roleV2 } = usePermissions();

  const hasAccess =
    ['program_admin', 'administrator', 'super_admin'].includes(role) ||
    roleV2.includes('brand_admin');

  return { enabled: enabled && hasAccess, loading: isLoading };
}
