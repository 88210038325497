import React from 'react';
import { FetchedCaption } from 'hooks/video';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { CaptionPreview } from './CaptionPreview';

export const List: React.FC<{
  captions: FetchedCaption[];
  onEdit: (index: number) => void;
  onRemove: (index: number) => void;
  filename: string;
}> = ({ captions, onEdit, onRemove, filename }) => {
  const { id: programId } = useProgram();
  const captionTranscriptionEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.CaptionTranscription'
  ).data?.value;

  return (
    <>
      <ul style={{ listStyleType: 'none' }}>
        {captions.map((caption, index) => (
          <li key={caption.url} style={{ margin: '3px 0' }}>
            <CaptionPreview
              canDownload={captionTranscriptionEnabled}
              filename={filename}
              onEdit={() => onEdit(index)}
              onRemove={() => onRemove(index)}
              caption={caption}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
