import * as React from 'react';
import { useJourneyState } from 'contexts/journey';
import { ValidationError } from 'services/Errors/ValidationError';
import { Journey, parseJourneyError } from 'models/journeys/journey';
import { WindowLocation, navigate, useLocation } from '@reach/router';
import { useProgram } from 'contexts/program';
import { useFlashMessage } from 'contexts/flasher';
import { JourneyErrors } from 'models/journeys/journey-errors';
import {
  CloudStatus,
  useJourneyPersistenceStatus,
} from './useJourneyPersistenceStatus';
import { PrimaryButton, SaveButton } from './Buttons';
import { useJourneyValidationErrors } from '../JourneyErrors/useJourneyValidationErrors';
import { ErrorJourneyModal } from './PublishModals/ErrorJourneyModal';
import { PublishJourneyModal } from './PublishModals/PublishJourneyModal';
import { SaveJourneyModal } from './PublishModals/SaveJourneyModal';

enum PublishFlowErrorType {
  save = 'Save',
  publish = 'Publish',
}
type PublishFlowError = {
  type: PublishFlowErrorType;
  message: string;
};

const defaultSaveError = {
  type: PublishFlowErrorType.save,
  message: 'There was an issue saving this journey. Please try again.',
};

const defaultPublishError = {
  type: PublishFlowErrorType.publish,
  message: 'There was an issue publishing this journey. Please try again.',
};

type PublishFlowProps = {
  openPublishErrorsModal: () => void;
};
export const PublishFlow: React.FC<PublishFlowProps> = ({
  openPublishErrorsModal,
}) => {
  const {
    persistJourney,
    publishJourney,
    isSaving,
    isPublishing,
    isValidating,
  } = useJourneyState();
  const { id: programId } = useProgram();
  const [showErrorsModal, setShowErrorsModal] = React.useState(false);
  const [error, setError] = React.useState<PublishFlowError>();
  const persistanceStatus = useJourneyPersistenceStatus();
  const location = useLocation() as WindowLocation<{
    showPublishModal?: boolean;
  }>;
  const [showPublishModal, setShowPublishModal] = React.useState(
    location.state?.showPublishModal
  );

  React.useEffect(() => {
    window.history.replaceState({}, '');
  }, []);

  const { setFlashMessage } = useFlashMessage();

  const { errors } = useJourneyValidationErrors();

  const outOfDate = persistanceStatus.cloudStatus !== CloudStatus.UpToDate;

  const openPublishModal = React.useCallback(() => {
    setShowPublishModal(true);
  }, []);
  const closePublishModal = React.useCallback(() => {
    setShowPublishModal(false);
  }, []);

  const navigateToJourney = React.useCallback(
    (serverJourney: Journey, options?: { showPublishModal?: boolean }) => {
      if (serverJourney.id) {
        navigate(`/${programId}/app/journeys/${serverJourney.id}/edit`, {
          state: options,
        });
      }
    },
    [programId]
  );

  const onSaveSuccess = (j: Journey) => {
    if (j) {
      navigateToJourney(j);
      setFlashMessage({
        message: 'Journey saved successfully',
        severity: 'info',
      });
    }
  };
  const navigateAndPublish = (j: Journey) => {
    if (j) {
      navigateToJourney(j, { showPublishModal: true });
      setFlashMessage({
        message: 'Journey saved successfully',
        severity: 'info',
      });
    }
  };

  const onSaveError = (saveError: Error | ValidationError) => {
    if (saveError instanceof ValidationError) {
      const json = JSON.parse(saveError.message);
      const msg = parseJourneyError(saveError.message);
      setShowErrorsModal(true);
      if (json.errors[0][1].name) {
        setError({ type: PublishFlowErrorType.save, message: msg });
      } else {
        setError(defaultSaveError);
      }
    } else {
      setShowErrorsModal(true);
      setError(defaultSaveError);
    }
  };

  const handleSave = () => {
    persistJourney({
      onSuccess: onSaveSuccess,
      onError: onSaveError,
    });
  };

  const handleSaveAndPublish = () => {
    persistJourney({
      onSuccess: navigateAndPublish,
      onError: onSaveError,
    });
  };

  const dismissErrorModal = () => {
    setShowErrorsModal(false);
    closePublishModal();
  };

  const onPublish = (name: Journey['name']) => {
    navigate(`/${programId}/app/journeys`);
    setFlashMessage({
      message: `${name} is processing. It will publish when processing is complete.`,
      severity: 'progress',
    });
  };

  const onPublishError = (payload: Error) => {
    const publishError = JSON.parse(payload.message) as JourneyErrors;
    if (publishError.graph) {
      openPublishErrorsModal();
    } else {
      setShowErrorsModal(true);
      setError(defaultPublishError);
    }

    closePublishModal();
  };

  const handlePublish = () => {
    publishJourney({
      onSuccess: onPublish,
      onError: onPublishError,
    });
  };

  const closeAndNavigate = React.useCallback(() => {
    window.history.replaceState({}, ''); // remove the location state
    closePublishModal();
  }, [closePublishModal]);

  const isPublishingDisabled =
    (errors?.graph !== undefined && Object.keys(errors.graph).length > 0) ||
    isValidating;

  return (
    <>
      <SaveButton
        isSaving={isSaving}
        status={persistanceStatus.cloudStatus}
        handleSave={handleSave}
      />
      <PrimaryButton
        disabled={isPublishingDisabled}
        onClick={openPublishModal}
      />
      {outOfDate && !showErrorsModal && showPublishModal && (
        <SaveJourneyModal
          action={handleSaveAndPublish}
          actionsDisabled={isSaving}
          cancel={closeAndNavigate}
        />
      )}
      {!outOfDate && !showErrorsModal && showPublishModal && (
        <PublishJourneyModal
          action={handlePublish}
          actionsDisabled={isPublishing}
          cancel={closeAndNavigate}
        />
      )}
      {showErrorsModal && (
        <ErrorJourneyModal
          action={dismissErrorModal}
          title={`${error?.type} error`}
          error={error?.message || defaultSaveError.message}
        />
      )}
    </>
  );
};
