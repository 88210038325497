import * as React from 'react';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { NavLink } from 'shared/NavLink';
import { MAIcon } from 'shared/MAIcon';
import { usePermissions } from 'contexts/permissions';
import { useCanvasBlockPanel } from 'contexts/publisher/compose/canvas-block-panel';
import { useCanvasFieldsPanel } from 'contexts/publisher/compose/canvas-fields-panel';
import {
  BlocksEditorContext,
  useBlocksEditor,
} from 'contexts/publisher/compose/blocks';
import { Flex } from 'DesignSystem/Layout/Flex';
import { titleCase } from 'utility/text';
import { useProductVocabulary } from 'hooks/useProductVocabulary';
import { useCanvasGlobalPanel } from 'contexts/publisher/compose/canvas-global-panel';
import { PublisherMode } from 'contexts/publisher';
import { DesignPermissionContext } from 'components/publisher/theme/Compose/DesignPermission';
import styles from '../compose.module.css';

export type menuItems = 'card' | 'preview' | 'personalizedFields';
export const Sidebar: React.FC<{
  blocksEditor: ReturnType<typeof useBlocksEditor>;
  omitMenuItems?: menuItems[];
}> = ({ blocksEditor, omitMenuItems, children }) => {
  const { data: newEditors } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.NewEditors'
  );
  const { data: personalizedFields } = useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Publish.PersonalizedFields'
  );

  const { PAGES } = useProductVocabulary();
  const blockPanel = useCanvasBlockPanel();
  const fieldsPanel = useCanvasFieldsPanel();
  const stylePanel = useCanvasGlobalPanel();
  const { publisherMode, select, selected } = React.useContext(
    BlocksEditorContext
  );
  const closePanels = React.useCallback(() => {
    select('');
    blockPanel.disable();
    if (personalizedFields?.value) fieldsPanel.disable();
    stylePanel.disable();
  }, [blockPanel, fieldsPanel, personalizedFields?.value, select, stylePanel]);

  const designPermission = React.useContext(DesignPermissionContext);

  const {
    permissions: { configureSetPersonalizedFields },
  } = usePermissions();

  if (!newEditors) return null; // wait until we know which editor to use...

  const noBlocksFound = blocksEditor.instances.length === 0;

  return newEditors.value ? (
    <Flex className={styles.compactTheme} start>
      {designPermission.canEdit && (
        <Flex column className={styles.menu}>
          {publisherMode !== PublisherMode.htmlFull && (
            <YaToolTip title="Blocks">
              <NavLink
                className="pendo-design-blocks"
                to="design"
                onClick={() => {
                  if (selected) {
                    setImmediate(() => {
                      select('');
                      blockPanel.enable();
                      if (personalizedFields?.value) fieldsPanel.disable();
                      stylePanel.disable();
                    });
                  } else {
                    blockPanel.toggle();
                    if (personalizedFields?.value) fieldsPanel.disable();
                    stylePanel.disable();
                  }
                }}
              >
                <MAIcon name="add_box" />
              </NavLink>
            </YaToolTip>
          )}
          <YaToolTip title="Style">
            <NavLink
              className="pendo-design-style"
              to="design"
              onClick={() => {
                if (publisherMode === PublisherMode.htmlFull) {
                  closePanels();
                  return;
                }

                if (selected) {
                  setImmediate(() => {
                    select('');
                    stylePanel.enable();
                    if (personalizedFields?.value) fieldsPanel.disable();
                    blockPanel.disable();
                  });
                } else {
                  stylePanel.toggle();
                  if (personalizedFields?.value) fieldsPanel.disable();
                  blockPanel.disable();
                }
              }}
            >
              <MAIcon name="palette" />
            </NavLink>
          </YaToolTip>
          {!omitMenuItems?.includes('card') && (
            <YaToolTip title="Cover">
              <NavLink
                className="pendo-design-cover"
                to="card"
                disabled={noBlocksFound}
              >
                <MAIcon name="subtitles" />
              </NavLink>
            </YaToolTip>
          )}
          {!omitMenuItems?.includes('preview') && (
            <YaToolTip title="Preview">
              <NavLink
                className="pendo-design-preview"
                to="preview"
                disabled={noBlocksFound}
              >
                <MAIcon name="visibility" />
              </NavLink>
            </YaToolTip>
          )}
          {!omitMenuItems?.includes('personalizedFields') &&
            personalizedFields?.value &&
            configureSetPersonalizedFields && (
              <YaToolTip title="Personalized Fields">
                <NavLink
                  className="pendo-design-personalized-fields"
                  to="design"
                  onClick={() => {
                    if (selected) {
                      setImmediate(() => {
                        select('');
                        fieldsPanel.enable();
                        stylePanel.disable();
                        blockPanel.disable();
                      });
                    } else {
                      fieldsPanel.toggle();
                      stylePanel.disable();
                      blockPanel.disable();
                    }
                  }}
                >
                  <MAIcon name="data_object" />
                </NavLink>
              </YaToolTip>
            )}
        </Flex>
      )}
      <main>{children}</main>
    </Flex>
  ) : (
    <div className={styles.theme}>
      {designPermission.canEdit && (
        <aside>
          <div className="page-header">
            {titleCase(PAGES.PUBLISHER.COMPOSE)}
          </div>
          <nav className={styles.nav}>
            <NavLink to="design">
              <MAIcon name="palette" />
              <span className={styles.text}>Style</span>
            </NavLink>
            <NavLink to="card" disabled={noBlocksFound}>
              <MAIcon name="subtitles" />
              <span className={styles.text}>Cover</span>
            </NavLink>
            <NavLink to="preview" disabled={noBlocksFound}>
              <MAIcon name="visibility" />
              <span className={styles.text}>Preview</span>
            </NavLink>
          </nav>
        </aside>
      )}
      <main>{children}</main>
    </div>
  );
};

function YaToolTip({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) {
  const [visible, setVisible] = React.useState(false);
  return (
    <div
      className={styles.YaToolTipWrapper}
      onMouseMove={() => setVisible(true)}
      onMouseOut={() => setVisible(false)}
      onBlur={() => setVisible(false)}
    >
      {children}
      {visible && <div className={styles.YaToolTip}>{title}</div>}
    </div>
  );
}
