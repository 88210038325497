import React, { useCallback } from 'react';
import { Section } from 'shared/SectionBlocks';
import { Settings } from 'models/publisher/settings';
import { Field, Fieldset } from 'shared/Fieldset';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { InitiativeSelect } from 'components/initiatives/InitiativeSelect';
import fieldsetStyles from 'shared/Fieldset/fieldset.module.css';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { AuthorSelect } from './Administration/AuthorSelect';
import styles from '../settings-editor.module.css';

export const Administration: React.FC = () => {
  const { contentPermissions, settings, update } = useSettings();
  const { canEdit } = contentPermissions;
  const { id: programId } = useProgram();

  const onAuthorChange = useCallback(
    (change: { value: Settings['contentAuthor'] }) => {
      update({ contentAuthor: change.value });
    },
    [update]
  );

  const onInitiativesChange = useCallback(
    (initiativeTags: Settings['initiativeTags']) => {
      update({ initiativeTags });
    },
    [update]
  );

  const useAuthorAliases = !!useFeatureFlagsQuery(
    programId,
    'Studio.Publish.AuthorAliases'
  ).data?.value;

  return (
    <Section title="Administration">
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label="Initiative(s)">
            <InitiativeSelect
              value={settings.initiativeTags}
              onChange={onInitiativesChange}
              disabled={!canEdit}
            />
          </Field>
        </div>
      </Fieldset>
      <Fieldset>
        <div className={fieldsetStyles.fieldGroupContainer}>
          <Field label={useAuthorAliases ? 'Author Alias' : 'Author'}>
            <AuthorSelect
              value={settings.contentAuthor}
              onChange={onAuthorChange}
              disabled={!canEdit}
            />
          </Field>
        </div>
      </Fieldset>
      <div className={styles.fieldset} />
    </Section>
  );
};
