import { includes } from 'utility/objectUtils';

export type BaseStepError<T extends string> = { [key in T]?: string[] };

const startStepErrorTypes = [
  'rootStepId',
  'trigger',
  'triggerCriterion',
  'scheduledTime',
] as const;
type StartStepErrorTypes = typeof startStepErrorTypes[number];
type StartStepErrors = BaseStepError<StartStepErrorTypes>;

const communicationStepErrorTypes = [
  'title',
  'designId',
  'designProcessingState',
  'acknowledgement',
] as const;
type CommunicationStepErrorTypes = typeof communicationStepErrorTypes[number];

const emailChannelErrorTypes = [
  'emailChannelAddress',
  'emailChannelSubject',
  'emailChannelPreview',
] as const;
type EmailChannelErrorTypes = typeof emailChannelErrorTypes[number];

const notificationCenterChannelErrorTypes = [
  'notificationCenterChannelTitle',
] as const;
type NotificationCenterChannelErrorTypes = typeof notificationCenterChannelErrorTypes[number];

export type CommunicationStepErrors = BaseStepError<
  CommunicationStepErrorTypes
> &
  BaseStepError<EmailChannelErrorTypes> &
  BaseStepError<NotificationCenterChannelErrorTypes> &
  BaseStepError<'pushChannelText'>;

const decisionStepErrorTypes = ['order', 'criterion'] as const;
type DecisionStepErrorTypes = typeof decisionStepErrorTypes[number];
type DecisionStepErrors = BaseStepError<DecisionStepErrorTypes>;

const delayStepErrorTypes = ['unit', 'quantity'] as const;
type DelayStepErrorTypes = typeof delayStepErrorTypes[number];
type DelayStepErrors = BaseStepError<DelayStepErrorTypes>;

export type StepErrors =
  | StartStepErrors
  | CommunicationStepErrors
  | DecisionStepErrors
  | DelayStepErrors;

export type JourneyErrors = {
  graph?: Record<string, StepErrors>;
  journey?: string;
};

export const isCommunicationStepError = (
  errors: unknown
): errors is CommunicationStepErrors => {
  return (
    !!errors &&
    !Object.keys(errors as CommunicationStepErrors).some(
      (key) =>
        !(
          includes(communicationStepErrorTypes, key) ||
          includes(emailChannelErrorTypes, key) ||
          includes(notificationCenterChannelErrorTypes, key) ||
          key === 'pushChannelText'
        )
    )
  );
};

export const isStartStepError = (errors: unknown): errors is StartStepErrors =>
  !!errors &&
  !Object.keys(errors as StartStepErrors).some((key) =>
    includes(startStepErrorTypes, key)
  );
