import React from 'react';
import { Pill } from 'DesignSystem/Components/Pill';
import { ChevronDown } from 'shared/icons';
import {
  HoverMenuDropdown,
  ItemType,
} from 'shared/hover-dropdown/HoverMenuDropdown';
import { useJourneyState } from 'contexts/journey';
import { MetricsMode } from 'models/journeys/journey';
import styles from './journey-metrics-type.module.css';

export const JourneyMetricsTypeMenu: React.FC = () => {
  const { metricsMode, setMetricsMode, currentGraph } = useJourneyState();

  if (!currentGraph?.isLive) return null;

  const menuItems: ItemType[] = [
    {
      title: 'Members',
      onClick: () => setMetricsMode(MetricsMode.members),
      active: metricsMode === MetricsMode.members,
    },
    {
      title: 'Engagement',
      onClick: () => setMetricsMode(MetricsMode.engagement),
      active: metricsMode === MetricsMode.engagement,
    },
  ];

  return (
    <HoverMenuDropdown
      openDelay="click"
      dropdownClassName="dropdown-align-right"
      menuItems={menuItems}
    >
      <Pill
        className={styles.pill}
        value={metricsMode}
        render={(value: MetricsMode) => (
          <>
            Metrics: {value}
            <ChevronDown />
          </>
        )}
      />
    </HoverMenuDropdown>
  );
};
