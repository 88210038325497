import React from 'react';
import { FAIcon } from 'shared/Icon/FAIcon';
import { ClickDropdown } from 'shared/ClickDropdown';
import cx from 'classnames';
import {
  Duration,
  DurationOptions,
  DurationUnit,
  DURATION_DAYS_BY_VALUE,
} from 'models/duration';
import styles from './component-layout.module.css';

function withLimit(duration: Duration): Duration {
  if (!duration.unit) return duration;
  const newDuration = duration;
  const value = newDuration.value || 1;
  newDuration.value = Math.min(Math.max(value), duration.unit.max);
  newDuration.label = `${newDuration.value} ${
    newDuration.unit && newDuration.unit.value[0]
  }`;
  return newDuration;
}

export const DurationInput: React.FC<{
  value?: Duration;
  onDurationChange: (d: Duration) => void;
  description: string;
  showLabel: boolean;
  disableDurationDropdown?: boolean;
}> = ({
  value,
  onDurationChange,
  description,
  showLabel,
  disableDurationDropdown,
}) => {
  const [isSelectorOpen, setSelectorState] = React.useState<boolean>(false);

  const handleDurationChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (value) {
        const d = event.target.value;
        const newValue = Math.max(d ? parseInt(d, 10) : 0, 0);
        const newDuration = { ...value, value: newValue };
        onDurationChange(withLimit(newDuration));
      }
    },
    [value, onDurationChange]
  );

  const decreaseDuration = React.useCallback(() => {
    if (value) {
      const d = value.value ? Math.max(value.value - 1, 1) : 0;
      const newDuration = { ...value, value: d };
      onDurationChange(withLimit(newDuration));
    }
  }, [value, onDurationChange]);

  const increaseDuration = React.useCallback(() => {
    if (value) {
      const d = value.value ? Math.max(value.value + 1, 1) : 1;
      const newDuration = { ...value, value: d };
      onDurationChange(withLimit(newDuration));
    }
  }, [value, onDurationChange]);

  const setUnit = React.useCallback(
    (d: DurationUnit) => {
      if (value) {
        const newDuration = { ...value, unit: d };
        onDurationChange(withLimit(newDuration));
      }
    },
    [value, onDurationChange]
  );

  const openSelector = React.useCallback(() => {
    setSelectorState(true);
    if (!value) {
      onDurationChange(DURATION_DAYS_BY_VALUE[14]);
    }
  }, [value, onDurationChange]);

  const closeSelector = React.useCallback(() => {
    setSelectorState(false);
  }, [setSelectorState]);

  const dropdownRenderProp = () => (
    <div className={styles.selectorWrapper} data-test="duration-input">
      <div className={styles.body}>
        <div className={styles.controls}>
          <button
            type="button"
            className={styles.durationButton}
            onClick={decreaseDuration}
          >
            <FAIcon name="minus" />
          </button>
          <input
            type="number"
            value={`${value?.value}`}
            onChange={handleDurationChange}
          />
          <button
            type="button"
            className={styles.durationButton}
            onClick={increaseDuration}
          >
            <FAIcon name="plus" />
          </button>
        </div>
        <div className={styles.labels}>
          {DurationOptions.map((d: DurationUnit) => (
            <button
              type="button"
              key={`duration-option-${d.value}`}
              onClick={() => setUnit(d)}
              className={d === value?.unit ? styles.selectedUnit : undefined}
            >
              {d.label}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <ClickDropdown
        dropdownRenderProp={dropdownRenderProp}
        onOpen={openSelector}
        onClose={closeSelector}
        isOpen={isSelectorOpen}
        disabled={disableDurationDropdown}
        left={-150}
        upward
      >
        <button
          type="button"
          className={cx(styles.label, {
            [styles.selected]: showLabel,
          })}
          disabled={disableDurationDropdown}
        >
          <span>{showLabel && value ? value.label : 'Select'}</span>
          {!isSelectorOpen && <FAIcon name="chevron-down" />}
          {isSelectorOpen && <FAIcon name="chevron-up" />}
        </button>
      </ClickDropdown>
    </div>
  );
};
