import React, { ChangeEvent, useCallback } from 'react';
import { useUniqueId } from 'hooks/useUniqueId';
import cx from 'classnames';
import { SearchInputType } from './types';
import styles from './search-inner.module.css';
import { Close } from '../icons';

export const SearchInputInnerButton: React.FC<SearchInputType> = ({
  value,
  onChange,
  onFocus,
  panelClassName,
  labelClassName,
  inputClassName,
  placeholder,
  totalRecords,
  hasClearButton = true,
  inputRef: suppliedInputRef,
  children,
  disabled,
}) => {
  const inputId = useUniqueId();
  const myRef = React.useRef<HTMLInputElement>(null);
  const inputRef = suppliedInputRef || myRef;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleTextInputFocus = React.useCallback(() => {
    if (!disabled) {
      onFocus?.();
    }
  }, [onFocus, disabled]);

  const clearSearch = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className={panelClassName}>
      <label className={cx(styles.label, labelClassName)} htmlFor={inputId}>
        <div>{children}</div>
        <input
          ref={inputRef}
          className={cx(styles.input, inputClassName)}
          id={inputId}
          type="text"
          value={value}
          onChange={handleChange}
          onFocus={handleTextInputFocus}
          placeholder={placeholder}
          data-test="TargetSelect"
          disabled={disabled}
        />
        {hasClearButton && value !== '' && (
          <button
            className={styles.closeButton}
            onClick={clearSearch}
            type="button"
          >
            <Close />
          </button>
        )}
      </label>
      {!!totalRecords && <span>{totalRecords} found</span>}
    </div>
  );
};
