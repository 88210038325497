import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useForYouSkip } from './useForYouSkip';
import { Delivery } from './sections/Delivery';
import { Schedule } from './sections/Schedule';
import { Priority } from './sections/Priority';

export const Setup: React.FC<RouteComponentProps> = () => {
  useForYouSkip();

  return (
    <>
      <Delivery />
      <Priority />
      <Schedule />
    </>
  );
};
