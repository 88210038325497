import * as React from 'react';
import { useNavigate } from '@reach/router';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useNavigationBlockerContext } from 'contexts/publisher/compose/navigation-blocker';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { useFlashMessage } from 'contexts/flasher';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useFooter } from 'hooks/useFooter';
import { useClickDropdown } from 'shared/ClickDropdown';
import { NavigationFooter } from 'shared/NavigationFooter';
import { LowerBar, UpperBar } from 'shared/NavigationBars/FixedBars';
import { CloseButton } from 'shared/NavigationBars/CloseButton';
import { TitleBar } from 'shared/NavigationBars/TitleBar';
import { isWeekendDuration } from 'utility/weekend-duration';
import { ScheduleContent } from './ScheduleContent';
import { SectionLinks } from './SectionLinks';
import { ContinueButton, SaveButton } from './Buttons';

export const SplitFooter: React.FC<
  React.ComponentProps<typeof NavigationFooter> & {
    mutateDraftItem?: () => void;
    disableSchedule?: boolean;
    createType?: 'campaign' | 'template';
    topicOnly?: boolean;
  }
> = (props) => {
  const {
    createType = 'campaign',
    nextPage,
    leftMenuItems,
    rightMenuAction,
    links,
    exitPath,
    exitHandler,
    title,
    canPerformAction,
    status,
    hideSubmit,
    hideMenu,
    isWorking,
    disableSchedule = false,
    saveDropdownDisabledMessage,
    mutateDraftItem,
    topicOnly,
  } = props;

  const {
    ClickDropdown: LeftDropdown,
    isOpen: leftMenuIsOpen,
  } = useClickDropdown();
  const { setFlashMessage } = useFlashMessage();
  const footer = useFooter(props);
  const navigate = useNavigate();
  const { blocker } = useNavigationBlockerContext({ noThrow: true });
  const { contentPermissions, settings } = useSettings();
  const { canEdit } = contentPermissions;

  const { duration, optimizedDeliveryEnabled, retries } = settings;
  const startDate = settings.publishedAt;
  const formattedDuration = duration
    ? { [duration.unit.value]: duration.value }
    : {};
  const endDate =
    startDate && duration ? startDate.plus(formattedDuration) : startDate;
  let isWeekend = false;
  if (startDate && endDate) {
    isWeekend = isWeekendDuration(startDate.toMillis(), endDate.toMillis());
  }

  const handleClose = React.useCallback(() => {
    if (exitHandler) exitHandler();
    else if (exitPath) navigate(exitPath);
  }, [navigate, exitPath, exitHandler]);

  const usingNewOrchestratePages = !!useFeatureFlagsQuery(
    useProgram().id,
    'Studio.Orchestrate.NewPages'
  ).data?.value;

  const usingGuaranteedDelivery = !!useFeatureFlagsQuery(
    useProgram().id,
    'Orchestrate.GuaranteedDelivery.Enabled'
  ).data?.value;

  return (
    <>
      <UpperBar>
        <TitleBar
          createType={createType}
          DropdownComponent={LeftDropdown}
          leftMenuItems={leftMenuItems}
          title={title}
          leftMenuIsOpen={leftMenuIsOpen}
          canEdit={canEdit}
          hideMenu={hideMenu}
        />
        <Flex end>
          <SaveButton
            visible={
              !!(
                (canEdit || saveDropdownDisabledMessage) &&
                rightMenuAction &&
                status &&
                isWorking !== undefined
              )
            }
            isWorking={!!isWorking}
            status={status ?? ''}
            action={rightMenuAction ?? (() => {})}
            disabledMessage={saveDropdownDisabledMessage}
          />
          <CloseButton handleClose={handleClose} />
        </Flex>
      </UpperBar>

      <LowerBar>
        <SectionLinks links={links} />
        <Flex>
          {usingNewOrchestratePages && (
            <ScheduleContent
              saveAsDraft={mutateDraftItem}
              disabled={disableSchedule}
            />
          )}
          <ContinueButton
            visible={!hideSubmit}
            nextPage={nextPage}
            blocker={blocker}
            footer={footer}
            canPerformAction={canPerformAction}
            setFlashMessage={setFlashMessage}
            isWeekend={isWeekend}
            optimizedDeliveryEnabled={optimizedDeliveryEnabled}
            usingGuaranteedDelivery={usingGuaranteedDelivery}
            retries={retries}
            topicOnly={topicOnly}
          />
        </Flex>
      </LowerBar>
    </>
  );
};
